import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './rect';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter as Router, Route, Link, Routes, useParams } from 'react-router-dom';

import Login from './login';
import Forgotpass from './forgotpass.js';
import Resetpass from './resetpass.js';
import Newpass from './newpass.js';
import Setpass from './setpass.js';
import Profilepage from './profile.js';
import Productpage from './product.js';
import Badgereceive from './Badgereceive.js';
import AnimeDetailPage from './AnimeDetailPage.js';
import CharDetailPage from './CharDetailPage.js';
import WeekepDetailPage from './WeekepDetailPage.js'; // صفحة تفاصيل الأنمي
import WatchPage from './WatchPage.js';
import Callbacktwt from './callbacktwt.js';
import Pentagonallist from './pentagonallist.js';
import Mohankeenawards from './mohankeenawards.js';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      
      <Routes>
          <Route exact path="/" element={<App />} />
          <Route path="/login" element={<Login />} />
          
          <Route path="/forgotpassword" element={<Forgotpass />} />
          <Route path="/resetpassword" element={<Resetpass />} />
          <Route path="/newpassword" element={<Newpass />} />
          <Route path="/api/setpassword" element={<Setpass />} />
          <Route path="/profile" element={<Profilepage />} />
          {/*
          <Route path="/product" element={<Productpage />} />
          */}
          <Route path="/anime/:id/:slug" element={<AnimeDetailPage />} />
          <Route path="/char/:id/:slug" element={<CharDetailPage />} />
          <Route path="/weekep/:id/:slug" element={<WeekepDetailPage />} />
          <Route path="/watchwithfriend/:id/:slug" element={<WatchPage />} />
         
          <Route path="/vote" element={<Callbacktwt />} />
          <Route path="/pentagonallist" element={<Pentagonallist />} />
          <Route path="/mohankeenawards" element={<Mohankeenawards />} />


        </Routes>
         
     

        
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
