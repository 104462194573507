import logo from './logo14400.png';
//import './components/animecard.css';
import './components/logincard.css';
import Forgotpass from './forgotpass.js';


//import './App.css';

//import Signup from './signup.js';
//import Button from './button.js';

//import { anime } from "../../backend/server.js"; // import Anime model from animeModel.js


import axios from './axiosConfig.js'; // استيراد axios من ملف الإعدادات
import './assets/css/bootstrap.min.css'; // استيراد ملف CSS من Bootstrap
import 'animate.css/animate.min.css';
//import './assets/js/bootstrap.bundle.js';
//import bootstrap from 'bootstrap';




import React, { useEffect, useState, Component } from 'react';
import api from './api';
import Cookies from 'js-cookie'; // استيراد مكتبة js-cookie
import ReactDOM from 'react-dom';
import {BrowserRouter as Router, Route, Link, Routes, Navigate} from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
//import { BrowserRouter, Route, Switch } from "react-router-dom";
//import { Button } from 'bootstrap';
//import { Offcanvas } from 'bootstrap';
import { Navbar, Offcanvas, Card, Button, CardDeck, CardGroup, CardImg, Dropdown, Form, Alert} from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { keyframes } from "styled-components";
//import keyframe from 'keyframe';
//import { Animated, Easing, keyframe } from 'react-native';
//import LottieView from 'lottie-react-native';
import {
  useScene,
  useSceneItem,
  useFrame,
  useNowFrame,
} from "react-scenejs";
import { Animate, AnimateKeyframes, AnimateGroup } from "react-simple-animate";



//import { Offcanvas } from 'react-bootstrap';

import _ from 'lodash';
//import Switch from "react-switch";
import { Tabs, Tab, Box, TabPanel, styled } from '@mui/material';


//import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
//import 'react-tabs/style/react-tabs.css';


class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogin: true,
      username: "",
      email: "",
      password: "",
      error: "",
      usernameOrEmail: "",
      
      loading: false,
      user: null,
      showPassword: false,
      success: "",
      submitted: false,
      submittedd: false,
    };
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleLoginSubmit = async (e) => {
    e.preventDefault();
    this.setState({ error: "", success: "", submitted: true });

    const { usernameOrEmail, password } = this.state;

    // تعريف تعبير منتظم للبريد الإلكتروني
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    // التحقق من حقول النموذج
    if (usernameOrEmail.trim() === "" || password.trim() === "") {
      this.setState({ error: "يرجى ملء جميع الحقول" });
      return;
    }

    if (!emailRegex.test(usernameOrEmail) && usernameOrEmail.length < 3) {
      this.setState({ error: "يرجى إدخال اسم مستخدم أو بريد إلكتروني صحيح" });
      return;
    }

    try {
      const response = await axios.post("/api/login", { usernameOrEmail, password });
      // قم بمعالجة استجابة الطلب هنا
      console.log(response.data); // سجل بيانات الاستجابة في وحدة التحكم (console)

      if (response.data.success) {
        // قم بإظهار رسالة نجاح هنا
        this.setState({ success: "تم التعرف على هويه المحنك بنجاح" });
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('username', response.data.user.username);
        localStorage.setItem('userId', response.data.user.id);

          // تخزين البيانات في الكوكيز
          Cookies.set('token', response.data.token, { expires: 30 }); // الكوكيز تنتهي صلاحيتها بعد 7 أيام
          Cookies.set('username', response.data.user.username, { expires: 30 });
          Cookies.set('userId', response.data.user.id, { expires: 30 });
       
        // قم بالانتقال إلى صفحة resetpassword
        if (this.state.success) {
           this.props.navigate('/');
          }
      } else {
        // قم بإظهار رسالة فشل هنا
        this.setState({ error: "فشل تسجيل الدخول" });
      }
    } catch (error) {
      console.error(error);
      // قم بمعالجة الأخطاء هنا
      this.setState({ error: error.response.data.message });
    }
  };


  handleRegisterSubmit = async (e) => {
    e.preventDefault();
    this.setState({ error: "", success: "", submittedd: true });

    const { username, password, email } = this.state;

    // تعريف تعبير منتظم للبريد الإلكتروني
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    // التحقق من حقول النموذج
    if (username.trim() === "" || password.trim() === "" || email.trim() === "") {
      this.setState({ error: "يرجى ملء جميع الحقول" });
      return;
    }

    if (!emailRegex.test(email)) {
      this.setState({ error: "يرجى إدخال بريد إلكتروني صحيح" });
      return;
    }

    if (password.length < 6) {
      this.setState({ error: "كلمة المرور يجب أن تكون على الأقل 6 أحرف" });
      return;
    }

    try {
      const response = await axios.post("/api/register", { username, password, email });
      // قم بمعالجة استجابة الطلب هنا
      console.log(response.data); // سجل بيانات الاستجابة في وحدة التحكم (console)

      if (response.data.success) {
        this.setState({ success: "نتشرف بانضمام محنك ذو هوية مخضرمة مثلك إلينا." });
        // قم بإظهار رسالة نجاح هنا
       
        localStorage.setItem('token', response.data.token);
        // قم بإرسال المستخدم إلى صفحة الصفحة الرئيسية أو أي صفحة تريدها
        
      } else {
        // قم بإظهار رسالة فشل هنا
        this.setState({ error: "فشل التسجيل" });
      }
    } catch (error) {
      console.error(error);
      // قم بمعالجة الأخطاء هنا
      this.setState({ error: error.response.data.message });
    }
  };



  // تعريف دالة المصادقة
authenticate = async () => {
  // تحديث الحالة لإظهار التحميل
  this.setState({ loading: true });
  try {
    // إعادة توجيه المستخدم إلى المسار /api/authenticate/google
    window.location.href = "https://mohankeen.com/api/authenticate/google";
  } catch (error) {
    // معالجة الأخطاء وتحديث الحالة بالخطأ الذي حدث
    this.setState({ error: error.message, loading: false });
  }
};




  render() {
    const { isLogin, username, email, password, error, usernameOrEmail, user, loading, success,  submitted,   submittedd } = this.state;

    return (
      <HelmetProvider>

<Helmet>
  <meta charSet="utf-8" />
  <meta name="viewport" content="width=device-width, initial-scale=1" />

  <title>تسجيل الدخول - MOHANKEEN</title>
  <meta name="description" content="تسجيل الدخول إلى موقع MOHANKEEN للوصول إلى جميع الميزات والمحتوى المميز." />
  <meta property="og:title" content="تسجيل الدخول - MOHANKEEN" />
  <meta property="og:description" content="تسجيل الدخول إلى موقع MOHANKEEN للوصول إلى جميع الميزات والمحتوى المميز." />
  <meta property="og:image" content="https://i.imgur.com/D4N14v1.jpg" />
  <meta property="og:type" content="website" />
  <meta property="og:url" content="https://mohankeen.com/login" />
  <meta name="keywords" content="تسجيل الدخول, MOHANKEEN, محنكين, حساب, دخول" />
  <meta name="author" content="المحنكين" />
  <meta property="og:site_name" content="MOHANKEEN" />
  <meta property="og:locale" content="ar_AR" />
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="تسجيل الدخول - MOHANKEEN" />
  <meta name="twitter:description" content="تسجيل الدخول إلى موقع MOHANKEEN للوصول إلى جميع الميزات والمحتوى المميز." />
  <meta name="twitter:image" content="https://i.imgur.com/D4N14v1.jpg" />
  <link rel="canonical" href="https://mohankeen.com/login" />
  <meta name="robots" content="noindex, nofollow" />
</Helmet>
      <div>
<title>المحنكين</title>{/* العنوان */}


<div id="boxq">
        <img 
        className="logohomex"
          src="https://i.imgur.com/4prsJLG.png" 
          alt="شعار الموقع" 
         
        />
      </div>
<div className="image-container">
        <img className="profile-image" src="https://i.imgur.com/0NHdvjQ.png" alt="صورة الملف الشخصي" />
        <div className="description-box">
        <p>
  المعلومات اللتي تريدها عن موقع المحنكين:
</p>

<ul>
  <li>
    <strong>الميزة الأولى:</strong> يهدف الموقع إلى تقييم كل شيء في عالم الانمي، بدءًا من الشخصيات والأنمي نفسه وصولًا إلى المقيمين وصناع المحتوى .
  </li>
  <li>
    <strong>الميزة الثانية:</strong> يمكنك تقييم العمل الواحد بأكثر من طريقة وأكثر من مرة بااستخدام التعليقات العلنيه. تزيد قيمة مرتبه الانمي كلما زاد عدد المتفقين معك.
  </li>
  <li>
    <strong>الميزة الثالثة:</strong> لا يعمل الموقع بنظام المتابعين، مما يعني أن صوتك سيصل إلى العالم بسهولة. يمكنك البحث عن اشخاص لتشاهد معهم الانمي وسيقترح لك الموقع المتشابهين معك بالذوق.
  </li>
</ul>
        </div>
      </div>

<div className="carb" style={{ backgroundImage: 'url("https://i.imgur.com/6MYR7Dp.png")', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)' }}>
        <div className="carb-body">
          <h5 className="carb-title">{isLogin ? "تسجيل الدخول" : "إنشاء حساب"}</h5>
          
          {isLogin ? (
            <Form onSubmit={this.handleLoginSubmit}>
              <Form.Group className="mb-3" controlId="usernameOrEmail">
                <Form.Label>اسم المستخدم أو البريد الإلكتروني</Form.Label>
                <Form.Control type="text" placeholder="أدخل اسم المستخدم أو البريد الإلكتروني" value={usernameOrEmail} onChange={(e) => this.setState({ usernameOrEmail: e.target.value })} required />
              </Form.Group>

              <Form.Group className="mb-3" controlId="password">
                <Form.Label>كلمة المرور</Form.Label>
                <Form.Control type={this.state.showPassword ? "text" : "password"} placeholder="أدخل كلمة المرور" value={password} onChange={(e) => this.setState({ password: e.target.value })} required />
                <Button
  type="button"
  onClick={() =>
    this.setState({ showPassword: !this.state.showPassword })
  }
>
  {this.state.showPassword ? (
   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-fill" viewBox="0 0 16 16">
   <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"/>
   <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"/>
 </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-slash" viewBox="0 0 16 16">
  <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z"/>
  <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z"/>
  <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z"/>
</svg>
  )}
</Button>
              </Form.Group>
              {error && <Alert variant="danger">{error}</Alert>}
              <Button variant="primary" type="submit">
                تسجيل الدخول
              </Button>
              {this.state.submitted && this.state.success && (
          <Navigate to="/" /> // استخدام Navigate في حالة نجاح التقديم
        )}
             
              {success && <Alert variant="success">{success}</Alert>}
            </Form>
          ) : (
            <Form onSubmit={this.handleRegisterSubmit}>
              <Form.Group className="mb-3" controlId="email">
                <Form.Label>البريد الإلكتروني</Form.Label>
                <Form.Control type="email" placeholder="أدخل البريد الإلكتروني" value={email} onChange={(e) => this.setState({ email: e.target.value })} required />
              </Form.Group>

              <Form.Group className="mb-3" controlId="username">
                <Form.Label>اسم المستخدم</Form.Label>
                <Form.Control type="text" placeholder="أدخل اسم المستخدم" value={username} onChange={(e) => this.setState({ username: e.target.value })} required />
              </Form.Group>

              <Form.Group className="mb-3" controlId="password">
                <Form.Label>كلمة المرور</Form.Label>
                <Form.Control type={this.state.showPassword ? "text" : "password"} placeholder="أدخل كلمة المرور" value={password} onChange={(e) => this.setState({ password: e.target.value })} required />
                <Button
  type="button"
  onClick={() =>
    this.setState({ showPassword: !this.state.showPassword })
  }
>
  {this.state.showPassword ? (
   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-fill" viewBox="0 0 16 16">
   <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"/>
   <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"/>
 </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-slash" viewBox="0 0 16 16">
  <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z"/>
  <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z"/>
  <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z"/>
</svg>
  )}
</Button>
              </Form.Group>
              {error && <Alert variant="danger">{error}</Alert>}
              <Button variant="primary" type="submit">
                إنشاء حساب
              </Button>
              {success && <Alert variant="success">{success}</Alert>}
            </Form>
          )}

          <div className="mt-3">
            <Form.Check
              type="switch"
              id="toggleLogin"
              label={isLogin ? "هل تريد إنشاء حساب جديد؟" : "تسجيل الدخول"}
              checked={isLogin}
              onChange={() => this.setState({ isLogin: !isLogin })}
            />
          </div>

          <div>
        
        <Link to="/forgotpassword"><button className="forgotpass" style={{position: 'fixed', top: '501.5px', right: '120px', zIndex: '9999'}}> 
              هل نسيت كلمه المرور؟ 
            </button>
            </Link>
       
    </div>
    <div>
    <button onClick={this.authenticate} className='google' style={{
  display: 'inline-block',
  backgroundColor: 'transparent',
  border: 'none',
  cursor: 'pointer',
  padding: 0,
  marginLeft: '65px',
  marginTop: '125px',
  backgroundRepeat: 'no-repeat', backgroundSize: 'cover', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)'
}}>
  <img  src="https://i.imgur.com/7lwkOEd.png" alt="Google sign in button" style={{
    width: '220px',
    height: 'auto',
    verticalAlign: 'middle',
    objectfit: 'contain',
    pointerEvents: 'none',
  }} />
</button>
</div>
        </div>
      </div>
      </div>
      </HelmetProvider>
    );
  }
}

export default Login;