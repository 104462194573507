import logo from './logo.svg';
import './components/animecard.css';
import './components/animedetails.css';
import './components/watchdetails.css';
import './App.css';


//import { anime } from "../../backend/server.js"; // import Anime model from animeModel.js


import axios from 'axios';
import './assets/css/bootstrap.min.css'; // استيراد ملف CSS من Bootstrap
import 'animate.css/animate.min.css';
//import './assets/js/bootstrap.bundle.js';
//import bootstrap from 'bootstrap';




import React, { useEffect, useState } from 'react';
import api from './api';
import ReactDOM from 'react-dom';
//import { Button } from 'bootstrap';
//import { Offcanvas } from 'bootstrap';
import { Navbar, Offcanvas, Card, Button, CardDeck, CardColumns, CardGroup, CardImg, Dropdown, DropdownButton, DropdownItem, DropdownMenu} from "react-bootstrap";
import { NavLink, Link } from "react-router-dom";
import Cookies from 'js-cookie';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { keyframes } from "styled-components";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { initializePaddle, Paddle } from '@paddle/paddle-js';
//import keyframe from 'keyframe';
//import { Animated, Easing, keyframe } from 'react-native';
//import LottieView from 'lottie-react-native';
import {
  useScene,
  useSceneItem,
  useFrame,
  useNowFrame,
} from "react-scenejs";
import { Animate, AnimateKeyframes, AnimateGroup } from "react-simple-animate";



//import { Offcanvas } from 'react-bootstrap';

import _ from 'lodash';
import Switch from "react-switch";
import { Tabs, Tab } from '@material-ui/core';
import withParams from './withParams.js'; // تأكد من مسار الاستيراد الصحيح
import { Helmet, HelmetProvider } from 'react-helmet-async';

import slugify from 'slugify';






class WatchPage extends React.Component {

  constructor(props) {
    super(props);
    // نضع قيمة الحالة الأولية في this.state
    this.state = {
      showBottom: false,
      showModal: false,
      message: '',
      animeData: [], // an empty array to store the anime data from the database
      isMobile: window.innerWidth <= 991, // تعيين القيمة الافتراضية للموبايل
      isActive: true,
      selectedGenre: 'عرض كل الانميات بكل التصنيفات', // التصنيف المختار
      
      animeId: "",
      commentText: '',
      commentType: '',
      
      
       //paypal
      //message: '',
      initialOptions: {
        'client-id': 'AWUoCwyW6i6htrGKgZhZm1QhjgPIb_UJxFu9GMg8Y1hAJ5AkS80VoUGHM9QO5lCwM0G1ddz8jPYHsZNt',
        'enable-funding': '',
        'disable-funding': 'paylater,venmo,card',
        'data-sdk-integration-source': 'integrationbuilder_sc',
      },
    
      
        //paddle
        
        name: '',
        productName: '',
        product: '',
       
        paddle: '',

      
        commentText: '',
        commentType: 'praiser',
        showExpandes: false,
        showExpandex: false,
        selectedAnime: "",
        userId: "", // استبدل 'USER_ID' بمعرف المستخدم الفعلي
        rating: "",
    note: '',
    randomNote: '',
    randomRating: '',
    randomUsername: '',
    randomUserProfileImage: '',

    isHovered: false,
    selectedAnimedatail: null,


    posts: [],
    loading: true,
    
    promoText: '',
    platformLink: '',
    currentMembers: 0,

    ad: null,
    isLoading: true,
    };

    this.toggleCards = this.toggleCards.bind(this); // bind toggleCards to the component
    this.textareaRef = React.createRef();
    
  }

  /*
  // دالة لجلب بيانات الأنمي من المسار /anime
  fetchData = async () => {
    try {
      const { id } = this.props.params;
      const response = await axios.get('/anime', { params: { id } });
      if (id) {
        this.setState({ animeData: [response.data] });
      } else {
        if (Array.isArray(response.data)) {
          this.setState({ animeData: response.data });
        } else {
          console.error('Expected array but got:', response.data);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
*/

fetchData = async () => {
  try {
    const { id } = this.props.params;
    const response = await axios.get('/anime', { params: { id } });

    if (id) {
      // إذا كان هناك معرف في رابط الصفحة، عرض مستند الأنمي الخاص بهذا المعرف
      const animeDataWithSlug = {
        ...response.data,
        slug: slugify(response.data.name, { lower: true })
      };
      this.setState({ animeData: [animeDataWithSlug] });
    } else {
      // إذا لم يكن هناك معرف، عرض جميع مستندات الأنمي مع إضافة تعريف slug
      if (Array.isArray(response.data)) {
        const animeDataWithSlugs = response.data.map(anime => ({
          ...anime,
          slug: slugify(anime.name, { lower: true })
        }));
        this.setState({ animeData: animeDataWithSlugs });
      } else {
        console.error('Expected array but got:', response.data);
      }
    }
  } catch (error) {
    console.error(error);
  }
};
  

  // use componentDidMount to fetch data from node server when the component mounts
  componentDidMount() {
    // use api.get to send a get request to /api endpoint
    api.get('/api')
      .then((res) => {
        // if the request is successful, set the message state to the data from the response
        this.setState({ message: res.data.message });
      })
      .catch((err) => {
        // if there is an error, log it and set the message state to an error message
        console.error(err);
        this.setState({ message: 'Something went wrong' });
      });



      window.addEventListener('resize', this.handleWindowResize);

     this.fetchData();
     this.fetchWatchWithFriendsData();
     this.fetchAd();

      const userId = Cookies.get('userId') || localStorage.getItem('userId');
      
      if (userId) {
        const cleanedUserId = userId.replace(/^j:/, '').replace(/^"|"$/g, '').trim();
    this.setState({ userId: cleanedUserId });
      }
       
      }

      componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowResize);
      }
    
      handleWindowResize = () => {
        const isMobile = window.innerWidth <= 991;
        this.setState({ isMobile });
      };
    
      // دالة لجلب بيانات الأنمي من المسار /anime
      
    
      // define a function called handleClick that calls the fetchData function when the button is clicked
      handleClick = () => {
        this.fetchData();
        this.setState({ isCharActive: false, dataType: 'anime' });
      };

      
  handleMouseEnter = () => {
    this.setState({ isActive: false });
  };

  handleMouseLeave = () => {
    this.setState({ isActive: true });
  };
  // create a method to handle the api call
  handleApi(e) {
    // do something with the event or the api
  
  }

  // لتغيير قيمة الحالة ، نستخدم this.setState
  // ونمرر له دالة تستلم حالة سابقة وخصائص كمعاملات
  // وترجع كائنًا يحتوي على التغييرات التي نريدها
  handleShowBottom = () => {
    this.setState((prevState, props) => ({
      showBottom: true
    }));
  };

  handleCloseBottom = () => {
    this.setState((prevState, props) => ({
      showBottom: false
    }));
  };

  
  
   // لتغيير قيمة الحالة ، نستخدم this.setState
  // ونمرر له دالة تستلم حالة سابقة وخصائص كمعاملات
  // وترجع كائنًا يحتوي على التغييرات التي نريدها
  handleShow = () => {
    this.setState((prevState, props) => ({
      show: true
    }));
  };

  handleClose = () => {
    this.setState((prevState, props) => ({
      show: false
    }));
  };

   // دالة للتبديل بين إظهار وإخفاء القائمة وتغيير اتجاه الزر
    // دالة للتبديل بين إظهار وإخفاء القائمة وتغيير اتجاه الزر
    
    toggleModal = () => {
      this.setState((prevState) => ({
        showModal: !prevState.showModal
      }));
    }

    toggleCards() {
      this.setState((prevState) => ({
        showCards: !prevState.showCards, // استخدام المعامل ! للحصول على القيمة المعاكسة
      }));
    }



    toggleChar = () => {
      this.setState((prevState) => ({
        isCharActive: !prevState.isCharActive,
        dataType: prevState.isCharActive ? 'anime' : 'character',
      }));
    };


    handleGenreChange = (event) => {
      // تحديث التصنيف المختار عند تغييره في قائمة التصنيفات
      const selectedGenre = event.currentTarget.getAttribute('value');
      this.setState({ selectedGenre });
    };
  



  
  //تعليقات علنيه
  submitComment = async () => {
    const { selectedAnime, commentText, commentType } = this.state;

    try {
      const response = await fetch('/public-comment', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          //'userId': 'your-user-id' // Replace with actual user ID or use cookies
        },
        body: JSON.stringify({ animeId: selectedAnime, commentText, commentType })
      });

      const data = await response.json();

      if (response.ok) {
        alert(data.message);
      } else {
        alert(data.message || 'An error occurred');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred while submitting your comment');
    }
  };
//تعليق علني فضي
  submitCommentsilverpaypal = async () => {
    const { selectedAnime, commentText, commentType } = this.state;

    try {
      const response = await fetch('/api/orders/:orderID/callbackpaypallsilver', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          //'userId': 'your-user-id' // Replace with actual user ID or use cookies
        },
        body: JSON.stringify({ animeId: selectedAnime, commentText, commentType })
      });

      const data = await response.json();

      if (response.ok) {
        alert(data.message);
      } else {
        alert(data.message || 'An error occurred');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred while submitting your comment');
    }
  };

  submitCommentsilverpaddle = async () => {
    const { selectedAnime, commentText, commentType } = this.state;

    try {
      const response = await fetch('/paddlecallback-silvercomment', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          //'userId': 'your-user-id' // Replace with actual user ID or use cookies
        },
        body: JSON.stringify({ animeId: selectedAnime, commentText, commentType })
      });

      const data = await response.json();

      if (response.ok) {
        alert(data.message);
      } else {
        alert(data.message || 'An error occurred');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred while submitting your comment');
    }
  };
  //تعليق علني ذهبي
  submitCommentgoldenpaypal = async () => {
    const { selectedAnime, commentText, commentType } = this.state;

    try {
      const response = await fetch('/api/orders/:orderID/callbackpaypallgolden', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          //'userId': 'your-user-id' // Replace with actual user ID or use cookies
        },
        body: JSON.stringify({ animeId: selectedAnime, commentText, commentType })
      });

      const data = await response.json();

      if (response.ok) {
        alert(data.message);
      } else {
        alert(data.message || 'An error occurred');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred while submitting your comment');
    }
  };

  submitCommentgoldenpaddle = async () => {
    const { selectedAnime, commentText, commentType } = this.state;

    try {
      const response = await fetch('/paddlecallback-goldencomment', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          //'userId': 'your-user-id' // Replace with actual user ID or use cookies
        },
        body: JSON.stringify({ animeId: selectedAnime, commentText, commentType })
      });

      const data = await response.json();

      if (response.ok) {
        alert(data.message);
      } else {
        alert(data.message || 'An error occurred');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred while submitting your comment');
    }
  };
    
   //دوال باي بال 
  createOrdersilver = async () => {
    try {
      // إنشاء قيمة عشوائية لـ reference
      const response = await fetch("/api/orderssilver", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          cart: [
            {
              id: "7b92603e77ed48968e785dea20504749",
              quantity: "1",
            },
          ],
        }),
      });
  
      // تحقق من حالة الاستجابة
      if (response.status === 401) {
        alert("YOU MUST LOGIN FIRST");
        // يمكنك إعادة توجيه المستخدم إلى صفحة تسجيل الدخول هنا إذا أردت
        return;
      }
  
      const orderData = await response.json();
  
      if (orderData.id) {
        return orderData.id;
      } else {
        const errorDetail = orderData?.details?.[0];
        const errorMessage = errorDetail
          ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
          : JSON.stringify(orderData);
  
        throw new Error(errorMessage);
      }
    } catch (error) {
      console.error("Error checking user login:", error);
      this.setState({
        message: `Could not initiate PayPal Checkout...${error.message}`,
      });
    }
  };


  createOrdergolden = async () => {
    try {
      // إنشاء قيمة عشوائية لـ reference
      const response = await fetch("/api/ordersgolden", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          cart: [
            {
              id: "7b92603e77ed48968e785dea20504749",
              quantity: "1",
            },
          ],
        }),
      });
  
      // تحقق من حالة الاستجابة
      if (response.status === 401) {
        alert("YOU MUST LOGIN FIRST");
        // يمكنك إعادة توجيه المستخدم إلى صفحة تسجيل الدخول هنا إذا أردت
        return;
      }
  
      const orderData = await response.json();
  
      if (orderData.id) {
        return orderData.id;
      } else {
        const errorDetail = orderData?.details?.[0];
        const errorMessage = errorDetail
          ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
          : JSON.stringify(orderData);
  
        throw new Error(errorMessage);
      }
    } catch (error) {
      console.error("Error checking user login:", error);
      this.setState({
        message: `Could not initiate PayPal Checkout...${error.message}`,
      });
    }
  };
  

  //paddle

   checkUserLogin = async () => {
    try {
      const response = await axios.get('/openpaddlepay');
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        alert("YOU MUST LOGIN FIRST");
        // يمكنك إعادة توجيه المستخدم إلى صفحة تسجيل الدخول هنا إذا أردت
       
      } else {
        console.error("Error checking user login:", error);
      }
      return null;
    }
  };


  openCheckoutsilver = async () => {
    const { paddle, product } = this.state;
    if (paddle && product) {
      const userId = await this.checkUserLogin();
      if (userId) {
        paddle.Checkout.open({
          items: [{ priceId: "pri_01j23kepkbj6k3jqjw3q5p5a9c", quantity: 1 }],
        });
      }
    }
  };
  loadProductDetailssilver = async () => {
    try {
      const response = await axios.get('/get-product-details-silvercomment'); // افترض أن لديك endpoint للحصول على تفاصيل المنتج
      this.setState({ product: response.data, error: null });
    } catch (error) {
      this.setState({ error: 'Failed to load product details' });
    }
  };

  initializePaddlesilver = async () => {
    try {
      const paddleInstance = await initializePaddle({
        environment: 'sandbox', // أو 'production' حسب البيئة التي تعمل بها
        token: 'test_1c2392837d3e38f245ec5a08e8b', // استبدل هذا بالتوكن الخاص بك
        eventCallback: async (data) => {
          switch(data.name) {
            case "checkout.loaded":
              console.log("Checkout opened");
              break;
            case "checkout.customer.created":
              console.log("Customer created");
              break;
            case "checkout.completed":
              console.log("Checkout completed");
               this.submitCommentsilverpaddle();
              break;
            default:
              console.log(data);
          }
        }
      });
      this.setState({ paddle: paddleInstance });
    } catch (error) {
      console.error('Failed to initialize Paddle:', error);
    }
  };


  openCheckoutgolden = async () => {
    const { paddle, product } = this.state;
    if (paddle && product) {
      const userId = await this.checkUserLogin();
      if (userId) {
        paddle.Checkout.open({
          items: [{ priceId: "pri_01j23kgqkqe828edee6kr4j1n0", quantity: 1 }],
        });
      }
    }
  };
  loadProductDetailsgolden = async () => {
    try {
      const response = await axios.get('/get-product-details-goldencomment'); // افترض أن لديك endpoint للحصول على تفاصيل المنتج
      this.setState({ product: response.data, error: null });
    } catch (error) {
      this.setState({ error: 'Failed to load product details' });
    }
  };

  initializePaddlegolden = async () => {
    try {
      const paddleInstance = await initializePaddle({
        environment: 'sandbox', // أو 'production' حسب البيئة التي تعمل بها
        token: 'test_1c2392837d3e38f245ec5a08e8b', // استبدل هذا بالتوكن الخاص بك
        eventCallback: async (data) => {
          switch(data.name) {
            case "checkout.loaded":
              console.log("Checkout opened");
              break;
            case "checkout.customer.created":
              console.log("Customer created");
              break;
            case "checkout.completed":
              console.log("Checkout completed");
               this.submitCommentgoldenpaddle();
              break;
            default:
              console.log(data);
          }
        }
      });
      this.setState({ paddle: paddleInstance });
    } catch (error) {
      console.error('Failed to initialize Paddle:', error);
    }
  };

  incrementViewCount = async () => {
    try {
    const response = await fetch('/public-comment/view', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
  } catch (error) {
    console.error('Error:', error);
  } 
};



handleCommentTextChange = (event) => {
  const { value } = event.target;
  this.setState({ commentText: value }, this.adjustTextareaHeight);
};

adjustTextareaHeight = () => {
  const textarea = this.textareaRef.current;
  textarea.style.height = 'auto'; // إعادة تعيين الارتفاع
  textarea.style.height = `${textarea.scrollHeight}px`; // ضبط الارتفاع بناءً على المحتوى
};

handleCommentTypeChange = () => {
  this.setState((prevState) => ({
    commentType: prevState.commentType === 'praiser' ? 'critic' : 'praiser',
  }));
};
  
handleExpandes = (animeId) => {
  this.setState((prevState) => ({
    showExpandes: !prevState.showExpandes,
    selectedAnime: prevState.showExpandes ? null : animeId, // إعادة تعيين selectedAnime عند إغلاق المربع
  }));
};

handleExpandex = (animeId) => {
  this.setState((prevState) => ({
    showExpandex: !prevState.showExpandex,
    selectedAnime: prevState.showExpandex ? null : animeId, // إعادة تعيين selectedAnime عند إغلاق المربع
    rating: prevState.showExpandex ? null : this.state.rating,
    note: prevState.showExpandex ? null : this.state.note,
    randomNote: prevState.showExpandex ? null : this.state.randomNote,
  }));
};
  
getCommentStyle = (commentPrice) => {
  let style = {
    overflow: 'hidden',
    display: 'flex',
    width: '95%',
    height: 'auto',
    boxShadow: '20px 20px 60px #bebebe, -20px -20px 60px #ffffff',
    background: 'linear-gradient(90deg, rgba(255,255,255,0.9) 0%, rgba(255,255,255,0.7) 50%, rgba(255,255,255,0.9) 80%)',
    outline: '3px solid #f5f5f5',
    outlineOffset: '-3px',
    position: 'relative',
    color: 'black',
    fontWeight: 'bold',
    fontSize: '16px',
    animation: 'glow 1.5s infinite',
  };

  if (commentPrice === 'silver') {
    style.boxShadow = '0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(169, 169, 169, 0.4), inset 0 -2px 5px 1px rgba(192, 192, 192, 1), inset 0 -1px 1px 3px rgba(211, 211, 211, 1)';
    style.background = 'linear-gradient(160deg, #d4d4d4, #e0e0e0, #f5f5f5, #e0e0e0, #d4d4d4)';
    style.outline = '3px solid #c0c0c0';
  } else if (commentPrice === 'golden') {
    style.boxShadow = '0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(110, 80, 20, 0.4), inset 0 -2px 5px 1px rgba(139, 66, 8, 1), inset 0 -1px 1px 3px rgba(250, 227, 133, 1)';
    style.background = 'linear-gradient( 160deg, #a54e07, #b47e11, #fef1a2, #bc881b, #a54e07 )';
    style.outline = '3px solid #a55d07';
  }

  return style;
}; 
  



   // دالة استدعاء للإعجاب
   likeComment = async (animeId, commentId) => {
    //console.log('User ID:', this.state.userId); // طباعة معرف المستخدم
    try {
      const response = await axios.post('/like', { animeId, commentId }, {
        headers: {
          userId: this.state.userId
        }
      });
      const updatedAnimeData = this.state.animeData.map(anime => {
        if (anime._id === animeId) {
          const updatedComments = anime.publicComment.map(comment => {
            if (comment._id === commentId) {
              return {
                ...comment,
                likesCount: response.data.likesCount,
                dislikesCount: response.data.dislikesCount,
                likedBy: response.data.likedBy,
                dislikedBy: response.data.dislikedBy,
              };
            }
            return comment;
          });
          return { ...anime, publicComment: updatedComments };
        }
        return anime;
      });
      this.setState({ animeData: updatedAnimeData });
    } catch (error) {
      if (error.response && error.response.status === 400 && error.response.data.error === 'you must login first') {
        alert('You must login first');
      } else {
        console.error('Error liking comment:', error);
      }
    }
  };

  // دالة استدعاء لعدم الإعجاب
  dislikeComment = async (animeId, commentId) => {
    //console.log('User ID:', this.state.userId); // طباعة معرف المستخدم
    try {
      const response = await axios.post('/dislike', { animeId, commentId }, {
        headers: {
          userId: this.state.userId
        }
      });
      const updatedAnimeData = this.state.animeData.map(anime => {
        if (anime._id === animeId) {
          const updatedComments = anime.publicComment.map(comment => {
            if (comment._id === commentId) {
              return {
                ...comment,
                likesCount: response.data.likesCount,
                dislikesCount: response.data.dislikesCount,
                likedBy: response.data.likedBy,
                dislikedBy: response.data.dislikedBy,
              };
            }
            return comment;
          });
          return { ...anime, publicComment: updatedComments };
        }
        return anime;
      });
      this.setState({ animeData: updatedAnimeData });
    } catch (error) {
      if (error.response && error.response.status === 400 && error.response.data.error === 'you must login first') {
        alert('You must login first');
      } else {
        console.error('Error disliking comment:', error);
      }
    }
  };

 // دالة لتحديد الفئة بناءً على حالة التعليق ومعرف المستخدم
 getButtonClass = (comment, userId, type) => {
  if (!comment) return '';
  // إزالة علامات الاقتباس الإضافية والمسافات غير المرئية
  const cleanedUserId = userId.replace(/^"|"$/g, '').trim();
  const cleanedLikedBy = comment.likedBy.map(id => id.replace(/^"|"$/g, '').trim());
  const cleanedDislikedBy = comment.dislikedBy.map(id => id.replace(/^"|"$/g, '').trim());

  // تحقق من أن userId موجود في المصفوفة
  const userIdExists = cleanedLikedBy.includes(cleanedUserId);
    // طباعة كل عنصر في likedBy مع طوله
  cleanedLikedBy.forEach((id, index) => {
   
  });

  if (type === 'like') {
    return `like-button ${userIdExists ? 'liked' : ''}`;
  } else if (type === 'dislike') {
    return `dislike-button ${cleanedDislikedBy.includes(cleanedUserId) ? 'disliked' : ''}`;
  }
  return '';
};


// دالة لإضافة أو تعديل التقييم
addOrUpdateRating = async () => {
  const { rating, note, selectedAnime } = this.state;



  try {
    const response = await fetch('/rateanime', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        //'userId': 'your-user-id' // Replace with actual user ID or use cookies
      },
      body: JSON.stringify({ animeId: selectedAnime, rating, note })
    });

    const data = await response.json();

    if (response.ok) {
      this.setState({
        message: data.message,
        randomNote: data.randomNote,
        randomRating: data.randomRating,
        randomUsername: data.randomUsername,
        randomUserProfileImage: data.randomUserProfileImage
      });

      alert(data.message);
    } else {
      alert(data.error || 'حدث خطأ ما');
    }
  } catch (error) {
    console.error('Error occurred:', error);
    alert('حدث خطأ ما أثناء إرسال التقييم');
  }
};

// دالة لحذف التقييم
deleteRating = async () => {
  const { selectedAnime } = this.state;

  

  try {
    const response = await fetch('/deleterateanime', {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        //'userId': 'your-user-id' // Replace with actual user ID or use cookies
      },
      body: JSON.stringify({ animeId: selectedAnime })
    });

    const data = await response.json();

    if (response.ok) {
      this.setState({ message: data.message });
      alert(data.message);
    } else {
      alert(data.error || 'حدث خطأ ما');
    }
  } catch (error) {
    console.error('Error occurred:', error);
    alert('حدث خطأ ما أثناء حذف التقييم');
  }
};
  
handleRatingChange = (e) => {
  let value = e.target.value;

  // تحقق من أن القيمة تحتوي فقط على أرقام أو نقطة عشرية
  const regex = /^[0-9]*\.?[0-9]*$/;

  // تحقق من أن طول القيمة لا يتجاوز أربع أرقام
  if (regex.test(value) && value.length <= 4) {
    this.setState({ rating: value });
  }
};

handleNoteChange = (e) => {
  this.setState({ note: e.target.value.slice(0, 50) });
};


handleMouseEnter = () => {
  this.setState({ isHovered: true });
}

handleMouseLeave = () => {
  this.setState({ isHovered: false });
}


fetchWatchWithFriendsData = async () => {
    const { id } = this.props.params; // تأكد من استخدام match.params
    try {
      const response = await axios.get('/data-watch-with-friends', { params: { id } });
      this.setState({ posts: response.data, loading: false });
    } catch (error) {
      console.error(error.response ? error.response.data.message : error.message);
      this.setState({ loading: false });
    }
  };


  handleDelete = async (postId) => {
    const { id } = this.props.params; // تأكد من استخدام match.params
    try {
      const response = await axios.delete('/delete-watch-friends', {
        data: { postId },
        params: { id } // تمرير المعرف كجزء من query
      });
      alert(response.data.message);
      this.fetchWatchWithFriendsData();
    } catch (error) {
      alert(error.response ? error.response.data.message : error.message);
    }
  };
  

  handleAddPost = async () => {
    const { id } = this.props.params; // تأكد من استخدام match.params
    const { promoText, platformLink, currentMembers } = this.state;
    try {
      const response = await axios.post('/watch-with-friend', {
        promoText,
        platformLink,
        currentMembers
      }, {
        params: { id } // تمرير المعرف كجزء من query
      });
      alert(response.data.message);
      this.setState({ showModal: false });
      this.fetchWatchWithFriendsData();
    } catch (error) {
      alert(error.response ? error.response.data.message : error.message);
    }
  };

  fetchAd = async () => {
    try {
      const response = await axios.get('/get-ad');
      if (response.data.ad) {
        this.setState({ ad: response.data.ad, isLoading: false });
      } else {
        this.setState({ isLoading: false });
      }
    } catch (error) {
      console.error(error.response ? error.response.data.message : error.message);
      this.setState({ isLoading: false });
    }
  };

  incrementAdClick = async (adId) => {
    try {
      const response = await axios.post('/increment-click', { adId });
      console.log(response.data.message);
    } catch (error) {
      console.error(error.response ? error.response.data.message : error.message);
    }
  };


  handleAdClick = (ad) => {
    this.incrementAdClick(ad._id);
    window.open(ad.link, '_blank');
  };

  render() {

    const { showModal, animeData, data, error, showCards, isMobile, isActive, isCharActive, dataType, activeTab, selectedGenre,   initialOptions, productId, customerEmail, name, productName, product, commentText, commentType,  selectedAnime, showExpandes, showExpandex, rating, note, randomNote, randomRating, randomUsername, randomUserProfileImage, isHovered, posts, loading,  promoText, platformLink, currentMembers,ad , isLoading } = this.state;
    const userId = this.state.userId; // جلب معرف المستخدم من الحالة
    const { id } = this.props.params;
 

    // دمج التصفية والترتيب
    const filteredAndSortedAnimeData = Array.isArray(animeData)
      ? animeData
          .filter((anime) => {
            const activeComment = Array.isArray(anime.publicComment)
              ? anime.publicComment.find(comment => comment.commentStatus === 'active')
              : null;
            anime.activeComment = activeComment; // إضافة التعليق النشط إلى كائن الأنمي
            return selectedGenre === "عرض كل الانميات بكل التصنيفات"
              ? true
              : anime.genre.filter((genre) => genre === selectedGenre).length > 0;
          })
          .sort((a, b) => {
            const aActive = a.activeComment && a.activeComment.remainingTime > 0;
            const bActive = b.activeComment && b.activeComment.remainingTime > 0;

            if (aActive && !bActive) return -1;
            if (!aActive && bActive) return 1;
            if (aActive && bActive) {
              return new Date(b.activeComment.commentDate) - new Date(a.activeComment.commentDate);
            }
            return b.honorPoints - a.honorPoints;
          })
      : [];
      //console.log('selectedAnime:', selectedAnime);
      
      const selectedAnimecomm = filteredAndSortedAnimeData.find(anime => anime._id === selectedAnime);
      //console.log('selectedAnimecomm:', selectedAnimecomm);
      const selectedAnimedatail = filteredAndSortedAnimeData.find(anime => anime._id === id);

      if (!selectedAnimedatail) {
        // التعامل مع الحالة التي لا يتم فيها العثور على الأنمي
        return <div></div>;
      }
      
      const description = `${selectedAnimedatail.name} هو أنمي يحتل في العالم العربي المرتبة # ${selectedAnimedatail.ranking.currentRank} بعد أن كان في المرتبة # ${selectedAnimedatail.ranking.previousRank}. آخر تغيير في المرتبة كان في ${new Date(selectedAnimedatail.ranking.lastchangeinRank).toLocaleDateString('ar-EG', { year: 'numeric', month: '2-digit', day: '2-digit' })}.`;
      
      // البحث عن تعليق علني من النوع الفضي أو الذهبي إن وجد
      const publicComment = selectedAnimedatail.publicComment.find(comment => comment.commentPrice === 'silver' || comment.commentPrice === 'golden');
      const commentTextseo = publicComment ? publicComment.commentText : '';

      const parentStyle = {
      //display: 'block', // use flex layout
      justifyContent: 'center', // center the items horizontally
      alignItems: 'center', // center the items vertically
      //height: '-250vh', // set the height to 100% of the viewport height
      //margintop: '100px', // set the margin to auto
      //padding: '280px',
      position: 'relative',
      margin: '10px',
       float: 'right'
      
    };


   

    const cardStyle = {
      width: isMobile ? window.innerWidth -20  : '75%',
      height: isMobile ? '600px' : '510px', // تعديل ارتفاع البطاقة
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      borderRadius: '15px',
    
      
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',

      position: 'absolute',
      

      
     
      float: 'center',
      left: isMobile ? '' :  '21.5%',
      
      marginTop: isMobile ? '150px' : '110px',
    };


    const commStyle = {
      overflow: 'hidden',
      display: 'flex',
      width: '95%',
      height: 'auto',
      boxShadow: '20px 20px 60px #bebebe, -20px -20px 60px #ffffff',
      background: 'linear-gradient(90deg, rgba(255,255,255,0.9) 0%, rgba(255,255,255,0.7) 50%, rgba(255,255,255,0.9) 80%)',
      outline: '3px solid #f5f5f5',
      outlineOffset: '-3px',
      position: 'relative',
      color: 'black',
  fontWeight: 'bold',
  fontSize: '16px',
  animation: 'glow 1.5s infinite',
    }
    
    

    
    const imageStyle = {
      width: '150px', // قم بتعديل عرض الصورة حسب الحاجة
      height: '150px', // قم بتعديل ارتفاع الصورة حسب الحاجة
      objectFit: 'cover', // تعديل تنسيق الصورة لتمتلئ داخل حاوية الصورة بشكل كامل دون تشويه النسبة
      borderRadius: '50%', // إضافة حواف دائرية للصورة
    };


    
      const avatarStyle = {

        width: '90px', // قم بتعديل عرض الصورة حسب الحاجة
      height: '90px', // قم بتعديل ارتفاع الصورة حسب الحاجة
      objectFit: 'cover', // تعديل تنسيق الصورة لتمتلئ داخل حاوية الصورة بشكل كامل دون تشويه النسبة
      borderRadius: '50%', // إضافة حواف دائرية للصورة


      };


    

    

    
    

    

    

    
    return (

      
        <HelmetProvider>
      
     

      
      <div className="pagebodywatch"> 
      
      <div className="logostyle">
    <img src="https://i.imgur.com/RpyLpGN.png" alt="شعار الموقع" style={{width: '319.98px', height: '180px', objectfit: 'contain', float: 'left',}}/>
</div>



      <div>

      <div className="containerwarrior">
        {ad ? (
          <>
            <button
              className="fightratex"
              onClick={() => this.handleAdClick(ad)}
            >
              <p>{ad.productType}</p>
            </button>
            
            <img src={ad.imageUrl} alt="Ad Image" className="imagewar" />
          </>
        ) : (
          <>
            <button className="fightrate">
              <p>عالم التقييم ممتع اكثر مما تظن</p>
            </button>
            <img src="https://i.imgur.com/mZXZ9gb.jpg" alt="Descriptive Alt Text" className="imagewar" />
          </>
        )}
      </div>
      



  

        
         

        <div >
        
       
        
        
       

        
        <div className="anime-list">

          
        <div className="container">
    <div className="row">
      <div className="col-md-9 offset-md-3">
      
      </div>
    </div>
  </div>
  
  {selectedAnimedatail && (
          
          
      

<div className="watch-with-friend-page">
<div className="goldtitle">
        <h1>شاهد مع رفيق</h1>
        <h2>{selectedAnimedatail.name}</h2>
        </div>
        <div className="posts-container" style={cardStyle} >
        <div className="info-box">
        <p>يتم ترتيب المنشورات تنازليا حسب عدد الشخصيات المفضله المشتركه بينك وبين صاحب المنشور...تأكد انك سجلت دخولك</p>
          <p>يتم عرض منشورات من اخر 24 ساعه فقط</p>
          </div>
          <button className="add-post-button" onClick={() => this.setState({ showModal: true })}><svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-plus-square-fill" viewBox="0 0 16 16">
  <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0"/>
</svg></button>
        
          {loading ? (
            <div className="loadingwatchx">LOADING...
            <div className="sharingon">
  <div className="ringshar">
  <div className="to"></div>
<div className="to"></div>
<div className="to"></div>
<div className="circleshar"></div>
</div>
</div>
            </div>
          ) : posts.length === 0 ? (
            <div className="loadingwatch">لايوجد اي منشورات شاهد مع رفيق من اخر 24 ساعه
            
            <div className="sharingon">
  <div className="ringshar">
  <div className="to"></div>
<div className="to"></div>
<div className="to"></div>
<div className="circleshar"></div>
</div>
</div>
            </div>
          ) : (
            posts.map(post => (
<div className="watchlist">
              <div className="xardwatch" key={post._id}>

<Helmet>
  <meta charSet="utf-8" />
  <meta name="viewport" content="width=device-width, initial-scale=1" />
  
  <title>شاهد مع رفيق - {selectedAnimedatail.name}</title>
  <meta name="description" content="شاهد مع رفيق هو قسم موقع المحنكين يتيح لك العثور على رفقاء لمشاهدة الانمي المفضل لديك عبر ديسكورد، تويتش، وكيك. نقترح لك الاشخاص الاكثر توافقا معك بشخصياتك المفضله المسجله لدينا اكتشف الأشخاص الأكثر توافقاً مع ذوقك واستمتع بمشاهدة الأنمي معاً." />
  <meta property="og:title" content= {selectedAnimedatail.name} />
  <meta property="og:description" content="شاهد مع رفيق هو قسم موقع المحنكين يتيح لك العثور على رفقاء لمشاهدة الانمي المفضل لديك عبر ديسكورد، تويتش، وكيك. نقترح لك الاشخاص الاكثر توافقا معك بشخصياتك المفضله المسجله لدينا اكتشف الأشخاص الأكثر توافقاً مع ذوقك واستمتع بمشاهدة الأنمي معاً." />
  <meta property="og:image" content="https://i.imgur.com/D4N14v1.jpg" />
  <meta property="og:type" content="website" />
  <meta property="og:url" content={`https://mohankeen.com/watchwithfriend/${selectedAnimedatail._id}/`} />
  <meta name="keywords" content="شاهد مع رفيق, مشاهدة الانمي, ديسكورد, الحلقه, تويتش, kick, المحنكين" />
  <meta name="author" content="المحنكين" />
  <meta property="og:site_name" content="MOHANKEEN" />
  <meta property="og:locale" content="ar_AR" />
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content= {selectedAnimedatail.name} />
  <meta name="twitter:description" content="شاهد مع رفيق هو قسم موقع المحنكين يتيح لك العثور على رفقاء لمشاهدة الانمي المفضل لديك عبر ديسكورد، تويتش، وكيك. نقترح لك الاشخاص الاكثر توافقا معك بشخصياتك المفضله المسجله لدينا اكتشف الأشخاص الأكثر توافقاً مع ذوقك واستمتع بمشاهدة الأنمي معاً." />
  <meta name="twitter:image" content="https://i.imgur.com/D4N14v1.jpg" />
  <link rel="canonical" href={`https://mohankeen.com/watchwithfriend/${selectedAnimedatail._id}/`} />
  <meta name="robots" content="index, follow" />
  <meta property="og:updated_time" content={new Date().toISOString()} />
  <meta property="og:author" content="المحنكين" />
  <meta property="article:section" content="Watch with Friend" />
  <meta property="article:tag" content="شاهد مع رفيق, مشاهدة الانمي, ديسكورد, تويتش, المحنكين, كيك" />
</Helmet>

                {post.userId === userId && (
                  <button className="delete-button" onClick={() => this.handleDelete(post._id)}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash3-fill" viewBox="0 0 16 16">
                  <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06m6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528M8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5"/>
                </svg></button>
                )}
                <img src={post.platformImage} alt={post.platformType} className="platform-image" />
                <div className="post-header">
                 
                  <a href={`/profile?username=${post.username}`} target="_blank" rel="noopener noreferrer">
                  <div>
  <img
    src={post.userImage.startsWith('http') ? post.userImage : `/${post.userImage.replace(/\\/g, '/')}`}
    alt={post.username}
    className="user-imagexq"
  />
</div>
                    <span className="usernamexq">{post.username}</span>

                  </a>
                </div>
                <p className="promo-text">{post.promoText}</p>
                <div className="post-footer">
                  
                <a href={post.platformLink} className="platform-link" target="_blank" rel="noopener noreferrer">
          {post.platformLink}
        </a>
                  <span className="current-members">الأعضاء الحاليين: {post.currentMembers}</span>
                </div>
              </div>
              </div>
            ))
          )}
        </div>
        {showModal && (
          <div className="expanded-overlayssx">
            <div className="modal-contentxq">
              <button className="close-button" onClick={() => this.setState({ showModal: false })}>إغلاق</button>
              <h4>ابحث عن رفيق للمشاهدة</h4>
              <input
                type="text"
                placeholder="اكتب نص لجذب نوع معين من الناس لغرفه مشاهدتك"
                value={promoText}
                 maxLength="50"
                  className="comment-input"
                onChange={(e) => this.setState({ promoText: e.target.value })}
              />
              <input
                type="text"
                placeholder="رابط المنصة مسموح فقط بديسكورد وتويتش وكيك"
                 className="comment-input"
                value={platformLink}
                onChange={(e) => this.setState({ platformLink: e.target.value })}
              />
               <h4>اكتب عدد الأعضاء الحاليين في سيرفر الديسكورد او في البث الخاص بك :</h4>
              <input
                type="number"
               className="comment-input"
               placeholder="عدد الأعضاء الحاليين في سيرفر الديسكورد او في البث الخاص بك"
                value={currentMembers}
                onChange={(e) => this.setState({ currentMembers: e.target.value })}
              />
              <button className="confirm-button" onClick={this.handleAddPost}>تأكيد النشر</button>
            </div>
          </div>
        )}
      </div>





)}

</div>






</div>






          
          
         
        
       
        <style dangerouslySetInnerHTML={{__html: "\n      /* تحديد خلفية الموقع */\n    body {\n        \n      background: linear-gradient(to right, #ffffff , #fcfcfc 0%);\n  }\n\n    " }} />
      

        
        </div>
        </div> 
         </HelmetProvider>
    );
  }
}





export default withParams(WatchPage);


