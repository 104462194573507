import logo from './logo.svg';
import './components/animecard.css';
import './App.css';

//import { anime } from "../../backend/server.js"; // import Anime model from animeModel.js


import axios from 'axios';
import './assets/css/bootstrap.min.css'; // استيراد ملف CSS من Bootstrap
import 'animate.css/animate.min.css';
//import './assets/js/bootstrap.bundle.js';
//import bootstrap from 'bootstrap';




import React, { useEffect, useState } from 'react';
import api from './api';
import ReactDOM from 'react-dom';
//import { Button } from 'bootstrap';
//import { Offcanvas } from 'bootstrap';
import { Navbar, Offcanvas, Card, Button, CardDeck, CardGroup, CardImg} from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { keyframes } from "styled-components";
//import keyframe from 'keyframe';
//import { Animated, Easing, keyframe } from 'react-native';
//import LottieView from 'lottie-react-native';
import {
  useScene,
  useSceneItem,
  useFrame,
  useNowFrame,
} from "react-scenejs";
import { Animate, AnimateKeyframes, AnimateGroup } from "react-simple-animate";



//import { Offcanvas } from 'react-bootstrap';

import _ from 'lodash';








class CharPageweek extends React.Component {

  constructor(props) {
    super(props);
    // نضع قيمة الحالة الأولية في this.state
    this.state = {
      showBottom: false,
      showModal: false,
      message: '',
      charData: [], // an empty array to store the anime data from the database
      isMobile: window.innerWidth <= 991, // تعيين القيمة الافتراضية للموبايل
      isActive: true,
      
      
      
      
      
      
    };

    this.toggleCards = this.toggleCards.bind(this); // bind toggleCards to the component
  }

  // دالة لجلب بيانات الأنمي من المسار /anime
  fetchData = async () => {
    try {
      const response = await axios.get('/char');
      this.setState({ charData: response.data });
    } catch (error) {
      console.error(error);
    }
  };

  // use componentDidMount to fetch data from node server when the component mounts
  componentDidMount() {
    // use api.get to send a get request to /api endpoint
    api.get('/api')
      .then((res) => {
        // if the request is successful, set the message state to the data from the response
        this.setState({ message: res.data.message });
      })
      .catch((err) => {
        // if there is an error, log it and set the message state to an error message
        console.error(err);
        this.setState({ message: 'Something went wrong' });
      });



      window.addEventListener('resize', this.handleWindowResize);

      this.fetchData();



      
       
      }

      componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowResize);
      }
    
      handleWindowResize = () => {
        const isMobile = window.innerWidth <= 991;
        this.setState({ isMobile });
      };
    
      // دالة لجلب بيانات الأنمي من المسار /anime
      
    
      // define a function called handleClick that calls the fetchData function when the button is clicked
      handleClick = () => {
        this.fetchData();
      };

      
  handleMouseEnter = () => {
    this.setState({ isActive: false });
  };

  handleMouseLeave = () => {
    this.setState({ isActive: true });
  };
  // create a method to handle the api call
  handleApi(e) {
    // do something with the event or the api
  
  }

  // لتغيير قيمة الحالة ، نستخدم this.setState
  // ونمرر له دالة تستلم حالة سابقة وخصائص كمعاملات
  // وترجع كائنًا يحتوي على التغييرات التي نريدها
  handleShowBottom = () => {
    this.setState((prevState, props) => ({
      showBottom: true
    }));
  };

  handleCloseBottom = () => {
    this.setState((prevState, props) => ({
      showBottom: false
    }));
  };

  
  
   // لتغيير قيمة الحالة ، نستخدم this.setState
  // ونمرر له دالة تستلم حالة سابقة وخصائص كمعاملات
  // وترجع كائنًا يحتوي على التغييرات التي نريدها
  handleShow = () => {
    this.setState((prevState, props) => ({
      show: true
    }));
  };

  handleClose = () => {
    this.setState((prevState, props) => ({
      show: false
    }));
  };

   // دالة للتبديل بين إظهار وإخفاء القائمة وتغيير اتجاه الزر
    // دالة للتبديل بين إظهار وإخفاء القائمة وتغيير اتجاه الزر
    
    toggleModal = () => {
      this.setState((prevState) => ({
        showModal: !prevState.showModal
      }));
    }

    toggleCards() {
      this.setState((prevState) => ({
        showCards: !prevState.showCards, // استخدام المعامل ! للحصول على القيمة المعاكسة
      }));
    }

    
    
  
    
    
    
  

  

  
  
  render() {

    const { showModal, charData, data, error, showCards, isMobile, isActive } = this.state;
    
    const filteredCharData = Array.isArray(charData)
    ? charData.filter(char => char.status === 'weekly')
    : [];

    const parentStyle = {
      //display: 'block', // use flex layout
      justifyContent: 'center', // center the items horizontally
      alignItems: 'center', // center the items vertically
      //height: '-250vh', // set the height to 100% of the viewport height
      //margintop: '100px', // set the margin to auto
      //padding: '280px',
      position: 'relative',
      margin: '10px',
       float: 'right'
      
    };

    const cardStyle = {
      width: isMobile ? '100%' : '75%',
      height: isMobile ? '400px' : 'auto', // تعديل ارتفاع البطاقة
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      borderRadius: '12px',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',

      position: 'relative',
      float: 'right',
      //margintop: '20px', // إضافة هذا الخاصية لإضافة بعض المسافة بين البطاقات
      
      
    };


    const commStyle = {
      overflow: 'hidden',
      display: 'flex',
      width: '95%',
      height: 'auto',
      boxShadow: '20px 20px 60px #bebebe, -20px -20px 60px #ffffff',
      background: 'linear-gradient(90deg, rgba(255,255,255,0.9) 0%, rgba(255,255,255,0.7) 50%, rgba(255,255,255,0.9) 80%)',
      outline: '3px solid #f5f5f5',
      outlineOffset: '-3px',
      position: 'relative',
      color: 'black',
  fontWeight: 'bold',
  fontSize: '16px',
    }
    
    

    
    const imageStyle = {
      width: '150px', // قم بتعديل عرض الصورة حسب الحاجة
      height: '150px', // قم بتعديل ارتفاع الصورة حسب الحاجة
      objectFit: 'cover', // تعديل تنسيق الصورة لتمتلئ داخل حاوية الصورة بشكل كامل دون تشويه النسبة
      borderRadius: '50%', // إضافة حواف دائرية للصورة
    };


    
      const avatarStyle = {

        width: '90px', // قم بتعديل عرض الصورة حسب الحاجة
      height: '90px', // قم بتعديل ارتفاع الصورة حسب الحاجة
      objectFit: 'cover', // تعديل تنسيق الصورة لتمتلئ داخل حاوية الصورة بشكل كامل دون تشويه النسبة
      borderRadius: '50%', // إضافة حواف دائرية للصورة


      };


    


    

    
    

    

    

    
    return (

      

      

      
      
      <div>


        <div style={{ marginBottom: '1023px',position: 'relative', marginTop: '-110px' }}>
        
       
        
        
       

        
        <div className="char-list">

          
        <div className="container">
    <div className="row">
      <div className="col-md-9 offset-md-3">
        <h4 className="text-center mt-4">اعظم الشخصيات لهذا الاسبوع</h4>
      </div>
    </div>
  </div>
          
  {filteredCharData.map((char, index) => (
            <div className="card mb-3" key={char.id} style={cardStyle}>
              <div className="col-md-2">
                <div className="circle-image">
                  <img src={char.imageUrl} alt={char.name} className="img-fluid" style={imageStyle} />
                </div>
              </div>
              <div className="col-md-10">
                <div className="card-body">
                <div className="number-badge">{index + 1}</div>
                  <h4>{char.name}</h4>
                  <p className="card-text">التقييم: {char.ratingValue[0].rating}</p> 
                  
                  <div className="row">
                    <div className="col">
                      <p className="card-text">عدد المقيمين بتقييم 10: {char.tenRatingsCount}%</p>
                      <p className="card-text">عدد المقيمين بتقييم 0: {char.zeroRatingsCount}%</p>
                      <button className="btn-md rounded my-button">مراجعات</button>
                    </div>

                    
                    <div className="col">
  <div className="row">
    <div className="col">
      <div className="honor-points">
        <div className="circle-honor">
          <div>نقاط الشرف</div>
          <div>{char.honorPoints}</div>
        </div>
      </div>
      
    
    </div>
    
    <div className="col">
  <div className="review-section">
    <button className="btn-md rounded comm-button" type="button">
      <strong>نشر تعليق علني</strong>
      <div id="container-stars">
    <div id="stars"></div>
  </div>
  

  <div id="glowx">
    <div class="circle"></div>
    <div class="circle"></div>
  </div>
    </button>
    </div>
    </div>


  </div>

  
  </div>
                    <div className="col">
                    <div className="col">
  <div className="button-row">
    <button className="btn-md rounded button-style">ميمز</button>
    <button className="btn-md rounded button-style">كوسبلاي</button>
    <button className="btn-md rounded button-style">انيميشن</button>
  </div>
  <div className="button-row">
    <button className="btn-md rounded button-style">تأديه اصوات</button>
    <button className="btn-md rounded button-style">ايديت</button>
    <button className="btn-md rounded button-style">نظريات</button>
  </div>
  <div className="button-row">
    <button className="btn-md rounded button-style">شاهد انمي مع رفيق</button>
    <button className="btn-md rounded button-style">فان ارت</button>
    <button className="btn-md rounded button-style">منتجات</button>
  </div>
</div>

</div>
</div>
</div>

<div className="col">
  <div className="row2">
    <div className={`comment-card card ${char.publicComment.commentType === 'critic' ? 'critic-comment' : 'praiser-comment'}`} style={commStyle}>
      <div class="glow-bar"></div>
      <div className="card-body">
        <div className="row">
          <div className="col-auto">
            <img src={char.publicComment.userAvatar} alt="User Avatar" className="user-avatar" style={avatarStyle} />
          </div>
          <div className="col">
            <div className="row">
              <div className="col-auto">
                <span className="user-name">{char.publicComment.userName}</span>
              </div>
              <div className="col-auto">
                <span className="comment-date">{char.publicComment.commentDate}</span>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="comment-text">{char.publicComment.commentText}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <button className="like-button">
              <span className="likes-count">{char.publicComment.likesCount} أتفق</span>
            </button>
            <button className="dislike-button">
              <span className="dislikes-count">{char.publicComment.dislikesCount} أختلف</span>
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="comment-type-container">
              <div className={`comment-type ${char.publicComment.commentType === 'critic' ? 'critic-comment' : 'praiser-comment'}`}>
                {char.publicComment.commentType === 'critic' ? 'تعليق انتقادي' : 'تعليق مدح'}
              </div>
            </div>
          </div>
          <div className="row">
          <div className="col">
            <div className="comment-notification" style={{ fontSize: '12px', textShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', padding: '4px', marginTop: 'auto', marginBottom: '0', textAlign: 'center' }}>
              {char.publicComment.commentType === 'critic' ? 'سيتم خصم نقاط شرف من هذا الانمي إذا كان عدد الاتفافات أكبر من عدد الاختلافات لهذا التعليق' : 'سيتم زيادة نقاط شرف هذا الانمي إذا كان عدد الاتفاقات أكثر من عدد الاختلافات لهذا التعليق'}
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>

</div>



))}
</div>
</div>

          
          
         
        
       
        <style dangerouslySetInnerHTML={{__html: "\n      /* تحديد خلفية الموقع */\n    body {\n        \n      background: linear-gradient(to right, #ffffff , #fcfcfc 0%);\n  }\n\n    " }} />
      

        
        </div>
    );
  }
}





export default CharPageweek;


