import logo from './logo14400.png';
import ErrorPage from "./ErrorPage.js";
import CharPage from './char.js';
import WeekEp from './weekep.js';
import AnimePage from "./anime.js";
import CharPageweek from "./charweek.js"
import Login from './login.js';


//import Signup from './signup.js';
//import Button from './button.js';
import './components/profile.css';

//import { anime } from "../../backend/server.js"; // import Anime model from animeModel.js


import axios from 'axios';
import './assets/css/bootstrap.min.css'; // استيراد ملف CSS من Bootstrap
import 'animate.css/animate.min.css';
//import './assets/js/bootstrap.bundle.js';
//import bootstrap from 'bootstrap';



import InfiniteScroll from 'react-infinite-scroll-component';
import React, { useEffect, useState } from 'react';
import api from './api';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router, Route, Link, Routes, Navigate,} from 'react-router-dom';
import Cookies from 'js-cookie';
//import { BrowserRouter, Route, Switch } from "react-router-dom";
//import { Button } from 'bootstrap';
//import { Offcanvas } from 'bootstrap';
import { Navbar, Offcanvas, Card, Button, CardDeck, CardGroup, CardImg, Dropdown} from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { keyframes } from "styled-components";
//import keyframe from 'keyframe';
//import { Animated, Easing, keyframe } from 'react-native';
//import LottieView from 'lottie-react-native';
import {
  useScene,
  useSceneItem,
  useFrame,
  useNowFrame,
} from "react-scenejs";
import { Animate, AnimateKeyframes, AnimateGroup } from "react-simple-animate";



//import { Offcanvas } from 'react-bootstrap';

import _ from 'lodash';
//import Switch from "react-switch";
import { Tabs, Tab, Box, TabPanel, styled } from '@mui/material';


//import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
//import 'react-tabs/style/react-tabs.css';








class RatePagee extends React.Component {

  constructor(props) {
    super(props);
    // نضع قيمة الحالة الأولية في this.state
    this.state = {
      showBottom: false,
      showModal: false,
      message: '',
      animeData: [], // an empty array to store the anime data from the database
      userdata: "",
      isMobile: window.innerWidth <= 991, // تعيين القيمة الافتراضية للموبايل
      isActive: true,
      value: 0,
      redirectToNewPage: false,
      showExpanded: false,
      
      
      isLoggedIn: false,
     
      menuOpen: false,
      token: "", // قيمة الرمز
      //username: "", // اسم المستخدم
      profileImage: "", // صورة الملف الشخصي 
      //Cookies: "",
      redirect: false,

      // تعيين الحالة query إلى فارغة
      query: '',
      // تعيين الحالة results إلى مصفوفة فارغة
      results: [],
      
      profileId: null, // الحالة التي تحتوي على معرف الملف الشخصي الذي يتم عرضه في الصفحة
      
      isOwnProfile: false,
      
      ratedCharacters: [], // مصفوفة فارغة لتخزين الشخصيات المقيمة
      resultss: [],
      ratedanimes: [],

      page: 1,
      limit: 15,
      hasMore: true,
      totalPages: 0,
    };

    
  }


     // تعريف الدالة التي تستدعي المسار
  // تعريف الدالة التي تستدعي المسار
getRatedAnimes = () => {
  const { page, limit, ratedanimes } = this.state;

  // استخراج اسم المستخدم من رابط صفحة البروفايل
  const urlParams = new URLSearchParams(window.location.search);
  const username = urlParams.get('username');

  if (!username) {
    console.error('اسم المستخدم غير موجود في رابط صفحة البروفايل');
    return;
  }

  // إرسال طلب get إلى المسار مع اسم المستخدم في المعلمات ومعلمات الصفحة والحد
  axios
    .get("/ratedd", {
      params: { username: username, page: page, limit: limit }
    })
    .then((response) => {
      const { ratedanimes: newRatedanimes, totalPages } = response.data;
      this.setState({
        ratedanimes: [...ratedanimes, ...newRatedanimes],
        page: page + 1,
        totalPages: totalPages,
        hasMore: page < totalPages,
      });
    })
    .catch((error) => {
      // التعامل مع الخطأ
      console.error(error);
    });
};
  
    
      
    
    
    
      // تعريف دالة handleChange للمكون
      handleChangee = (event) => {
        // تحديث قيمة الحالة query بقيمة الحدث
        this.setState({ query: event.target.value });
        // استدعاء دالة searchCharacters مع قيمة الحدث
        this.searchAnimes(event.target.value);
      };
    
     // تعريف دالة addFavorite للمكون
    addFavoritee = (name, imageUrl) => {
      // إنشاء كائن معطيات يحتوي على اسم الشخصية وصورتها
      const data = { name: name, imageUrl: imageUrl };
      // إرسال طلب POST إلى المسار '/addFavorite' مع الكائن data
      axios
        .post('/addFavoritee', data)
        .then((res) => {
          // إذا تم الطلب بنجاح، إظهار رسالة الاستجابة
          alert(res.data.message);
          // تحديث الحالة userdata بالشخصية المضافة إذا كانت موجودة في الاستجابة
          if (res.data.anime) {
            this.setState((prevState) => ({
              userdata: {
                ...prevState.userdata,
                favoriteAnimes: [
                  ...prevState.userdata.favoriteAnimes,
                  res.data.anime,
                ],
              },
            }));
          }
        })
        .catch((err) => {
          // إذا حدث خطأ، إظهار رسالة الخطأ
          alert(err.response.data.message);
        });
    };
    
    
    
    
    // تعريف دالة deleteFavorite للمكون
    deleteFavoritee = (name) => {
      // إنشاء كائن معطيات يحتوي على اسم الشخصية ومعرف المستخدم
      const data = { name: name, userId: this.state.userdata._id };
      // إرسال طلب DELETE إلى المسار '/deleteFavorite' مع الكائن data
      axios
        .delete('/deleteFavoritee', { data: data })
        .then((res) => {
          // إذا تم الطلب بنجاح، إظهار رسالة الاستجابة
          alert(res.data.message);
          // تحديث الحالة userdata بحذف الشخصية من المصفوفة
          this.setState((prevState) => ({
            userdata: {
              ...prevState.userdata,
              favoriteAnimes: prevState.userdata.favoriteAnimes.filter(
                (anime) => anime.name !== name
              ),
            },
          }));
        })
        .catch((err) => {
          // إذا حدث خطأ، إظهار رسالة الخطأ
          alert(err.response.data.message);
        });
    };
    
      // تعريف دالة searchCharacters للمكون
      searchAnimes = (query) => {
        // إرسال طلب GET إلى المسار '/search' مع استعلام البحث كمعامل
        axios
          .get('/searchh', { params: { q: query } })
          .then((res) => {
            // إذا تم الطلب بنجاح، تحديث الحالة بالنتائج
            this.setState({ results: res.data });
          })
          .catch((err) => {
            // إذا حدث خطأ، إظهار رسالة الخطأ
            alert(err.response.data.message);
          });
      };
    
       // تعريف دالة hideSuggestions للمكون
       hideSuggestionss = () => {
        // تغيير قيمة الحالة results إلى مصفوفة فارغة
        this.setState({ resultss: [] });
      };
    
  // داخل الكلاس الخاصة بمكونك
handleExpand = () => {
  this.setState((prevState) => ({
    showExpanded: !prevState.showExpanded,
  }));
};
  // دالة لجلب بيانات الأنمي من المسار /anime
  fetchData = async () => {
    try {
      const response = await axios.get('/anime');
      this.setState({ animeData: response.data });
    } catch (error) {
      console.error(error);
    }
  };

  // تعريف الدالة في مكون الصفحة الشخصية
getUserData = () => {
  // الحصول على اسم المستخدم من الرابط
  const searchParams = new URLSearchParams(window.location.search);
  const username = searchParams.get("username");


  const currentUser = Cookies.get("username") || localStorage.getItem("username"); // الحصول على اسم المستخدم الحالي من الكوكيز أو الذاكرة المحلية
const isOwnProfile = username === currentUser; // مقارنة الاسمين
this.setState({ isOwnProfile: isOwnProfile }); // تحديث الحالة بنتيجة المقارنة

  // إرسال طلب get إلى الواجهة البرمجية مع اسم المستخدم
  axios
    .get(`/profile/${username}`)
    .then((response) => {
      // تغيير الحالة ببيانات المستخدم
      this.setState({ userdata: response.data });
    })
    .catch((error) => {
      // التعامل مع الخطأ
      console.error(error);
      // هنا يمكنك تحديد الحالة التي تريد التعامل معها، مثل 404 أو غيرها
      if (error.response.status === 404) {
        // هنا يمكنك تحديد الإجراء الذي تريد اتخاذه، مثل إعادة التوجيه أو عرض رسالة
        this.setState({ redirect: true,  });
        return (
          <ErrorPage
            message="الرابط ربما يكون خاطئًا أو المستخدم غير موجود"
            // يجب أن تحدد النمط الذي تريد تطبيقه على الرسالة ككائن يحتوي على خصائص CSS
            // مثلاً، إذا كنت تريد جعل الخط واضح وكبير وسميك وفي منتصف الصفحة ومرتب ومنظم، فتكتب:
            style={{
              color: "red",
              fontSize: "30px",
              fontWeight: "bold",
              textAlign: "center",
              margin: "auto",
            }}
          />
        );
      }
    });
};


 
  

  // use componentDidMount to fetch data from node server when the component mounts
  componentDidMount() {
    // use api.get to send a get request to /api endpoint
    api.get('/api')
      .then((res) => {
        // if the request is successful, set the message state to the data from the response
        this.setState({ message: res.data.message });
      })
      .catch((err) => {
        // if there is an error, log it and set the message state to an error message
        console.error(err);
        this.setState({ message: 'Something went wrong' });
      });



      window.addEventListener('resize', this.handleWindowResize);

     //this.fetchData();
    
     //this.handleLogin();

 

     const userId = Cookies.get('userId') || localStorage.getItem('userId');
      // التحقق من وجود token في الذاكرة المحلية
      const token = Cookies.get('token') || localStorage.getItem("token") 
    if (token) {
      this.setState({ isLoggedIn: true });
      // ارسل token إلى الواجهة البرمجية للحصول على بيانات المستخدم
     
    };




    this.getUserData();
   
  this.getRatedAnimes();
    
    
      
       
      }

      render() {

    


        const { showModal, animeData, data, error, showCards, isMobile, isActive, isCharActive, dataType, activeTab, showLogin,  message, isLoggedIn, menuOpen, profileImage, token, redirect, params, userdata, showExpanded, query, results, isOwnProfile, ratedCharacters, resultss, ratedanimes } = this.state;
        const { value } = this.state;
        

        return (

      

      

            
      
            <div>
  {this.state.userdata && (
   <div id="characters-container" style={{ height: 450, overflow: 'auto' }}>

   <InfiniteScroll
     dataLength={this.state.ratedanimes.length}
     next={this.getRatedAnimes}
     hasMore={this.state.hasMore}
    scrollableTarget="characters-container"
    loader={<p>جاري التحميل...</p>}
   >
      {this.state.ratedanimes.map((anime) => (
        <div className="character-card" key={anime._id}>
          <img src={anime.anime.imageUrl} alt={anime.anime.name} />
          <div className="character-details">
            <h3>{anime.anime.name}</h3>
            <div className="rating-container">
              <div className="rating-value">{anime.rating}/10</div>
              <div className="rating-label">
                <span>تم التقييم</span>
               
              </div>
            </div>
          </div>
        </div>
      ))}
      </InfiniteScroll>
    </div>
  )}
</div>
                


);
}
}


                export default RatePagee;