import logo from './logo14400.png';
import './components/animecard.css';
import './App.css';
import CharPage from './char.js';
import WeekEp from './weekep.js';
import AnimePage from "./anime.js";
import CharPageweek from "./charweek.js"
import Login from './login.js';
//import Signup from './signup.js';
//import Button from './button.js';

//import { anime } from "../../backend/server.js"; // import Anime model from animeModel.js


import axios from 'axios';
import './assets/css/bootstrap.min.css'; // استيراد ملف CSS من Bootstrap
import 'animate.css/animate.min.css';

//import './assets/js/bootstrap.bundle.js';
//import bootstrap from 'bootstrap';




import React, { useEffect, useState } from 'react';
import api from './api';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router, Route, Link, Routes} from 'react-router-dom';
import Cookies from 'js-cookie';
import CryptoJS from "crypto-js";
import Moamalat from 'reactjs-moamalat';
import Lightbox from 'reactjs-lightbox';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { initializePaddle, Paddle } from '@paddle/paddle-js';
//import Lightbox from 'npm-lightbox';
import moment from 'moment';
//import { BrowserRouter, Route, Switch } from "react-router-dom";
//import { Button } from 'bootstrap';
//import { Offcanvas } from 'bootstrap';
import { Navbar, Offcanvas, Card, Button, CardDeck, CardGroup, CardImg, Dropdown} from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { keyframes } from "styled-components";
//import keyframe from 'keyframe';
//import { Animated, Easing, keyframe } from 'react-native';
//import LottieView from 'lottie-react-native';
import {
  useScene,
  useSceneItem,
  useFrame,
  useNowFrame,
} from "react-scenejs";
import { Animate, AnimateKeyframes, AnimateGroup } from "react-simple-animate";



//import { Offcanvas } from 'react-bootstrap';

import _ from 'lodash';
//import Switch from "react-switch";
import { Tabs, Tab, Box, TabPanel, styled } from '@mui/material';

import { Helmet, HelmetProvider } from 'react-helmet-async';
//import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
//import 'react-tabs/style/react-tabs.css';






class Message extends React.Component {
  render() {
    return <p>{this.props.content}</p>;
  }
}

class homepage extends React.Component {

  constructor(props) {
    super(props);
    const isDevelopment = process.env.REACT_APP_NODE_ENV === 'development';

      // جلب معرفات PayPal وPaddle من متغيرات البيئة
      const PAYPAL_CLIENT_ID = isDevelopment ? process.env.REACT_APP_PAYPAL_SANDBOX_CLIENT_ID : process.env.REACT_APP_PAYPAL_LIVE_CLIENT_ID;
      const PADDLE_TOKEN = isDevelopment ? process.env.REACT_APP_PADDLE_SANDBOX_TOKEN : process.env.REACT_APP_PADDLE_LIVE_TOKEN;
      const PRODUCT_PRICE_PADDLE_SILVER = isDevelopment ? process.env.REACT_APP_SANDBOX_PRICE_PADDLE_SILVER : process.env.REACT_APP_LIVE_PRICE_PADDLE_SILVER;
 const PRODUCT_PRICE_PADDLE_GOLDEN = isDevelopment ? process.env.REACT_APP_SANDBOX_PRICE_PADDLE_GOLDEN : process.env.REACT_APP_LIVE_PRICE_PADDLE_GOLDEN;
    // نضع قيمة الحالة الأولية في this.state
    this.state = {
      showBottom: false,
      showModal: false,
      message: '',
      animeData: [], // an empty array to store the anime data from the database
      isMobile: window.innerWidth <= 991, // تعيين القيمة الافتراضية للموبايل
      isActive: true,
      value: 0,
      redirectToNewPage: false,
      
      
      isLoggedIn: false,
     
      menuOpen: false,
      token: "", // قيمة الرمز
      username: "", // اسم المستخدم
      profileImage: "", // صورة الملف الشخصي 
      //Cookies: "",
      //بوابه الدفع
      AmountTrxn: '', // قيمة الكمية الديناميكية
      reference: '', // مرجع المعاملة الفريد
      
      trxDateTime: '', // تاريخ ووقت المعاملة
      lightboxOpen: false, // حالة فتح أو إغلاق صندوق الضوء
      paymentStatus: '', // حالة المعاملة (نجاح ، فشل ، معلق)
     
      MID: '',
      TID: '',
      Secret: '', // المفتاح السري الخاص بك
      
      SecureHash: '',


      //paypal
      //message: '',
      initialOptions: {
       'client-id': PAYPAL_CLIENT_ID,
        'enable-funding': '',
        'disable-funding': 'paylater,venmo,card',
        'data-sdk-integration-source': 'integrationbuilder_sc',
      },
    
      
        //paddle
        
        name: '',
        productName: '',
        product: '',
       
        paddle: '',

        //notifications
        notifications: [],
        hasNewNotifications: false,
        usernamerandom: '',
        dailyWinner: null,
        showExpandexrank:false,
      
    };

    this.toggleCards = this.toggleCards.bind(this); // bind toggleCards to the component
    this.handleChange = this.handleChange.bind(this);
     // ربط دالتي handleLogin و handleLogout بال class component
    
     this.handleLogout = this.handleLogout.bind(this);
     this.toggleMenu = this.toggleMenu.bind(this);
     this.logout = this.logout.bind(this);
     this.getRandomUsername = this.getRandomUsername.bind(this);
//بوابه الدفع
    
  }

  // دالة لجلب بيانات الأنمي من المسار /anime
  fetchData = async () => {
    try {
      const response = await axios.get('/anime');
      this.setState({ animeData: response.data });
    } catch (error) {
      console.error(error);
    }
  };

  fetchDailyWinner = async () => {
    try {
      const response = await axios.get('/daily-winner');
      this.setState({ dailyWinner: response.data });
    } catch (error) {
      this.setState({ error: error.message });
    }
  };

  handleExpandexrank = () => {
    this.setState((prevState) => ({
      showExpandexrank: !prevState.showExpandexrank,
    }));
  };
  
  

  // use componentDidMount to fetch data from node server when the component mounts
  componentDidMount() {
    // use api.get to send a get request to /api endpoint
    api.get('/api')
      .then((res) => {
        // if the request is successful, set the message state to the data from the response
        this.setState({ message: res.data.message });
      })
      .catch((err) => {
        // if there is an error, log it and set the message state to an error message
        console.error(err);
        this.setState({ message: 'Something went wrong' });
      });



      window.addEventListener('resize', this.handleWindowResize);

     //this.fetchData();
    
     //this.handleLogin();

 
     const currentUser = Cookies.get("username") || localStorage.getItem('username'); // الحصول على اسم المستخدم الحالي من الكوكيز أو الذاكرة المحلية
     const userId = Cookies.get('userId') || localStorage.getItem('userId');
      // التحقق من وجود token في الذاكرة المحلية
      const token = Cookies.get('token') || localStorage.getItem("token") 
    if (token) {
      this.setState({ isLoggedIn: true });
      // ارسل token إلى الواجهة البرمجية للحصول على بيانات المستخدم
      axios
      .get("/api/user", { headers: { Authorization: `Bearer ${token}` }, credentials: "include", withCredentials: true, })
        .then((response) => {
          this.setState({
            profileImage: response.data.profileImage,
            username: response.data.username,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }


      // Initialize Paddle.js

    
      
      //this.loadProductDetails();
       
      //notifications
      this.fetchNotifications();

      this.fetchDailyWinner();
      }
     
    
     
    

      componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowResize);



        
      }
    
      handleWindowResize = () => {
        const isMobile = window.innerWidth <= 991;
        this.setState({ isMobile });
      };
    
      // دالة لجلب بيانات الأنمي من المسار /anime
      
    
      // define a function called handleClick that calls the fetchData function when the button is clicked
      handleClick = () => {
        this.fetchData();
        this.setState({ isCharActive: false, dataType: 'anime' });
      };

      
  handleMouseEnter = () => {
    this.setState({ isActive: false });
  };

  handleMouseLeave = () => {
    this.setState({ isActive: true });
  };
  // create a method to handle the api call
  handleApi(e) {
    // do something with the event or the api
  
  }

  // لتغيير قيمة الحالة ، نستخدم this.setState
  // ونمرر له دالة تستلم حالة سابقة وخصائص كمعاملات
  // وترجع كائنًا يحتوي على التغييرات التي نريدها
  handleShowBottom = () => {
    this.setState((prevState, props) => ({
      showBottom: true
    }));
  };

  handleCloseBottom = () => {
    this.setState((prevState, props) => ({
      showBottom: false
    }));
  };

  
  
   // لتغيير قيمة الحالة ، نستخدم this.setState
  // ونمرر له دالة تستلم حالة سابقة وخصائص كمعاملات
  // وترجع كائنًا يحتوي على التغييرات التي نريدها
  handleShow = () => {
    this.setState((prevState, props) => ({
      show: true
    }));
  };

  handleClose = () => {
    this.setState((prevState, props) => ({
      show: false
    }));
  };

  handleShownoti = () => {
    this.setState((prevState, props) => ({
      shownoti: true,
       hasNewNotifications: false
    }));
  };

  handleClosenoti = () => {
    this.setState((prevState, props) => ({
      shownoti: false,
      hasNewNotifications: false
    }));
  };

   // دالة للتبديل بين إظهار وإخفاء القائمة وتغيير اتجاه الزر
    // دالة للتبديل بين إظهار وإخفاء القائمة وتغيير اتجاه الزر
    
    toggleModal = () => {
      this.setState((prevState) => ({
        showModal: !prevState.showModal
      }));
    }

    toggleCards() {
      this.setState((prevState) => ({
        showCards: !prevState.showCards, // استخدام المعامل ! للحصول على القيمة المعاكسة
      }));
    }



    toggleChar = () => {
      this.setState((prevState) => ({
        isCharActive: !prevState.isCharActive,
        dataType: prevState.isCharActive ? 'anime' : 'character',
      }));
    };


    handleChange(event, newValue) {
      this.setState({ value: newValue });
    }
  
  
    
   

    handleButtonClick = () => {
      this.props.history.push('/new-page');
    };
  

    // إنشاء دالة handleLogout لل class component
 handleLogout = async () => {
  // استخدام axios لإرسال طلب إلى خادم الواجهة الخلفية
  try {
    const response = await axios.get('/api/logout', {
      withCredentials: true,
    });
    // إنهاء جلسة المستخدم وتغيير حالة isLoggedIn إلى false
    if (response.data.success) {
      this.setState({
        isLoggedIn: false,
        username: '',
      });
    }
  } catch (error) {
    console.error(error);
  }
};


toggleMenu() {
  this.setState((prevState) => ({
    menuOpen: !prevState.menuOpen,
  }));
}

logout = async () => {
  // أرسل طلب GET إلى مسار /logout
  axios
    .get("/api/logout") // تأكد من استخدام البروتوكول الصحيح والمنفذ الصحيح
    .then((response) => {
      if (response.status === 200) {
        // إزالة token من الذاكرة المحلية
        localStorage.removeItem("token");
        Cookies.remove('token');
        localStorage.removeItem("username");
        Cookies.remove('username');
        localStorage.removeItem("userId");
        Cookies.remove('userId');
        // تغيير حالة تسجيل الدخول إلى false
        this.setState({ isLoggedIn: false });
        // إعادة تحميل الصفحة الحالية
        window.location.reload();
      } else {
        console.log('Logout was not successful.');
      }
    })
    .catch((error) => {
      // تحقق من وجود response قبل محاولة الوصول إلى data
      if (error.response) {
        console.log(error.response.data.message);
      } else {
        console.error(error);
        console.log('An error occurred. Please try again.');
      }
      // إزالة token من الذاكرة المحلية في حالة الخطأ أيضًا
      localStorage.removeItem("token");
      Cookies.remove('token');
      localStorage.removeItem("userId");
      Cookies.remove('userId');
      localStorage.removeItem("username");
      Cookies.remove('username');
    });
}

//بوابه الدفععع

  
  
  
  

   // قم بإنشاء دالة لتحديث كائن طلب الدفع
   updatePaymentRequest = (paymentRequest) => {
    this.setState({ paymentRequest });
  };

  startPayment = async () => {
    // قم بالحصول على بيانات التاجر والمحطة والمفتاح السري والعنوان من الخاصية
    const { MID, TID, Secret, url } = this.props;
  
    // قم بإنشاء كائن طلب الدفع
    const paymentRequest = {
      MID: '', // معرّف التاجر الخاص بك
      TID: '', // معرّف المحطة الخاص بك
      AmountTrxn: this.state.AmountTrxn, // المبلغ المراد دفعه // تم تغيير الخاصية من Amount إلى AmountTrxn
      MerchantReference: '', // مرجع فريد للمعاملة
      TrxDateTime: new Date().toGMTString(), // تاريخ ووقت المعاملة // تم تغيير الخاصية من DateTimeLocalTrxn إلى TrxDateTime
      SecureHash: '', // قيمة تجزئة لضمان سلامة البيانات
      Secret: '', // المفتاح السري الخاص بك
      url: '' // العنوان الخاص بك
    };
  
    try {
      // قم بإرسال طلب إلى المسار /pay للحصول على بيانات الدفع
      const response = await axios.post('/payy', paymentRequest);
  
      // قم بتحديث الحالة بالبيانات الواردة من الخادم
      this.setState({
        reference: response.data.MerchantReference,
        secureHash: response.data.SecureHash,
        trxDateTime: response.data.TrxDateTime,
        AmountTrxn: response.data.AmountTrxn,
        MID: response.data.MID,
        TID: response.data.TID,
        Secret: response.data.Secret,
        url: response.data.url,
        SecureHash: response.data.SecureHash,
      });
  
      // قم بفتح صندوق الضوء لإدخال بيانات البطاقة
      this.setState({ lightboxOpen: true });
    } catch (error) {
      // قم بإظهار رسالة الخطأ في حالة حدوث أي خطأ
      alert(error.message);
    }
  };
  


  // قم بتعريف الوظيفة التي تتلقى رد الفعل من بوابة الدفع
  handlePaymentResponse = (data) => {
    // قم بإغلاق صندوق الضوء
    this.setState({ lightboxOpen: false });

    // قم بتحديث الحالة بحالة المعاملة
    

    // قم بإرسال طلب إلى المسار /callback للتحقق من المعاملة
    axios.post('/callback', data).then((response) => {
      // قم بإظهار رسالة التأكيد أو الخطأ التي ترسلها الخادم
      console.log(response.data);
      console.log('payment completed');
    });
  };


  // قم بتعريف الوظيفة التي تتلقى رد الفعل من بوابة الدفع
  handlePaymentResponsepaypal = (data) => {

    // قم بإرسال طلب إلى المسار /callback للتحقق من المعاملة
    axios.post('/api/orders/:orderID/callbackpaypall', data).then((response) => {
      // قم بإظهار رسالة التأكيد أو الخطأ التي ترسلها الخادم
      //console.log(response.data);
      console.log('payment completed finally');
    });
  };


  // قم بتعريف الوظيفة التي تتلقى رد الفعل من بوابة الدفع
  handlePaymentResponseerr = (data) => {
    // قم بإغلاق صندوق الضوء
  this.setState({ lightboxOpen: false });

    // قم بتحديث الحالة بحالة المعاملة
    

    // قم بإرسال طلب إلى المسار /callback للتحقق من المعاملة
    axios.post('/callbackerr', data).then((response) => {
      // قم بإظهار رسالة التأكيد أو الخطأ التي ترسلها الخادم
      alert(response.data);
      console.log('payment failed');
    });
  };

  // قم بتعريف الوظيفة التي تتعامل مع إلغاء المعاملة
  handlePaymentCancel = () => {
    // قم بإغلاق صندوق الضوء
    this.setState({ lightboxOpen: false });

    // قم بإظهار رسالة تخبر المستخدم بأنه ألغى المعاملة
    alert('You have canceled the payment. Please try again later.');
  };
   

  // نقطه نهايه كود بوابه الدفع

  setMessage = (message) => {
    this.setState({ message });
  };
    
    
  createOrder = async () => {
    try {
      // إنشاء قيمة عشوائية لـ reference
      const response = await fetch("/api/orders", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          cart: [
            {
              id: "7b92603e77ed48968e785dea20504749",
              quantity: "1",
            },
          ],
        }),
      });
  
      // تحقق من حالة الاستجابة
      if (response.status === 401) {
        alert("YOU MUST LOGIN FIRST");
        // يمكنك إعادة توجيه المستخدم إلى صفحة تسجيل الدخول هنا إذا أردت
        return;
      }
  
      const orderData = await response.json();
  
      if (orderData.id) {
        return orderData.id;
      } else {
        const errorDetail = orderData?.details?.[0];
        const errorMessage = errorDetail
          ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
          : JSON.stringify(orderData);
  
        throw new Error(errorMessage);
      }
    } catch (error) {
      console.error("Error checking user login:", error);
      this.setState({
        message: `Could not initiate PayPal Checkout...${error.message}`,
      });
    }
  };
  

  //paddle

   checkUserLogin = async () => {
    try {
      const response = await axios.get('/openpaddlepay');
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        alert("YOU MUST LOGIN FIRST");
        // يمكنك إعادة توجيه المستخدم إلى صفحة تسجيل الدخول هنا إذا أردت
       
      } else {
        console.error("Error checking user login:", error);
      }
      return null;
    }
  };

  saveTransactionData = (data) => {
      // قم بإرسال طلب إلى المسار /callback للتحقق من المعاملة
      axios.post('/paddlecallback', data).then((response) => {
        // قم بإظهار رسالة التأكيد أو الخطأ التي ترسلها الخادم
        console.log(response.data);
        console.log('payment completed finally');
      });
  };

   handleInputChange = (event) => {
    this.setState({ productName: event.target.value });
  }

  createCheckoutSession = async () => {
    const { productName } = this.state;
    try {
      const response = await axios.post('/create-checkout', { name: productName });
      this.setState({ product: response.data, error: null });
    } catch (error) {
      this.setState({ error: 'Failed to create checkout session' });
    }
  }

  openCheckout = async () => {
    const { paddle, product } = this.state;
    this.initializePaddle();
    if (paddle && product) {
      const userId = await this.checkUserLogin();
      if (userId) {
        paddle.Checkout.open({
          items: [{ priceId: "pri_01j0yv6wb8ch3qxvx0zy3k78ey", quantity: 1 }],
        });
      }
    }
  };
  loadProductDetails = async () => {
    try {
      const response = await axios.get('/get-product-details'); // افترض أن لديك endpoint للحصول على تفاصيل المنتج
      this.setState({ product: response.data, error: null });
    } catch (error) {
      this.setState({ error: 'Failed to load product details' });
    }
  };

  initializePaddle = async () => {
    const isDevelopment = process.env.REACT_APP_NODE_ENV === 'development';
    const PADDLE_TOKEN = isDevelopment ? process.env.REACT_APP_PADDLE_SANDBOX_TOKEN : process.env.REACT_APP_PADDLE_LIVE_TOKEN;
    try {
      const paddleInstance = await initializePaddle({
        environment: isDevelopment ? 'sandbox' : 'production', // أو 'production' حسب البيئة التي تعمل بها
        token: PADDLE_TOKEN, // استبدل هذا بالتوكن الخاص بك
        eventCallback: async (data) => {
          switch(data.name) {
            case "checkout.loaded":
              console.log("Checkout opened");
              break;
            case "checkout.customer.created":
              console.log("Customer created");
              break;
            case "checkout.completed":
              console.log("Checkout completed");
              await this.saveTransactionData();
              break;
            default:
              console.log(data);
          }
        }
      });
      this.setState({ paddle: paddleInstance });
    } catch (error) {
      console.error('Failed to initialize Paddle:', error);
    }
  };


  //notifications

  fetchNotifications = async () => {
    try {
      const response = await axios.get('/get-notifications');

      const notifications = response.data.notifications.map(notification => ({
        ...notification,
        formattedDate: this.formatNotificationDate(notification.date),
      }));

      const hasNewNotifications = notifications.some(notification => !notification.read);

      this.setState({ notifications, loading: false, hasNewNotifications: true });
    } catch (error) {
      console.error('Error fetching notifications:', error);
      this.setState({ error: 'Error fetching notifications', loading: false });
    }
  };

  formatNotificationDate(date) {
    const now = moment();
    const notificationDate = moment(date);
    const diff = now.diff(notificationDate, 'minutes');

    if (diff < 60) {
      return `منذ ${diff} دقيقة`;
    } else if (diff < 1440) {
      return `منذ ${Math.floor(diff / 60)} ساعة`;
    } else {
      return `منذ ${Math.floor(diff / 1440)} يوم`;
    }
  }


  async getRandomUsername(event) {
    event.preventDefault();
    try {
      const response = await axios.get('/random-username');
      const usernamerandom = response.data.username;
      this.setState({ usernamerandom }, () => {
        window.open(`/profile?username=${encodeURIComponent(usernamerandom)}`, '_blank');
      });
    } catch (error) {
      console.error('Error fetching random username:', error);
    }
  };


  render() {

    


    const { showModal, animeData, data, error, showCards, isMobile, isActive, isCharActive, dataType, activeTab, showLogin, username, message, isLoggedIn, menuOpen, profileImage, token,   amount, reference, mid, tid, secret, paymentRequest, SecureHash, trxDateTime, lightboxOpen, paymentStatus, CountryCode, CurrencyCode, Language, AmountTrxn, MID, TID, url, Secret,  initialOptions, productId, customerEmail, name, productName, product, notifications, hasNewNotifications,usernamerandom,  dailyWinner,showExpandexrank} = this.state;
    const { value } = this.state;


    
    //const { isLoggedIn, handleLogout } = this.props;

    const todayNotifications = notifications.filter(notification =>
      moment(notification.date).isSame(moment(), 'day')
    );

    const olderNotifications = notifications.filter(notification =>
      !moment(notification.date).isSame(moment(), 'day')
    );
    const parentStyle = {
      //display: 'block', // use flex layout
      justifyContent: 'center', // center the items horizontally
      alignItems: 'center', // center the items vertically
      //height: '-250vh', // set the height to 100% of the viewport height
      //margintop: '100px', // set the margin to auto
      //padding: '280px',
      position: 'relative',
      margin: '10px',
       float: 'right'
      
    };

    const cardStyle = {
      width: isMobile ? '100%' : '75%',
      height: isMobile ? '400px' : 'auto', // تعديل ارتفاع البطاقة
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      borderRadius: '12px',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',

      position: 'relative',
      float: 'right',
      //margintop: '20px', // إضافة هذا الخاصية لإضافة بعض المسافة بين البطاقات
      
      
    };


    const commStyle = {
      overflow: 'hidden',
      display: 'flex',
      width: '95%',
      height: 'auto',
      boxShadow: '20px 20px 60px #bebebe, -20px -20px 60px #ffffff',
      background: 'linear-gradient(90deg, rgba(255,255,255,0.9) 0%, rgba(255,255,255,0.7) 50%, rgba(255,255,255,0.9) 80%)',
      outline: '3px solid #f5f5f5',
      outlineOffset: '-3px',
      position: 'relative',
      color: 'black',
  fontWeight: 'bold',
  fontSize: '16px',
    }
    
    

    
    const imageStyle = {
      width: '150px', // قم بتعديل عرض الصورة حسب الحاجة
      height: '150px', // قم بتعديل ارتفاع الصورة حسب الحاجة
      objectFit: 'cover', // تعديل تنسيق الصورة لتمتلئ داخل حاوية الصورة بشكل كامل دون تشويه النسبة
      borderRadius: '50%', // إضافة حواف دائرية للصورة
    };


    
      const avatarStyle = {

        width: '90px', // قم بتعديل عرض الصورة حسب الحاجة
      height: '90px', // قم بتعديل ارتفاع الصورة حسب الحاجة
      objectFit: 'cover', // تعديل تنسيق الصورة لتمتلئ داخل حاوية الصورة بشكل كامل دون تشويه النسبة
      borderRadius: '50%', // إضافة حواف دائرية للصورة


      };

      const ButtonBox = styled(Box)({
        position: 'relative',
        marginTop: '30px',
        marginBottom: '30px',
        boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.16)',
        borderRadius: '10px',
        overflow: 'hidden',
        background: 'linear-gradient(to right, rgba(255, 0, 0, 1), rgba(0, 255, 0, 1))',
      });
      
      const StyledTabs = styled(Tabs)({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: 'bold',
        fontSize: '2.5rem',
        color: 'white',
        textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',
      });
      
      const StyledTab = styled(Tab)(({ theme }) => ({
        fontSize: '1.6rem',
        textShadow: '2px 2px 4px rgba(0, 0, 0, 1.3)',
        background: 'linear-gradient(to right, rgba(255, 0, 0, 1), rgba(0, 255, 0, 1))',
        borderRadius: '10px',
        margin: '0 10px',
        padding: '10px 20px',
        boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.16)',
        '&.Mui-selected': {
          background: theme.palette.primary.main,
        },
      }));

    
      

    

    
    

    

    

    
    return (

      

      

      <HelmetProvider>
      
      <div>



      <Helmet>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />

      <title>المحنكين</title>
      <meta name="description" content="المحنكين هو موقع متخصص في تقييم ومراجعة الأنمي بالعالم العربي. نحن محطتك بعد كل حلقه لايحتاج ان تكون مشهورا حتى تعبر عن رأيك عالم التقييم والحنكه ممتع اكثر مما تظن." />
      <meta property="og:title" content="المحنكين" />
      <meta property="og:description" content="المحنكين هو موقع متخصص في تقييم ومراجعة الأنمي بالعالم العربي. نحن محطتك بعد كل حلقه لايحتاج ان تكون مشهورا حتى تعبر عن رأيك عالم التقييم والحنكه ممتع اكثر مما تظن." />
      <meta property="og:image" content="https://i.imgur.com/D4N14v1.jpg" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://mohankeen.com/" />
      <meta name="keywords" content="تقييم الأنمي, مراجعة الأنمي, أفضل الأنميات, المحنكين" />
      <meta name="author" content="المحنكين" />
      <meta property="og:site_name" content="MOHANKEEN" />
      <meta property="og:locale" content="ar_AR" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="المحنكين - تقييم الأنمي" />
      <meta name="twitter:description" content="المحنكين هو موقع متخصص في تقييم ومراجعة الأنمي بالعالم العربي. نحن محطتك بعد كل حلقه لايحتاج ان تكون مشهورا حتى تعبر عن رأيك عالم التقييم والحنكه ممتع اكثر مما تظن." />
      <meta name="twitter:image" content="https://i.imgur.com/D4N14v1.jpg" />
      <link rel="canonical" href="https://mohankeen.com/" />
      <meta name="robots" content="index, follow" />
      <meta property="og:updated_time" content={new Date().toISOString()} />
      <meta property="og:author" content="المحنكين" />
      <meta property="article:section" content="Anime Rating" />
      <meta property="article:tag" content="تقييم الأنمي, مراجعة الأنمي, أفضل الأنميات" />


         {/* إضافة البيانات المنظمة */}
         <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "المحنكين - الصفحة الرئيسية",
                "description": "المحنكين هو موقع متخصص في تقييم ومراجعة الأنمي بالعالم العربي. نحن محطتك بعد كل حلقة لا يحتاج أن تكون مشهورًا حتى تعبر عن رأيك. عالم التقييم والحنكة ممتع أكثر مما تظن.",
                "url": "https://mohankeen.com/",
                "potentialAction": [
                  {
                    "@type": "Action",
                    "target": {
                      "@type": "EntryPoint",
                      "urlTemplate": "https://mohankeen.com/login"
                    },
                    "name": "تسجيل الدخول",
                    "description": "احصل على بروفايل تسجل فيه كل تفضيلاتك وصفاتك بعالم الانمي"
                  },
                  {
                    "@type": "CommentAction",
                    "target": {
                      "@type": "EntryPoint",
                      "urlTemplate": "https://mohankeen.com/"
                    },
                    "name": "تعليق علني",
                    "description": "قم بتعليق علني يراه الجميع لاتحتاج ان تكون مشهورا ليشاهدوا رأيك"
                  },
                  {
                    "@type": "WatchAction",
                    "target": {
                      "@type": "EntryPoint",
                      "urlTemplate": "https://mohankeen.com/"
                    },
                    "name": "شاهد مع رفيق",
                    "description": "ابحث عن سيرفر ديسكورد أو قنوات تويتش وكيك وسيتم اقتراح الأشخاص المتوافقين معك بتفضيلات الشخصيات لمشاهدة الأنمي معه"
                  },
                  {
                    "@type": "ReviewAction",
                    "target": {
                      "@type": "EntryPoint",
                      "urlTemplate": "https://mohankeen.com/"
                    },
                    "name": "تقييم الأنميات والشخصيات والحلقات",
                    "description": "قيم أنمياتك وشخصياتك المفضلة وشاهد رد المختلفين معك بالرأي عليك"
                  },
                  {
                    "@type": "ViewAction",
                    "target": {
                      "@type": "EntryPoint",
                      "urlTemplate": "https://mohankeen.com/mohankeenawards"
                    },
                    "name": "جوائز السنة والأرقام القياسية",
                    "description": "تصفح أغرب الجوائز والأرقام القياسية لعالم الأنمي"
                  },
                  {
                    "@type": "VoteAction",
                    "target": {
                      "@type": "EntryPoint",
                      "urlTemplate": "https://mohankeen.com/pentagonallist"
                    },
                    "name": "القوائم الخمس لموقع المحنكين",
                    "description": "التصويت لشخصيات الأنمي المستمرة بقوائم أفضل المحاربين، أسوأ الحثالات، أجمل الذكور والإناث، أفضل شخصيات كوميدية"
                  }
                ]
              }
            `}
          </script>
    </Helmet>

{/*
<div className="paddle">
       
{product ? (
          <div>
              
            <h2>Product Details</h2>
            <p>Product ID: {product.id}</p>
              
            <p>Product Name: {product.name}</p>

            <button onClick={this.openCheckout}>Pay with Paddle</button>
          </div>
        ) : (
          <p>Loading product details...</p>
        )}
        {error && <p style={{ color: 'red' }}>{error}</p>}
      </div>


       
<div className="paypal">
      <PayPalScriptProvider options={initialOptions}>
        <PayPalButtons
          style={{
            shape: "rect",
            layout: "vertical",
          }}
          createOrder={this.createOrder} // استدعاء الدالة الخارجية هنا
          onApprove={async (data, actions) => {
            try {
              const response = await fetch(
                `/api/orders/${data.orderID}/capture`,
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                },
              );
            
              const orderData = await response.json();
              // Three cases to handle:
              //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
              //   (2) Other non-recoverable errors -> Show a failure message
              //   (3) Successful transaction -> Show confirmation or thank you message

              const errorDetail = orderData?.details?.[0];

              if (errorDetail?.issue === "INSTRUMENT_DECLINED") {
                // (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                // recoverable state, per https://developer.paypal.com/docs/checkout/standard/customize/handle-funding-failures/
                return actions.restart();
              } else if (errorDetail) {
                // (2) Other non-recoverable errors -> Show a failure message
                throw new Error(
                  `${errorDetail.description} (${orderData.debug_id})`,
                );
              } else {
                // (3) Successful transaction -> Show confirmation or thank you message
        
      this.handlePaymentResponsepaypal(); // استدعاء الدالة هنا
    
                // Or go to another URL:  actions.redirect('thank_you.html');
                const transaction = orderData.purchase_units[0].payments.captures[0];
                alert(`Transaction ${transaction.status}: ${transaction.id}`);
                
                console.log(
                  "Capture result",
                  orderData,
                  JSON.stringify(orderData, null, 2),
                );
              }
            } catch (error) {
              console.error(error);
              alert(`Sorry, your transaction could not be processed...${error}`);

            }
          }}
        />
      </PayPalScriptProvider>
      <Message content={message} />
    </div>

*/}
{/*
<div className="payment">
        
        <p>The amount to be paid is {this.state.amount} USD</p>
        <button onClick={this.startPayment} style={{ marginLeft: "1200px" }}>
        Pay Now
      </button>
      {this.state.lightboxOpen && (
      <Lightbox
      MID={this.state.MID}
      TID={this.state.TID}
      AmountTrxn={this.state.AmountTrxn} // تم تغيير الخاصية من Amount إلى AmountTrxn
     
      MerchantReference={this.state.reference}
      TrxDateTime={this.state.trxDateTime} // تم تغيير الخاصية من DateTimeLocalTrxn إلى TrxDateTime
      completeCallback={this.handlePaymentResponse}
      errorCallback={this.handlePaymentResponseerr}
      //cancelCallback={this.handlePaymentCancel}
      Secret={this.state.Secret} // قيمة المفتاح السري الخاص بك
     
      SecureHash={this.state.SecureHash}
      
       
      
      
    />
    
      )}

      </div>
    */}
  

 
<title>المحنكين</title>{/* العنوان */}
<div id="box">
        <img 
        className="logohome"
          src="https://i.imgur.com/4prsJLG.png" 
          alt="شعار الموقع" 
         
        />
      </div>

      {( !isMobile && (
      <div className="sidebar">
        <h5 className="sidebar-title">أدوات المحنك</h5>
        <ul className="sidebar-list">
          <li className="sidebar-item" style={{ backgroundImage: 'url(https://i.imgur.com/PPLaKaJ.jpg)' }}>
            <a className="sidebar-link" onClick={this.getRandomUsername}>
                عرض بروفايل عشوائي لتقييمه وتصفح ذائقته
            </a>
          </li>
          <li className="sidebar-item" style={{ backgroundImage: 'url(https://i.imgur.com/sh42EcV.jpg)' }}>
            <Link className="sidebar-link" to={`/mohankeenawards`} target="_blank">
              الأرقام القياسية وجوائز السنة
            </Link>
          </li>
          <li className="sidebar-item" style={{ backgroundImage: 'url(https://i.imgur.com/dBj8l1M.png)' }}>
            <Link className="sidebar-link" to={`https://x.com/mohankeen`} target="_blank">
              حسابنا في منصة اكس
            </Link>
          </li>
          <li className="sidebar-item" style={{ backgroundImage: 'url(https://i.imgur.com/Fh8nDH5.jpg)' }}>
            <a className="sidebar-link" href="mailto:mohankeen26034@gmail.com?subject=إعلان&body=أود الإعلان على موقعكم">
              هل تريد الاعلان لدينا؟
            </a>
          </li>
          <li className="sidebar-item" style={{ backgroundImage: 'url(https://i.imgur.com/wVoqbk0.jpg)' }}>
            <Link className="sidebar-link" to={`https://buymeacoffee.com/mohankeen26034`} target="_blank">
              دعم مادي
            </Link>
          </li>
        </ul>
      </div>
       )  )}
        <nav className="navbar navbar-expand-lg d-block d-lg-none">
        <button
          className="navbar-toggler"
          type="button"
          onClick={this.handleShow}
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
          data-bs-backdrop="false"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <Offcanvas
          show={this.state.show}
          onHide={this.handleClose}
          tabIndex={-1}
          id="navbarNav"
          aria-labelledby="navbarNavLabel"
        >
          <Offcanvas.Header>
            <Offcanvas.Title id="navbarNavLabel">أدوات المحنك</Offcanvas.Title>
            <button
              type="button"
              className="btn-close text-reset"
              onClick={this.handleClose}
              aria-label="Close"
            />
          </Offcanvas.Header>
          <Offcanvas.Body>
            <ul className="navbar-nav">
              <li className="nav-item">
               
                <a className="nav-link active"   onClick={this.getRandomUsername} style={{ cursor: 'pointer' }}>
              عرض بروفايل عشوائي لتقييمه وتصفح ذوقه
              <img src="https://i.imgur.com/PPLaKaJ.jpg" alt="Random Profile" className="button-iconx"  />
            </a>
                
              </li>
              <li className="nav-item">
              <a className="nav-link active"   style={{ cursor: 'pointer' }}>
            <Link to={`/mohankeenawards`}   target="_blank"  >
              الأرقام القياسية وجوائز السنة
              <img src="https://i.imgur.com/sh42EcV.jpg" alt="mohankeen awards" className="button-iconx"  />
              </Link>
            </a>
              </li>
              <li className="nav-item">
              <a className="nav-link active"   style={{ cursor: 'pointer' }}>
            <Link to={`https://x.com/mohankeen`}   target="_blank"  >
              حسابنا في منصه اكس
              <img src="https://i.imgur.com/dBj8l1M.png" alt="twitter" className="button-iconx"  />
              </Link>
            </a>
              </li>
                      
             
            
              <li className="nav-item">
          <a className="nav-link active"   style={{ cursor: 'pointer' }} href="mailto:mohankeen26034@gmail.com?subject=إعلان&body=أود الإعلان على موقعكم">
            
            هل تريد الاعلان لدينا؟
            <img src="https://i.imgur.com/Fh8nDH5.jpg"  className="button-iconx"  />
          </a>
        </li>

              <li className="nav-item">
              <a className="nav-link active"   style={{ cursor: 'pointer' }}>
            <Link to={`https://buymeacoffee.com/mohankeen26034`}   target="_blank"  >
              دعم مادي
             
              </Link>
            </a>
              </li>
               {/*
              <li className="nav-item">
                <a className="nav-link" href="#">
                  خدمه العملاء
                </a>
              </li>
             
              <li className="nav-item">
                <a className="nav-link" href="#">
                  اقتراحات لتحسين الموقع؟
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  أفضل منتجات الانمي
                </a>
              </li>
              */}
            </ul>
          </Offcanvas.Body>
        </Offcanvas>
      </nav>
   
      

        <style dangerouslySetInnerHTML={{__html: "\n     .navbar-light .navbar-toggler-icon {\n      background-image: url(\"data:image/svg+xml,%3csvg xmlns='http://www.w3.orq/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e\"); \n  display: inline-block;\n  width: 2.2rem;\n  height: 3rem;\n  background-repeat: no-repeat;\n  background-position: center;\n  background-size: 70%;\n  border-radius: 0.25rem;\n  transition: transform 0.2s ease-in-out;\n}\n.navbar-toggler[aria-expanded=\"true\"] .navbar-toggler-icon {\n  transform: rotate(90deg);\n}\n      .nav-link:hover {\n  color: red;\n}\n.nav-link:active {\n  color: green;\n}\n.nav-link {\n  padding: 1rem;\n}\n.nav-link:hover {\n  color: red;\n}\n\n.nav-link:active {\n  color: green;\n}\n\n.nav-link {\n  padding: 1rem;\n}\n     " }} />
        <style dangerouslySetInnerHTML={{__html: "\n      \n      \n      .navbar-nav .nav-link {\n  border-bottom: 1px solid rgba(0,0,0,.1);\n  padding: 10px 15px;\n}\n      /* تصميم زر الهامبرغر */\n.navbar-toggler-icon {\n  background-image: url('data:image/svg+xml;charset=UTF-8,%3csvg viewBox=\"0 0 30 30\" xmlns=\"http://www.w3.org/2000/svg\"%3e%3cpath stroke=\"rgba(0, 0, 0, 1)\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-miterlimit=\"10\" d=\"M4 7h22M4 15h22M4 23h22\"/%3e%3c/svg%3e');\n}\n\n.navbar-toggler:focus, .navbar-toggler:active {\n  outline: none;\n  box-shadow: none;\n}\n\n/* تصميم القائمة الجانبية */\n.offcanvas-header {\n  background-color: #ffc107;\n}\n\n.offcanvas-body {\n  background-color: #fff;\n  border: none;\n}\n\n.navbar-nav .nav-link {\n  color: #333;\n  font-weight: 600;\n  font-size: 18px;\n  transition: color 0.3s ease-in-out;\n}\n\n.navbar-nav .nav-link:hover {\n  color: #ffc107;\n}\n     " }} />
        {/* زر السهم لفتح النافذة*/}
        <button
          className="bottom-modal-btn" onClick={this.toggleModal}
        >
         
        </button>
      



        {/* نافذة العناصر*/}

        
        
          
        
        
          
        
          
          <div className={`bottom-modal ${showModal ? "show" : ""}`}>
         
          
          <div className="bottom-modal-content">
            <div className="container">
              <div className="row">
                <div className="col-md-4">
                  <div className="card">
                  <Link to={`/pentagonallist`}  className="linkbuttonzz" target="_blank"  >
                  <div className="card-body">
                      <h5 className="card-title">القوائم الخمس للشخصيات المستمرة</h5>
                      <p className="card-text">القوائم الخمس هي 5 انواع من قوائم ترتيب خاصه بشخصيات الانميات المستمره فقط وتعمل بالتصويت قم بالتصويت  للشخصيات اللذي تحبها  وستأخذ الشخصيات جنسيه المصوتين حسب اكثريه الاصوات  اجعل شخصيتك المفضله بالمراكز الاولى واجعلها تنتمى لوطنك واعرف الشخصيات المحببه لأهل وطنك وللمصوتين بنفس عمرك وجنسك طلع كل العنصريه اللي داخلك !!  مع وجود فلاتر لفرز الاصوات حسب بلدك او عمرك او جنسك </p>
                    </div>
            </Link>
                  
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card">
                  <Link to={`/mohankeenawards`}  className="linkbuttonzz" target="_blank"  >
                  <div className="card-body">
                      <h5 className="card-title">الأرقام القياسية وجوائز السنة</h5>
                      <p className="card-text">يقوم مدراء الموقع، من ذوي المقامات الرفيعة والخبرة العميقة والخضرمه اللي مو طبيعيه، بتوثيق الأرقام القياسية للأنميات والشخصيات دون كلل، سواء بالمدح أو النقد. ويعلنون جوائز العام للأنميات بانتظام، في سعيهم المتواصل للرقي بالمحتوى وتثقيف قليلين الخضرمه. جزاهم الله خير الجزاء على سخاء عطائهم وطيب فضلهم على مجتمع الحنكه والمحنكين ووفقهم الله. </p>
                    </div>
            </Link>
                  </div>
                </div>
                {/*
                <div className="col-md-4">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">وايفوز</h5>
                      <p className="card-text">عبر عن حبك وسيتم تسجيل الوايفو اللذي تريدها في حسابك عن طريق دفع مهرها او ان تكون ضمن توب 100 شخص ساهم في رفع تقييم الشخصيه بالموقع</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">شاهد الانمي مع رفيق</h5>
                      <p className="card-text">ابحث عن اشخاص يشاهدون الانمي اللذي تحبه وانضم اليهم في ديسكورد او في مختلف المنصات</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">احزاب الفانزات</h5>
                      <p className="card-text">وضح لنا اذا كنت متعصبا او ضمن حزب فانزات معين مشهور او قم بااضافه حزب غير موجود بالموقع</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">تجنيد محنكين للرد على رأي معطوب بالانترنت</h5>
                      <p className="card-text">قم بطلب مساعده من محنكين يتفقون معك بالرأي للرد في نقاش محنك معين في اي مكان بالانترنت وسوف تصلك المساعده بسرعه ايها القائد</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">مناقشات صوتيه</h5>
                      <p className="card-text">مجلس طيب مع نقاش عظيم عن اخر الاحداث بالانميات او عن موضوع محنك معين هو شي لايستغني عنه اي محنك قم بفتح غرفه صوتيه وسينضم لك الاخرون او انضم الى غرفه صوتيه بشكل عشوائي وقم بتقييم المتناقشين ايضا</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">كوسبلاي</h5>
                      <p className="card-text">قم بتقييم وتصفح الكوسبلايات وتطهير عينك بالجمال الواقعي</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">توصيات انمي</h5>
                      <p className="card-text">هل انت فقير من الانميات حاليا؟ ابحث عن انمي تشاهده بسرعه بدقه عن طريق خاصيه البحث العظيمه لدينا ويمكنك حتى مشاهده توصيه عشوائيه</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">فان انيميشن</h5>
                      <p className="card-text">تصفح فان انيميشن لمختلف الانميات وقم بتقييمها كمحنك</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">اخبار</h5>
                      <p className="card-text">اعرف اخر الاخبار الطارئه للانميات الترند في لحظات دون بحث</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">نظريات</h5>
                      <p className="card-text">قم بتحليل اخر الحلقات اللتي شاهدتها وصناعه نظريات واذا تحققت نظريتك سوف تزيد نقاطك وترتيبك بين المحنكين</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">روايات</h5>
                      <p className="card-text">تصفح روايات قصيره او طويله من صناعه محنكين عرب وقيمها او حقق حلمك واستخدمنا كمنصه نشر مجانيه لايصال تحفتك الفنيه الى جميع متابعين الانمي العرب لن يتم تضييع اي عمل عظيم </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">مدونه او ثريد</h5>
                      <p className="card-text">قم بعمل ثريد لتوضيح اشياء مهمه عن انمي او حلقه او شخصيه معينه هناك اشياء لايلاحظها الجميع وتلميحات من الكاتب للمشاهدين</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">مترجمين</h5>
                      <p className="card-text">قم بتحميل حلقه الانمي مع ترجمه مثاليه باعلى جوده لاتشاهد شيئا رخيصا</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">مانجا</h5>
                      <p className="card-text">تصفح مانجا من صناعه محنكين عرب وقيمها او حقق حلمك واستخدمنا كمنصه نشر مجانيه لايصال تحفتك الفنيه الى جميع متابعين الانمي العرب</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">فان ارت</h5>
                      <p className="card-text">الرسم والفن عالم واسع احصل على القطع الفنيه النادره للانميات والشخصيات اللتي تهمك من هنا واربح المال من طلبات من يحبون رسمك</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">الايديت</h5>
                      <p className="card-text">شاهد التصاميم للانمي او الشخصيه اللذي تختارها دون عناء البحث في مكان اخر كل التصاميم مجتمعه هنا واربح المال من طلبات من يحبون تصميمك</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">ميمز</h5>
                      <p className="card-text">هل تريد ميمز عن انمي او شخصيه او شي معين؟ تعال واضحك بعض الوقت الضحك مجاني</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">تأديه الاصوات</h5>
                      <p className="card-text">هذا مجال مظلوم في مجتمعنا ويجب ان نقوم بتقدير اصحاب هذا الفن ونقيمهم ونحسنهم ويمكنك تأديه مقطع معين بنفسك</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">منتجات الانمي</h5>
                      <p className="card-text">مرجع لجميع منتجات الانمي المميزه حول الوطن العربي  مع تفاصيلها واماكن شراءها</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">طبخات انمي</h5>
                      <p className="card-text">اعرف مكونات ووصفات اشهر الاطباق المشهوره بالانميات او المفضله للشخصيات</p>
                    </div>
                   
                  </div>
                </div>
                 */}
              </div>
            </div>
          </div>
        </div>



 

       
        <div>
        {/* استخدام عامل && لعرض أو إخفاء زر تسجيل الدخول */}
        {!this.state.isLoggedIn && (
            <Link to="/login" className="active">
              <Button
                className="loginb"
              
              >
                Login/Sign Up
              </Button>
            </Link>
          )}

          {/* استخدام مكون Dropdown لعرض أو إخفاء زر الملف الشخصي */}
          {this.state.isLoggedIn && (
            <Dropdown
              className="logind"
            
            >
              <Dropdown.Toggle
    variant="primary"
    className="profile-button"
    id="dropdown-basic"
  >
    <img
      src={this.state.profileImage}
      alt="profile"
      className="profile-image"
    />
    <span className="profile-name">{this.state.username}</span>
  </Dropdown.Toggle>

              <Dropdown.Menu className="aactive">
             
<Dropdown.Item as={Link} to={`/profile?username=${this.state.username}`} className="aaactive" style={{ color: '#fff', fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.8)', borderRadius: '0', borderBottom: '1px solid #666' }}>

                  Profile
                </Dropdown.Item>
                <Dropdown.Item className="aaaactive" style={{ color: '#fff', fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.8)', borderRadius: '0', borderBottom: '1px solid #666' }} onClick={this.logout}>Logout</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
      </div>
   
      {dailyWinner ? (
          <div>
            {/*
            <img src={dailyWinner.profileImage} alt={`${dailyWinner.username}'s profile`} />
            <h2>{dailyWinner.username}</h2>
            <p>نقاط الخبرة: {dailyWinner.expertisePoints}</p>
            <p>عدد المنافسين: {dailyWinner.competitorsCount}</p>
            <p>تاريخ: {new Date(dailyWinner.date).toLocaleDateString()}</p>
*/}
           <div className="openprize" >
           <div class="cards"onClick={() => this.handleExpandexrank()}>
  <div class="outlinePage">
    <svg
      class="icon trophy"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="160"
      height="160"
    >
      <path
        d="M469.333333 682.666667h85.333334v128h-85.333334zM435.2 810.666667h153.6c4.693333 0 8.533333 3.84 8.533333 8.533333v34.133333h-170.666666v-34.133333c0-4.693333 3.84-8.533333 8.533333-8.533333z"
        fill="#ea9518"
        data-spm-anchor-id="a313x.search_index.0.i10.40193a81WcxQiT"
        class=""
      ></path>
      <path
        d="M384 853.333333h256a42.666667 42.666667 0 0 1 42.666667 42.666667v42.666667H341.333333v-42.666667a42.666667 42.666667 0 0 1 42.666667-42.666667z"
        fill="#6e4a32"
        data-spm-anchor-id="a313x.search_index.0.i1.40193a81WcxQiT"
        class=""
      ></path>
      <path
        d="M213.333333 256v85.333333a42.666667 42.666667 0 0 0 85.333334 0V256H213.333333zM170.666667 213.333333h170.666666v128a85.333333 85.333333 0 1 1-170.666666 0V213.333333zM725.333333 256v85.333333a42.666667 42.666667 0 0 0 85.333334 0V256h-85.333334z m-42.666666-42.666667h170.666666v128a85.333333 85.333333 0 1 1-170.666666 0V213.333333z"
        fill="#f4ea2a"
        data-spm-anchor-id="a313x.search_index.0.i9.40193a81WcxQiT"
        class=""
      ></path>
      <path
        d="M298.666667 85.333333h426.666666a42.666667 42.666667 0 0 1 42.666667 42.666667v341.333333a256 256 0 1 1-512 0V128a42.666667 42.666667 0 0 1 42.666667-42.666667z"
        fill="#f2be45"
        data-spm-anchor-id="a313x.search_index.0.i5.40193a81WcxQiT"
        class=""
      ></path>
      <path
        d="M512 469.333333l-100.309333 52.736 19.157333-111.701333-81.152-79.104 112.128-16.298667L512 213.333333l50.176 101.632 112.128 16.298667-81.152 79.104 19.157333 111.701333z"
        fill="#FFF2A0"
      ></path>
    </svg>
    <p class="ranking_number">1<span class="ranking_word">st.محنك اليوم</span></p>
    <div class="splitLine"></div>
    <img  className="userAvatarprize" src={dailyWinner.profileImage} alt={`${dailyWinner.username}'s profile`} />
    <p class="userNameprize">{dailyWinner.username}</p>
  </div>
  <div class="detailPage">
    <svg
      class="icon medals slide-in-top"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="80"
    >
      <path
        d="M896 42.666667h-128l-170.666667 213.333333h128z"
        fill="#FF4C4C"
      ></path>
      <path
        d="M768 42.666667h-128l-170.666667 213.333333h128z"
        fill="#3B8CFF"
      ></path>
      <path d="M640 42.666667h-128L341.333333 256h128z" fill="#F1F1F1"></path>
      <path
        d="M128 42.666667h128l170.666667 213.333333H298.666667z"
        fill="#FF4C4C"
      ></path>
      <path
        d="M256 42.666667h128l170.666667 213.333333h-128z"
        fill="#3B8CFF"
      ></path>
      <path
        d="M384 42.666667h128l170.666667 213.333333h-128z"
        fill="#FBFBFB"
      ></path>
      <path
        d="M298.666667 256h426.666666v213.333333H298.666667z"
        fill="#E3A815"
      ></path>
      <path
        d="M512 661.333333m-320 0a320 320 0 1 0 640 0 320 320 0 1 0-640 0Z"
        fill="#FDDC3A"
      ></path>
      <path
        d="M512 661.333333m-256 0a256 256 0 1 0 512 0 256 256 0 1 0-512 0Z"
        fill="#E3A815"
      ></path>
      <path
        d="M512 661.333333m-213.333333 0a213.333333 213.333333 0 1 0 426.666666 0 213.333333 213.333333 0 1 0-426.666666 0Z"
        fill="#F5CF41"
      ></path>
      <path
        d="M277.333333 256h469.333334a21.333333 21.333333 0 0 1 0 42.666667h-469.333334a21.333333 21.333333 0 0 1 0-42.666667z"
        fill="#D19A0E"
      ></path>
      <path
        d="M277.333333 264.533333a12.8 12.8 0 1 0 0 25.6h469.333334a12.8 12.8 0 1 0 0-25.6h-469.333334z m0-17.066666h469.333334a29.866667 29.866667 0 1 1 0 59.733333h-469.333334a29.866667 29.866667 0 1 1 0-59.733333z"
        fill="#F9D525"
      ></path>
      <path
        d="M512 746.666667l-100.309333 52.736 19.157333-111.701334-81.152-79.104 112.128-16.298666L512 490.666667l50.176 101.632 112.128 16.298666-81.152 79.104 19.157333 111.701334z"
        fill="#FFF2A0"
      ></path>
    </svg>
    <div class="gradesBox">
      <svg
        class="icon gradesIcon"
        viewBox="0 0 1024 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width="60"
        height="60"
      >
        <path
          d="M382.6 805H242.2c-6.7 0-12.2-5.5-12.2-12.2V434.3c0-6.7 5.5-12.2 12.2-12.2h140.4c6.7 0 12.2 5.5 12.2 12.2v358.6c0 6.6-5.4 12.1-12.2 12.1z"
          fill="#ea9518"
          data-spm-anchor-id="a313x.search_index.0.i36.40193a81WcxQiT"
          class=""
        ></path>
        <path
          d="M591.1 805H450.7c-6.7 0-12.2-5.5-12.2-12.2V254.9c0-6.7 5.5-12.2 12.2-12.2h140.4c6.7 0 12.2 5.5 12.2 12.2v537.9c0 6.7-5.5 12.2-12.2 12.2z"
          fill="#f2be45"
          data-spm-anchor-id="a313x.search_index.0.i35.40193a81WcxQiT"
          class=""
        ></path>
        <path
          d="M804.4 805H663.9c-6.7 0-12.2-5.5-12.2-12.2v-281c0-6.7 5.5-12.2 12.2-12.2h140.4c6.7 0 12.2 5.5 12.2 12.2v281c0.1 6.7-5.4 12.2-12.1 12.2z"
          fill="#ea9518"
          data-spm-anchor-id="a313x.search_index.0.i37.40193a81WcxQiT"
          class=""
        ></path>
      </svg>
      <p class="gradesBoxLabel">نقاط حنكة</p>
      <p class="gradesBoxNum">{dailyWinner.expertisePoints}</p>
      <p class="gradesBoxNumx"> تغلب على {dailyWinner.competitorsCount} مرشح للجائزة </p>
    </div>
  </div>
</div>
    {showExpandexrank &&  (

<div className="expanded-overlayssx">
<div className="expanded-cardssxrankx">

<button className="close-button" onClick={this.handleExpandexrank}>×</button>

  
   
     <div className="ranking-info">
    

          <p>
            جائزة محنك اليوم هي جائزه يتم منحها تلقائيا يوميا الساعه 6 مساءا بتوقيت السعودية حسب خوارزميات معينه حيث يشترط حتى تكون مرشح للجائزه ان تكون قمت بنشر تعليق علني في اي قائمه من قوائم الموقع وسيتم اضافتك للمرشحين وسيتم حساب نقاط الحنكه اللذي اكتسبتها لهذا اليوم عن طريق بضعه عوامل مثل عدد اعجابات وعدم الاعجابات لتعليقاتك العلنيه لهذا اليوم او اي اوسمه شرفيه او اي تقييمات بحسابك حصلت عليها لهذا اليوم وسيتم احتساب عد المنافسين اللذي تفوقت عليهم وبيانات اخرى فمثلا الفائز بالجائزه لهذا اليوم ...
          </p>
          <div className="winner-example">
          <div class="containerprize">
   <svg class="svg-icon" height="100" preserveAspectRatio="xMidYMid meet" viewBox="0 0 100 100" width="100" x="0" xmlns="http://www.w3.org/2000/svg" y="0">
    <path d="M62.11,53.93c22.582-3.125,22.304-23.471,18.152-29.929-4.166-6.444-10.36-2.153-10.36-2.153v-4.166H30.099v4.166s-6.194-4.291-10.36,2.153c-4.152,6.458-4.43,26.804,18.152,29.929l5.236,7.777v8.249s-.944,4.597-4.833,4.986c-3.903,.389-7.791,4.028-7.791,7.374h38.997c0-3.347-3.889-6.986-7.791-7.374-3.889-.389-4.833-4.986-4.833-4.986v-8.249l5.236-7.777Zm7.388-24.818s2.833-3.097,5.111-1.347c2.292,1.75,2.292,15.86-8.999,18.138l3.889-16.791Zm-44.108-1.347c2.278-1.75,5.111,1.347,5.111,1.347l3.889,16.791c-11.291-2.278-11.291-16.388-8.999-18.138Z">
    </path>
  </svg>  
  <div class="container__star">
     
    <div class="star-eight"></div>
  </div>
  
<div></div></div>
              <img src={dailyWinner.profileImage} alt={`${dailyWinner.username}'s profile`} onClick={() => window.open(`/profile?username=${dailyWinner.username}`, '_blank', 'noopener,noreferrer')}/>
              <h2>{dailyWinner.username}</h2>
              <p>نقاط الحنكة: {dailyWinner.expertisePoints}</p>
              <p>عدد المنافسين اللذي هزمهم: {dailyWinner.competitorsCount}</p>
              <p>تاريخ الجائزة: {new Date(dailyWinner.date).toLocaleDateString()}</p>
            </div>
                  </div>
                


                </div>
              </div>
            )} 

</div>
           






          </div>

//البطاقه






        ) : (
          <p></p>
        )}



        <div >
        <Box
        className="tabsbox"
        style={{
          position: 'relative',
          marginTop: '30px',
          minWidth:'730px',
          backgroundColor: 'linear-gradient(to right, #000000, #ffffff)',
          marginBottom: '30px',
 
         
          
        }}
      >
        <Tabs
        className="tabsgroup"
          value={value}
          onChange={this.handleChange}
          centered
          /*
          style={{
            margin: '110px',
            display: 'flex',
            flexDirection: 'column',
            fontWeight: 'bold',
            fontSize: '2.5rem',
            color: '#ffffff',
           minWidth:'100%',
           //marginLeft: '400px',
            textShadow: '20px 2px 4px rgba(0, 0, 0, 0.3)',
            
          }}
            */
        >
          <Tab
          className="tabsone"
            label="اعظم انميات للابد"
            style={{
              fontSize: '1.6rem',
              textShadow: '2px 2px 4px rgba(0, 0, 0, 1.3)',
                background: 'linear-gradient(to top, #ffffff, #000000)',
              borderRadius: '15px',
              margin: '0 10px',
              padding: '10px 20px',
              color: '#ffffff',
              boxShadow: '0 0 10px #fff, 0 0 20px #fff, 0 0 80px #0ff, 0 0 100px #0ff, 0 0 150px #0ff',
              textdecoration: 'none',
              
              
            }}
          />
          <Tab
          className="tabsone"
            label="اعظم شخصيات للابد"
            style={{
              fontSize: '1.6rem',
              textShadow: '2px 2px 4px rgba(0, 0, 0, 1.3)',
                background: 'linear-gradient(to top, #ffffff, #000000)',
              borderRadius: '15px',
              margin: '0 10px',
              padding: '10px 20px',
              color: '#ffffff',
              boxShadow: '0 0 10px #fff, 0 0 20px #fff, 0 0 80px #0ff, 0 0 100px #0ff, 0 0 150px #0ff',
              textdecoration: 'none',
              
            }}
          />
          <Tab
          className="tabsone"
            label="افضل الحلقات الاسبوعيه"
            style={{
              fontSize: '1.6rem',
              textShadow: '2px 2px 4px rgba(0, 0, 0, 1.3)',
                background: 'linear-gradient(to top, #ffffff, #000000)',
              borderRadius: '15px',
              margin: '0 10px',
              padding: '10px 20px',
              color: '#ffffff',
              boxShadow: '0 0 10px #fff, 0 0 20px #fff, 0 0 80px #0ff, 0 0 100px #0ff, 0 0 150px #0ff',
              textdecoration: 'none',
              
              
            }}
          />
         
        </Tabs>
        {value === 0 && <AnimePage />}
        {value === 1 && <CharPage />}
        {value === 2 && <WeekEp />}
      
      </Box>

        {/*
        <div className="anime-list">

          
       
          
  {animeData.map((anime, index) => (
            <div className="card mb-3" key={anime.id} style={cardStyle}>
              <div className="col-md-2">
                <div className="circle-image">
                  <img src={anime.imageUrl} alt={anime.name} className="img-fluid" style={imageStyle} />
                </div>
              </div>
              <div className="col-md-10">
                <div className="card-body">
                <div className="number-badge">{index + 1}</div>
                  <h4>{anime.name}</h4>
                  <p className="card-text">التقييم: {anime.ratingValue[0].rating}</p> 
                  
                  <div className="row">
                    <div className="col">
                      <p className="card-text">عدد المقيمين بتقييم 10: {anime.tenRatingsCount}</p>
                      <p className="card-text">عدد المقيمين بتقييم 0: {anime.zeroRatingsCount}</p>
                      <button className="btn-md rounded my-button">مراجعات</button>
                    </div>

                    
                    <div className="col">
  <div className="row">
    <div className="col">
      <div className="honor-points">
        <div className="circle-honor">
          <div>نقاط الشرف</div>
          <div>{anime.honorPoints}</div>
        </div>
      </div>
      
    
    </div>
    
    <div className="col">
  <div className="review-section">
    <button className="btn-md rounded comm-button" type="button">
      <strong>نشر تعليق علني</strong>
      <div id="container-stars">
    <div id="stars"></div>
  </div>
  

  <div id="glowx">
    <div class="circle"></div>
    <div class="circle"></div>
  </div>
    </button>
    </div>
    </div>


  </div>

  
  </div>
                    <div className="col">
                    <div className="col">
  <div className="button-row">
    <button className="btn-md rounded button-style">ميمز</button>
    <button className="btn-md rounded button-style">كوسبلاي</button>
    <button className="btn-md rounded button-style">انيميشن</button>
  </div>
  <div className="button-row">
    <button className="btn-md rounded button-style">تأديه اصوات</button>
    <button className="btn-md rounded button-style">ايديت</button>
    <button className="btn-md rounded button-style">نظريات</button>
  </div>
  <div className="button-row">
    <button className="btn-md rounded button-style">شاهد انمي مع رفيق</button>
    <button className="btn-md rounded button-style">فان ارت</button>
    <button className="btn-md rounded button-style">منتجات</button>
  </div>
</div>

</div>
</div>
</div>

<div className="col">
  <div className="row2">
    <div className={`comment-card card ${anime.publicComment.commentType === 'critic' ? 'critic-comment' : 'praiser-comment'}`} style={commStyle}>
      <div class="glow-bar"></div>
      <div className="card-body">
        <div className="row">
          <div className="col-auto">
            <img src={anime.publicComment.userAvatar} alt="User Avatar" className="user-avatar" style={avatarStyle} />
          </div>
          <div className="col">
            <div className="row">
              <div className="col-auto">
                <span className="user-name">{anime.publicComment.userName}</span>
              </div>
              <div className="col-auto">
                <span className="comment-date">{anime.publicComment.commentDate}</span>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="comment-text">{anime.publicComment.commentText}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <button className="like-button">
              <span className="likes-count">{anime.publicComment.likesCount} أتفق</span>
            </button>
            <button className="dislike-button">
              <span className="dislikes-count">{anime.publicComment.dislikesCount} أختلف</span>
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="comment-type-container">
              <div className={`comment-type ${anime.publicComment.commentType === 'critic' ? 'critic-comment' : 'praiser-comment'}`}>
                {anime.publicComment.commentType === 'critic' ? 'تعليق انتقادي' : 'تعليق مدح'}
              </div>
            </div>
          </div>
          <div className="row">
          <div className="col">
            <div className="comment-notification" style={{ fontSize: '12px', textShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', padding: '4px', marginTop: 'auto', marginBottom: '0', textAlign: 'center' }}>
              {anime.publicComment.commentType === 'critic' ? 'سيتم خصم نقاط شرف من هذا الانمي إذا كان عدد الاتفافات أكبر من عدد الاختلافات لهذا التعليق' : 'سيتم زيادة نقاط شرف هذا الانمي إذا كان عدد الاتفاقات أكثر من عدد الاختلافات لهذا التعليق'}
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>

</div>






))}
</div>

*/}




</div>








<div>
{this.state.isLoggedIn && (
  <div className="notifications">
  <nav className="navbarx navbar-expand-lg d-block d-lg-none-noti">
    <button
      className={`navbar-toggler-noti ${hasNewNotifications ? 'has-new-notifications' : ''}`}
      type="button"
      onClick={this.handleShownoti}
      aria-controls="navbarNav"
      aria-expanded="false"
      aria-label="Toggle navigation"
      data-bs-backdrop="false"
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-bell-fill" viewBox="0 0 16 16">
        <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2m.995-14.901a1 1 0 1 0-1.99 0A5 5 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901"/>
      </svg>
      {hasNewNotifications && <span className="notification-dot"></span>}
    </button>
    <Offcanvas
      show={this.state.shownoti}
      onHide={this.handleClosenoti}
      tabIndex={-1}
      id="navbarNav"
      aria-labelledby="navbarNavLabel"
    >
      <Offcanvas.Header>
        <Offcanvas.Title id="navbarNavLabel">الإشعارات</Offcanvas.Title>
        <button
          type="button"
          className="btn-close text-reset"
          onClick={this.handleClosenoti}
          aria-label="Close"
        />
      </Offcanvas.Header>
      <Offcanvas.Body>
        <ul className="navbar-nav-noti">
          {todayNotifications.length > 0 && (
            <>
              <h5>هذا اليوم</h5>
              {todayNotifications.map((notification, index) => (
                <li key={index} className="notification-item"
                onClick={() => {
                  if (notification.type === "kingaward") {
                    window.open('/mohankeenawards', '_blank');
                  }
                }}
                style={notification.type === "kingaward" ? { cursor: 'pointer' } : {}}>
                  <div className="notification-content">
                    {notification.fromUser && notification.fromUser.avatar ? (
                      notification.type === "anime notification"||"kingaward" ? (
                        <div className="notification-avatar-placeholder">
                        <img
                          src={notification.fromUser.avatar}
                          alt={`${notification.fromUser.username} avatar`}
                          className="notification-avatar"
                        />
                         </div>
                      ) : (
                        <img
                          src={notification.fromUser.avatar}
                          alt={`${notification.fromUser.username} avatar`}
                          className="notification-avatar"
                          onClick={() => window.open(`/profile?username=${notification.fromUser.username}`, '_blank')}
                        />
                      )
                    ) : (
                      <div className="notification-avatar-placeholderx">
                        <img src="https://i.imgur.com/0a5KXxq.jpg" alt="مطور الموقع" />
                      </div>
                    )}
                    <div className="notification-text">
                      <h7>{notification.message}</h7>
                      <p className="notification-date">{notification.formattedDate}</p>
                    </div>
                  </div>
                  {index < todayNotifications.length - 1 && <hr className="notification-divider" />}
                </li>
              ))}
              <hr className="section-divider" />
            </>
          )}
          {olderNotifications.length > 0 && (
            <>
              <h5>الإشعارات السابقة</h5>
              {olderNotifications.map((notification, index) => (
                <li key={index} className="notification-item">
                  <div className="notification-content">
                    {notification.fromUser && notification.fromUser.avatar ? (
                      notification.type === "anime notification" ? (
                        <img
                          src={notification.fromUser.avatar}
                          alt={`${notification.fromUser.username} avatar`}
                          className="notification-avatar"
                        />
                      ) : (
                        <img
                          src={notification.fromUser.avatar}
                          alt={`${notification.fromUser.username} avatar`}
                          className="notification-avatar"
                          onClick={() => window.open(`/profile?username=${notification.fromUser.username}`, '_blank')}
                        />
                      )
                    ) : (
                      <div className="notification-avatar-placeholderx">
                        <img src="https://i.imgur.com/0a5KXxq.jpg" alt="مطور الموقع" />
                      </div>
                    )}
                    <div className="notification-text">
                      <h7 className="rtl-text">{notification.message}</h7>
                      <p className="notification-date">{notification.formattedDate}</p>
                    </div>
                  </div>
                  {index < olderNotifications.length - 1 && <hr className="notification-divider" />}
                </li>
              ))}
            </>
          )}
        </ul>
      </Offcanvas.Body>
    </Offcanvas>
  </nav>
</div>


)}
</div>
          
          
         
        
       
<style dangerouslySetInnerHTML={{__html: `
  /* تحديد خلفية الموقع */
  body {
    background: linear-gradient(135deg, rgba(10, 10, 10, 1.8) 0%, rgba(30, 30, 30, 1) 25%, rgba(50, 50, 50, 0.8) 50%, rgba(70, 70, 70, 0.6) 75%, rgba(90, 90, 90, 1.8) 100%);
    background-attachment: fixed;
    background-size: cover;
   
  }
`}} />

        
        </div>
        </HelmetProvider>
    );
  }
}





export default homepage;


