import logo from './logo.svg';
import './components/animecard.css';
import './App.css';

//import { anime } from "../../backend/server.js"; // import Anime model from animeModel.js


import axios from 'axios';
import './assets/css/bootstrap.min.css'; // استيراد ملف CSS من Bootstrap
import 'animate.css/animate.min.css';
//import './assets/js/bootstrap.bundle.js';
//import bootstrap from 'bootstrap';




import React, { useEffect, useState, Component, createRef } from 'react';
import api from './api';
import ReactDOM from 'react-dom';
//import { Button } from 'bootstrap';
//import { Offcanvas } from 'bootstrap';
import { Navbar, Offcanvas, Card, Button, CardDeck, CardColumns, CardGroup, CardImg, Dropdown, DropdownButton, DropdownItem, DropdownMenu} from "react-bootstrap";
import { NavLink, Link  } from "react-router-dom";
import Cookies from 'js-cookie';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { keyframes } from "styled-components";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { initializePaddle, Paddle } from '@paddle/paddle-js';
//import keyframe from 'keyframe';
//import { Animated, Easing, keyframe } from 'react-native';
//import LottieView from 'lottie-react-native';
import {
  useScene,
  useSceneItem,
  useFrame,
  useNowFrame,
} from "react-scenejs";
import { Animate, AnimateKeyframes, AnimateGroup } from "react-simple-animate";



//import { Offcanvas } from 'react-bootstrap';

import _ from 'lodash';
import Switch from "react-switch";
import { Tabs, Tab } from '@material-ui/core';

import slugify from 'slugify';
import Clipboard from 'clipboard';
import ReactHtml2Canvas from 'react-html2canvas'
import InfiniteScroll from 'react-infinite-scroll-component';




class WeekEp extends React.Component {

  constructor(props) {
    super(props);
    const isDevelopment = process.env.REACT_APP_NODE_ENV === 'development';

      // جلب معرفات PayPal وPaddle من متغيرات البيئة
      const PAYPAL_CLIENT_ID = isDevelopment ? process.env.REACT_APP_PAYPAL_SANDBOX_CLIENT_ID : process.env.REACT_APP_PAYPAL_LIVE_CLIENT_ID;
      const PADDLE_TOKEN = isDevelopment ? process.env.REACT_APP_PADDLE_SANDBOX_TOKEN : process.env.REACT_APP_PADDLE_LIVE_TOKEN;
      const PRODUCT_PRICE_PADDLE_SILVER = isDevelopment ? process.env.REACT_APP_SANDBOX_PRICE_PADDLE_SILVER : process.env.REACT_APP_LIVE_PRICE_PADDLE_SILVER;
 const PRODUCT_PRICE_PADDLE_GOLDEN = isDevelopment ? process.env.REACT_APP_SANDBOX_PRICE_PADDLE_GOLDEN : process.env.REACT_APP_LIVE_PRICE_PADDLE_GOLDEN;
    // نضع قيمة الحالة الأولية في this.state
    this.state = {
      showBottom: false,
      showModal: false,
      message: '',
      weekepData: [], // an empty array to store the weekep data from the database
      isMobile: window.innerWidth <= 991, // تعيين القيمة الافتراضية للموبايل
      isActive: true,
      selectedGenre: 'عرض كل الانميات بكل التصنيفات', // التصنيف المختار
      
      weekepId: "",
      commentText: '',
      commentType: '',
      
      
       //paypal
      //message: '',
      initialOptions: {
        'client-id': PAYPAL_CLIENT_ID,
        'enable-funding': '',
        'disable-funding': 'paylater,venmo,card',
        'data-sdk-integration-source': 'integrationbuilder_sc',
      },
    
      
        //paddle
        
        name: '',
        productName: '',
        product: '',
       
        paddle: '',

      
        commentText: '',
        commentType: 'praiser',
        showExpandes: false,
        showExpandex: false,
        showExpandexrank:false,
        selectedweekep: "",
        userId: "", // استبدل 'USER_ID' بمعرف المستخدم الفعلي
        rating: "",
    note: '',
    randomNote: '',
    randomRating: '',
    randomUsername: '',
    randomUserProfileImage: '',

    isHovered: false,
    searchQuery: '',
    searchResults: [],



    page: 1,
    limit: 11,
    totalPages: 1,
    loading: false,
    error: null,
    hasMore: true     


    };

    this.toggleCards = this.toggleCards.bind(this); // bind toggleCards to the component
    this.textareaRef = React.createRef();
    this.linkRef = React.createRef();
    this.canvasRef = createRef();
    this.handleDownload = this.handleDownload.bind(this);
  }

  /*
  // دالة لجلب بيانات الأنمي من المسار /weekep
  fetchData = async (searchQuery = '') => {
    try {
      const response = await axios.get('/weekep', {
        params: { search: searchQuery }
      });
      if (Array.isArray(response.data)) {
        const weekepDataWithSlugs = response.data.map(weekep => ({
          ...weekep,
          slug: slugify(weekep.name, { lower: true })
        }));
        this.setState({ weekepData: weekepDataWithSlugs, searchResults: response.data });
      } else {
        console.error('Expected array but got:', response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

*/
fetchData = async () => {
  const { page, limit, weekepData } = this.state;
  this.setState({ loading: true, error: null });

  try {
    const response = await axios.get('/weekep', {
      params: { page, limit }
    });

    const { weekeps, totalPages } = response.data;

    const weekepDataWithSlugs = weekeps.map(weekep => ({
      ...weekep,
      slug: slugify(weekep.name, { lower: true })
    }));

    this.setState({
      weekepData: [...weekepData, ...weekepDataWithSlugs],
      totalPages,
      page: page + 1,
      loading: false,
      hasMore: page < totalPages
    });
  } catch (error) {
    console.error('Error fetching weekep data:', error);
    this.setState({ loading: false, error: 'Failed to fetch weekep data' });
  }
};

 
  

  // use componentDidMount to fetch data from node server when the component mounts
  componentDidMount() {
    // use api.get to send a get request to /api endpoint
    api.get('/api')
      .then((res) => {
        // if the request is successful, set the message state to the data from the response
        this.setState({ message: res.data.message });
      })
      .catch((err) => {
        // if there is an error, log it and set the message state to an error message
        console.error(err);
        this.setState({ message: 'Something went wrong' });
      });



      window.addEventListener('resize', this.handleWindowResize);

     this.fetchData();
     
     //this.incrementViewCount();

     //paddle
     
      
      this.loadProductDetailssilver();

    
      
      this.loadProductDetailsgolden();

      const userId = Cookies.get('userId') || localStorage.getItem('userId');
      
      if (userId) {
        const cleanedUserId = userId.replace(/^j:/, '');
    this.setState({ userId: cleanedUserId });
      }

       
      }

      componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowResize);
       
      }
    
      handleWindowResize = () => {
        const isMobile = window.innerWidth <= 991;
        this.setState({ isMobile });
      };
    
      // دالة لجلب بيانات الأنمي من المسار /weekep
      
    
      // define a function called handleClick that calls the fetchData function when the button is clicked
      handleClick = () => {
        this.fetchData();
        this.setState({ isweekepActive: false, dataType: 'weekep' });
      };

      
  handleMouseEnter = () => {
    this.setState({ isActive: false });
  };

  handleMouseLeave = () => {
    this.setState({ isActive: true });
  };
  // create a method to handle the api call
  handleApi(e) {
    // do something with the event or the api
  
  }

  // لتغيير قيمة الحالة ، نستخدم this.setState
  // ونمرر له دالة تستلم حالة سابقة وخصائص كمعاملات
  // وترجع كائنًا يحتوي على التغييرات التي نريدها
  handleShowBottom = () => {
    this.setState((prevState, props) => ({
      showBottom: true
    }));
  };

  handleCloseBottom = () => {
    this.setState((prevState, props) => ({
      showBottom: false
    }));
  };

  
  
   // لتغيير قيمة الحالة ، نستخدم this.setState
  // ونمرر له دالة تستلم حالة سابقة وخصائص كمعاملات
  // وترجع كائنًا يحتوي على التغييرات التي نريدها
  handleShow = () => {
    this.setState((prevState, props) => ({
      show: true
    }));
  };

  handleClose = () => {
    this.setState((prevState, props) => ({
      show: false
    }));
  };

   // دالة للتبديل بين إظهار وإخفاء القائمة وتغيير اتجاه الزر
    // دالة للتبديل بين إظهار وإخفاء القائمة وتغيير اتجاه الزر
    
    toggleModal = () => {
      this.setState((prevState) => ({
        showModal: !prevState.showModal
      }));
    }

    toggleCards() {
      this.setState((prevState) => ({
        showCards: !prevState.showCards, // استخدام المعامل ! للحصول على القيمة المعاكسة
      }));
    }



    toggleweekep = () => {
      this.setState((prevState) => ({
        isweekepActive: !prevState.isweekepActive,
        dataType: prevState.isweekepActive ? 'weekep' : 'weekepacter',
      }));
    };


    handleGenreChange = (event) => {
      // تحديث التصنيف المختار عند تغييره في قائمة التصنيفات
      const selectedGenre = event.currentTarget.getAttribute('value');
      this.setState({ selectedGenre });
    };
  



  
  //تعليقات علنيه
  submitComment = async () => {
    const { selectedweekep, commentText, commentType } = this.state;

    try {
      const response = await fetch('/public-comment-weekep', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          //'userId': 'your-user-id' // Replace with actual user ID or use cookies
        },
        body: JSON.stringify({ weekepId: selectedweekep, commentText, commentType })
      });

      const data = await response.json();

      if (response.ok) {
        alert(data.message);
      } else {
        alert(data.message || 'An error occurred');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred while submitting your comment');
    }
  };
//تعليق علني فضي
  submitCommentsilverpaypal = async () => {
    const { selectedweekep, commentText, commentType } = this.state;

    try {
      const response = await fetch('/api/orders/:orderID/callbackpaypallsilverweekep', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          //'userId': 'your-user-id' // Replace with actual user ID or use cookies
        },
        body: JSON.stringify({ weekepId: selectedweekep, commentText, commentType })
      });

      const data = await response.json();

      if (response.ok) {
        alert(data.message);
      } else {
        alert(data.message || 'An error occurred');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred while submitting your comment');
    }
  };

  submitCommentsilverpaddle = async () => {
    const { selectedweekep, commentText, commentType } = this.state;

    try {
      const response = await fetch('/paddlecallback-silvercommentweekep', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          //'userId': 'your-user-id' // Replace with actual user ID or use cookies
        },
        body: JSON.stringify({ weekepId: selectedweekep, commentText, commentType })
      });

      const data = await response.json();

      if (response.ok) {
        alert(data.message);
      } else {
        alert(data.message || 'An error occurred');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred while submitting your comment');
    }
  };
  //تعليق علني ذهبي
  submitCommentgoldenpaypal = async () => {
    const { selectedweekep, commentText, commentType } = this.state;

    try {
      const response = await fetch('/api/orders/:orderID/callbackpaypallgoldenweekep', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          //'userId': 'your-user-id' // Replace with actual user ID or use cookies
        },
        body: JSON.stringify({ weekepId: selectedweekep, commentText, commentType })
      });

      const data = await response.json();

      if (response.ok) {
        alert(data.message);
      } else {
        alert(data.message || 'An error occurred');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred while submitting your comment');
    }
  };

  submitCommentgoldenpaddle = async () => {
    const { selectedweekep, commentText, commentType } = this.state;

    try {
      const response = await fetch('/paddlecallback-goldencommentweekep', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          //'userId': 'your-user-id' // Replace with actual user ID or use cookies
        },
        body: JSON.stringify({ weekepId: selectedweekep, commentText, commentType })
      });

      const data = await response.json();

      if (response.ok) {
        alert(data.message);
      } else {
        alert(data.message || 'An error occurred');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred while submitting your comment');
    }
  };
    
   //دوال باي بال 
  createOrdersilver = async () => {
    try {
      // إنشاء قيمة عشوائية لـ reference
      const response = await fetch("/api/orderssilver", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          cart: [
            {
              id: "7b92603e77ed48968e785dea20504749",
              quantity: "1",
            },
          ],
        }),
      });
  
      // تحقق من حالة الاستجابة
      if (response.status === 401) {
        alert("YOU MUST LOGIN FIRST");
        // يمكنك إعادة توجيه المستخدم إلى صفحة تسجيل الدخول هنا إذا أردت
        return;
      }
  
      const orderData = await response.json();
  
      if (orderData.id) {
        return orderData.id;
      } else {
        const errorDetail = orderData?.details?.[0];
        const errorMessage = errorDetail
          ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
          : JSON.stringify(orderData);
  
        throw new Error(errorMessage);
      }
    } catch (error) {
      console.error("Error checking user login:", error);
      this.setState({
        message: `Could not initiate PayPal Checkout...${error.message}`,
      });
    }
  };


  createOrdergolden = async () => {
    try {
      // إنشاء قيمة عشوائية لـ reference
      const response = await fetch("/api/ordersgolden", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          cart: [
            {
              id: "7b92603e77ed48968e785dea20504749",
              quantity: "1",
            },
          ],
        }),
      });
  
      // تحقق من حالة الاستجابة
      if (response.status === 401) {
        alert("YOU MUST LOGIN FIRST");
        // يمكنك إعادة توجيه المستخدم إلى صفحة تسجيل الدخول هنا إذا أردت
        return;
      }
  
      const orderData = await response.json();
  
      if (orderData.id) {
        return orderData.id;
      } else {
        const errorDetail = orderData?.details?.[0];
        const errorMessage = errorDetail
          ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
          : JSON.stringify(orderData);
  
        throw new Error(errorMessage);
      }
    } catch (error) {
      console.error("Error checking user login:", error);
      this.setState({
        message: `Could not initiate PayPal Checkout...${error.message}`,
      });
    }
  };
  

  //paddle

   checkUserLogin = async () => {
    try {
      const response = await axios.get('/openpaddlepay');
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        alert("YOU MUST LOGIN FIRST");
        // يمكنك إعادة توجيه المستخدم إلى صفحة تسجيل الدخول هنا إذا أردت
       
      } else {
        console.error("Error checking user login:", error);
      }
      return null;
    }
  };


  openCheckoutsilver = async () => {
    const { paddle, product } = this.state;
    const isDevelopment = process.env.REACT_APP_NODE_ENV === 'development';
    const PRODUCT_PRICE_PADDLE_SILVER = isDevelopment ? process.env.REACT_APP_SANDBOX_PRICE_PADDLE_SILVER : process.env.REACT_APP_LIVE_PRICE_PADDLE_SILVER;
    this.initializePaddlesilver();
    if (paddle && product) {
      const userId = await this.checkUserLogin();
      if (userId) {
        paddle.Checkout.open({
          items: [{ priceId: PRODUCT_PRICE_PADDLE_SILVER, quantity: 1 }],
        });
      }
    }
  };
  loadProductDetailssilver = async () => {
    try {
      const response = await axios.get('/get-product-details-silvercomment'); // افترض أن لديك endpoint للحصول على تفاصيل المنتج
      this.setState({ product: response.data, error: null });
    } catch (error) {
      this.setState({ error: 'Failed to load product details' });
    }
  };

  initializePaddlesilver = async () => {
    const isDevelopment = process.env.REACT_APP_NODE_ENV === 'development';
    const PADDLE_TOKEN = isDevelopment ? process.env.REACT_APP_PADDLE_SANDBOX_TOKEN : process.env.REACT_APP_PADDLE_LIVE_TOKEN;
    try {
      const paddleInstance = await initializePaddle({
        environment: isDevelopment ? 'sandbox' : 'production', // أو 'production' حسب البيئة التي تعمل بها
        token: PADDLE_TOKEN, // استبدل هذا بالتوكن الخاص بك
        eventCallback: async (data) => {
          switch(data.name) {
            case "checkout.loaded":
              console.log("Checkout opened");
              break;
            case "checkout.customer.created":
              console.log("Customer created");
              break;
            case "checkout.completed":
              console.log("Checkout completed");
               this.submitCommentsilverpaddle();
              break;
            default:
              console.log(data);
          }
        }
      });
      this.setState({ paddle: paddleInstance });
    } catch (error) {
      console.error('Failed to initialize Paddle:', error);
    }
  };


  openCheckoutgolden = async () => {
    const { paddle, product } = this.state;
    const isDevelopment = process.env.REACT_APP_NODE_ENV === 'development';
    const PRODUCT_PRICE_PADDLE_GOLDEN = isDevelopment ? process.env.REACT_APP_SANDBOX_PRICE_PADDLE_GOLDEN : process.env.REACT_APP_LIVE_PRICE_PADDLE_GOLDEN;
    this.initializePaddlegolden();
    if (paddle && product) {
      const userId = await this.checkUserLogin();
      if (userId) {
        paddle.Checkout.open({
          items: [{ priceId: PRODUCT_PRICE_PADDLE_GOLDEN, quantity: 1 }],
        });
      }
    }
  };
  loadProductDetailsgolden = async () => {
    try {
      const response = await axios.get('/get-product-details-goldencomment'); // افترض أن لديك endpoint للحصول على تفاصيل المنتج
      this.setState({ product: response.data, error: null });
    } catch (error) {
      this.setState({ error: 'Failed to load product details' });
    }
  };

  initializePaddlegolden = async () => {
    const isDevelopment = process.env.REACT_APP_NODE_ENV === 'development';
    const PADDLE_TOKEN = isDevelopment ? process.env.REACT_APP_PADDLE_SANDBOX_TOKEN : process.env.REACT_APP_PADDLE_LIVE_TOKEN;
    try {
      const paddleInstance = await initializePaddle({
        environment: isDevelopment ? 'sandbox' : 'production', // أو 'production' حسب البيئة التي تعمل بها
        token: PADDLE_TOKEN, // استبدل هذا بالتوكن الخاص بك
        eventCallback: async (data) => {
          switch(data.name) {
            case "checkout.loaded":
              console.log("Checkout opened");
              break;
            case "checkout.customer.created":
              console.log("Customer created");
              break;
            case "checkout.completed":
              console.log("Checkout completed");
               this.submitCommentgoldenpaddle();
              break;
            default:
              console.log(data);
          }
        }
      });
      this.setState({ paddle: paddleInstance });
    } catch (error) {
      console.error('Failed to initialize Paddle:', error);
    }
  };

  incrementViewCount = async () => {
    try {
    const response = await fetch('/public-comment/viewweekep', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
  } catch (error) {
    console.error('Error:', error);
  } 
};



handleCommentTextChange = (event) => {
  const { value } = event.target;
  this.setState({ commentText: value }, this.adjustTextareaHeight);
};

adjustTextareaHeight = () => {
  const textarea = this.textareaRef.current;
  textarea.style.height = 'auto'; // إعادة تعيين الارتفاع
  textarea.style.height = `${textarea.scrollHeight}px`; // ضبط الارتفاع بناءً على المحتوى
};

handleCommentTypeChange = () => {
  this.setState((prevState) => ({
    commentType: prevState.commentType === 'praiser' ? 'critic' : 'praiser',
  }));
};
  
handleExpandes = (weekepId) => {
  this.setState((prevState) => ({
    showExpandes: !prevState.showExpandes,
    selectedweekep: prevState.showExpandes ? null : weekepId, // إعادة تعيين selectedweekep عند إغلاق المربع
  }));
};

handleExpandex = (weekepId) => {
  this.setState((prevState) => ({
    showExpandex: !prevState.showExpandex,
    selectedweekep: prevState.showExpandex ? null : weekepId, // إعادة تعيين selectedweekep عند إغلاق المربع
    rating: prevState.showExpandex ? null : this.state.rating,
    note: prevState.showExpandex ? null : this.state.note,
    randomNote: prevState.showExpandex ? null : this.state.randomNote,
  }));
};

handleExpandexrank = (weekepId) => {
  this.setState((prevState) => ({
    showExpandexrank: !prevState.showExpandexrank,
    selectedweekep: prevState.showExpandexrank ? null : weekepId, // إعادة تعيين selectedAnime عند إغلاق المربع
   
  }));
};
  
getCommentStyle = (commentPrice) => {
  const isMobile = window.innerWidth <= 991;
 let style = {
   overflow: 'hidden',
   display: 'flex',
   width: '95%',
   maxWidth: isMobile ? window.innerWidth - 150 : '95%', 
   height: 'auto',
   boxShadow: '20px 20px 60px #bebebe, -20px -20px 60px #ffffff',
   background: 'linear-gradient(90deg, rgba(255,255,255,0.9) 0%, rgba(255,255,255,0.7) 50%, rgba(255,255,255,0.9) 80%)',
   outline: '3px solid #f5f5f5',
   outlineOffset: '-3px',
   position: 'relative',
   color: 'black',
   fontWeight: 'bold',
   fontSize: '16px',
   animation: 'glow 1.5s infinite',
   marginLeft: isMobile ? '30px':'1px',
 };

 if (commentPrice === 'silver') {
   style.boxShadow = '0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(169, 169, 169, 0.4), inset 0 -2px 5px 1px rgba(192, 192, 192, 1), inset 0 -1px 1px 3px rgba(211, 211, 211, 1)';
   style.background = 'linear-gradient(160deg, #d4d4d4, #e0e0e0, #f5f5f5, #e0e0e0, #d4d4d4)';
   style.outline = '3px solid #c0c0c0';
 } else if (commentPrice === 'golden') {
   style.boxShadow = '0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(110, 80, 20, 0.4), inset 0 -2px 5px 1px rgba(139, 66, 8, 1), inset 0 -1px 1px 3px rgba(250, 227, 133, 1)';
   style.background = 'linear-gradient( 160deg, #a54e07, #b47e11, #fef1a2, #bc881b, #a54e07 )';
   style.outline = '3px solid #a55d07';
 }

 return style;
}; 
  



   // دالة استدعاء للإعجاب
   likeComment = async (weekepId, commentId) => {
    //console.log('User ID:', this.state.userId); // طباعة معرف المستخدم
    try {
      const response = await axios.post('/likeweekep', { weekepId, commentId }, {
        headers: {
          userId: this.state.userId
        }
      });
      const updatedweekepData = this.state.weekepData.map(weekep => {
        if (weekep._id === weekepId) {
          const updatedComments = weekep.publicComment.map(comment => {
            if (comment._id === commentId) {
              return {
                ...comment,
                likesCount: response.data.likesCount,
                dislikesCount: response.data.dislikesCount,
                likedBy: response.data.likedBy,
                dislikedBy: response.data.dislikedBy,
              };
            }
            return comment;
          });
          return { ...weekep, publicComment: updatedComments };
        }
        return weekep;
      });
      this.setState({ weekepData: updatedweekepData });
    } catch (error) {
      if (error.response && error.response.status === 400 && error.response.data.error === 'you must login first') {
        alert('You must login first');
      } else {
        console.error('Error liking comment:', error);
      }
    }
  };

  // دالة استدعاء لعدم الإعجاب
  dislikeComment = async (weekepId, commentId) => {
    //console.log('User ID:', this.state.userId); // طباعة معرف المستخدم
    try {
      const response = await axios.post('/dislikeweekep', { weekepId, commentId }, {
        headers: {
          userId: this.state.userId
        }
      });
      const updatedweekepData = this.state.weekepData.map(weekep => {
        if (weekep._id === weekepId) {
          const updatedComments = weekep.publicComment.map(comment => {
            if (comment._id === commentId) {
              return {
                ...comment,
                likesCount: response.data.likesCount,
                dislikesCount: response.data.dislikesCount,
                likedBy: response.data.likedBy,
                dislikedBy: response.data.dislikedBy,
              };
            }
            return comment;
          });
          return { ...weekep, publicComment: updatedComments };
        }
        return weekep;
      });
      this.setState({ weekepData: updatedweekepData });
    } catch (error) {
      if (error.response && error.response.status === 400 && error.response.data.error === 'you must login first') {
        alert('You must login first');
      } else {
        console.error('Error disliking comment:', error);
      }
    }
  };

 // دالة لتحديد الفئة بناءً على حالة التعليق ومعرف المستخدم
 getButtonClass = (comment, userId, type) => {
  if (!comment) return '';
  // إزالة علامات الاقتباس الإضافية والمسافات غير المرئية
  const cleanedUserId = userId.replace(/^"|"$/g, '').trim();
  const cleanedLikedBy = comment.likedBy.map(id => id.replace(/^"|"$/g, '').trim());
  const cleanedDislikedBy = comment.dislikedBy.map(id => id.replace(/^"|"$/g, '').trim());

  // تحقق من أن userId موجود في المصفوفة
  const userIdExists = cleanedLikedBy.includes(cleanedUserId);
    // طباعة كل عنصر في likedBy مع طوله
  cleanedLikedBy.forEach((id, index) => {
   
  });

  if (type === 'like') {
    return `like-button ${userIdExists ? 'liked' : ''}`;
  } else if (type === 'dislike') {
    return `dislike-button ${cleanedDislikedBy.includes(cleanedUserId) ? 'disliked' : ''}`;
  }
  return '';
};


// دالة لإضافة أو تعديل التقييم
addOrUpdateRating = async () => {
  const { rating, note, selectedweekep } = this.state;



  try {
    const response = await fetch('/rateweekep', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        //'userId': 'your-user-id' // Replace with actual user ID or use cookies
      },
      body: JSON.stringify({ weekepId: selectedweekep, rating, note })
    });

    const data = await response.json();

    if (response.ok) {
      this.setState({
        message: data.message,
        randomNote: data.randomNote,
        randomRating: data.randomRating,
        randomUsername: data.randomUsername,
        randomUserProfileImage: data.randomUserProfileImage
      });

      alert(data.message);
    } else {
      alert(data.error || 'حدث خطأ ما');
    }
  } catch (error) {
    console.error('Error occurred:', error);
    alert('حدث خطأ ما أثناء إرسال التقييم');
  }
};

// دالة لحذف التقييم
deleteRating = async () => {
  const { selectedweekep } = this.state;

  

  try {
    const response = await fetch('/deleterateweekep', {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        //'userId': 'your-user-id' // Replace with actual user ID or use cookies
      },
      body: JSON.stringify({ weekepId: selectedweekep })
    });

    const data = await response.json();

    if (response.ok) {
      this.setState({ message: data.message });
      alert(data.message);
    } else {
      alert(data.error || 'حدث خطأ ما');
    }
  } catch (error) {
    console.error('Error occurred:', error);
    alert('حدث خطأ ما أثناء حذف التقييم');
  }
};
  
handleRatingChange = (e) => {
  let value = e.target.value;

  // تحقق من أن القيمة تحتوي فقط على أرقام أو نقطة عشرية
  const regex = /^[0-9]*\.?[0-9]*$/;

  // تحقق من أن طول القيمة لا يتجاوز أربع أرقام
  if (regex.test(value) && value.length <= 4) {
    this.setState({ rating: value });
  }
};

handleNoteChange = (e) => {
  this.setState({ note: e.target.value.slice(0, 50) });
};


handleMouseEnter = () => {
  this.setState({ isHovered: true });
}

handleMouseLeave = () => {
  this.setState({ isHovered: false });
}

handleDownload() {
  if (this.canvasRef.current) {
    this.canvasRef.current.handleDownload();
  }
}

handleSearchChange = (event) => {
  const searchQuery = event.target.value;
  this.setState({ searchQuery });
  this.fetchData(searchQuery);
};

handleSuggestionClick = (animeId) => {
  this.setState({ searchQuery: '', searchResults: [] });
  this.fetchDataByAnimeId(animeId);
};

fetchDataByAnimeId = async (animeId) => {
  try {
    const response = await axios.get('/weekep', {
      params: { animeId }
    });
    if (Array.isArray(response.data)) {
      const weekepDataWithSlugs = response.data.map(weekep => ({
        ...weekep,
        slug: slugify(weekep.name, { lower: true })
      }));
      this.setState({ weekepData: weekepDataWithSlugs });
    } else {
      console.error('Expected array but got:', response.data);
    }
  } catch (error) {
    console.error(error);
  }
};



  render() {

    const { showModal, weekepData, data, error, showCards, isMobile, isActive, isweekepActive, dataType, activeTab, selectedGenre,   initialOptions, productId, customerEmail, name, productName, product, commentText, commentType,  selectedweekep, showExpandes, showExpandex, rating, note, randomNote, randomRating, randomUsername, randomUserProfileImage, isHovered, searchQuery,  searchResults, hasMore, loading,showExpandexrank } = this.state;
    const userId = this.state.userId; // جلب معرف المستخدم من الحالة
    
  

    // دمج التصفية والترتيب
    const filteredAndSortedweekepData = Array.isArray(weekepData)
      ? weekepData
          .filter((weekep) => {
            const activeComment = Array.isArray(weekep.publicComment)
              ? weekep.publicComment.find(comment => comment.commentStatus === 'active')
              : null;
            weekep.activeComment = activeComment; // إضافة التعليق النشط إلى كائن الأنمي
            return true;
          })
          .sort((a, b) => {
            const aActive = a.activeComment && a.activeComment.remainingTime > 0;
            const bActive = b.activeComment && b.activeComment.remainingTime > 0;

            if (aActive && !bActive) return -1;
            if (!aActive && bActive) return 1;
            if (aActive && bActive) {
              return new Date(b.activeComment.commentDate) - new Date(a.activeComment.commentDate);
            }
            return b.honorPoints- a.honorPoints;
          })
      : [];
      //console.log('selectedweekep:', selectedweekep);
      
      const selectedweekepcomm = filteredAndSortedweekepData.find(weekep => weekep._id === selectedweekep);
      //console.log('selectedweekepcomm:', selectedweekepcomm);
      //const weekepSlug = slugify(selectedweekepcomm.name, { lower: true });
      const parentStyle = {
      //display: 'block', // use flex layout
      justifyContent: 'center', // center the items horizontally
      alignItems: 'center', // center the items vertically
      //height: '-250vh', // set the height to 100% of the viewport height
      //margintop: '100px', // set the margin to auto
      //padding: '280px',
      position: 'relative',
      margin: '10px',
       float: 'right'
      
    };

    const cardStyle = {
      width: isMobile ? window.innerWidth - 20 : '80%', //-120
      height: isMobile ? 'auto' : 'auto', // تعديل ارتفاع البطاقة
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      borderRadius: '12px',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',

      position: 'relative',
      float: isMobile ?'left': 'right',
    marginLeft:  '2px', // إضافة هذا الخاصية لإضافة بعض المسافة بين البطاقات
      
      
    };

    const scrolxStyle = {
      width: isMobile ? window.innerWidth - 20 : 'auto', //-120
     
     
     
      
    };

    const commStyle = {
      overflow: 'hidden',
      display: 'flex',
      width: '95%',
      height: 'auto',
      boxShadow: '20px 20px 60px #bebebe, -20px -20px 60px #ffffff',
      background: 'linear-gradient(90deg, rgba(255,255,255,0.9) 0%, rgba(255,255,255,0.7) 50%, rgba(255,255,255,0.9) 80%)',
      outline: '3px solid #f5f5f5',
      outlineOffset: '-3px',
      position: 'relative',
      color: 'black',
  fontWeight: 'bold',
  fontSize: '16px',
  animation: 'glow 1.5s infinite',
    }
    
    

    
    const imageStyle = {
      width: '150px', // قم بتعديل عرض الصورة حسب الحاجة
      height: '150px', // قم بتعديل ارتفاع الصورة حسب الحاجة
      objectFit: 'cover', // تعديل تنسيق الصورة لتمتلئ داخل حاوية الصورة بشكل كامل دون تشويه النسبة
      borderRadius: '50%', // إضافة حواف دائرية للصورة
    };


    
    const avatarStyle = {

      width: isMobile ?'70px': '90px', // قم بتعديل عرض الصورة حسب الحاجة
    height: isMobile ?'70px': '90px', // قم بتعديل ارتفاع الصورة حسب الحاجة
    objectFit: 'cover', // تعديل تنسيق الصورة لتمتلئ داخل حاوية الصورة بشكل كامل دون تشويه النسبة
    borderRadius: '50%', // إضافة حواف دائرية للصورة


    };


    

    

    
    

    

    

    
    return (

      

      

      
      
      <div>

        
        



  

        
         

        <div >
        
       
        
        
       

        
        <div className="anime-list">

          
        <div className="container">
    <div className="row">
      <div className="col-md-9 offset-md-3">
        <h4 className="text-center mt-4">اعظم الحلقات الاسبوعيه</h4>
      </div>

      
    </div>
  </div>


  <InfiniteScroll
          dataLength={weekepData.length}
          next={this.fetchData}
          hasMore={hasMore}
        
          style={scrolxStyle} 
        >  
        
        {filteredAndSortedweekepData.map((weekep, index) => (
          
          
            <div  className="card mb-3" key={weekep._id} style={cardStyle}  >
              {/* اول سطرين تجطهم على زر او بطاقه
              onMouseEnter={this.handleMouseEnter}
            onMouseLeave={this.handleMouseLeave}
              {isHovered && (
              <div className="stars">
    {Array.from({ length: 30 }).map((_, index) => (
      <div className="star" key={index} style={{
        '--star-tail-length': `${Math.random() * 2 + 4}em`,
        '--top-offset': `${Math.random() * 50}vh`,
        '--fall-duration': `${Math.random() * 3 + 3}s`,
        '--fall-delay': `${Math.random() * 1}s`
      }}></div>
    ))}
  </div>
    )}  
      */}  
              <div className="col-md-2">
                <div className="circle-image">
                  <img src={weekep.imageUrl} alt={weekep.name} className="img-fluid" style={imageStyle} />
                </div>
              </div>
              <div className="col-md-10">
                <div className="card-body">
               
                    <Link to={`/weekep/${weekep._id}/${weekep.slug}`}  className="linkbutton" target="_blank" style={{ color: 'white', textDecoration: 'none' }} >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-link-45deg" viewBox="0 0 16 16">
  <path d="M4.715 6.542 3.343 7.914a3 3 0 1 0 4.243 4.243l1.828-1.829A3 3 0 0 0 8.586 5.5L8 6.086a1 1 0 0 0-.154.199 2 2 0 0 1 .861 3.337L6.88 11.45a2 2 0 1 1-2.83-2.83l.793-.792a4 4 0 0 1-.128-1.287z"/>
  <path d="M6.586 4.672A3 3 0 0 0 7.414 9.5l.775-.776a2 2 0 0 1-.896-3.346L9.12 3.55a2 2 0 1 1 2.83 2.83l-.793.792c.112.42.155.855.128 1.287l1.372-1.372a3 3 0 1 0-4.243-4.243z"/>
</svg>
            </Link>

                <div className="number-badge">
  {filteredAndSortedweekepData.some(weekep => weekep.activeComment && weekep.activeComment.remainingTime > 0)
    ? weekep.ranking.currentRank
    : index + 1}
</div>
                  <h4>{weekep.name}</h4>
                  <button className="btnmohank cube cube-hover" type="button" onClick={() => this.handleExpandex(weekep._id)}>                <div class="bg-top">
    <div class="bg-inner"></div>
  </div>
  <div class="bg-right">
    <div class="bg-inner"></div>
  </div>
  <div class="bg">
    <div class="bg-inner"></div>
  </div>
  <div class="textmohank">ادخال التقييم</div>
                  </button>
                  
                  {showExpandex && selectedweekepcomm && selectedweekepcomm._id === weekep._id && (

<div className="expanded-overlayssx">
<div className="expanded-cardssx">

<button className="close-button" onClick={this.handleExpandex}>×</button>
  
     <div className="anime-info">
     <img src={selectedweekepcomm.imageUrl} alt={selectedweekepcomm.name} className="anime-image" />
    <span className="anime-name">{selectedweekepcomm.name}</span>
     </div>
    <input
    type="hidden"
   value={selectedweekepcomm.id}
     className="anime-id-input"
      />
        <div className="containergold">
      <div className="gold-box">
<h6>اولا : ادخل تقييمك من 10</h6>
</div>
</div>
<input
  type="number"
  value={rating}
  onChange={this.handleRatingChange}
  className="rate-input"
  placeholder="0.0"
  maxLength="4"
  step="0.1" // تحديد الدقة العشرية
  min="0" // الحد الأدنى للقيمة
  max="10" // الحد الأقصى للقيمة
/>
<button onClick={this.deleteRating} className="delete-rating-button"><span class="textdelete">حذف تقييمك</span><span class="icon"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"></path></svg></span></button>
<div className="containergold">
<div className="gold-box">
<h6>ثانيا : اكتب كلمه توجهها لمن يختلف معك بالتقييم</h6>
</div>
</div>
<div class="circle-left"></div>
<div class="cardratemargin">
<div class="cardrate">

  <input
  value={note}
  onChange={this.handleNoteChange}
  maxLength="50"
  className="note-input"
  ref={this.textareaRef}
  placeholder="كن لبقا ورقيقا مع المختلفين مع حنكتك ايها المحنك"
  
   
  
  
  />

</div>
<div class="circle-right"></div>
</div>
<button onClick={this.addOrUpdateRating} className="confirm-rating-button">تأكيد التقييم <div id="clip">
        <div id="leftTop" class="corner"></div>
        <div id="rightBottom" class="corner"></div>
        <div id="rightTop" class="corner"></div>
        <div id="leftBottom" class="corner"></div>
    </div>
    <span id="rightArrow" class="arrow"></span>
    <span id="leftArrow" class="arrow"></span>
</button>
<div className="containergold">
<div className="gold-box">
<h6>ثالثا : انتظر ستظهر كلمه يوجهها لك محنك يختلف معك بالتقييم</h6>
</div>
</div>
<div class="voltage-button">
<div className="random-note">
{randomNote ? (
 randomNote === "انت اول من يقيم هذه الحلقه" ? (
    <div className="first-rating-note">
      <p>{randomNote}</p>
    </div>
  ) : (
    <div className="random-note-container">
      <img src={randomUserProfileImage} alt={randomUsername} className="random-avatar" />
      <p className="random-rating">تقييم {randomUsername} لهذه الحلقه: <span className="rating-valueran">#{randomRating}</span></p>
      <p className="random-noteqq">يوجه {randomUsername} كلمه لمن يختلف معه بالتقييم وهي: <span className="ratingnote-valueran">{randomNote}</span></p>
      <button
  className="fight"
  onClick={() => window.open(`/profile?username=${randomUsername}`, '_blank', 'noopener,noreferrer')}
>
  اذهب لبروفايله وقيمه
</button>
    </div>
  )
) : (
    
    <div class="notificationgoku">
  	<div id="goku">
      <div class="headgoku"></div>
      <div class="bodygoku"></div>
      <div class="leggoku"></div>
      <div class="shadowgoku"></div>
	  </div>

  <span class="dirt">
  
  <div class="textgoku">
    <span class="font hover">WAIT!</span>
    <span class="font-text hover">بعد تقييمك:</span>
    <span class="font-power hover">ستظهر ملاحظة عشوائية</span>
  </div>
  <div class="parchment">
  </div>
</span></div>
  

   
  )}
</div>
<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 234.6 61.3" preserveAspectRatio="none" xmlSpace="preserve">
 
 <filter id="glow">
   <feGaussianBlur class="blur" result="coloredBlur" stdDeviation="2"></feGaussianBlur>
   <feTurbulence type="fractalNoise" baseFrequency="0.075" numOctaves="3" result="turbulence"></feTurbulence>
<feDisplacementMap in="SourceGraphic" in2="turbulence" scale="30" xChannelSelector="R" yChannelSelector="G" result="displace"></feDisplacementMap>
   <feMerge>
     <feMergeNode in="coloredBlur"></feMergeNode>
     <feMergeNode in="coloredBlur"></feMergeNode>
     <feMergeNode in="coloredBlur"></feMergeNode>
     <feMergeNode in="displace"></feMergeNode>
     <feMergeNode in="SourceGraphic"></feMergeNode>
   </feMerge>
 </filter>
 <path class="voltage line-1" d="m216.3 51.2c-3.7 0-3.7-1.1-7.3-1.1-3.7 0-3.7 6.8-7.3 6.8-3.7 0-3.7-4.6-7.3-4.6-3.7 0-3.7 3.6-7.3 3.6-3.7 0-3.7-0.9-7.3-0.9-3.7 0-3.7-2.7-7.3-2.7-3.7 0-3.7 7.8-7.3 7.8-3.7 0-3.7-4.9-7.3-4.9-3.7 0-3.7-7.8-7.3-7.8-3.7 0-3.7-1.1-7.3-1.1-3.7 0-3.7 3.1-7.3 3.1-3.7 0-3.7 10.9-7.3 10.9-3.7 0-3.7-12.5-7.3-12.5-3.7 0-3.7 4.6-7.3 4.6-3.7 0-3.7 4.5-7.3 4.5-3.7 0-3.7 3.6-7.3 3.6-3.7 0-3.7-10-7.3-10-3.7 0-3.7-0.4-7.3-0.4-3.7 0-3.7 2.3-7.3 2.3-3.7 0-3.7 7.1-7.3 7.1-3.7 0-3.7-11.2-7.3-11.2-3.7 0-3.7 3.5-7.3 3.5-3.7 0-3.7 3.6-7.3 3.6-3.7 0-3.7-2.9-7.3-2.9-3.7 0-3.7 8.4-7.3 8.4-3.7 0-3.7-14.6-7.3-14.6-3.7 0-3.7 5.8-7.3 5.8-2.2 0-3.8-0.4-5.5-1.5-1.8-1.1-1.8-2.9-2.9-4.8-1-1.8 1.9-2.7 1.9-4.8 0-3.4-2.1-3.4-2.1-6.8s-9.9-3.4-9.9-6.8 8-3.4 8-6.8c0-2.2 2.1-2.4 3.1-4.2 1.1-1.8 0.2-3.9 2-5 1.8-1 3.1-7.9 5.3-7.9 3.7 0 3.7 0.9 7.3 0.9 3.7 0 3.7 6.7 7.3 6.7 3.7 0 3.7-1.8 7.3-1.8 3.7 0 3.7-0.6 7.3-0.6 3.7 0 3.7-7.8 7.3-7.8h7.3c3.7 0 3.7 4.7 7.3 4.7 3.7 0 3.7-1.1 7.3-1.1 3.7 0 3.7 11.6 7.3 11.6 3.7 0 3.7-2.6 7.3-2.6 3.7 0 3.7-12.9 7.3-12.9 3.7 0 3.7 10.9 7.3 10.9 3.7 0 3.7 1.3 7.3 1.3 3.7 0 3.7-8.7 7.3-8.7 3.7 0 3.7 11.5 7.3 11.5 3.7 0 3.7-1.4 7.3-1.4 3.7 0 3.7-2.6 7.3-2.6 3.7 0 3.7-5.8 7.3-5.8 3.7 0 3.7-1.3 7.3-1.3 3.7 0 3.7 6.6 7.3 6.6s3.7-9.3 7.3-9.3c3.7 0 3.7 0.2 7.3 0.2 3.7 0 3.7 8.5 7.3 8.5 3.7 0 3.7 0.2 7.3 0.2 3.7 0 3.7-1.5 7.3-1.5 3.7 0 3.7 1.6 7.3 1.6s3.7-5.1 7.3-5.1c2.2 0 0.6 9.6 2.4 10.7s4.1-2 5.1-0.1c1 1.8 10.3 2.2 10.3 4.3 0 3.4-10.7 3.4-10.7 6.8s1.2 3.4 1.2 6.8 1.9 3.4 1.9 6.8c0 2.2 7.2 7.7 6.2 9.5-1.1 1.8-12.3-6.5-14.1-5.5-1.7 0.9-0.1 6.2-2.2 6.2z" fill="transparent" stroke="#fff"></path>
 <path class="voltage line-2" d="m216.3 52.1c-3 0-3-0.5-6-0.5s-3 3-6 3-3-2-6-2-3 1.6-6 1.6-3-0.4-6-0.4-3-1.2-6-1.2-3 3.4-6 3.4-3-2.2-6-2.2-3-3.4-6-3.4-3-0.5-6-0.5-3 1.4-6 1.4-3 4.8-6 4.8-3-5.5-6-5.5-3 2-6 2-3 2-6 2-3 1.6-6 1.6-3-4.4-6-4.4-3-0.2-6-0.2-3 1-6 1-3 3.1-6 3.1-3-4.9-6-4.9-3 1.5-6 1.5-3 1.6-6 1.6-3-1.3-6-1.3-3 3.7-6 3.7-3-6.4-6-6.4-3 2.5-6 2.5h-6c-3 0-3-0.6-6-0.6s-3-1.4-6-1.4-3 0.9-6 0.9-3 4.3-6 4.3-3-3.5-6-3.5c-2.2 0-3.4-1.3-5.2-2.3-1.8-1.1-3.6-1.5-4.6-3.3s-4.4-3.5-4.4-5.7c0-3.4 0.4-3.4 0.4-6.8s2.9-3.4 2.9-6.8-0.8-3.4-0.8-6.8c0-2.2 0.3-4.2 1.3-5.9 1.1-1.8 0.8-6.2 2.6-7.3 1.8-1 5.5-2 7.7-2 3 0 3 2 6 2s3-0.5 6-0.5 3 5.1 6 5.1 3-1.1 6-1.1 3-5.6 6-5.6 3 4.8 6 4.8 3 0.6 6 0.6 3-3.8 6-3.8 3 5.1 6 5.1 3-0.6 6-0.6 3-1.2 6-1.2 3-2.6 6-2.6 3-0.6 6-0.6 3 2.9 6 2.9 3-4.1 6-4.1 3 0.1 6 0.1 3 3.7 6 3.7 3 0.1 6 0.1 3-0.6 6-0.6 3 0.7 6 0.7 3-2.2 6-2.2 3 4.4 6 4.4 3-1.7 6-1.7 3-4 6-4 3 4.7 6 4.7 3-0.5 6-0.5 3-0.8 6-0.8 3-3.8 6-3.8 3 6.3 6 6.3 3-4.8 6-4.8 3 1.9 6 1.9 3-1.9 6-1.9 3 1.3 6 1.3c2.2 0 5-0.5 6.7 0.5 1.8 1.1 2.4 4 3.5 5.8 1 1.8 0.3 3.7 0.3 5.9 0 3.4 3.4 3.4 3.4 6.8s-3.3 3.4-3.3 6.8 4 3.4 4 6.8c0 2.2-6 2.7-7 4.4-1.1 1.8 1.1 6.7-0.7 7.7-1.6 0.8-4.7-1.1-6.8-1.1z" fill="transparent" stroke="#fff"></path>
</svg>
<div class="dots">
 <div class="dot dot-1"></div>
 <div class="dot dot-2"></div>
 <div class="dot dot-3"></div>
 <div class="dot dot-4"></div>
 <div class="dot dot-5"></div>
</div>
</div>
</div>
</div>
                  )}
                  <div className="row">
                    <div className="col">
                    <div className="rating-containerss">
    <p className="card-text rating-textss">عدد المقيمين بتقييم 10: %{weekep.tenRatingsCount}</p>
  </div>
  <div className="rating-containerss">
    <p className="card-text rating-textss">عدد المقيمين بتقييم 0: %{weekep.zeroRatingsCount}</p>
  </div>
  
                      {/*
                      <button className="btn-md rounded my-button">مراجعات</button>
                      */}
                    </div>

                    
                    <div className="col">
  <div className="row">
    <div className="col">
      <div className="honor-points">
        <div className="circle-honor">
          <div>نقاط الشرف</div>
          <div>{weekep.honorPoints.toFixed(1)}</div>
        </div>
      </div>
      
    
    </div>
    
    <div className="col">
  <div className="review-section">
    <button className="btn-md rounded comm-button" type="button" onClick={() => this.handleExpandes(weekep._id)}>
      <strong>نشر تعليق علني</strong>
      <div id="container-stars">
    <div id="stars"></div>
  </div>
  

  <div id="glowx">
    <div class="circle"></div>
    <div class="circle"></div>
  </div>
    </button>

    {showExpandes && selectedweekepcomm && selectedweekepcomm._id === weekep._id && (

             <div className="expanded-overlayss">
    <div className="expanded-cardss">

<div className="modal-overlay">
<div className="modal-contents">
  <button className="close-button" onClick={this.handleExpandes}>×</button>
  <h5>نشر تعليق علني</h5>
     <div className="anime-info">
     <img src={selectedweekepcomm.imageUrl} alt={selectedweekepcomm.name} className="anime-image" />
    <span className="anime-name">{selectedweekepcomm.name}</span>
     </div>
    <input
    type="hidden"
   value={selectedweekepcomm.id}
     className="anime-id-input"
      />
    <textarea
        ref={this.textareaRef}
        value={commentText}
        onChange={this.handleCommentTextChange}
        maxLength="250"
        placeholder="اكتب تعليقك هنا (250 حرف كحد أقصى)"
        className="comment-input"
      ></textarea>
  <div className="comment-type-switch">
  
  <div class="checkboxtype">
  {commentType === 'critic' ? 'تعليقك انتقادي وفي حال فوزه سيخصم نقاط شرف' : 'تعليق مدح وفي حال فوزه سيزيد نقاط الشرف'}
           </div>
    <input id="checkbox_toggle" type="checkbox" class="check"  checked={commentType === 'critic'}
        onChange={this.handleCommentTypeChange}/>
      
<div class="checkbox">
  <label class="slide" for="checkbox_toggle">
    <label class="toggle" for="checkbox_toggle"></label>
    <label class="textt" for="checkbox_toggle"> {commentType === 'critic' ? 'انتقاد' : 'مدح'}</label>
    
    
   
  </label>
  
</div>
  </div>
  <div className="comment-info-tooltip">
    <span className="tooltip-icon">ℹ️</span>

    <div className="tooltip-text">مفهوم التلعيق العلني هو ان يتم نشر تعليق علني على بطاقه الحلقه بحيث بطاقه الحلقه تعرض تعليق واحد فقط بنفس الوقت ويتم انشاء طابور لاي تعليقات اخرى ويتم نشرهم تلقائيا وكل تعليق له مده ظهور معينه بالصفحه الرئيسيه  وبعدها يختفي ويهدف نظام التعليق العلني ان يستطيع متابع الحلقه تسليط عيون الجمهور على رأيه وبناءا على عدد من يتفق معك او يختلف معك يؤثر تعليقك على ترتيب الحلقه وبالطبع التعليقات العلنيه تظهر بالصفحه الرئيسيه لذلك تكون المشاهدات عاليه  وتستطيع نشر رأيك للعالم وهناك  3 باقات للتعليق العلني وكلما زادت باقتك زاد صدى صوت تعليقك </div>
  </div>
  <table className="comment-pricing-table">
    <thead>
      <tr>
      <th>ذهبي - 15 دولار
      <img src="https://i.imgur.com/NqQSvC2.png"  className="pricing-icon" />
      </th>
        <th>فضي - 3 دولار 
        <img src="https://i.imgur.com/ge7iVrg.png"  className="pricing-icon" />
        </th>
        <th>مجاني 
        <img src="https://i.imgur.com/dTiRNW3.jpg"  className="pricing-icon" />
        </th>
      </tr>
    </thead>
    <tbody>
    <tr>
            <td className="golden-column">
              <ol>
                <li>جميع مميزات الباقه الفضيه</li>
                <li>تم عرضه بالصفحه لمده 300 دقيقه</li>
                <li>يتم ارسال اشعار جماعي لجميع مستخدمين الموقع بنص تعليقك على هذا الانمي لحظه النشر وسيظهر الاشعار حتى للحسابات الجديده اللذي سيتم انشاءها من اي مستخدم مستقبلا </li>
                <li>اذا كان تعليقك مدحا ستتم زياده مباشر لحظه النشر بمقدار 3%+250 من نقاط الشرف لهذه الحلقه في حال الانتقاد سيتم خصم نفس الكميه وبالطبع نقاط الشرف تؤثر في ترتيب الحلقه</li>
              </ol>
            </td>
            <td className="silver-column">
              <ol>
                <li>جميع مميزات الباقه المجانيه</li>
                <li>من يمتلك عضويه الموقع يستطيع نشر تعليق فضي مجانا مره واحده يوميا</li>
                <li>يتم عرضه بالصفحه لمده 120 دقيقه</li>
                <li>اذا كان هناك اي تعليق مجاني نشط حاليا على هذه الحلقه سيتم اسكاته ونشر تعليقك الفضي بااختصار التعليقات الفضيه والذهبيه لاتنتظر بالطابور وتتخطاه</li>
                <li>اذا كان تعليقك مدحا ستتم زياده مباشر لحظه النشر بمقدار 1%+100 من نقاط الشرف لهذه الحلقه في حال الانتقاد سيتم خصم نفس الكميه وبالطبع نقاط الشرف تؤثر في ترتيب الحلقه</li>
              </ol>
            </td>
            <td className="free-column">
              <ol>
                <li>يتم عرض التعليق المجاني على بطاقه الحلقه بالصفحه الرئيسيه لمده 30 دقيقه</li>
                <li>ترتفع بطاقه الحلقه اللذي علقت عليها لقمه الصفحه حتى يرى الناس تعليقك</li>
                <li>مسموح بعرض تعليق نشط واحد فقط على نفس الحلقه واي تعليق اخر يتم نشره اثناء ذلك ينضم للطابور ويتم نشره تلقائيا حسب دوره</li>
                <li>نتائج التعليق العلني تؤثر في ترتيب الحلقه وفي ترتيبك انت ايها المستخدم بين المحنكين</li>
                <li>بعد انتهاء 30 دقيقه سيأتيك اشعار بتفاصيل نتائج التعليق من عدد اتافاقات واختلافات وهل فاز تعليقك ام خسر ومامقدر تأثيره على مرتبه هذا الحلقه ومقدار التأثير يعتمد على عدد الاتفاقات والاختلافات الخاصه بتعليقك</li>
              </ol>
            </td>
          </tr>
      <tr>
        <td>
          
       
          <button onClick={this.openCheckoutgolden}>الدفع لنشر تعليق ذهبي <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-credit-card-fill" viewBox="0 0 16 16">
  <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v1H0zm0 3v5a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7zm3 2h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-1a1 1 0 0 1 1-1"/>
</svg></button>
          <div className="paypal">
          <PayPalScriptProvider options={initialOptions}>
        <PayPalButtons
          style={{
            shape: "rect",
            layout: "vertical",
          }}
          createOrder={this.createOrdergolden} // استدعاء الدالة الخارجية هنا
          onApprove={async (data, actions) => {
            try {
              const response = await fetch(
                `/api/orders/${data.orderID}/capture`,
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                },
              );
            
              const orderData = await response.json();
              // Three cases to handle:
              //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
              //   (2) Other non-recoverable errors -> Show a failure message
              //   (3) Successful transaction -> Show confirmation or thank you message

              const errorDetail = orderData?.details?.[0];

              if (errorDetail?.issue === "INSTRUMENT_DECLINED") {
                // (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                // recoverable state, per https://developer.paypal.com/docs/checkout/standard/customize/handle-funding-failures/
                return actions.restart();
              } else if (errorDetail) {
                // (2) Other non-recoverable errors -> Show a failure message
                throw new Error(
                  `${errorDetail.description} (${orderData.debug_id})`,
                );
              } else {
                // (3) Successful transaction -> Show confirmation or thank you message
        
      this.submitCommentgoldenpaypal(); // استدعاء الدالة هنا
    
                // Or go to another URL:  actions.redirect('thank_you.html');
                const transaction = orderData.purchase_units[0].payments.captures[0];
                alert(`Transaction ${transaction.status}: ${transaction.id}`);
                
                console.log(
                  "Capture result",
                  orderData,
                  JSON.stringify(orderData, null, 2),
                );
              }
            } catch (error) {
              console.error(error);
              alert(`Sorry, your transaction could not be processed...${error}`);

            }
          }}
        />
      </PayPalScriptProvider>
      </div>
        </td>
        <td>
          <button onClick={this.openCheckoutsilver} >الدفع لنشر تعليق فضي<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-credit-card-fill" viewBox="0 0 16 16">
  <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v1H0zm0 3v5a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7zm3 2h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-1a1 1 0 0 1 1-1"/>
</svg></button>
          <div className="paypal">
          <PayPalScriptProvider options={initialOptions}>
        <PayPalButtons
          style={{
            shape: "rect",
            layout: "vertical",
          }}
          createOrder={this.createOrdersilver} // استدعاء الدالة الخارجية هنا
          onApprove={async (data, actions) => {
            try {
              const response = await fetch(
                `/api/orders/${data.orderID}/capture`,
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                },
              );
            
              const orderData = await response.json();
              // Three cases to handle:
              //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
              //   (2) Other non-recoverable errors -> Show a failure message
              //   (3) Successful transaction -> Show confirmation or thank you message

              const errorDetail = orderData?.details?.[0];

              if (errorDetail?.issue === "INSTRUMENT_DECLINED") {
                // (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                // recoverable state, per https://developer.paypal.com/docs/checkout/standard/customize/handle-funding-failures/
                return actions.restart();
              } else if (errorDetail) {
                // (2) Other non-recoverable errors -> Show a failure message
                throw new Error(
                  `${errorDetail.description} (${orderData.debug_id})`,
                );
              } else {
                // (3) Successful transaction -> Show confirmation or thank you message
        
      this.submitCommentsilverpaypal(); // استدعاء الدالة هنا
    
                // Or go to another URL:  actions.redirect('thank_you.html');
                const transaction = orderData.purchase_units[0].payments.captures[0];
                alert(`Transaction ${transaction.status}: ${transaction.id}`);
                
                console.log(
                  "Capture result",
                  orderData,
                  JSON.stringify(orderData, null, 2),
                );
              }
            } catch (error) {
              console.error(error);
              alert(`Sorry, your transaction could not be processed...${error}`);

            }
          }}
        />
      </PayPalScriptProvider>
      </div>
        </td>
        <td>
        <button onClick={this.submitComment}>نشر تعليق مجاني</button>
        </td>
      </tr>
    </tbody>
  </table>
</div>
</div>
</div>
</div>
)}


    </div>
    </div>


  </div>

  
  </div>
                    <div className="col">
                  
                    <div className="col">
                      

  <div className="button-row">
   {/*
    <button className="btn-md rounded button-style">ترتيب محنكين</button>
    <button className="btn-md rounded button-style">افضل كوليكشن</button>
    */}
  </div>
  <div className="button-row">
   
  <button className="btn-md rounded button-style" onClick={() => this.handleExpandexrank(weekep._id)}>تفاصيل المرتبة</button>
    {showExpandexrank && selectedweekepcomm && selectedweekepcomm._id === weekep._id && (

<div className="expanded-overlayssx">
<div className="expanded-cardssxrank">

<button className="close-button" onClick={this.handleExpandexrank}>×</button>
  
     <div className="anime-info">
     <img src={selectedweekepcomm.imageUrl} alt={selectedweekepcomm.name} className="anime-imagex" />
    <span className="anime-name">{selectedweekepcomm.name}</span>
     </div>
   
     <div className="ranking-info">
  
  
                    <div className="ranking-row">
                      <span className="ranking-label">المرتبة الحالية:</span>
                      <span className="ranking-value">{selectedweekepcomm.ranking.currentRank}</span>
                    </div>
                    <div className="ranking-row">
                      <span className="ranking-label">المرتبة السابقة:</span>
                      <span className="ranking-value">{selectedweekepcomm.ranking.previousRank}</span>
                    </div>
                    <div className="ranking-row">
                      <span className="ranking-label">آخر تغيير في المرتبة:</span>
                      <span className="ranking-value">
                        {new Date(selectedweekepcomm.ranking.lastchangeinRank).toLocaleDateString('ar-EG', {
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric'
                        })}
                      </span>
                    </div>
                  </div>
                


                </div>
              </div>
            )}
            {/*
    <button className="btn-md rounded button-style">منتجات</button>
    */}
  </div>
</div>

</div>
</div>
</div>

{weekep.activeComment &&  (
<div className="col">
  <div className="row2">
  <div className={`comment-card card ${weekep.activeComment.commentType === 'critic' ? 'critic-comment' : 'praiser-comment'}`} style={this.getCommentStyle(weekep.activeComment.commentPrice)}>
                    <div className="glow-bar">
                    <div className="card-body">
                      <div className="row">
                      <div className="col-auto">
  <a href={`/profile?username=${weekep.activeComment.userName}`} target="_blank" rel="noopener noreferrer">
    <img src={weekep.activeComment.userAvatar} alt="User Avatar" className="user-avatar" style={avatarStyle} />
  </a>
</div>
                        <div className="col">
                          <div className="row">
                            <div className="col-auto">
                              <span className="user-name">{weekep.activeComment.userName}</span>
                            </div>
                            <div className="col-auto">
                              <span className={`comment-price ${weekep.activeComment.commentPrice}`}>{weekep.activeComment.commentPrice}</span>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col">
                              <div className="comment-text">{weekep.activeComment.commentText}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                        <button
                  className={this.getButtonClass(weekep.activeComment, userId, 'like')}
                  onClick={() => this.likeComment(weekep._id, weekep.activeComment._id)}
                >
                                  <span className="likes-count">{weekep.activeComment.likesCount}  <svg viewBox="0 0 50 50" version="1.1" xmlns="http://www.w3.org/2000/svg" width="19" height="19" class="icon">
    <path d="M 24.10 6.29 Q 28.34 7.56 28.00 12.00 Q 27.56 15.10 27.13 18.19 A 0.45 0.45 4.5 0 0 27.57 18.70 Q 33.16 18.79 38.75 18.75 Q 42.13 18.97 43.23 21.45 Q 43.91 22.98 43.27 26.05 Q 40.33 40.08 40.19 40.44 Q 38.85 43.75 35.50 43.75 Q 21.75 43.75 7.29 43.75 A 1.03 1.02 0.0 0 1 6.26 42.73 L 6.42 19.43 A 0.54 0.51 -89.4 0 1 6.93 18.90 L 14.74 18.79 A 2.52 2.31 11.6 0 0 16.91 17.49 L 22.04 7.17 A 1.74 1.73 21.6 0 1 24.10 6.29 Z M 21.92 14.42 Q 20.76 16.58 19.74 18.79 Q 18.74 20.93 18.72 23.43 Q 18.65 31.75 18.92 40.06 A 0.52 0.52 88.9 0 0 19.44 40.56 L 35.51 40.50 A 1.87 1.83 5.9 0 0 37.33 39.05 L 40.51 23.94 Q 40.92 22.03 38.96 21.97 L 23.95 21.57 A 0.49 0.47 2.8 0 1 23.47 21.06 Q 23.76 17.64 25.00 12.00 Q 25.58 9.36 24.28 10.12 Q 23.80 10.40 23.50 11.09 Q 22.79 12.80 21.92 14.42 Z M 15.57 22.41 A 0.62 0.62 0 0 0 14.95 21.79 L 10.01 21.79 A 0.62 0.62 0 0 0 9.39 22.41 L 9.39 40.07 A 0.62 0.62 0 0 0 10.01 40.69 L 14.95 40.69 A 0.62 0.62 0 0 0 15.57 40.07 L 15.57 22.41 Z" fill-opacity="1.000"></path>
    <circle r="1.51" cy="37.50" cx="12.49" fillOpacity="1.000"></circle>
    </svg> أتفق</span>
                                </button>
                                <button
                  className={this.getButtonClass(weekep.activeComment, userId, 'dislike')}
                  onClick={() => this.dislikeComment(weekep._id, weekep.activeComment._id)}
                >
                                  <span className="dislikes-count">{weekep.activeComment.dislikesCount} <svg viewBox="0 0 50 50" version="1.1" xmlns="http://www.w3.org/2000/svg" width="19" height="19" class="icon" transform="rotate(180 0 0)">
    <path d="M 24.10 6.29 Q 28.34 7.56 28.00 12.00 Q 27.56 15.10 27.13 18.19 A 0.45 0.45 4.5 0 0 27.57 18.70 Q 33.16 18.79 38.75 18.75 Q 42.13 18.97 43.23 21.45 Q 43.91 22.98 43.27 26.05 Q 40.33 40.08 40.19 40.44 Q 38.85 43.75 35.50 43.75 Q 21.75 43.75 7.29 43.75 A 1.03 1.02 0.0 0 1 6.26 42.73 L 6.42 19.43 A 0.54 0.51 -89.4 0 1 6.93 18.90 L 14.74 18.79 A 2.52 2.31 11.6 0 0 16.91 17.49 L 22.04 7.17 A 1.74 1.73 21.6 0 1 24.10 6.29 Z M 21.92 14.42 Q 20.76 16.58 19.74 18.79 Q 18.74 20.93 18.72 23.43 Q 18.65 31.75 18.92 40.06 A 0.52 0.52 88.9 0 0 19.44 40.56 L 35.51 40.50 A 1.87 1.83 5.9 0 0 37.33 39.05 L 40.51 23.94 Q 40.92 22.03 38.96 21.97 L 23.95 21.57 A 0.49 0.47 2.8 0 1 23.47 21.06 Q 23.76 17.64 25.00 12.00 Q 25.58 9.36 24.28 10.12 Q 23.80 10.40 23.50 11.09 Q 22.79 12.80 21.92 14.42 Z M 15.57 22.41 A 0.62 0.62 0 0 0 14.95 21.79 L 10.01 21.79 A 0.62 0.62 0 0 0 9.39 22.41 L 9.39 40.07 A 0.62 0.62 0 0 0 10.01 40.69 L 14.95 40.69 A 0.62 0.62 0 0 0 15.57 40.07 L 15.57 22.41 Z" fill-opacity="1.000"></path>
    <circle r="1.51" cy="37.50" cx="12.49" fill-opacity="1.000"></circle>
</svg> أختلف</span>
                                </button>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <div className="comment-type-container">
                            <div className={`comment-type ${weekep.activeComment.commentType === 'critic' ? 'critic-comment' : 'praiser-comment'}`}>
                              {weekep.activeComment.commentType === 'critic' ? 'تعليق انتقادي' : 'تعليق مدح'}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <div className="comment-notification" style={{ fontSize: '12px', textShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', padding: '4px', marginTop: 'auto', marginBottom: '0', textAlign: 'center' }}>
                              {weekep.activeComment.commentType === 'critic' ? 'سيتم خصم نقاط شرف من هذه الحلقه إذا كان عدد الاتفافات أكبر من عدد الاختلافات لهذا التعليق' : 'سيتم زيادة نقاط شرف هذه الحلقه إذا كان عدد الاتفاقات أكثر من عدد الاختلافات لهذا التعليق'}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <div className="remaining-time">
                              الوقت المتبقي: {weekep.activeComment.remainingTime} دقيقة
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    </div>
                  </div>
  </div>
</div>
 )}
</div>

</div>



))}
 </InfiniteScroll>
</div>





</div>






          
          
         
        
       
        <style dangerouslySetInnerHTML={{__html: "\n      /* تحديد خلفية الموقع */\n    body {\n        \n      background: linear-gradient(to right, #ffffff , #fcfcfc 0%);\n  }\n\n    " }} />
      

        
        </div>
    );
  }
}





export default WeekEp;


