import logo from './logo14400.png';
//import './components/animecard.css';
import './components/logincard.css';
import Resetpass from './resetpass.js';


//import './App.css';

//import Signup from './signup.js';
//import Button from './button.js';

//import { anime } from "../../backend/server.js"; // import Anime model from animeModel.js


import axios from 'axios';
import './assets/css/bootstrap.min.css'; // استيراد ملف CSS من Bootstrap
import 'animate.css/animate.min.css';
//import './assets/js/bootstrap.bundle.js';
//import bootstrap from 'bootstrap';




import React, { useEffect, useState, Component } from 'react';
import api from './api';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router, Route, Link, Routes, Navigate} from 'react-router-dom';

//import { BrowserRouter, Route, Switch } from "react-router-dom";
//import { Button } from 'bootstrap';
//import { Offcanvas } from 'bootstrap';
import { Navbar, Offcanvas, Card, Button, CardDeck, CardGroup, CardImg, Dropdown, Form, Alert} from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { keyframes } from "styled-components";
//import keyframe from 'keyframe';
//import { Animated, Easing, keyframe } from 'react-native';
//import LottieView from 'lottie-react-native';
import {
  useScene,
  useSceneItem,
  useFrame,
  useNowFrame,
} from "react-scenejs";
import { Animate, AnimateKeyframes, AnimateGroup } from "react-simple-animate";



//import { Offcanvas } from 'react-bootstrap';

import _ from 'lodash';
//import Switch from "react-switch";
import { Tabs, Tab, Box, TabPanel, styled } from '@mui/material';


//import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
//import 'react-tabs/style/react-tabs.css';


class Forgotpass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogin: true,
      username: "",
      email: "",
      password: "",
      error: "",
      usernameOrEmail: "",
      isCodeSent: false,
      success: "",
      submitted: false, // إضافة حالة جديدة لتتبع حالة التقديم
    };
  }

  handleLoginSubmit = async (e) => {
    e.preventDefault();
    this.setState({ error: "" });

    const { usernameOrEmail, password } = this.state;

    try {
      const response = await axios.post("/api/login", {
        usernameOrEmail,
        password
      });
      // قم بمعالجة استجابة الطلب هنا
    } catch (error) {
      console.error(error);
      // قم بمعالجة الأخطاء هنا
      this.setState({ error: "هناك خطأ في كلمه المرور او اسم المستخدم" });
    }
  };

  handleRegisterSubmit = async (e) => {
    e.preventDefault();
    this.setState({ error: "" });

    const { username, email, password } = this.state;

    try {
      const response = await axios.post("/api/register", {
        username,
        email,
        password
      });
      // قم بمعالجة استجابة الطلب هنا
    } catch (error) {
      console.error(error);
      // قم بمعالجة الأخطاء هنا
      this.setState({ error: "هناك خطأ ما" });
    }
  };




  handleForgotPassword = async (e) => {
    e.preventDefault();
    this.setState({ error: "", success: "", submitted: true });
  
    const { email } = this.state;
  
    // تعريف تعبير منتظم للبريد الإلكتروني
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  
    // التحقق من حقل البريد الإلكتروني
    if (email.trim() === "") {
      this.setState({ error: "يرجى إدخال بريد إلكتروني" });
      return;
    }
  
    if (!emailRegex.test(email)) {
      this.setState({ error: "يرجى إدخال بريد إلكتروني صحيح" });
      return;
    }
  
    try {
      const response = await axios.post("/api/forgot-password", { email });
      // قم بمعالجة استجابة الطلب هنا
      console.log(response.data); // سجل بيانات الاستجابة في وحدة التحكم (console)
  
      if (response.data.success) {
        // قم بإظهار رسالة نجاح هنا
        this.setState({ success: "تم إرسال رمز التأكيد إلى بريدك الإلكتروني" });
        
        // قم بالانتقال إلى صفحة resetpassword
        if (this.state.success) {
           this.props.navigate('/resetpassword');
          }
          
      } else {
        // قم بإظهار رسالة فشل هنا
        this.setState({ error: "فشل في إرسال رمز التأكيد" });
      }
    } catch (error) {
      console.error(error);
      // قم بمعالجة الأخطاء هنا
      this.setState({ error: error.response.data.message });
    }
  };
  



  render() {
    const { isLogin, username, email, password, error, usernameOrEmail, isCodeSent, success } = this.state;

    return (
      <div>
<title>المحنكين</title>{/* العنوان */}
<style dangerouslySetInnerHTML={{__html: "\n            #box {\n                background-color: black;\n                 border: 2.5px solid rgba(192, 192, 192, 192);\n                box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.8), 0 0 10px 5px rgba(255, 255, 255, 0.8);\n                pointer-events: none;\n        display: flex;\n                justify-content: center;\n                align-items: center;\n             align-items: center;\n                     border-radius: 70px;\n                padding: 5px;\n                objectfit: 'cover';\n                width: 49%;\n                height: 235px;\n                margin: 50px;\n         margin-top: 560px;\n           margin-left: 20px;\n           }\n    \n            h1 {\n                font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;\n                font-size: 36px;\n                font-weight: bold;\n                color: #000;\n                margin-top: 0px;\n                text-shadow: 2px 2px 4px #000;\n                letter-spacing: 2px;\n                text-align: center;\n            }\n        " }} />
<div id="box">
    <img src="././logo14400.png" alt="شعار الموقع" style={{width: '888.83px', height: '500px', objectfit: 'contain'}}/>
</div>
<div className="image-container">
        <img className="profile-image" src="https://i.imgur.com/0NHdvjQ.png" alt="صورة الملف الشخصي" />
        <div className="description-box">
        <p>
  المعلومات اللتي تريدها عن موقع المحنكين:
</p>

<ul>
  <li>
    <strong>الميزة الأولى:</strong> يهدف الموقع إلى تقييم كل شيء في عالم الانمي، بدءًا من الشخصيات والأنمي نفسه وصولًا إلى المقيمين ورسومات ومقاطع الانميشن.
  </li>
  <li>
    <strong>الميزة الثانية:</strong> يمكنك تقييم العمل الواحد بأكثر من طريقة وأكثر من مرة. تزيد قيمة تقييمك كلما زاد عدد المتفقين مع تقييمك.
  </li>
  <li>
    <strong>الميزة الثالثة:</strong> لا يعمل الموقع بنظام المتابعين، مما يعني أن صوتك سيصل إلى العالم بسهولة. كما يقوم الموقع بتصنيف المحنكين وترتيبهم حسب خوارزميات معينة.
  </li>
</ul>
        </div>
      </div>

<div className="carb" style={{ backgroundImage: 'url("https://i.imgur.com/6MYR7Dp.png")', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)' }}>
<div className="carb-body">
  <h5 className="card-title" style={{ fontSize: '32px', marginBottom: '10px', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)', textalign: 'center', marginLeft: '15px' }}>إعادة تعيين كلمة المرور</h5>
  
    <Form onSubmit={this.handleForgotPassword}>
      <Form.Group className="mb-3" controlId="email">
        <Form.Label>البريد الإلكتروني</Form.Label>
        <Form.Control type="email" placeholder="أدخل البريد الإلكتروني" value={email} onChange={(e) => this.setState({ email: e.target.value })} />
      </Form.Group>

      
  <Button variant="primary" type="submit">
    إرسال رمز التأكيد
  </Button>
  {this.state.submitted && this.state.success && (
          <Navigate to="/resetpassword" /> // استخدام Navigate في حالة نجاح التقديم
        )}

     

      {error && <Alert variant="danger">{error}</Alert>}
      {success && <Alert variant="success">{success}</Alert>}
      
    </Form>
  </div>

      </div>
      </div>
    );
  }
}

export default Forgotpass;