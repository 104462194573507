import logo from './logo.svg';
import './components/animecard.css';
import './App.css';

//import { anime } from "../../backend/server.js"; // import Anime model from animeModel.js


import axios from 'axios';
import './assets/css/bootstrap.min.css'; // استيراد ملف CSS من Bootstrap
import 'animate.css/animate.min.css';

//import './assets/js/bootstrap.bundle.js';
//import bootstrap from 'bootstrap';




import React, { useEffect, useState, Component, createRef } from 'react';
import api from './api';
import ReactDOM from 'react-dom';
//import { Button } from 'bootstrap';
//import { Offcanvas } from 'bootstrap';
import { Navbar, Offcanvas, Card, Button, CardDeck, CardColumns, CardGroup, CardImg, Dropdown, DropdownButton, DropdownItem, DropdownMenu} from "react-bootstrap";
import { NavLink, Link  } from "react-router-dom";
import Cookies from 'js-cookie';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { keyframes } from "styled-components";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { initializePaddle, Paddle } from '@paddle/paddle-js';
//import keyframe from 'keyframe';
//import { Animated, Easing, keyframe } from 'react-native';
//import LottieView from 'lottie-react-native';
import {
  useScene,
  useSceneItem,
  useFrame,
  useNowFrame,
} from "react-scenejs";
import { Animate, AnimateKeyframes, AnimateGroup } from "react-simple-animate";



//import { Offcanvas } from 'react-bootstrap';

import _ from 'lodash';
import Switch from "react-switch";
import { Tabs, Tab } from '@material-ui/core';

import slugify from 'slugify';
import Clipboard from 'clipboard';
import ReactHtml2Canvas from 'react-html2canvas'
import InfiniteScroll from 'react-infinite-scroll-component';




class TweeterList extends React.Component {

  constructor(props) {
    super(props);
    // نضع قيمة الحالة الأولية في this.state
    this.state = {
 
    page: 1,
    limit: 30,
   
    hasMore: true ,
    userId: "", // استبدل 'USER_ID' بمعرف المستخدم الفعلي 
      
    };

   
  }




  
  

  // use componentDidMount to fetch data from node server when the component mounts
  componentDidMount() {
  

      const userId = Cookies.get('userId') || localStorage.getItem('userId');
      
      if (userId) {
        const cleanedUserId = userId.replace(/^j:/, '');
    this.setState({ userId: cleanedUserId });
      }

       
      }

  

  handleTwitterLoginx = async () => {
    try {
      const animeId = "66c9bac54a621856b4ee663a"; // افترض أن animeId يتم تمريره كـ prop إلى المكون
      const response = await axios.get('/auth/twitter', {
        params: { animeId },
        withCredentials: true // تأكد من تمرير الكوكيز والجلسة
      });

      console.log('Response from /auth/twitter:', response.data);

      // إعادة توجيه المستخدم إلى رابط تسجيل الدخول عبر تويتر
      if (response.status === 200) {
        // لا حاجة للتحقق من response.data.redirectUrl لأننا نستخدم passport.authenticate مباشرة
        window.location.href = response.data.authUrl;
      } else {
        console.error('Unexpected response status:', response.status);
      }
    } catch (error) {
      console.error('Error during Twitter login:', error);
      // يمكنك إضافة معالجة للأخطاء هنا إذا لزم الأمر
    }
  };

  handleTwitterLogin = () => {
    const { selectedAnime } = this.state;
    const animeId = selectedAnime;
    if (animeId) {
      // إعادة توجيه المستخدم إلى رابط تسجيل الدخول عبر تويتر مع تمرير معرف الأنمي كـ query parameter
      window.location.href = `https://mohankeen.com/auth/twitter?animeId=${animeId}`;
    } else {
      console.error('Anime ID is required');
      // يمكنك إضافة معالجة للأخطاء هنا إذا لزم الأمر
    }
  };



  handleSubmittwittercheck = async (event) => {
    event.preventDefault();
    const { selectedAnime } = this.state; // افترض أن selectedAnime موجود في حالة المكون
  
    try {
    const response = await axios.get('/twittercheck', {
      params: { animeId: selectedAnime } // إرسال animeId كجزء من معلمات الاستعلام
    });
  
      this.setState({ message: response.data.message, error: '' });
      return response; // إرجاع الاستجابة إذا كانت ناجحة
    } catch (error) {
      let errorMessage = 'An error occurred'; // رسالة خطأ افتراضية
  
      if (error.response) {
        // إذا كان هناك استجابة من السيرفر، استخدم الرسالة الموجودة في response.data.message
        errorMessage = error.response.data.message || errorMessage;
      }
  
      this.setState({ error: errorMessage, message: '' });
      alert(errorMessage); // عرض رسالة الخطأ في alert
      throw error; // رمي الخطأ لإيقاف تنفيذ handleTwitterLogin
    }
  };
  
  handleButtonClicktweet = async (event) => {
    event.preventDefault();
    
    try {
      await this.handleSubmittwittercheck(event);
      this.handleTwitterLogin(event);
    } catch (error) {
      // لا تفعل شيئًا إذا كانت الاستجابة غير ناجحة
    }
  };
/*

  handleTwitterLogin = async () => {
    const animeId = "66c9bac54a621856b4ee663a";
    if (!animeId) {
      console.error('Anime ID is required');
      return;
    }
  
    try {
      const response = await fetch(`/auth/twitter?animeId=${animeId}`, {
        method: 'GET',
        credentials: 'include' // لتضمين الكوكيز في الطلب
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        alert(errorData.message || 'An error occurred');
        return;
      }
  
      // إذا كان الطلب ناجحًا، إعادة توجيه المستخدم إلى رابط تسجيل الدخول عبر تويتر
      const redirectUrl = response.url;
      window.location.href = redirectUrl;
    } catch (error) {
      console.error('Error during Twitter login:', error);
      alert('An error occurred during Twitter login');
    }
  };
 handleTwitterLogin() {
     const animeId = "66c9bac54a621856b4ee663a"; // يمكنك تغيير هذا إلى القيمة المطلوبة
    axios.get(`urlngrok/auth/twitter?animeId=${animeId}`)
      .then(response => {
        // التعامل مع الاستجابة
        console.log(response.data);
        // إعادة توجيه المستخدم إلى رابط المصادقة
        window.location.href = response.data.authUrl;
      })
      .catch(error => {
        console.error('Error during Twitter login:', error);
      });
  }

  handleTwitterLogin = () => {
    const animeId = "66c9bac54a621856b4ee663a";
    if (animeId) {
      // إعادة توجيه المستخدم إلى رابط تسجيل الدخول عبر تويتر مع تمرير معرف الأنمي كـ query parameter
      window.location.href = `http://localhost:3001/auth/twitter?animeId=${animeId}`;
    } else {
      console.error('Anime ID is required');
      // يمكنك إضافة معالجة للأخطاء هنا إذا لزم الأمر
    }
  };
*/


  render() {

    const { page, limit,hasMore    } = this.state;
    const userId = this.state.userId; // جلب معرف المستخدم من الحالة
    
  

    

    

    

    
    

    

    

    
    return (

      

      

      
      
      <div>

        
        

<button onClick={this.handleButtonClicktweet}>اضف نفسك لقائمه مغردين هذا الانمي</button>

  

        
         

   
        
        </div>
    );
  }
}





export default TweeterList;


