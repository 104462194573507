// src/api.js
import axios from 'axios';

// create an axios instance with the base url of the node server
// create an axios instance with the base url of '/'
const api = axios.create({
    baseURL: '/'
  });
  

// export the api function
export default api;
