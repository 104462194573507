// src/axiosConfig.js
import axios from 'axios';

// إعداد axios لإرسال البيانات المخزنة في localStorage مع كل طلب
axios.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  const userId = localStorage.getItem('userId');
  
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  
  if (userId) {
    config.headers['userId'] = userId;
  }
  
  return config;
}, (error) => {
  return Promise.reject(error);
});

export default axios;