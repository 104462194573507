import logo from './logo14400.png';
//import './components/animecard.css';
import './components/logincard.css';


//import './App.css';

//import Signup from './signup.js';
//import Button from './button.js';

//import { anime } from "../../backend/server.js"; // import Anime model from animeModel.js


import axios from 'axios';
import './assets/css/bootstrap.min.css'; // استيراد ملف CSS من Bootstrap
import 'animate.css/animate.min.css';
//import './assets/js/bootstrap.bundle.js';
//import bootstrap from 'bootstrap';




import React, { useEffect, useState, Component } from 'react';
import api from './api';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router, Route, Link, Routes, Navigate} from 'react-router-dom';

//import { BrowserRouter, Route, Switch } from "react-router-dom";
//import { Button } from 'bootstrap';
//import { Offcanvas } from 'bootstrap';
import { Navbar, Offcanvas, Card, Button, CardDeck, CardGroup, CardImg, Dropdown, Form, Alert} from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { keyframes } from "styled-components";
//import keyframe from 'keyframe';
//import { Animated, Easing, keyframe } from 'react-native';
//import LottieView from 'lottie-react-native';
import {
  useScene,
  useSceneItem,
  useFrame,
  useNowFrame,
} from "react-scenejs";
import { Animate, AnimateKeyframes, AnimateGroup } from "react-simple-animate";



//import { Offcanvas } from 'react-bootstrap';

import _ from 'lodash';
//import Switch from "react-switch";
import { Tabs, Tab, Box, TabPanel, styled } from '@mui/material';


//import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
//import 'react-tabs/style/react-tabs.css';


class Resetpass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogin: true,
      username: "",
      email: this.props.email, // Get the email from the props
      password: "",
      error: "",
      usernameOrEmail: "",
      isCodeSent: false,
      code: "", // The verification code
      success: "",
      submitted: false, // إضافة حالة جديدة لتتبع حالة التقديم
    };
  }

  






   // Define a method to handle the code input change
handleCodeChange = (e) => {
  this.setState({ code: e.target.value }); // Update the code in the state with the input value
};

// Define a method to handle the email input change
handleEmailChange = (e) => {
  this.setState({ email: e.target.value }); // Update the email in the state with the input value
};

// Define a method to handle the form submission
handleSubmit = async (e) => {
  e.preventDefault(); // Prevent the default behavior of the form
  this.setState({ error: '', success: '', submitted: true }); // Reset the error and success messages and set the submitted to true

  // Get the email and code from the state
  const email = this.state.email;
  const code = this.state.code;

  // Validate the code input
  if (code.trim() === '') {
    this.setState({ error: 'يرجى إدخال رمز التأكيد' }); // Set the error message if the code is empty
    return;
  }

  try {
    // Make a POST request to /api/verify-code with the email and code
    const response = await axios.post('/api/verify-code', { email, code });
    // Handle the response here
    console.log(response.data); // Log the response data to the console

    if (response.data.message === 'Your verification code is valid') {
      // Show a success message here
      this.setState({ success: 'تم التحقق من رمز التأكيد بنجاح' });
      // Store the token in the local storage
      localStorage.setItem('token', response.data.token);
      // Redirect to the reset password page using history.push method from react-router-dom package
      // قم بالانتقال إلى صفحة resetpassword
      if (this.state.success) {
        this.props.navigate('/newpassword');
       }
    } else {
      // Show a failure message here
      this.setState({ error: 'فشل في التحقق من رمز التأكيد' });
    }
  } catch (error) {
    console.error(error); // Log the error to the console
    // Handle the errors here
    this.setState({ error: error.response.data.message }); // Set the error message from the response data
  }
};


  



  render() {
    const { isLogin, username, email, password, error, usernameOrEmail, isCodeSent, code, success, submitted } = this.state;

    return (
      <div>
<title>المحنكين</title>{/* العنوان */}
<style dangerouslySetInnerHTML={{__html: "\n            #box {\n                background-color: black;\n                 border: 2.5px solid rgba(192, 192, 192, 192);\n                box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.8), 0 0 10px 5px rgba(255, 255, 255, 0.8);\n                pointer-events: none;\n        display: flex;\n                justify-content: center;\n                align-items: center;\n             align-items: center;\n                     border-radius: 70px;\n                padding: 5px;\n                objectfit: 'cover';\n                width: 49%;\n                height: 235px;\n                margin: 50px;\n         margin-top: 560px;\n           margin-left: 20px;\n           }\n    \n            h1 {\n                font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;\n                font-size: 36px;\n                font-weight: bold;\n                color: #000;\n                margin-top: 0px;\n                text-shadow: 2px 2px 4px #000;\n                letter-spacing: 2px;\n                text-align: center;\n            }\n        " }} />
<div id="box">
    <img src="././logo14400.png" alt="شعار الموقع" style={{width: '888.83px', height: '500px', objectfit: 'contain'}}/>
</div>
<div className="image-container">
        <img className="profile-image" src="https://i.imgur.com/0NHdvjQ.png" alt="صورة الملف الشخصي" />
        <div className="description-box">
        <p>
  المعلومات اللتي تريدها عن موقع المحنكين:
</p>

<ul>
  <li>
    <strong>الميزة الأولى:</strong> يهدف الموقع إلى تقييم كل شيء في عالم الانمي، بدءًا من الشخصيات والأنمي نفسه وصولًا إلى المقيمين ورسومات ومقاطع الانميشن.
  </li>
  <li>
    <strong>الميزة الثانية:</strong> يمكنك تقييم العمل الواحد بأكثر من طريقة وأكثر من مرة. تزيد قيمة تقييمك كلما زاد عدد المتفقين مع تقييمك.
  </li>
  <li>
    <strong>الميزة الثالثة:</strong> لا يعمل الموقع بنظام المتابعين، مما يعني أن صوتك سيصل إلى العالم بسهولة. كما يقوم الموقع بتصنيف المحنكين وترتيبهم حسب خوارزميات معينة.
  </li>
</ul>
        </div>
      </div>

<div className="carb" style={{ backgroundImage: 'url("https://i.imgur.com/6MYR7Dp.png")', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)' }}>
        <div className="carb-body">
        <h5 className="card-title" style={{ fontSize: '32px', marginBottom: '10px', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)', textalign: 'center', marginLeft: '15px' }}>إعادة تعيين كلمة المرور</h5>
        <Form onSubmit={this.handleSubmit}>
  <Form.Group className="mb-3" controlId="code">
    <Form.Label>كود التأكيد</Form.Label>
    <Form.Control
      type="text"
      placeholder="أدخل رمز التأكيد"
      value={this.state.code}
      onChange={this.handleCodeChange}
      required
    />
  </Form.Group>

  <Form.Group className="mb-3" controlId="email">
    <Form.Label>البريد الإلكتروني</Form.Label>
    <Form.Control
      type="email"
      placeholder="أدخل البريد الإلكتروني"
      value={this.state.email}
      onChange={this.handleEmailChange}
      required
    />
  </Form.Group>

  <Button variant="primary" type="submit" disabled={this.state.submitted}>
    تأكيد
  </Button>
  {this.state.submitted && this.state.success && (
          <Navigate to="/newpassword" /> // استخدام Navigate في حالة نجاح التقديم
        )}

  {this.state.error && <Alert variant="danger">{this.state.error}</Alert>}
  {this.state.success && <Alert variant="success">{this.state.success}</Alert>}
</Form>
        </div>
      </div>
      </div>
    );
  }
}

export default Resetpass;