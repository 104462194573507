import logo from './logo.svg';
import './components/animecard.css';
import './components/animedetails.css';
import './components/kingaward.css';
import './App.css';


//import { anime } from "../../backend/server.js"; // import Anime model from animeModel.js


import axios from 'axios';
import './assets/css/bootstrap.min.css'; // استيراد ملف CSS من Bootstrap
import 'animate.css/animate.min.css';
//import './assets/js/bootstrap.bundle.js';
//import bootstrap from 'bootstrap';



import moment from 'moment';
import React, { useEffect, useState } from 'react';
import api from './api';
import ReactDOM from 'react-dom';
//import { Button } from 'bootstrap';
//import { Offcanvas } from 'bootstrap';
import { Navbar, Offcanvas, Card, Button, CardDeck, CardColumns, CardGroup, CardImg, Dropdown, DropdownButton, DropdownItem, DropdownMenu} from "react-bootstrap";
import { NavLink, Link } from "react-router-dom";
import Cookies from 'js-cookie';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { keyframes } from "styled-components";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { initializePaddle, Paddle } from '@paddle/paddle-js';
//import keyframe from 'keyframe';
//import { Animated, Easing, keyframe } from 'react-native';
//import LottieView from 'lottie-react-native';
import {
  useScene,
  useSceneItem,
  useFrame,
  useNowFrame,
} from "react-scenejs";
import { Animate, AnimateKeyframes, AnimateGroup } from "react-simple-animate";



//import { Offcanvas } from 'react-bootstrap';

import _ from 'lodash';
import Switch from "react-switch";
import { Tabs, Tab } from '@material-ui/core';
import withParams from './withParams.js'; // تأكد من مسار الاستيراد الصحيح
import { Helmet, HelmetProvider } from 'react-helmet-async';

import slugify from 'slugify';

import Select from 'react-select';
import InfiniteScroll from 'react-infinite-scroll-component';





class Mohankeenawards extends React.Component {

  constructor(props) {
    super(props);
    // نضع قيمة الحالة الأولية في this.state
    this.state = {
      showBottom: false,
      showModal: false,
      message: '',
      charData: [], // an empty array to store the char data from the database
      isMobile: window.innerWidth <= 991, // تعيين القيمة الافتراضية للموبايل
      isActive: true,
      selectedGenre: 'عرض كل الانميات بكل التصنيفات', // التصنيف المختار
      
      charId: "",
      commentText: '',
      commentType: '',
      
      
       //paypal
      //message: '',
      initialOptions: {
        'client-id': 'AWUoCwyW6i6htrGKgZhZm1QhjgPIb_UJxFu9GMg8Y1hAJ5AkS80VoUGHM9QO5lCwM0G1ddz8jPYHsZNt',
        'enable-funding': '',
        'disable-funding': 'paylater,venmo,card',
        'data-sdk-integration-source': 'integrationbuilder_sc',
      },
    
      
        //paddle
        
        name: '',
        productName: '',
        product: '',
       
        paddle: '',

      
        commentText: '',
        commentType: 'praiser',
        showExpandes: false,
        showExpandex: false,
        showExpandexrank:false,
        selectedchar: "",
        userId: "", // استبدل 'USER_ID' بمعرف المستخدم الفعلي
        rating: "",
    note: '',
    randomNote: '',
    randomRating: '',
    randomUsername: '',
    randomUserProfileImage: '',

    isHovered: false,
    selectedchardatail: null,

    ad: null,
    isLoading: true,

    searchQuery: '',
   

    searchResultssugg: [],



    awards: [],
      currentPage: 1,
      totalPages: 1,
      totalAwards: 0,
      loading: true,
      hasMore: true,
      
    };

   
    
  }

 
  

  // use componentDidMount to fetch data from node server when the component mounts
  componentDidMount() {
    // use api.get to send a get request to /api endpoint
    api.get('/api')
      .then((res) => {
        // if the request is successful, set the message state to the data from the response
        this.setState({ message: res.data.message });
      })
      .catch((err) => {
        // if there is an error, log it and set the message state to an error message
        console.error(err);
        this.setState({ message: 'Something went wrong' });
      });



     
     //this.incrementViewCount();
     this.fetchAd();
     //paddle
     this.fetchAwards();

      const userId = Cookies.get('userId') || localStorage.getItem('userId');
      
      if (userId) {
        const cleanedUserId = userId.replace(/^j:/, '');
    this.setState({ userId: cleanedUserId });
      }
     
       
      }

      componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowResize);
      }
    
    




fetchAd = async () => {
  try {
    const response = await axios.get('/get-ad');
    if (response.data.ad) {
      this.setState({ ad: response.data.ad, isLoading: false });
    } else {
      this.setState({ isLoading: false });
    }
  } catch (error) {
    console.error(error.response ? error.response.data.message : error.message);
    this.setState({ isLoading: false });
  }
};

incrementAdClick = async (adId) => {
  try {
    const response = await axios.post('/increment-click', { adId });
    console.log(response.data.message);
  } catch (error) {
    console.error(error.response ? error.response.data.message : error.message);
  }
};


handleAdClick = (ad) => {
  this.incrementAdClick(ad._id);
  window.open(ad.link, '_blank');
};




fetchAwards = async (page = 1, limit = 25) => {
    try {
      const response = await axios.get('/kingawardsdata', {
        params: { page, limit }
      });
      const { awards, currentPage, totalPages, totalAwards } = response.data;
      this.setState({
        awards: page === 1 ? awards : [...this.state.awards, ...awards],
        currentPage,
        totalPages,
        totalAwards,
        hasMore: currentPage < totalPages,
        loading: false
      });
    } catch (error) {
      this.setState({ error: 'حدث خطأ أثناء جلب الجوائز.', loading: false });
    }
  };

  formatNotificationDate(date) {
    const now = moment();
    const notificationDate = moment(date);
    const diff = now.diff(notificationDate, 'minutes');

    if (diff < 60) {
      return `منذ ${diff} دقيقة`;
    } else if (diff < 1440) {
      return `منذ ${Math.floor(diff / 60)} ساعة`;
    } else {
      return `منذ ${Math.floor(diff / 1440)} يوم`;
    }
  }


  handleWindowResize = () => {
    const isMobile = window.innerWidth <= 991;
    this.setState({ isMobile });
  };


  render() {

    const { showModal, charData, data, error, showCards, isMobile, isActive, isCharActive, dataType, activeTab, selectedGenre,   initialOptions, productId, customerEmail, name, productName, product, commentText, commentType,  selectedchar, showExpandes, showExpandex, rating, note, randomNote, randomRating, randomUsername, randomUserProfileImage, isHovered, ad, isLoading, searchQuery, searchResultssugg, showExpandexrank, awards, currentPage, totalPages, totalAwards, loading, hasMore } = this.state;
    const userId = this.state.userId; // جلب معرف المستخدم من الحالة
   
  
    const scrolxStyle = {
      width: isMobile ? window.innerWidth - 20 : 'auto', //-120
     

     
      
    };


    

    
    return (

      
        <HelmetProvider>
      
      <Helmet>
    <meta charSet="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <title>جوائز السنة والأرقام القياسية - المحنكين</title>
    <meta name="description" content="يقوم مطورون موقع المحنكين اصحاب المراتب العليا بالخضرمه والحنكه بمنح جوائز السنه للانميات او يقومون بتسجيل الارقام القياسيه لمختلف الشخصيات والانميات لذلك فلنستمتع." />
    <meta property="og:title" content="جوائز السنة والأرقام القياسية - المحنكين" />
    <meta property="og:description" content="يقوم مطورون موقع المحنكين اصحاب المراتب العليا بالخضرمه والحنكه بمنح جوائز السنه للانميات او يقومون بتسجيل الارقام القياسيه لمختلف الشخصيات والانميات لذلك فلنستمتع." />
    <meta property="og:image" content="https://i.imgur.com/RpyLpGN.png" />
    <meta property="og:type" content="website" />
    <meta property="og:url" content="https://mohankeen.com/mohankeenawards" />
    <meta name="keywords" content="ارقام قياسيه, جوائز السنه, المحنكين, المخضرمين, افضل الانميات , افضل الشخصيات, تحطيم الارقام القياسيه, انمي, مراجعات,اعلان جوائز السنه, جوائز السنه للانميات" />
    <meta name="author" content="المحنكين" />
    <meta property="og:site_name" content="MOHANKEEN" />
    <meta property="og:locale" content="ar_AR" />
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:title" content="جوائز السنة والأرقام القياسية - المحنكين" />
    <meta name="twitter:description" content="يقوم مطورون موقع المحنكين اصحاب المراتب العليا بالخضرمه والحنكه بمنح جوائز السنه للانميات او يقومون بتسجيل الارقام القياسيه لمختلف الشخصيات والانميات لذلك فلنستمتع." />
    <meta name="twitter:image" content="https://i.imgur.com/RpyLpGN.png" />
    <link rel="canonical" href="https://mohankeen.com/mohankeenawards" />
    <meta name="robots" content="index, follow" />
    <meta property="og:updated_time" content={new Date().toISOString()} />
    <meta property="og:author" content="المحنكين" />
    <meta property="article:section" content="الأرقام القياسيه للانميات وجوائز السنه" />
    <meta property="article:tag" content="ارقام قياسيه, جوائز السنه, المحنكين, المخضرمين, افضل الانميات , افضل الشخصيات, تحطيم الارقام القياسيه, انمي, مراجعات,اعلان جوائز السنه, جوائز السنه للانميات" />
  </Helmet>
      
      <div className="pagebodyx"> 
      
      <div className="logostyle">
    <img src="https://i.imgur.com/RpyLpGN.png" alt="شعار الموقع" style={{width: '319.98px', height: '180px', objectfit: 'contain', float: 'left',}}/>
</div>


<Link to="/" className="home-buttonx" >
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-house-door" viewBox="0 0 16 16">
        <path d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293zM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4z"/>
      </svg>
    </Link>
      <div>

      <div className="containerwarriorx">
        {ad ? (
          <>
            <button
              className="fightratex"
              onClick={() => this.handleAdClick(ad)}
            >
              <p>{ad.productType}</p>
            </button>
            <img src={ad.imageUrl} alt="Ad Image" className="imagewar" />
          </>
        ) : (
          <>
            <button className="fightrate">
              <p>عالم التقييم ممتع اكثر مما تظن</p>
            </button>
            <img src="https://i.imgur.com/mZXZ9gb.jpg" alt="Descriptive Alt Text" className="imagewar" />
          </>
        )}
      </div>







      <div className="awards-containerxx">
        <h1>جوائز السنة والأرقام القياسية</h1>
        <InfiniteScroll
          dataLength={awards.length}
          next={() => this.fetchAwards(this.state.currentPage + 1)}
          hasMore={hasMore}
          loader={
            <div className="listloadingx">
             
              <div className="loader"></div>
            </div>
              
          }
          endMessage={<p>تم عرض جميع الجوائز</p>}
          style={scrolxStyle} 
        >
          <div className="awards-list">
            {awards.map(award => (
              <div className="award-cardx" key={award._id} style={{ backgroundImage: `url(${award.awardbackground})` }}>
                <div className="award-card-content">
                  <div className="award-card-header">
                    <img src={award.winnerImage} alt={award.winnerName} className="winner-image" />
                    <div>
                      <h2>{award.awardTitle}</h2>
                      <p>{award.winnerName}</p>
                    </div>
                  </div>
                  <div className="award-card-body">
                    <div className="award-info">
                     
                      <div className="award-info-row">
                        <div className="award-info-col">
                          <p className="datax">{award.awardReason}</p>
                        </div>
                        <div className="award-info-row">
                       
                       <div className="award-info-col">
                         <p className="data">{moment(award.awardDate).format('YYYY')}</p>
                         <p className="data">{this.formatNotificationDate(award.awardDate)}</p>
                         <p className="data">{award.awardSeason ? `الموسم: ${award.awardSeason}` : ''}</p>
                       </div>
                     </div>
                        <div className="award-info-col">
                          <img src={award.awardTypeImage} alt={award.awardType} className="award-type-image" />
                          <p className="data">نوع الجائزة:{award.awardType}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </InfiniteScroll>
      </div>
  

        
         

     






          
          
         
        
       
        <style dangerouslySetInnerHTML={{__html: "\n      /* تحديد خلفية الموقع */\n    body {\n        \n      background: linear-gradient(to right, #ffffff , #fcfcfc 0%);\n  }\n\n    " }} />
      

        
        </div>
        </div> 
         </HelmetProvider>
    );
  }
}






export default Mohankeenawards;

