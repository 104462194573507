import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import './components/tweet.css';
import styled, { keyframes } from 'styled-components';

class Callbacktwt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: '',
      error: '',
      showLoginButton: false,
      loading: false // حالة التحميل
    };
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const tweeterId = urlParams.get('tweeterId');

    if (tweeterId) {
      this.voteForTweeter(tweeterId);
    } else {
      this.setState({ error: 'معرف المغرد غير صالح' });
    }
  }

  async voteForTweeter(tweeterId) {
    this.setState({ loading: true }); // بدء التحميل
    try {
      const response = await axios.post('/votefromlink', null, {
        params: { tweeterId },
        withCredentials: true // لضمان إرسال الكوكيز مع الطلب
      });

      this.setState({ message: response.data.message, loading: false }); // انتهاء التحميل بنجاح
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'حدث خطأ أثناء التصويت للمغرد';
      this.setState({ error: errorMessage, loading: false }); // انتهاء التحميل بفشل

      if (errorMessage === 'يجب عليك تسجيل الدخول أولاً') {
        this.setState({ showLoginButton: true });
      }
    }
  }

  render() {
    const { message, error, showLoginButton, loading } = this.state;

    const PageWrapper = styled.div`
      background-image: url('https://i.imgur.com/vvZOPVf.jpg');
      background-size: cover;
      background-position: center -420px;
      background-repeat: no-repeat;
      margin: 0;
      padding: 0;
      font-family: Arial, sans-serif;
      min-height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    `;

    return (
      <PageWrapper>
        <div className="vote-from-link-page">
          {loading ? (
            <div class="loaderxa">
            <div data-glitch="Loading..." class="glitch">Loading...</div>
         </div>
          ) : (
            <div className="vote" style={{ textAlign: 'center', marginBottom: '20%' }}>
              {message && <h1 className="messagevote">{message}</h1>}
              {error && <h1 className="errorvote">{error}</h1>}
              {showLoginButton && (
                <Link to="/login">
                  <button
                    className="loginb"
                    style={{
                      position: 'fixed',
                      top: '20px',
                      right: '20px',
                      zIndex: '9999'
                    }}
                  >
                    تسجيل الدخول/التسجيل
                  </button>
                </Link>
              )}
            </div>
          )}
        </div>
      </PageWrapper>
    );
  }
}

export default Callbacktwt;