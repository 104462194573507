// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 990px) {

 
    
      .mobilex {
        width: 1920px; 
        
     
      }

      .listheaderfive{

        margin-top: 100px;
      }

      .previous-year-awards {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgb(73, 73, 73);
        padding: 20px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
        z-index: 1000;
        width: auto;
        height: auto;
        overflow-y: auto;
    }


    .actions {
        width: 190px;
        height: 254px;
        background: black;
        display: flex;
        flex-direction: column;
        align-items: center;
        top: 0;
        right: 0;
        margin-top: 150px;
        margin-right: 40px;
        border-radius: 20%;
        position: absolute;
        transition: 0.2s;
    }
      
}`, "",{"version":3,"sources":["webpack://./src/components/mobilefivelist.css"],"names":[],"mappings":"AAAA;;;;MAIM;QACE,aAAa;;;MAGf;;MAEA;;QAEE,iBAAiB;MACnB;;MAEA;QACE,eAAe;QACf,QAAQ;QACR,SAAS;QACT,gCAAgC;QAChC,iCAAiC;QACjC,aAAa;QACb,uCAAuC;QACvC,aAAa;QACb,WAAW;QACX,YAAY;QACZ,gBAAgB;IACpB;;;IAGA;QACI,YAAY;QACZ,aAAa;QACb,iBAAiB;QACjB,aAAa;QACb,sBAAsB;QACtB,mBAAmB;QACnB,MAAM;QACN,QAAQ;QACR,iBAAiB;QACjB,kBAAkB;QAClB,kBAAkB;QAClB,kBAAkB;QAClB,gBAAgB;IACpB;;AAEJ","sourcesContent":["@media (max-width: 990px) {\n\n \n    \n      .mobilex {\n        width: 1920px; \n        \n     \n      }\n\n      .listheaderfive{\n\n        margin-top: 100px;\n      }\n\n      .previous-year-awards {\n        position: fixed;\n        top: 50%;\n        left: 50%;\n        transform: translate(-50%, -50%);\n        background-color: rgb(73, 73, 73);\n        padding: 20px;\n        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);\n        z-index: 1000;\n        width: auto;\n        height: auto;\n        overflow-y: auto;\n    }\n\n\n    .actions {\n        width: 190px;\n        height: 254px;\n        background: black;\n        display: flex;\n        flex-direction: column;\n        align-items: center;\n        top: 0;\n        right: 0;\n        margin-top: 150px;\n        margin-right: 40px;\n        border-radius: 20%;\n        position: absolute;\n        transition: 0.2s;\n    }\n      \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
