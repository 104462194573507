// استيراد React من مكتبة React
import React from "react";

// تعريف مكون ErrorPage كدالة
function ErrorPage(props) {
  // إرجاع عنصر div يحتوي على الرسالة والنمط المرسلين كخصائص
  return (
    <div>
      <p style={props.style}>{props.message}</p>
      <h1>THIS PAGE ISN`T FOUND</h1>
    </div>
  );
}

// تصدير مكون ErrorPage ليتم استيراده في مكونات أخرى
export default ErrorPage;
