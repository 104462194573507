import logo from './logo14400.png';
//import './components/animecard.css';
import './components/logincard.css';


//import './App.css';

//import Signup from './signup.js';
//import Button from './button.js';

//import { anime } from "../../backend/server.js"; // import Anime model from animeModel.js


import axios from 'axios';
import './assets/css/bootstrap.min.css'; // استيراد ملف CSS من Bootstrap
import 'animate.css/animate.min.css';
//import './assets/js/bootstrap.bundle.js';
//import bootstrap from 'bootstrap';




import React, { useEffect, useState, Component } from 'react';
import api from './api';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router, Route, Link, Routes, Navigate, useParams} from 'react-router-dom';

//import { BrowserRouter, Route, Switch } from "react-router-dom";
//import { Button } from 'bootstrap';
//import { Offcanvas } from 'bootstrap';
import { Navbar, Offcanvas, Card, Button, CardDeck, CardGroup, CardImg, Dropdown, Form, Alert} from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { keyframes } from "styled-components";
//import keyframe from 'keyframe';
//import { Animated, Easing, keyframe } from 'react-native';
//import LottieView from 'lottie-react-native';
import {
  useScene,
  useSceneItem,
  useFrame,
  useNowFrame,
} from "react-scenejs";
import { Animate, AnimateKeyframes, AnimateGroup } from "react-simple-animate";



//import { Offcanvas } from 'react-bootstrap';

import _ from 'lodash';
//import Switch from "react-switch";
import { Tabs, Tab, Box, TabPanel, styled } from '@mui/material';


//import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
//import 'react-tabs/style/react-tabs.css';


class Setpass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogin: true,
      
      password: "",
      error: "",
      usernameOrEmail: "",
      isCodeSent: false,
      code: "", // The verification code
      success: "",
      submitted: false, // إضافة حالة جديدة لتتبع حالة التقديم
      showPassword: false,
      state: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSetPassword = this.handleSetPassword.bind(this);
  }

  






   // Define a method to handle the code input change
handleCodeChange = (e) => {
  this.setState({ code: e.target.value }); // Update the code in the state with the input value
};

// Define a method to handle the email input change
handleEmailChange = (e) => {
  this.setState({ email: e.target.value }); // Update the email in the state with the input value
};

// Define a method to handle the form submission
handleSubmit = async (e) => {
  e.preventDefault(); // Prevent the default behavior of the form
  this.setState({ error: '', success: '', submitted: true }); // Reset the error and success messages and set the submitted to true

  // Get the email and code from the state
  const email = this.state.email;
  const code = this.state.code;

  // Validate the code input
  if (code.trim() === '') {
    this.setState({ error: 'يرجى إدخال رمز التأكيد' }); // Set the error message if the code is empty
    return;
  }

  try {
    // Make a POST request to /api/verify-code with the email and code
    const response = await axios.post('/api/verify-code', { email, code });
    // Handle the response here
    console.log(response.data); // Log the response data to the console

    if (response.data.message === 'Your verification code is valid') {
      // Show a success message here
      this.setState({ success: 'تم التحقق من رمز التأكيد بنجاح' });
      // Store the token in the local storage
      
      // Redirect to the reset password page using history.push method from react-router-dom package
      // قم بالانتقال إلى صفحة resetpassword
      if (this.state.success) {
        this.props.navigate('/newpassword');
       }
    } else {
      // Show a failure message here
      this.setState({ error: 'فشل في التحقق من رمز التأكيد' });
    }
  } catch (error) {
    console.error(error); // Log the error to the console
    // Handle the errors here
    this.setState({ error: error.response.data.message }); // Set the error message from the response data
  }
};




handleChange(e) {
  // Handle the change of the input value
  this.setState({ [e.target.name]: e.target.value });
}

componentDidMount() {
  const params = new URLSearchParams(window.location.search);
  const username = params.get('username');
  const email = params.get('email');
  const state = params.get('state');

  if (username && email) {
    this.setState({ username, email });
  }
}

handleSetPassword = async (e) => {
  e.preventDefault();
  this.setState({ error: "", success: "", submitted: true });

  const { password, email, username, state, token } = this.state;
  
  
 // const { email, username } = this.props;

  // التحقق من حقل كلمة السر
  if (password.trim() === "") {
    this.setState({ error: "يرجى إدخال كلمة سر" });
    return;
  }

  if (password.length < 6) {
    this.setState({ error: "كلمة السر يجب أن تكون على الأقل 6 حروف" });
    return;
  }

  try {
    // Get the token from localstorage
    
    // Send the password, email and username in the request with secondtoken in header
    const response = await axios.post("/api/setpassword", { password, email, username, state, token  }, {
      
    });
    // قم بمعالجة استجابة الطلب هنا
    console.log(response.data); // سجل بيانات الاستجابة في وحدة التحكم (console)

    // Check the response status before handling the data
    if (response.status === 200) {
      if (response.data.success) {
        // قم بإظهار رسالة نجاح هنا
        this.setState({ success: response.data.message });
        localStorage.setItem('token', response.data.token);
        
        // قم بالانتقال إلى صفحة الرئيسية أو أي صفحة ترغب بها
        if (this.state.success) {
          this.props.navigate("/");
        }
      } else {
        // قم بإظهار رسالة فشل هنا
        this.setState({ error: response.data.message });
      }
    } else {
      // Handle unexpected response status
      this.setState({ error: "حدث خطأ غير متوقع. يرجى المحاولة مرة أخرى." });
    }
  } catch (error) {
    console.error(error);
    // قم بمعالجة الأخطاء هنا
    // Check if the error has a response before accessing its data or message
    if (error.response) {
      this.setState({ error: error.response.data.message });
    } else {
      // Handle errors without a response
      this.setState({ error: "حدث خطأ في الاتصال. يرجى التحقق من اتصالك بالإنترنت." });
    }
  }
};




  render() {
    const { isLogin, password, error, usernameOrEmail, isCodeSent, code, success, submitted } = this.state;
    
    return (
      <div>
<title>المحنكين</title>{/* العنوان */}
<style dangerouslySetInnerHTML={{__html: "\n            #box {\n                background-color: black;\n                 border: 2.5px solid rgba(192, 192, 192, 192);\n                box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.8), 0 0 10px 5px rgba(255, 255, 255, 0.8);\n                pointer-events: none;\n        display: flex;\n                justify-content: center;\n                align-items: center;\n             align-items: center;\n                     border-radius: 70px;\n                padding: 5px;\n                objectfit: 'cover';\n                width: 49%;\n                height: 235px;\n                margin: 50px;\n         margin-top: 560px;\n           margin-left: 20px;\n           }\n    \n            h1 {\n                font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;\n                font-size: 36px;\n                font-weight: bold;\n                color: #000;\n                margin-top: 0px;\n                text-shadow: 2px 2px 4px #000;\n                letter-spacing: 2px;\n                text-align: center;\n            }\n        " }} />
<div id="box">
    <img src="https://i.imgur.com/Iv8Rh1o.png" alt="شعار الموقع" style={{width: '888.83px', height: '500px', objectfit: 'contain'}}/>
</div>
<div className="image-container">
        <img className="profile-image" src="https://i.imgur.com/0NHdvjQ.png" alt="صورة الملف الشخصي" />
        <div className="description-box">
        <p>
  المعلومات اللتي تريدها عن موقع المحنكين:
</p>

<ul>
  <li>
    <strong>الميزة الأولى:</strong> يهدف الموقع إلى تقييم كل شيء في عالم الانمي، بدءًا من الشخصيات والأنمي نفسه وصولًا إلى المقيمين ورسومات ومقاطع الانميشن.
  </li>
  <li>
    <strong>الميزة الثانية:</strong> يمكنك تقييم العمل الواحد بأكثر من طريقة وأكثر من مرة. تزيد قيمة تقييمك كلما زاد عدد المتفقين مع تقييمك.
  </li>
  <li>
    <strong>الميزة الثالثة:</strong> لا يعمل الموقع بنظام المتابعين، مما يعني أن صوتك سيصل إلى العالم بسهولة. كما يقوم الموقع بتصنيف المحنكين وترتيبهم حسب خوارزميات معينة.
  </li>
</ul>
        </div>
      </div>

<div className="carb" style={{ backgroundImage: 'url("https://i.imgur.com/6MYR7Dp.png")', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)' }}>
        <div className="carb-body">
        <h5 className="card-title" style={{ fontSize: '32px', marginBottom: '10px', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)', textalign: 'center', marginLeft: '15px' }}>إعادة تعيين كلمة المرور</h5>
        <Form onSubmit={this.handleSetPassword}>
        <Form.Group className="mb-3" controlId="username">
    
    <Form.Control
      type="text"
      name="username"
      
      value={this.state.username}
      hidden
    />
  </Form.Group>
  <Form.Group className="mb-3" controlId="email">
    
    <Form.Control
      type="email"
      name="email"
      
      value={this.state.email}
      hidden
    />
  </Form.Group>
  <Form.Group className="mb-3" controlId="password">
    <Form.Label>كلمه السر الجديده</Form.Label>
    <Form.Control
           
            
              type={this.state.showPassword ? "text" : "password"}
              name="password"
              
              value={this.state.password}
              onChange={this.handleChange}
              required
            />
             <Button
  type="button"
  onClick={() =>
    this.setState({ showPassword: !this.state.showPassword })
  }
>
  {this.state.showPassword ? (
   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-fill" viewBox="0 0 16 16">
   <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"/>
   <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"/>
 </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-slash" viewBox="0 0 16 16">
  <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z"/>
  <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z"/>
  <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z"/>
</svg>
  )}
</Button>
</Form.Group>
          <Button variant="primary" type="submit" >
            تأكيد
          </Button>
          {this.state.submitted && this.state.success && (
          <Navigate to="/" /> // استخدام Navigate في حالة نجاح التقديم
        )}
  

  {this.state.error && <Alert variant="danger">{this.state.error}</Alert>}
  {this.state.success && <Alert variant="success">{this.state.success}</Alert>}
</Form>
        </div>
      </div>
      </div>
    );
  }
}

export default Setpass;