import logo from './logo14400.png';
import ErrorPage from "./ErrorPage.js";
import CharPage from './char.js';
import WeekEp from './weekep.js';
import AnimePage from "./anime.js";
import CharPageweek from "./charweek.js"
import Login from './login.js';


//import Signup from './signup.js';
//import Button from './button.js';
import './components/profile.css';

//import { anime } from "../../backend/server.js"; // import Anime model from animeModel.js


import axios from 'axios';
import './assets/css/bootstrap.min.css'; // استيراد ملف CSS من Bootstrap
import 'animate.css/animate.min.css';
//import './assets/js/bootstrap.bundle.js';
//import bootstrap from 'bootstrap';




import React, { useEffect, useState } from 'react';
import api from './api';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router, Route, Link, Routes, Navigate,} from 'react-router-dom';
import Cookies from 'js-cookie';
import moment from "moment";
//import { BrowserRouter, Route, Switch } from "react-router-dom";
//import { Button } from 'bootstrap';
//import { Offcanvas } from 'bootstrap';
import { Navbar, Offcanvas, Card, Button, CardDeck, CardGroup, CardImg, Dropdown} from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { keyframes } from "styled-components";
//import keyframe from 'keyframe';
//import { Animated, Easing, keyframe } from 'react-native';
//import LottieView from 'lottie-react-native';
import {
  useScene,
  useSceneItem,
  useFrame,
  useNowFrame,
} from "react-scenejs";
import { Animate, AnimateKeyframes, AnimateGroup } from "react-simple-animate";



//import { Offcanvas } from 'react-bootstrap';

import _ from 'lodash';
//import Switch from "react-switch";
import { Tabs, Tab, Box, TabPanel, styled } from '@mui/material';


//import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
//import 'react-tabs/style/react-tabs.css';








class Memories extends React.Component {

  constructor(props) {
    super(props);
    // نضع قيمة الحالة الأولية في this.state
    this.state = {
      showBottom: false,
      showModal: false,
      message: '',
      animeData: [], // an empty array to store the anime data from the database
      userdata: "",
      isMobile: window.innerWidth <= 991, // تعيين القيمة الافتراضية للموبايل
      isActive: true,
      value: 0,
      redirectToNewPage: false,
      showExpanded: false,
      
      
      isLoggedIn: false,
     
      menuOpen: false,
      token: "", // قيمة الرمز
      //username: "", // اسم المستخدم
      profileImage: "", // صورة الملف الشخصي 
      //Cookies: "",
      redirect: false,

      // تعيين الحالة query إلى فارغة
      query: '',
      // تعيين الحالة results إلى مصفوفة فارغة
      results: [],
      
      profileId: null, // الحالة التي تحتوي على معرف الملف الشخصي الذي يتم عرضه في الصفحة
      
      isOwnProfile: false,
      
      ratedCharacters: [], // مصفوفة فارغة لتخزين الشخصيات المقيمة
      wallOfMemories: [], // مصفوفة من التعليقات
    comment: "", // التعليق الذي يدخله المستخدم
    image: "", // الصورة التي يختارها المستخدم
    imageSelected: false, // حالة تتتبع اختيار الملف
    
      
    };

    this.handleChangee = this.handleChangee.bind(this);
    this.handleChangeee = this.handleChangeee.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }


   

  handleChangevalue(event, newValue) {
    this.setState({ value: newValue });
  }



  // تعريف دالة handleChange للمكون
  handleChange = (event) => {
    // تحديث قيمة الحالة query بقيمة الحدث
    this.setState({ query: event.target.value });
    // استدعاء دالة searchCharacters مع قيمة الحدث
    this.searchCharacters(event.target.value);
  };

 




  

  

  // تعريف الدالة في مكون الصفحة الشخصية
getUserData = () => {
  // الحصول على اسم المستخدم من الرابط
  const searchParams = new URLSearchParams(window.location.search);
  const username = searchParams.get("username");


  const currentUser = Cookies.get("username") || localStorage.getItem("username"); // الحصول على اسم المستخدم الحالي من الكوكيز أو الذاكرة المحلية
const isOwnProfile = username === currentUser; // مقارنة الاسمين
this.setState({ isOwnProfile: isOwnProfile }); // تحديث الحالة بنتيجة المقارنة

  // إرسال طلب get إلى الواجهة البرمجية مع اسم المستخدم
  axios
    .get(`/profile/${username}`)
    .then((response) => {
      // تغيير الحالة ببيانات المستخدم
      this.setState({ userdata: response.data });
    })
    .catch((error) => {
      // التعامل مع الخطأ
      console.error(error);
      // هنا يمكنك تحديد الحالة التي تريد التعامل معها، مثل 404 أو غيرها
      if (error.response.status === 404) {
        // هنا يمكنك تحديد الإجراء الذي تريد اتخاذه، مثل إعادة التوجيه أو عرض رسالة
        this.setState({ redirect: true,  });
        return (
          <ErrorPage
            message="الرابط ربما يكون خاطئًا أو المستخدم غير موجود"
            // يجب أن تحدد النمط الذي تريد تطبيقه على الرسالة ككائن يحتوي على خصائص CSS
            // مثلاً، إذا كنت تريد جعل الخط واضح وكبير وسميك وفي منتصف الصفحة ومرتب ومنظم، فتكتب:
            style={{
              color: "red",
              fontSize: "30px",
              fontWeight: "bold",
              textAlign: "center",
              margin: "auto",
            }}
          />
        );
      }
    });
};


 
  

  // use componentDidMount to fetch data from node server when the component mounts
  componentDidMount() {
    // use api.get to send a get request to /api endpoint
    api.get('/api')
      .then((res) => {
        // if the request is successful, set the message state to the data from the response
        this.setState({ message: res.data.message });
      })
      .catch((err) => {
        // if there is an error, log it and set the message state to an error message
        console.error(err);
        this.setState({ message: 'Something went wrong' });
      });



      window.addEventListener('resize', this.handleWindowResize);

     //this.fetchData();
    
     //this.handleLogin();

    //this.getRatedCharacters();

     const userId = Cookies.get('userId') || localStorage.getItem('userId');
      // التحقق من وجود token في الذاكرة المحلية
      const token = Cookies.get('token') || localStorage.getItem("token") 
    if (token) {
      this.setState({ isLoggedIn: true });
      // ارسل token إلى الواجهة البرمجية للحصول على بيانات المستخدم
     
    };




    this.getUserData();
   
  
    
    
      
       
      }


 // انشاء دالة مساعدة باسم handleChange واجعلها تتعامل مع حدث تغيير العناصر input في الواجهة
 handleChangee = event => {
    // الحصول على الاسم والقيمة من العنصر input
    const { name, value } = event.target;
    // تحديث الحالة بالقيمة الجديدة
    this.setState({
      [name]: value
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    // هنا يمكنك تنفيذ الطلب الذي تريده لإرسال البيانات إلى الخادم
    const userId = Cookies.get("userId") || localStorage.getItem("userId");
    // الحصول على الرقم التعريفي والتعليق والصورة من الحالة
    const { comment, image } = this.state;
    

    // إنشاء كائن FormData وإضافة الرقم التعريفي والتعليق والصورة إليه
    const data = new FormData();
    data.append("userId", userId);
    data.append("comment", comment);
    data.append("image", image);
    // إرسال الكائن كجسم لطلب ajax إلى المسار '/postComment' باستخدام طريقة HTTP POST
    axios
      .post("/postComment", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => { 
        // التعامل مع الاستجابة من المسار
        if (res.data.message === "Comment posted successfully") {
          // إذا كانت الاستجابة ناجحة، تحديث الحالتين this.state.wallOfMemories و this.state.userdata بالتعليق الجديد
          this.setState((prevState,) => ({
            //wallOfMemories: prevState.wallOfMemories.concat(res.data.comment),
            userdata: {
              ...prevState.userdata,
              wallOfMemories: prevState.userdata.wallOfMemories.concat(res.data.comment),
            },
          }));
          alert(res.data.message);
        } else {
          // إذا كانت الاستجابة فاشلة، إظهار رسالة الخطأ
            alert(`Error: ${res.data.message}`);
        }
      })
      .catch((err) => {
        // التعامل مع الخطأ من المسار
        // إظهار رسالة الخطأ المفصلة
        const errorMessage = err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : err.message;
        alert(` ${errorMessage}`);
      });
  };
  

  // انشاء دالة مساعدة باسم handleDelete واجعلها تتعامل مع حدث النقر على زر حذف التعليق في الواجهة
  handleDelete = commentId => {
    // الحصول على الرقم التعريفي للمستخدم من الحالة
    const userId = Cookies.get("userId") || localStorage.getItem("userId");
    // إرسال طلب ajax إلى المسار '/deleteComment' باستخدام طريقة HTTP DELETE وإرسال الرقم التعريفي للمستخدم والرقم التعريفي للتعليق كمعلمات استعلام
    fetch(`deleteComment?commentId=${commentId}`, {
      method: "DELETE"
    })
      .then(res => res.json())
      .then(data => {
        // التعامل مع الاستجابة من المسار
        if (data.message === "Comment deleted successfully") {
          // إذا كانت الاستجابة ناجحة، تحديث الحالة بحذف التعليق وإظهار رسالة نجاح
          this.setState({
            userdata: {
            wallOfMemories: this.state.userdata.wallOfMemories.filter(
              comment => comment._id !== commentId
            )
            }
          });
          console.log(data.message);
        } else {
          // إذا كانت الاستجابة فاشلة، إظهار رسالة الخطأ
          alert(data.message);
        }
      })
      .catch(err => {
        // التعامل مع الخطأ من المسار
        // إظهار رسالة الخطأ
        alert(err.message);
      });
  };


  handleChangeee = () => {
    // الحصول على العنصر input المخفي باستخدام الخاصية ref
    const fileInput = this.fileInput;
    // استخدام الدالة click على العنصر input المخفي
    fileInput.click();
    // الحصول على القيمة من العنصر input المخفي
    const image = fileInput.files[0]; // استخدام الخاصية files
    // تحديث الحالة بالصورة الجديدة
    this.setState({ image }); // استخدام تخصيص الخصائص
  };
  


  handleFileChange(event) {
    const file = event.target.files[0];
    if (file) {
      this.setState({ image: file, imageSelected: true }); // تحديث الحالة بالملف والقيمة الحقيقية
    }
  }
  

      render() {

    


        const { showModal, animeData, data, error, showCards, isMobile, isActive, isCharActive, dataType, activeTab, showLogin,  message, isLoggedIn, menuOpen, profileImage, token, redirect, params, userdata, showExpanded, query, results, isOwnProfile, ratedCharacters, wallOfMemories, comment, image, imageSelected,  } = this.state;
        const { value } = this.state;
        

        return (

      

      

            
      
            <div>
  {this.state.userdata && (
      
   <div className="expanded-overlayy">
    
    
    <div className="comment-section">
    <div className="containerInput">
      <input
        type="text"
        name="comment"
        value={this.state.comment}
        onChange={this.handleChangee}
        maxLength="50"
        placeholder="اكتب ذكرى كمثال عن انهاءك لمشاهده انمي ما او اي ذكرى تريدها"
        required
      />
    
      </div>
     
      <button className="memorybotnimage" onClick={this.handleChangeee} >
          ارفق صورة مع الذكرى
          {this.state.imageSelected && <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="green" class="bi bi-check-circle" viewBox="0 0 16 16" Background="green">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
  <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05"/>
</svg>}
        </button>

        <input
  type="file"
  name="image"
  ref={fileInput => (this.fileInput = fileInput)}
  className="file-input"
  hidden
  required="true" // تحديد قيمة معينة للخاصية required
  onChange={event => this.handleFileChange(event)} // استدعاء الدالة handleFileChange عند اختيار ملف
/>


        
      <button className="memorybotnimagex" onClick={this.handleSubmit} >نشر</button>
    </div>
    
    <div className="comment-container">
   
    {this.state.userdata.wallOfMemories.reverse().map((comment, index) => (
      
        <div className="neon-border" key={comment._id}>
            
          <div className="book">
          <div className="text">
          <p>{comment.commenttext}</p>
          </div>
          <div className="commentiamge">
          {comment.content_type === "both" && (
            <img src={comment.commentimage} alt="صورة التعليق" />
          )}
          </div>
          <div className="cover">
          <div className="date">
          <p>  ذكرى من تاريخ    {moment(comment.date).format("YYYY-MM-DD")}  </p>
          </div>
          </div>
          </div>
          <div className="dropdown-content">
  <button className="dropbtn">
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
  <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0"/>
</svg>
  </button>
  <ul className="dropdown-menu">
    <li>
      <button onClick={() => this.handleDelete(comment._id)}>
        حذف
      </button>
    </li>
  </ul>
</div>
{(index + 1) % 4 === 0 && <div className="clear"></div>}
        </div>
          
      ))}
    </div>
    

    </div>
  )}
</div>
                


);
}
}


                export default Memories;