import logo from './logo14400.png';
import ErrorPage from "./ErrorPage.js";
import CharPage from './char.js';
import WeekEp from './weekep.js';
import AnimePage from "./anime.js";
import CharPageweek from "./charweek.js"

//import Signup from './signup.js';
//import Button from './button.js';
import './components/temp.css';

//import { anime } from "../../backend/server.js"; // import Anime model from animeModel.js


import axios from 'axios';
import './assets/css/bootstrap.min.css'; // استيراد ملف CSS من Bootstrap
import 'animate.css/animate.min.css';
//import './assets/js/bootstrap.bundle.js';
//import bootstrap from 'bootstrap';




import React, { useEffect, useState } from 'react';
import api from './api';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router, Route, Link, Routes, Navigate,} from 'react-router-dom';
import Cookies from 'js-cookie';
//import { BrowserRouter, Route, Switch } from "react-router-dom";
//import { Button } from 'bootstrap';
//import { Offcanvas } from 'bootstrap';
import { Navbar, Offcanvas, Card, Button, CardDeck, CardGroup, CardImg, Dropdown} from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { keyframes } from "styled-components";
//import keyframe from 'keyframe';
//import { Animated, Easing, keyframe } from 'react-native';
//import LottieView from 'lottie-react-native';
import {
  useScene,
  useSceneItem,
  useFrame,
  useNowFrame,
} from "react-scenejs";
import { Animate, AnimateKeyframes, AnimateGroup } from "react-simple-animate";



//import { Offcanvas } from 'react-bootstrap';

import _ from 'lodash';
//import Switch from "react-switch";
import { Tabs, Tab, Box, TabPanel, styled } from '@mui/material';


//import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
//import 'react-tabs/style/react-tabs.css';








class Productpage extends React.Component {

  constructor(props) {
    super(props);
    // نضع قيمة الحالة الأولية في this.state
    this.state = {
      showBottom: false,
      showModal: false,
      message: '',
      animeData: [], // an empty array to store the anime data from the database
      userdata: "",
      isMobile: window.innerWidth <= 991, // تعيين القيمة الافتراضية للموبايل
      isActive: true,
      value: 0,
      
      
      redirectToNewPage: false,
      showExpanded: false,

      showExpandedd: false,

      showExpandeddd: false,
      
      
      isLoggedIn: false,
     
      menuOpen: false,
      token: "", // قيمة الرمز
      //username: "", // اسم المستخدم
      profileImage: "", // صورة الملف الشخصي 
      //Cookies: "",
      redirect: false,

      // تعيين الحالة query إلى فارغة
      query: '',
      // تعيين الحالة results إلى مصفوفة فارغة
      results: [],
      
      profileId: null, // الحالة التي تحتوي على معرف الملف الشخصي الذي يتم عرضه في الصفحة
      
      isOwnProfile: false,
      
      ratedCharacters: [], // مصفوفة فارغة لتخزين الشخصيات المقيمة
      resultss: [],
      selectedImage: null,
      selectedGenre: "قم بتقييم هذا المحنك", // التصنيف المختار
      rating: "", // لا يوجد تقييم سابق
  ratingAverage: 1, // متوسط التقييم هو 8.5
  ratingCount: 0 // عدد المستخدمين الذين قاموا بالتقييم هو 12
    };

 
    
  }


  render() {

    


    const { showModal, animeData, data, error, showCards, isMobile, isActive, isCharActive, dataType, activeTab, showLogin,  message, isLoggedIn, menuOpen, profileImage, token, redirect, params, userdata, showExpanded, query, results, isOwnProfile, ratedCharacters, resultss, showExpandedd, showExpandeddd, selectedImage, selectedGenre, rating, ratingAverage, ratingCount  } = this.state;
    const { value } = this.state;
    

    return (

  

  

  
  
        <div className="products-page">
        <h1 className="page-title">منتجات الموقع</h1>
        <div className="product-cards">
          <div className="product-card">
            <h2 className="product-name">1000 نقطة حنكة</h2>
            <div className="product-image">
            
            </div>
            <div className="product-description-container">
              <div className="product-description-overlay">
                <p className="product-description">
                  احصل على نقاط حنكة الآن واستمتع بامتيازات التعليقات العلنية!
                </p>
                <p className="product-description">
                  قم بجعل تقييمك ومراجعتك مرئية للجميع وأثر في ترتيب الأنمي وتقييمه.
                </p>
                <p className="product-description">
                  استمتع بالتأثير والتألق والتميز في عالم الأنمي بتعليقاتك الفريدة.
                </p>
              </div>
            </div>
            <p className="product-price">450 EGP</p>
            <button className="product-button">اشتر الآن</button>
          </div>
          <div className="product-card">
            <h2 className="product-name">5000 نقطة حنكة</h2>
            <div className="product-image">
              
            </div>
            <div className="product-description-container">
              <div className="product-description-overlay">
                <p className="product-description">
                  احصل على نقاط حنكة أكثر واستمتع بامتيازات أكبر في التعليقات العلنية!
                </p>
                <p className="product-description">
                  قم بجعل تقييمك ومراجعتك مرئية للمزيد من الناس وأثر في ترتيب وتقييم الأنميات الأكثر شعبية.
                </p>
                <p className="product-description">
                  استمتع بالتأثير والتألق والتميز في عالم الأنمي بتعليقاتك الرائعة.
                </p>
              </div>
            </div>
            <p className="product-price">1800 EGP</p>
            <button className="product-button">اشتر الآن</button>
          </div>
          <div className="product-card">
            <h2 className="product-name">عضوية الموقع الشهرية</h2>
            <div className="product-image">
              
            </div>
            <div className="product-description-container">
              <div className="product-description-overlay">
                <p className="product-description">
                  احصل على عضوية الموقع الشهرية واستمتع بمزايا خاصة!
                </p>
                <p className="product-description">
                  قم بتسجيل دخولك يوميا للموقع واحصل على 100 نقطة حنكة كهدية.
                </p>
               
                <p className="product-description">
                  قم بالمشاركة في الاستطلاعات والتصويتات والمسابقات والفعاليات الخاصة بالموقع واربح جوائز قيمة.
                </p>
              </div>
            </div>
            <p className="product-price">300 EGP</p>
            <button className="product-button">اشتر الآن</button>
          </div>
          <div className="product-card">
            <h2 className="product-name">اشتراك تسويق وحجز مكان لمنتجك</h2>
            <div className="product-image">
            
            </div>
            <div className="product-description-container">
              <div className="product-description-overlay">
                <p className="product-description">
                  احصل على اشتراك تسويق واحجز مكان لمنتجك الخاص بعالم الانمي في موقعنا قسم المنتجات وازدد شهرة وربح!
                </p>
                <p className="product-description">
                  قم بإضافة بعض منتجاتك الخاصة بمنتجات الأنمي إلى قسم المنتجات بالموقع بحيث يتم اقتراحها على زوار ومستخدمين الموقع والإعلان عنها وتصفحها.
                </p>
                <p className="product-description">
                  قم بالحصول على عرض جيد بحيث يبقى منتجك في صفحاتنا لمده شهر او اكثر.
                </p>
                <p className="product-description">
                  قم بالاستفادة من شهرة وجودة وثقة الموقع لزيادة مبيعاتك وأرباحك.
                </p>
              </div>
            </div>
            <p className="product-price">1500 EGP</p>
            <button className="product-button">اشتر الآن</button>
          </div>
          <div className="product-card">
            <h2 className="product-name">إضافة متجرك بالكامل</h2>
            <div className="product-image">
              
            </div>
            <div className="product-description-container">
              <div className="product-description-overlay">
                <p className="product-description">
                  احصل على إضافة متجرك الخاص بمنتجات الانمي  بالكامل واجعله جزءا من الموقع!
                </p>
                <p className="product-description">
                  قم بإضافة كل منتجاتك الخاصة بمنتجات الأنمي إلى قسم المنتجات بالموقع بحيث يتم عرضها وتصنيفها وتقييمها والترويج لها.
                </p>
                <p className="product-description">
                  قم بالحصول على صفحة خاصة بمتجرك داخل الموقع بحيث يمكن للزوار والمستخدمين الوصول إليها والتواصل معك والتعرف على عروضك وخدماتك.
                </p>
                <p className="product-description">
                  قم بالاستفادة من شهرة وجودة وثقة الموقع لزيادة مبيعاتك وأرباحك وزبائنك.
                </p>
              </div>
            </div>
            <p className="product-price">3000 EGP</p>
            <button className="product-button">اشتر الآن</button>
          </div>
        </div>
      </div>
      
    );
  }
}


                export default Productpage;