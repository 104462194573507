import logo from './logo.svg';
import './components/animecard.css';
import './components/animedetails.css';
import './App.css';


//import { anime } from "../../backend/server.js"; // import Anime model from animeModel.js


import axios from 'axios';
import './assets/css/bootstrap.min.css'; // استيراد ملف CSS من Bootstrap
import 'animate.css/animate.min.css';
//import './assets/js/bootstrap.bundle.js';
//import bootstrap from 'bootstrap';




import React, { useEffect, useState } from 'react';
import api from './api';
import ReactDOM from 'react-dom';
//import { Button } from 'bootstrap';
//import { Offcanvas } from 'bootstrap';
import { Navbar, Offcanvas, Card, Button, CardDeck, CardColumns, CardGroup, CardImg, Dropdown, DropdownButton, DropdownItem, DropdownMenu} from "react-bootstrap";
import { NavLink, Link } from "react-router-dom";
import Cookies from 'js-cookie';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { keyframes } from "styled-components";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { initializePaddle, Paddle } from '@paddle/paddle-js';
//import keyframe from 'keyframe';
//import { Animated, Easing, keyframe } from 'react-native';
//import LottieView from 'lottie-react-native';
import {
  useScene,
  useSceneItem,
  useFrame,
  useNowFrame,
} from "react-scenejs";
import { Animate, AnimateKeyframes, AnimateGroup } from "react-simple-animate";



//import { Offcanvas } from 'react-bootstrap';

import _ from 'lodash';
import Switch from "react-switch";
import { Tabs, Tab } from '@material-ui/core';
import withParams from './withParams.js'; // تأكد من مسار الاستيراد الصحيح
import { Helmet, HelmetProvider } from 'react-helmet-async';

import slugify from 'slugify';
import InfiniteScroll from 'react-infinite-scroll-component';





class AnimeDetailPage extends React.Component {

  constructor(props) {
    super(props);
    const isDevelopment = process.env.REACT_APP_NODE_ENV === 'development';

      // جلب معرفات PayPal وPaddle من متغيرات البيئة
      const PAYPAL_CLIENT_ID = isDevelopment ? process.env.REACT_APP_PAYPAL_SANDBOX_CLIENT_ID : process.env.REACT_APP_PAYPAL_LIVE_CLIENT_ID;
      const PADDLE_TOKEN = isDevelopment ? process.env.REACT_APP_PADDLE_SANDBOX_TOKEN : process.env.REACT_APP_PADDLE_LIVE_TOKEN;
      const PRODUCT_PRICE_PADDLE_SILVER = isDevelopment ? process.env.REACT_APP_SANDBOX_PRICE_PADDLE_SILVER : process.env.REACT_APP_LIVE_PRICE_PADDLE_SILVER;
 const PRODUCT_PRICE_PADDLE_GOLDEN = isDevelopment ? process.env.REACT_APP_SANDBOX_PRICE_PADDLE_GOLDEN : process.env.REACT_APP_LIVE_PRICE_PADDLE_GOLDEN;
    // نضع قيمة الحالة الأولية في this.state
    this.state = {
      showBottom: false,
      showModal: false,
      message: '',
      animeData: [], // an empty array to store the anime data from the database
      isMobile: window.innerWidth <= 991, // تعيين القيمة الافتراضية للموبايل
      isActive: true,
      selectedGenre: 'عرض كل الانميات بكل التصنيفات', // التصنيف المختار
      
      animeId: "",
      commentText: '',
      commentType: '',
      
      
       //paypal
      //message: '',
      initialOptions: {
        'client-id': PAYPAL_CLIENT_ID,
        'enable-funding': '',
        'disable-funding': 'paylater,venmo,card',
        'data-sdk-integration-source': 'integrationbuilder_sc',
      },
    
      
        //paddle
        
        name: '',
        productName: '',
        product: '',
       
        paddle: '',

      
        commentText: '',
        commentType: 'praiser',
        showExpandes: false,
        showExpandex: false,
        showExpandexrank:false,
        selectedAnime: "",
        userId: "", // استبدل 'USER_ID' بمعرف المستخدم الفعلي
        rating: "",
    note: '',
    randomNote: '',
    randomRating: '',
    randomUsername: '',
    randomUserProfileImage: '',

    isHovered: false,
    selectedAnimedatail: null,

    ad: null,
    isLoading: true,


    loggedTweeterData: null,
   
    errorMessage: '',
    successMessage: '',  
    tweetersData: [],
    goldenTweeterData: null,
    justiceTweeterData: null,
    tweetersPage: 1,
    tweetersLimit: 20,
    tweetersTotalPages: 0,
    tweetersTotalCount: 0,
    hasMoretweeters: true,
    tweetersLoading: true,
    tweetersError: null,

    votedTweeters: [], // قائمة بالمغردين الذين تم التصويت لهم
      
    };

    this.toggleCards = this.toggleCards.bind(this); // bind toggleCards to the component
    this.textareaRef = React.createRef();
    this.handleTwitterLogin = this.handleTwitterLogin.bind(this);
    
  }

  /*
  // دالة لجلب بيانات الأنمي من المسار /anime
  fetchData = async () => {
    try {
      const { id } = this.props.params;
      const response = await axios.get('/anime', { params: { id } });
      if (id) {
        this.setState({ animeData: [response.data] });
      } else {
        if (Array.isArray(response.data)) {
          this.setState({ animeData: response.data });
        } else {
          console.error('Expected array but got:', response.data);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
*/

fetchData = async () => {
  try {
    const { id } = this.props.params;
    const response = await axios.get('/anime', { params: { id } });

    if (id) {
      // إذا كان هناك معرف في رابط الصفحة، عرض مستند الأنمي الخاص بهذا المعرف
      const animeDataWithSlug = {
        ...response.data,
        slug: slugify(response.data.name, { lower: true })
      };
      this.setState({ animeData: [animeDataWithSlug] });
    } else {
      // إذا لم يكن هناك معرف، عرض جميع مستندات الأنمي مع إضافة تعريف slug
      if (Array.isArray(response.data)) {
        const animeDataWithSlugs = response.data.map(anime => ({
          ...anime,
          slug: slugify(anime.name, { lower: true })
        }));
        this.setState({ animeData: animeDataWithSlugs });
      } else {
        console.error('Expected array but got:', response.data);
      }
    }
  } catch (error) {
    console.error(error);
  }
};
  

  // use componentDidMount to fetch data from node server when the component mounts
  componentDidMount() {
    // use api.get to send a get request to /api endpoint
    api.get('/api')
      .then((res) => {
        // if the request is successful, set the message state to the data from the response
        this.setState({ message: res.data.message });
      })
      .catch((err) => {
        // if there is an error, log it and set the message state to an error message
        console.error(err);
        this.setState({ message: 'Something went wrong' });
      });



      window.addEventListener('resize', this.handleWindowResize);

     this.fetchData();
     
     //this.incrementViewCount();
     this.fetchAd();
     //paddle
    
      
      this.loadProductDetailssilver();

     
      
      this.loadProductDetailsgolden();

      const userId = Cookies.get('userId') || localStorage.getItem('userId');
      
      if (userId) {
        const cleanedUserId = userId.replace(/^j:/, '');
    this.setState({ userId: cleanedUserId });
      }
       
      }

      componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowResize);
      }
    
      handleWindowResize = () => {
        const isMobile = window.innerWidth <= 991;
        this.setState({ isMobile });
      };
    
      // دالة لجلب بيانات الأنمي من المسار /anime
      
    
      // define a function called handleClick that calls the fetchData function when the button is clicked
      handleClick = () => {
        this.fetchData();
        this.setState({ isCharActive: false, dataType: 'anime' });
      };

      
  handleMouseEnter = () => {
    this.setState({ isActive: false });
  };

  handleMouseLeave = () => {
    this.setState({ isActive: true });
  };
  // create a method to handle the api call
  handleApi(e) {
    // do something with the event or the api
  
  }

  // لتغيير قيمة الحالة ، نستخدم this.setState
  // ونمرر له دالة تستلم حالة سابقة وخصائص كمعاملات
  // وترجع كائنًا يحتوي على التغييرات التي نريدها
  handleShowBottom = () => {
    this.setState((prevState, props) => ({
      showBottom: true
    }));
  };

  handleCloseBottom = () => {
    this.setState((prevState, props) => ({
      showBottom: false
    }));
  };

  
  
   // لتغيير قيمة الحالة ، نستخدم this.setState
  // ونمرر له دالة تستلم حالة سابقة وخصائص كمعاملات
  // وترجع كائنًا يحتوي على التغييرات التي نريدها
  handleShow = () => {
    this.setState((prevState, props) => ({
      show: true
    }));
  };

  handleClose = () => {
    this.setState((prevState, props) => ({
      show: false
    }));
  };

   // دالة للتبديل بين إظهار وإخفاء القائمة وتغيير اتجاه الزر
    // دالة للتبديل بين إظهار وإخفاء القائمة وتغيير اتجاه الزر
    
    toggleModal = () => {
      this.setState((prevState) => ({
        showModal: !prevState.showModal
      }));
    }

    toggleCards() {
      this.setState((prevState) => ({
        showCards: !prevState.showCards, // استخدام المعامل ! للحصول على القيمة المعاكسة
      }));
    }



    toggleChar = () => {
      this.setState((prevState) => ({
        isCharActive: !prevState.isCharActive,
        dataType: prevState.isCharActive ? 'anime' : 'character',
      }));
    };


    handleGenreChange = (event) => {
      // تحديث التصنيف المختار عند تغييره في قائمة التصنيفات
      const selectedGenre = event.currentTarget.getAttribute('value');
      this.setState({ selectedGenre });
    };
  



  
  //تعليقات علنيه
  submitComment = async () => {
    const { selectedAnime, commentText, commentType } = this.state;

    try {
      const response = await fetch('/public-comment', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          //'userId': 'your-user-id' // Replace with actual user ID or use cookies
        },
        body: JSON.stringify({ animeId: selectedAnime, commentText, commentType })
      });

      const data = await response.json();

      if (response.ok) {
        alert(data.message);
      } else {
        alert(data.message || 'An error occurred');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred while submitting your comment');
    }
  };
//تعليق علني فضي
  submitCommentsilverpaypal = async () => {
    const { selectedAnime, commentText, commentType } = this.state;

    try {
      const response = await fetch('/api/orders/:orderID/callbackpaypallsilver', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          //'userId': 'your-user-id' // Replace with actual user ID or use cookies
        },
        body: JSON.stringify({ animeId: selectedAnime, commentText, commentType })
      });

      const data = await response.json();

      if (response.ok) {
        alert(data.message);
      } else {
        alert(data.message || 'An error occurred');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred while submitting your comment');
    }
  };

  submitCommentsilverpaddle = async () => {
    const { selectedAnime, commentText, commentType } = this.state;

    try {
      const response = await fetch('/paddlecallback-silvercomment', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          //'userId': 'your-user-id' // Replace with actual user ID or use cookies
        },
        body: JSON.stringify({ animeId: selectedAnime, commentText, commentType })
      });

      const data = await response.json();

      if (response.ok) {
        alert(data.message);
      } else {
        alert(data.message || 'An error occurred');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred while submitting your comment');
    }
  };
  //تعليق علني ذهبي
  submitCommentgoldenpaypal = async () => {
    const { selectedAnime, commentText, commentType } = this.state;

    try {
      const response = await fetch('/api/orders/:orderID/callbackpaypallgolden', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          //'userId': 'your-user-id' // Replace with actual user ID or use cookies
        },
        body: JSON.stringify({ animeId: selectedAnime, commentText, commentType })
      });

      const data = await response.json();

      if (response.ok) {
        alert(data.message);
      } else {
        alert(data.message || 'An error occurred');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred while submitting your comment');
    }
  };

  submitCommentgoldenpaddle = async () => {
    const { selectedAnime, commentText, commentType } = this.state;

    try {
      const response = await fetch('/paddlecallback-goldencomment', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          //'userId': 'your-user-id' // Replace with actual user ID or use cookies
        },
        body: JSON.stringify({ animeId: selectedAnime, commentText, commentType })
      });

      const data = await response.json();

      if (response.ok) {
        alert(data.message);
      } else {
        alert(data.message || 'An error occurred');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred while submitting your comment');
    }
  };
    
   //دوال باي بال 
  createOrdersilver = async () => {
    try {
      // إنشاء قيمة عشوائية لـ reference
      const response = await fetch("/api/orderssilver", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          cart: [
            {
              id: "7b92603e77ed48968e785dea20504749",
              quantity: "1",
            },
          ],
        }),
      });
  
      // تحقق من حالة الاستجابة
      if (response.status === 401) {
        alert("YOU MUST LOGIN FIRST");
        // يمكنك إعادة توجيه المستخدم إلى صفحة تسجيل الدخول هنا إذا أردت
        return;
      }
  
      const orderData = await response.json();
  
      if (orderData.id) {
        return orderData.id;
      } else {
        const errorDetail = orderData?.details?.[0];
        const errorMessage = errorDetail
          ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
          : JSON.stringify(orderData);
  
        throw new Error(errorMessage);
      }
    } catch (error) {
      console.error("Error checking user login:", error);
      this.setState({
        message: `Could not initiate PayPal Checkout...${error.message}`,
      });
    }
  };


  createOrdergolden = async () => {
    try {
      // إنشاء قيمة عشوائية لـ reference
      const response = await fetch("/api/ordersgolden", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          cart: [
            {
              id: "7b92603e77ed48968e785dea20504749",
              quantity: "1",
            },
          ],
        }),
      });
  
      // تحقق من حالة الاستجابة
      if (response.status === 401) {
        alert("YOU MUST LOGIN FIRST");
        // يمكنك إعادة توجيه المستخدم إلى صفحة تسجيل الدخول هنا إذا أردت
        return;
      }
  
      const orderData = await response.json();
  
      if (orderData.id) {
        return orderData.id;
      } else {
        const errorDetail = orderData?.details?.[0];
        const errorMessage = errorDetail
          ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
          : JSON.stringify(orderData);
  
        throw new Error(errorMessage);
      }
    } catch (error) {
      console.error("Error checking user login:", error);
      this.setState({
        message: `Could not initiate PayPal Checkout...${error.message}`,
      });
    }
  };
  

  //paddle

   checkUserLogin = async () => {
    try {
      const response = await axios.get('/openpaddlepay');
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        alert("YOU MUST LOGIN FIRST");
        // يمكنك إعادة توجيه المستخدم إلى صفحة تسجيل الدخول هنا إذا أردت
       
      } else {
        console.error("Error checking user login:", error);
      }
      return null;
    }
  };


  openCheckoutsilver = async () => {
    const { paddle, product } = this.state;
    const isDevelopment = process.env.REACT_APP_NODE_ENV === 'development';
    const PRODUCT_PRICE_PADDLE_SILVER = isDevelopment ? process.env.REACT_APP_SANDBOX_PRICE_PADDLE_SILVER : process.env.REACT_APP_LIVE_PRICE_PADDLE_SILVER;
    this.initializePaddlesilver();
    if (paddle && product) {
      const userId = await this.checkUserLogin();
      if (userId) {
        paddle.Checkout.open({
          items: [{ priceId: PRODUCT_PRICE_PADDLE_SILVER, quantity: 1 }],
        });
      }
    }
  };
  loadProductDetailssilver = async () => {
    try {
      const response = await axios.get('/get-product-details-silvercomment'); // افترض أن لديك endpoint للحصول على تفاصيل المنتج
      this.setState({ product: response.data, error: null });
    } catch (error) {
      this.setState({ error: 'Failed to load product details' });
    }
  };

  initializePaddlesilver = async () => {
    const isDevelopment = process.env.REACT_APP_NODE_ENV === 'development';
    const PADDLE_TOKEN = isDevelopment ? process.env.REACT_APP_PADDLE_SANDBOX_TOKEN : process.env.REACT_APP_PADDLE_LIVE_TOKEN;
    try {
      const paddleInstance = await initializePaddle({
        environment: isDevelopment ? 'sandbox' : 'production', // أو 'production' حسب البيئة التي تعمل بها
        token: PADDLE_TOKEN, // استبدل هذا بالتوكن الخاص بك
        eventCallback: async (data) => {
          switch(data.name) {
            case "checkout.loaded":
              console.log("Checkout opened");
              break;
            case "checkout.customer.created":
              console.log("Customer created");
              break;
            case "checkout.completed":
              console.log("Checkout completed");
               this.submitCommentsilverpaddle();
              break;
            default:
              console.log(data);
          }
        }
      });
      this.setState({ paddle: paddleInstance });
    } catch (error) {
      console.error('Failed to initialize Paddle:', error);
    }
  };


  openCheckoutgolden = async () => {
    const { paddle, product } = this.state;
    const isDevelopment = process.env.REACT_APP_NODE_ENV === 'development';
    const PRODUCT_PRICE_PADDLE_GOLDEN = isDevelopment ? process.env.REACT_APP_SANDBOX_PRICE_PADDLE_GOLDEN : process.env.REACT_APP_LIVE_PRICE_PADDLE_GOLDEN;
    this.initializePaddlegolden();
    if (paddle && product) {
      const userId = await this.checkUserLogin();
      if (userId) {
        paddle.Checkout.open({
          items: [{ priceId: PRODUCT_PRICE_PADDLE_GOLDEN, quantity: 1 }],
        });
      }
    }
  };
  loadProductDetailsgolden = async () => {
    try {
      const response = await axios.get('/get-product-details-goldencomment'); // افترض أن لديك endpoint للحصول على تفاصيل المنتج
      this.setState({ product: response.data, error: null });
    } catch (error) {
      this.setState({ error: 'Failed to load product details' });
    }
  };

  initializePaddlegolden = async () => {
    const isDevelopment = process.env.REACT_APP_NODE_ENV === 'development';
    const PADDLE_TOKEN = isDevelopment ? process.env.REACT_APP_PADDLE_SANDBOX_TOKEN : process.env.REACT_APP_PADDLE_LIVE_TOKEN;
    try {
      const paddleInstance = await initializePaddle({
        environment: isDevelopment ? 'sandbox' : 'production', // أو 'production' حسب البيئة التي تعمل بها
        token: PADDLE_TOKEN, // استبدل هذا بالتوكن الخاص بك
        eventCallback: async (data) => {
          switch(data.name) {
            case "checkout.loaded":
              console.log("Checkout opened");
              break;
            case "checkout.customer.created":
              console.log("Customer created");
              break;
            case "checkout.completed":
              console.log("Checkout completed");
               this.submitCommentgoldenpaddle();
              break;
            default:
              console.log(data);
          }
        }
      });
      this.setState({ paddle: paddleInstance });
    } catch (error) {
      console.error('Failed to initialize Paddle:', error);
    }
  };

  incrementViewCount = async () => {
    try {
    const response = await fetch('/public-comment/view', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
  } catch (error) {
    console.error('Error:', error);
  } 
};



handleCommentTextChange = (event) => {
  const { value } = event.target;
  this.setState({ commentText: value }, this.adjustTextareaHeight);
};

adjustTextareaHeight = () => {
  const textarea = this.textareaRef.current;
  textarea.style.height = 'auto'; // إعادة تعيين الارتفاع
  textarea.style.height = `${textarea.scrollHeight}px`; // ضبط الارتفاع بناءً على المحتوى
};

handleCommentTypeChange = () => {
  this.setState((prevState) => ({
    commentType: prevState.commentType === 'praiser' ? 'critic' : 'praiser',
  }));
};
  
handleExpandes = (animeId) => {
  this.setState((prevState) => ({
    showExpandes: !prevState.showExpandes,
    selectedAnime: prevState.showExpandes ? null : animeId, // إعادة تعيين selectedAnime عند إغلاق المربع
  }));
};

handleExpandex = (animeId) => {
  this.setState((prevState) => ({
    showExpandex: !prevState.showExpandex,
    selectedAnime: prevState.showExpandex ? null : animeId, // إعادة تعيين selectedAnime عند إغلاق المربع
    rating: prevState.showExpandex ? null : this.state.rating,
    note: prevState.showExpandex ? null : this.state.note,
    randomNote: prevState.showExpandex ? null : this.state.randomNote,
  }));
};
  

handleExpandexrank = (animeId) => {
  this.setState((prevState) => ({
    showExpandexrank: !prevState.showExpandexrank,
    selectedAnime: prevState.showExpandexrank ? null : animeId, // إعادة تعيين selectedAnime عند إغلاق المربع
   
  }));
};

getCommentStyle = (commentPrice) => {
  const isMobile = window.innerWidth <= 991;
 let style = {
   overflow: 'hidden',
   display: 'flex',
   width: '95%',
   maxWidth: isMobile ? window.innerWidth - 150 : '95%', 
   height: 'auto',
   boxShadow: '20px 20px 60px #bebebe, -20px -20px 60px #ffffff',
   background: 'linear-gradient(90deg, rgba(255,255,255,0.9) 0%, rgba(255,255,255,0.7) 50%, rgba(255,255,255,0.9) 80%)',
   outline: '3px solid #f5f5f5',
   outlineOffset: '-3px',
   position: 'relative',
   color: 'black',
   fontWeight: 'bold',
   fontSize: '16px',
   animation: 'glow 1.5s infinite',
   marginLeft: isMobile ? '30px':'1px',
 };

 if (commentPrice === 'silver') {
   style.boxShadow = '0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(169, 169, 169, 0.4), inset 0 -2px 5px 1px rgba(192, 192, 192, 1), inset 0 -1px 1px 3px rgba(211, 211, 211, 1)';
   style.background = 'linear-gradient(160deg, #d4d4d4, #e0e0e0, #f5f5f5, #e0e0e0, #d4d4d4)';
   style.outline = '3px solid #c0c0c0';
 } else if (commentPrice === 'golden') {
   style.boxShadow = '0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(110, 80, 20, 0.4), inset 0 -2px 5px 1px rgba(139, 66, 8, 1), inset 0 -1px 1px 3px rgba(250, 227, 133, 1)';
   style.background = 'linear-gradient( 160deg, #a54e07, #b47e11, #fef1a2, #bc881b, #a54e07 )';
   style.outline = '3px solid #a55d07';
 }

 return style;
};  
  



   // دالة استدعاء للإعجاب
   likeComment = async (animeId, commentId) => {
    //console.log('User ID:', this.state.userId); // طباعة معرف المستخدم
    try {
      const response = await axios.post('/like', { animeId, commentId }, {
        headers: {
          userId: this.state.userId
        }
      });
      const updatedAnimeData = this.state.animeData.map(anime => {
        if (anime._id === animeId) {
          const updatedComments = anime.publicComment.map(comment => {
            if (comment._id === commentId) {
              return {
                ...comment,
                likesCount: response.data.likesCount,
                dislikesCount: response.data.dislikesCount,
                likedBy: response.data.likedBy,
                dislikedBy: response.data.dislikedBy,
              };
            }
            return comment;
          });
          return { ...anime, publicComment: updatedComments };
        }
        return anime;
      });
      this.setState({ animeData: updatedAnimeData });
    } catch (error) {
      if (error.response && error.response.status === 400 && error.response.data.error === 'you must login first') {
        alert('You must login first');
      } else {
        console.error('Error liking comment:', error);
      }
    }
  };

  // دالة استدعاء لعدم الإعجاب
  dislikeComment = async (animeId, commentId) => {
    //console.log('User ID:', this.state.userId); // طباعة معرف المستخدم
    try {
      const response = await axios.post('/dislike', { animeId, commentId }, {
        headers: {
          userId: this.state.userId
        }
      });
      const updatedAnimeData = this.state.animeData.map(anime => {
        if (anime._id === animeId) {
          const updatedComments = anime.publicComment.map(comment => {
            if (comment._id === commentId) {
              return {
                ...comment,
                likesCount: response.data.likesCount,
                dislikesCount: response.data.dislikesCount,
                likedBy: response.data.likedBy,
                dislikedBy: response.data.dislikedBy,
              };
            }
            return comment;
          });
          return { ...anime, publicComment: updatedComments };
        }
        return anime;
      });
      this.setState({ animeData: updatedAnimeData });
    } catch (error) {
      if (error.response && error.response.status === 400 && error.response.data.error === 'you must login first') {
        alert('You must login first');
      } else {
        console.error('Error disliking comment:', error);
      }
    }
  };

 // دالة لتحديد الفئة بناءً على حالة التعليق ومعرف المستخدم
 getButtonClass = (comment, userId, type) => {
  if (!comment) return '';
  // إزالة علامات الاقتباس الإضافية والمسافات غير المرئية
  const cleanedUserId = userId.replace(/^"|"$/g, '').trim();
  const cleanedLikedBy = comment.likedBy.map(id => id.replace(/^"|"$/g, '').trim());
  const cleanedDislikedBy = comment.dislikedBy.map(id => id.replace(/^"|"$/g, '').trim());

  // تحقق من أن userId موجود في المصفوفة
  const userIdExists = cleanedLikedBy.includes(cleanedUserId);
    // طباعة كل عنصر في likedBy مع طوله
  cleanedLikedBy.forEach((id, index) => {
   
  });

  if (type === 'like') {
    return `like-button ${userIdExists ? 'liked' : ''}`;
  } else if (type === 'dislike') {
    return `dislike-button ${cleanedDislikedBy.includes(cleanedUserId) ? 'disliked' : ''}`;
  }
  return '';
};


// دالة لإضافة أو تعديل التقييم
addOrUpdateRating = async () => {
  const { rating, note, selectedAnime } = this.state;



  try {
    const response = await fetch('/rateanime', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        //'userId': 'your-user-id' // Replace with actual user ID or use cookies
      },
      body: JSON.stringify({ animeId: selectedAnime, rating, note })
    });

    const data = await response.json();

    if (response.ok) {
      this.setState({
        message: data.message,
        randomNote: data.randomNote,
        randomRating: data.randomRating,
        randomUsername: data.randomUsername,
        randomUserProfileImage: data.randomUserProfileImage
      });

      alert(data.message);
    } else {
      alert(data.error || 'حدث خطأ ما');
    }
  } catch (error) {
    console.error('Error occurred:', error);
    alert('حدث خطأ ما أثناء إرسال التقييم');
  }
};

// دالة لحذف التقييم
deleteRating = async () => {
  const { selectedAnime } = this.state;

  

  try {
    const response = await fetch('/deleterateanime', {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        //'userId': 'your-user-id' // Replace with actual user ID or use cookies
      },
      body: JSON.stringify({ animeId: selectedAnime })
    });

    const data = await response.json();

    if (response.ok) {
      this.setState({ message: data.message });
      alert(data.message);
    } else {
      alert(data.error || 'حدث خطأ ما');
    }
  } catch (error) {
    console.error('Error occurred:', error);
    alert('حدث خطأ ما أثناء حذف التقييم');
  }
};
  
handleRatingChange = (e) => {
  let value = e.target.value;

  // تحقق من أن القيمة تحتوي فقط على أرقام أو نقطة عشرية
  const regex = /^[0-9]*\.?[0-9]*$/;

  // تحقق من أن طول القيمة لا يتجاوز أربع أرقام
  if (regex.test(value) && value.length <= 4) {
    this.setState({ rating: value });
  }
};

handleNoteChange = (e) => {
  this.setState({ note: e.target.value.slice(0, 50) });
};


handleMouseEnter = () => {
  this.setState({ isHovered: true });
}

handleMouseLeave = () => {
  this.setState({ isHovered: false });
}




fetchAd = async () => {
  try {
    const response = await axios.get('/get-ad');
    if (response.data.ad) {
      this.setState({ ad: response.data.ad, isLoading: false });
    } else {
      this.setState({ isLoading: false });
    }
  } catch (error) {
    console.error(error.response ? error.response.data.message : error.message);
    this.setState({ isLoading: false });
  }
};

incrementAdClick = async (adId) => {
  try {
    const response = await axios.post('/increment-click', { adId });
    console.log(response.data.message);
  } catch (error) {
    console.error(error.response ? error.response.data.message : error.message);
  }
};


handleAdClick = (ad) => {
  this.incrementAdClick(ad._id);
  window.open(ad.link, '_blank');
};


handleTwitterLoginx = async () => {
  try {
    const animeId = "66c9bac54a621856b4ee663a"; // افترض أن animeId يتم تمريره كـ prop إلى المكون
    const response = await axios.get('/auth/twitter', {
      params: { animeId },
      withCredentials: true // تأكد من تمرير الكوكيز والجلسة
    });

    console.log('Response from /auth/twitter:', response.data);

    // إعادة توجيه المستخدم إلى رابط تسجيل الدخول عبر تويتر
    if (response.status === 200) {
      // لا حاجة للتحقق من response.data.redirectUrl لأننا نستخدم passport.authenticate مباشرة
      window.location.href = response.data.authUrl;
    } else {
      console.error('Unexpected response status:', response.status);
    }
  } catch (error) {
    console.error('Error during Twitter login:', error);
    // يمكنك إضافة معالجة للأخطاء هنا إذا لزم الأمر
  }
};

handleTwitterLogin = () => {
  const { selectedAnime } = this.state;
  const animeId = selectedAnime;
  if (animeId) {
    // إعادة توجيه المستخدم إلى رابط تسجيل الدخول عبر تويتر مع تمرير معرف الأنمي كـ query parameter
    window.location.href = `https://mohankeen.com/auth/twitter?animeId=${animeId}`;
  } else {
    console.error('Anime ID is required');
    // يمكنك إضافة معالجة للأخطاء هنا إذا لزم الأمر
  }
};



handleSubmittwittercheck = async (event) => {
  event.preventDefault();
  const { selectedAnime } = this.state; // افترض أن selectedAnime موجود في حالة المكون

  try {
  const response = await axios.get('/twittercheck', {
    params: { animeId: selectedAnime } // إرسال animeId كجزء من معلمات الاستعلام
  });

    this.setState({ message: response.data.message, error: '' });
    return response; // إرجاع الاستجابة إذا كانت ناجحة
  } catch (error) {
    let errorMessage = 'An error occurred'; // رسالة خطأ افتراضية

    if (error.response) {
      // إذا كان هناك استجابة من السيرفر، استخدم الرسالة الموجودة في response.data.message
      errorMessage = error.response.data.message || errorMessage;
    }

    this.setState({ error: errorMessage, message: '' });
    alert(errorMessage); // عرض رسالة الخطأ في alert
    throw error; // رمي الخطأ لإيقاف تنفيذ handleTwitterLogin
  }
};

handleButtonClicktweet = async (event) => {
  event.preventDefault();
  
  try {
    await this.handleSubmittwittercheck(event);
    this.handleTwitterLogin(event);
  } catch (error) {
    // لا تفعل شيئًا إذا كانت الاستجابة غير ناجحة
  }
};
/*

handleTwitterLogin = async () => {
  const animeId = "66c9bac54a621856b4ee663a";
  if (!animeId) {
    console.error('Anime ID is required');
    return;
  }

  try {
    const response = await fetch(`/auth/twitter?animeId=${animeId}`, {
      method: 'GET',
      credentials: 'include' // لتضمين الكوكيز في الطلب
    });

    if (!response.ok) {
      const errorData = await response.json();
      alert(errorData.message || 'An error occurred');
      return;
    }

    // إذا كان الطلب ناجحًا، إعادة توجيه المستخدم إلى رابط تسجيل الدخول عبر تويتر
    const redirectUrl = response.url;
    window.location.href = redirectUrl;
  } catch (error) {
    console.error('Error during Twitter login:', error);
    alert('An error occurred during Twitter login');
  }
};
handleTwitterLogin() {
   const animeId = "66c9bac54a621856b4ee663a"; // يمكنك تغيير هذا إلى القيمة المطلوبة
  axios.get(`urlngrok/auth/twitter?animeId=${animeId}`)
    .then(response => {
      // التعامل مع الاستجابة
      console.log(response.data);
      // إعادة توجيه المستخدم إلى رابط المصادقة
      window.location.href = response.data.authUrl;
    })
    .catch(error => {
      console.error('Error during Twitter login:', error);
    });
}

handleTwitterLogin = () => {
  const animeId = "66c9bac54a621856b4ee663a";
  if (animeId) {
    // إعادة توجيه المستخدم إلى رابط تسجيل الدخول عبر تويتر مع تمرير معرف الأنمي كـ query parameter
    window.location.href = `http://localhost:3001/auth/twitter?animeId=${animeId}`;
  } else {
    console.error('Anime ID is required');
    // يمكنك إضافة معالجة للأخطاء هنا إذا لزم الأمر
  }
};
*/


 // دالة استدعاء مسار /vote/tweeterlist
 handleVoteTweeter = async (tweeterId) => {
  try {
    const { selectedAnime } = this.state;
    const response = await axios.post('/vote/tweeterlist', { tweeterId, animeId: selectedAnime });
    alert(response.data.message);
    this.checkUserVotes();
  } catch (error) {
    alert(error.response?.data?.message || 'Error voting for tweeter');
  }
};

// دالة استدعاء مسار /generate-vote-link
generateVoteLink = async (tweeterId) => {
  try {
    const response = await axios.post('/generate-vote-link', { tweeterId });
    const voteLink = response.data.voteLink;

    // نسخ الرابط إلى الحافظة
    navigator.clipboard.writeText(voteLink).then(() => {
      console.log('Vote link copied to clipboard!');
      const button = document.getElementById(`copy-button-${tweeterId}`);
      button.classList.add('copied');
    
    }).catch(err => {
      console.error('Failed to copy vote link: ', err);
    });
  } catch (error) {
    console.error('Error generating vote link:', error);
    alert('Error generating vote link');
  }
};

// دالة استدعاء مسار /gettweeters
fetchTweeters = async () => {
  const { tweetersPage, tweetersLimit, selectedAnime, tweetersData } = this.state;

  if (!selectedAnime) return;

  try {
    const response = await axios.get('/gettweeters', {
      params: {
        animeId: selectedAnime,
        page: tweetersPage,
        limit: tweetersLimit
      }
    });

    this.setState({
      tweetersData: [...tweetersData, ...response.data.tweeters],
      goldenTweeterData: response.data.goldenTweeter,
      justiceTweeterData: response.data.justiceTweeter,
      tweetersTotalPages: response.data.totalPages,
      tweetersTotalCount: response.data.totalTweeters,
      tweetersLoading: false,
      tweetersError: null,
      hasMoretweeters: tweetersPage < response.data.totalPages
    });
  } catch (error) {
    console.error('Error fetching tweeters:', error);
    this.setState({
      tweetersLoading: false,
      tweetersError: 'Error fetching tweeters'
    });
  }
};

fetchMoreTweeters = () => {
  this.setState((prevState) => ({
    tweetersPage: prevState.tweetersPage + 1
  }), this.fetchTweeters);
};


// دالة استدعاء مسار /loggedtweeter
fetchLoggedTweeter = async () => {
  const { selectedAnime } = this.state;

  if (!selectedAnime) return;

  try {
    const response = await axios.get('/loggedtweeter', {
      params: {
        animeId: selectedAnime
      },
      withCredentials: true // للتأكد من إرسال الكوكيز مع الطلب
    });

    this.setState({
      loggedTweeterData: response.data,
      tweetersError: null
    });
  } catch (error) {
    console.error('Error fetching logged tweeter:', error);
    this.setState({
      tweetersError: 'Error fetching logged tweeter'
    });
  }
};

checkUserVotes = async () => {
  try {
    const {  selectedAnime } = this.state;
    const response = await axios.get('/user/votes', {
      params: { animeId: selectedAnime },
     
    });
    this.setState({ votedTweeters: response.data.votedTweeters });
  } catch (error) {
    console.error('Error fetching user votes:', error);
  }
};

getButtonClassvote = (tweeterId) => {
  return this.state.votedTweeters.includes(tweeterId) ? 'vote-button voted' : 'vote-button';
};

handleExpandexranktweet = (animeId) => {
  this.setState((prevState) => {
    const isClosing = prevState.showExpandexranktweet;
    return {
      showExpandexranktweet: !prevState.showExpandexranktweet,
      selectedAnime: isClosing ? null : animeId, // إعادة تعيين selectedAnime عند إغلاق المربع
      tweetersPage: 1,
      tweetersData: isClosing ? [] : prevState.tweetersData,
      hasMoretweeters: isClosing ? true : prevState.hasMoretweeters,
      tweetersLoading: isClosing ? false : true,
      tweetersError: isClosing ? null : prevState.tweetersError,
      goldenTweeterData: isClosing ? null : prevState.goldenTweeterData,
      justiceTweeterData: isClosing ? null : prevState.justiceTweeterData,
      loggedTweeterData: isClosing ? null : prevState.loggedTweeterData, // تفريغ loggedTweeterData عند إغلاق المربع
      votedTweeters: isClosing ? [] : prevState.votedTweeters, // تفريغ votedTweeters عند إغلاق المربع
    };
  }, () => {
    if (this.state.showExpandexranktweet) {
      this.fetchTweeters();
      this.fetchLoggedTweeter();
      this.checkUserVotes();
    }
  });
};


  render() {

    const { showModal, animeData, data, error, showCards, isMobile, isActive, isCharActive, dataType, activeTab, selectedGenre,   initialOptions, productId, customerEmail, name, productName, product, commentText, commentType,  selectedAnime, showExpandes, showExpandex, rating, note, randomNote, randomRating, randomUsername, randomUserProfileImage, isHovered, ad, isLoading,showExpandexrank,  showExpandexranktweet, tweeters, loggedTweeterData, goldenTweeter, justiceTweeter ,errorMessage, successMessage,tweetersData, goldenTweeterData, justiceTweeterData, tweetersPage,  tweetersLimit,tweetersTotalPages, tweetersTotalCount,hasMoretweeters, tweetersError,tweetersLoading, votedTweeters } = this.state;
    const userId = this.state.userId; // جلب معرف المستخدم من الحالة
    const { id } = this.props.params;
  

    // دمج التصفية والترتيب
    const filteredAndSortedAnimeData = Array.isArray(animeData)
      ? animeData
          .filter((anime) => {
            const activeComment = Array.isArray(anime.publicComment)
              ? anime.publicComment.find(comment => comment.commentStatus === 'active')
              : null;
            anime.activeComment = activeComment; // إضافة التعليق النشط إلى كائن الأنمي
            return selectedGenre === "عرض كل الانميات بكل التصنيفات"
              ? true
              : anime.genre.filter((genre) => genre === selectedGenre).length > 0;
          })
          .sort((a, b) => {
            const aActive = a.activeComment && a.activeComment.remainingTime > 0;
            const bActive = b.activeComment && b.activeComment.remainingTime > 0;

            if (aActive && !bActive) return -1;
            if (!aActive && bActive) return 1;
            if (aActive && bActive) {
              return new Date(b.activeComment.commentDate) - new Date(a.activeComment.commentDate);
            }
            return b.honorPoints - a.honorPoints;
          })
      : [];
      //console.log('selectedAnime:', selectedAnime);
      
      const selectedAnimecomm = filteredAndSortedAnimeData.find(anime => anime._id === selectedAnime);
      //console.log('selectedAnimecomm:', selectedAnimecomm);
      const selectedAnimedatail = filteredAndSortedAnimeData.find(anime => anime._id === id);

      if (!selectedAnimedatail) {
        // التعامل مع الحالة التي لا يتم فيها العثور على الأنمي
        return <div></div>;
      }
      
      const description = `${selectedAnimedatail.name} هو أنمي يحتل في العالم العربي المرتبة # ${selectedAnimedatail.ranking.currentRank} بعد أن كان في المرتبة # ${selectedAnimedatail.ranking.previousRank}. آخر تغيير في المرتبة كان في ${new Date(selectedAnimedatail.ranking.lastchangeinRank).toLocaleDateString('ar-EG', { year: 'numeric', month: '2-digit', day: '2-digit' })}.`;
      
      // البحث عن تعليق علني من النوع الفضي أو الذهبي إن وجد
      const publicComment = selectedAnimedatail.publicComment.find(comment => comment.commentPrice === 'silver' || comment.commentPrice === 'golden');
      const commentTextseo = publicComment ? publicComment.commentText : '';

      const parentStyle = {
      //display: 'block', // use flex layout
      justifyContent: 'center', // center the items horizontally
      alignItems: 'center', // center the items vertically
      //height: '-250vh', // set the height to 100% of the viewport height
      //margintop: '100px', // set the margin to auto
      //padding: '280px',
      position: 'relative',
      margin: '10px',
       float: 'right'
      
    };

    const cardStyle = {
      width: isMobile ? window.innerWidth - 20 : '75%',
      height: isMobile ? 'auto' : '530px', // تعديل ارتفاع البطاقة
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      borderRadius: '15px',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',

      position: 'absolute',
      

      
     
      float: 'center',
      left: isMobile ? '' :  '21.5%',
      
      marginTop: isMobile ? '150px' :  '200px',
    };


    const commStyle = {
      overflow: 'hidden',
      display: 'flex',
      width: '95%',
      height: 'auto',
      boxShadow: '20px 20px 60px #bebebe, -20px -20px 60px #ffffff',
      background: 'linear-gradient(90deg, rgba(255,255,255,0.9) 0%, rgba(255,255,255,0.7) 50%, rgba(255,255,255,0.9) 80%)',
      outline: '3px solid #f5f5f5',
      outlineOffset: '-3px',
      position: 'relative',
      color: 'black',
  fontWeight: 'bold',
  fontSize: '16px',
  animation: 'glow 1.5s infinite',
    }
    
    

    
    const imageStyle = {
      width: '150px', // قم بتعديل عرض الصورة حسب الحاجة
      height: '150px', // قم بتعديل ارتفاع الصورة حسب الحاجة
      objectFit: 'cover', // تعديل تنسيق الصورة لتمتلئ داخل حاوية الصورة بشكل كامل دون تشويه النسبة
      borderRadius: '50%', // إضافة حواف دائرية للصورة
    };


    
    const avatarStyle = {

      width: isMobile ?'70px': '90px', // قم بتعديل عرض الصورة حسب الحاجة
    height: isMobile ?'70px': '90px', // قم بتعديل ارتفاع الصورة حسب الحاجة
    objectFit: 'cover', // تعديل تنسيق الصورة لتمتلئ داخل حاوية الصورة بشكل كامل دون تشويه النسبة
    borderRadius: '50%', // إضافة حواف دائرية للصورة


    };


    

    

    
    

    

    

    
    return (

      
        <HelmetProvider>
      
     

      
      <div className="pagebody"> 
      
      <div className="logostyle">
    <img src="https://i.imgur.com/RpyLpGN.png" alt="شعار الموقع" style={{width: '319.98px', height: '180px', objectfit: 'contain', float: 'left',}}/>
</div>


<Link to="/" className="home-button" >
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-house-door" viewBox="0 0 16 16">
        <path d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293zM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4z"/>
      </svg>
    </Link>
      <div>

      <div className="containerwarrior">
        {ad ? (
          <>
            <button
              className="fightratex"
              onClick={() => this.handleAdClick(ad)}
            >
              <p>{ad.productType}</p>
            </button>
            <img src={ad.imageUrl} alt="Ad Image" className="imagewar" />
          </>
        ) : (
          <>
            <button className="fightrate">
              <p>عالم التقييم ممتع اكثر مما تظن</p>
            </button>
            <img src="https://i.imgur.com/mZXZ9gb.jpg" alt="Descriptive Alt Text" className="imagewar" />
          </>
        )}
      </div>
      



  

        
         

        <div >
        
       
        
        
       

        
        <div className="anime-list">

          
        <div className="container">
    <div className="row">
      <div className="col-md-9 offset-md-3">
      
      </div>
    </div>
  </div>
  
  {selectedAnimedatail && (
          
          
            <div  className="card mb-3" key={selectedAnimedatail._id} style={cardStyle}  >
             <Helmet>
             <meta charSet="utf-8" />
             <meta name="viewport" content="width=device-width, initial-scale=1" />

            <title>{selectedAnimedatail.name} </title>
            <meta name="description" content={`${description} يوجد تعليق علني مميز:${commentTextseo}`} />
            <meta property="og:title" content={selectedAnimedatail.name} />
            <meta property="og:description" content={`${description} يوجد تعليق علني مميز:${commentTextseo}`} />
            <meta property="og:image" content={selectedAnimedatail.imageUrl} />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={`https://mohankeen.com/anime/${selectedAnimedatail._id}/`} />
            <meta name="keywords" content={selectedAnimedatail.name} />
            <meta name="author" content="المحنكين" />
            <meta property="og:site_name" content="MOHANKEEN" />
            <meta property="og:locale" content="ar_AR" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={selectedAnimedatail.name} />
            <meta name="twitter:description" content={`${description} يوجد تعليق علني مميز:${commentTextseo}`} />
            <meta name="twitter:image" content={selectedAnimedatail.imageUrl} />
            <link rel="canonical" href={`https://mohankeen.com/anime/${selectedAnimedatail._id}/`} />
            <meta name="robots" content="index, follow" />
            <meta property="og:updated_time" content={new Date(selectedAnimedatail.updatedAt).toISOString()} />
            <meta property="og:author" content="المحنكين" />
            <meta property="article:section" content="Anime Rating" />
            <meta property="article:tag" content={selectedAnimedatail.name} />
            
          </Helmet>
            
              {/* اول سطرين تجطهم على زر او بطاقه
            
              onMouseEnter={this.handleMouseEnter}
            onMouseLeave={this.handleMouseLeave}
              {isHovered && (
              <div className="stars">
    {Array.from({ length: 30 }).map((_, index) => (
      <div className="star" key={index} style={{
        '--star-tail-length': `${Math.random() * 2 + 4}em`,
        '--top-offset': `${Math.random() * 50}vh`,
        '--fall-duration': `${Math.random() * 3 + 3}s`,
        '--fall-delay': `${Math.random() * 1}s`
      }}></div>
    ))}
  </div>
    )}  
      */}  
              <div className="col-md-2">
                <div className="circle-image">
                  <img src={selectedAnimedatail.imageUrl} alt={selectedAnimedatail.name} className="img-fluid" style={imageStyle} />
                </div>
              </div>
              <div className="col-md-10">
                <div className="card-body">
                <div className="number-badge">
   #{selectedAnimedatail.ranking.currentRank}
    
</div>
                  <h4>{selectedAnimedatail.name}</h4>
                  <button className="btnmohank cube cube-hover" type="button" onClick={() => this.handleExpandex(selectedAnimedatail._id)}>                <div class="bg-top">
    <div class="bg-inner"></div>
  </div>
  <div class="bg-right">
    <div class="bg-inner"></div>
  </div>
  <div class="bg">
    <div class="bg-inner"></div>
  </div>
  <div class="textmohank">ادخال التقييم</div>
                  </button>
                  
                  {showExpandex && selectedAnimecomm && (

<div className="expanded-overlayssx">
<div className="expanded-cardssx">

<button className="close-button" onClick={this.handleExpandex}>×</button>
  
     <div className="anime-info">
     <img src={selectedAnimecomm.imageUrl} alt={selectedAnimecomm.name} className="anime-image" />
    <span className="anime-name">{selectedAnimecomm.name}</span>
     </div>
    <input
    type="hidden"
   value={selectedAnimecomm.id}
     className="anime-id-input"
      />
        <div className="containergold">
      <div className="gold-box">
<h6>اولا : ادخل تقييمك من 10</h6>
</div>
</div>
<input
  type="number"
  value={rating}
  onChange={this.handleRatingChange}
  className="rate-input"
  placeholder="0.0"
  maxLength="4"
  step="0.1" // تحديد الدقة العشرية
  min="0" // الحد الأدنى للقيمة
  max="10" // الحد الأقصى للقيمة
/>
<button onClick={this.deleteRating} className="delete-rating-buttonm"><span class="textdelete">حذف تقييمك</span><span class="icon"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"></path></svg></span></button>
<div className="containergold">
<div className="gold-box">
<h6>ثانيا : اكتب كلمه توجهها لمن يختلف معك بالتقييم</h6>
</div>
</div>
<div class="circle-left"></div>
<div class="cardratemargin">
<div class="cardrate">

  <input
   value={note}
  onChange={this.handleNoteChange}
  maxLength="50"
  className="note-input"
  ref={this.textareaRef}
  placeholder="كن لبقا ورقيقا مع المختلفين مع حنكتك ايها المحنك"
  
   
  
  
  />

</div>
<div class="circle-right"></div>
</div>
<button onClick={this.addOrUpdateRating} className="confirm-rating-button">تأكيد التقييم <div id="clip">
        <div id="leftTop" class="corner"></div>
        <div id="rightBottom" class="corner"></div>
        <div id="rightTop" class="corner"></div>
        <div id="leftBottom" class="corner"></div>
    </div>
    <span id="rightArrow" class="arrow"></span>
    <span id="leftArrow" class="arrow"></span>
</button>
<div className="containergold">
<div className="gold-box">
<h6>ثالثا : انتظر ستظهر كلمه يوجهها لك محنك يختلف معك بالتقييم</h6>
</div>
</div>
<div class="voltage-button">
<div className="random-notem">
{randomNote ? (
  randomNote === "انت اول من يقيم هذا الانمي" ? (
    <div className="first-rating-note">
      <p>{randomNote}</p>
    </div>
  ) : (
    <div className="random-notem-container">
      <img 
      src={randomUserProfileImage.startsWith('http') ? randomUserProfileImage : `/${randomUserProfileImage.replace(/\\/g, '/')}`}
       alt={randomUsername} className="random-avatar" />
      <p className="random-rating">تقييم {randomUsername} لهذا الانمي: <span className="rating-valueran">#{randomRating}</span></p>
      <p className="random-noteqq">يوجه {randomUsername} كلمه لمن يختلف معه بالتقييم وهي: <span className="ratingnote-valueran">{randomNote}</span></p>
      <button
  className="fightm"
  onClick={() => window.open(`/profile?username=${randomUsername}`, '_blank', 'noopener,noreferrer')}
>
  اذهب لبروفايله وقيمه
</button>
    </div>
  )
) : (
    
    <div class="notificationgoku">
  	<div id="goku">
      <div class="headgoku"></div>
      <div class="bodygoku"></div>
      <div class="leggoku"></div>
      <div class="shadowgoku"></div>
	  </div>

  <span class="dirt">
  
  <div class="textgoku">
    <span class="font hover">WAIT!</span>
    <span class="font-text hover">بعد تقييمك:</span>
    <span class="font-power hover">ستظهر ملاحظة عشوائية</span>
  </div>
  <div class="parchment">
  </div>
</span></div>
  

   
  )}
</div>
<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 234.6 61.3" preserveAspectRatio="none" xmlSpace="preserve">
 
 <filter id="glow">
   <feGaussianBlur class="blur" result="coloredBlur" stdDeviation="2"></feGaussianBlur>
   <feTurbulence type="fractalNoise" baseFrequency="0.075" numOctaves="3" result="turbulence"></feTurbulence>
<feDisplacementMap in="SourceGraphic" in2="turbulence" scale="30" xChannelSelector="R" yChannelSelector="G" result="displace"></feDisplacementMap>
   <feMerge>
     <feMergeNode in="coloredBlur"></feMergeNode>
     <feMergeNode in="coloredBlur"></feMergeNode>
     <feMergeNode in="coloredBlur"></feMergeNode>
     <feMergeNode in="displace"></feMergeNode>
     <feMergeNode in="SourceGraphic"></feMergeNode>
   </feMerge>
 </filter>
 <path class="voltage line-1" d="m216.3 51.2c-3.7 0-3.7-1.1-7.3-1.1-3.7 0-3.7 6.8-7.3 6.8-3.7 0-3.7-4.6-7.3-4.6-3.7 0-3.7 3.6-7.3 3.6-3.7 0-3.7-0.9-7.3-0.9-3.7 0-3.7-2.7-7.3-2.7-3.7 0-3.7 7.8-7.3 7.8-3.7 0-3.7-4.9-7.3-4.9-3.7 0-3.7-7.8-7.3-7.8-3.7 0-3.7-1.1-7.3-1.1-3.7 0-3.7 3.1-7.3 3.1-3.7 0-3.7 10.9-7.3 10.9-3.7 0-3.7-12.5-7.3-12.5-3.7 0-3.7 4.6-7.3 4.6-3.7 0-3.7 4.5-7.3 4.5-3.7 0-3.7 3.6-7.3 3.6-3.7 0-3.7-10-7.3-10-3.7 0-3.7-0.4-7.3-0.4-3.7 0-3.7 2.3-7.3 2.3-3.7 0-3.7 7.1-7.3 7.1-3.7 0-3.7-11.2-7.3-11.2-3.7 0-3.7 3.5-7.3 3.5-3.7 0-3.7 3.6-7.3 3.6-3.7 0-3.7-2.9-7.3-2.9-3.7 0-3.7 8.4-7.3 8.4-3.7 0-3.7-14.6-7.3-14.6-3.7 0-3.7 5.8-7.3 5.8-2.2 0-3.8-0.4-5.5-1.5-1.8-1.1-1.8-2.9-2.9-4.8-1-1.8 1.9-2.7 1.9-4.8 0-3.4-2.1-3.4-2.1-6.8s-9.9-3.4-9.9-6.8 8-3.4 8-6.8c0-2.2 2.1-2.4 3.1-4.2 1.1-1.8 0.2-3.9 2-5 1.8-1 3.1-7.9 5.3-7.9 3.7 0 3.7 0.9 7.3 0.9 3.7 0 3.7 6.7 7.3 6.7 3.7 0 3.7-1.8 7.3-1.8 3.7 0 3.7-0.6 7.3-0.6 3.7 0 3.7-7.8 7.3-7.8h7.3c3.7 0 3.7 4.7 7.3 4.7 3.7 0 3.7-1.1 7.3-1.1 3.7 0 3.7 11.6 7.3 11.6 3.7 0 3.7-2.6 7.3-2.6 3.7 0 3.7-12.9 7.3-12.9 3.7 0 3.7 10.9 7.3 10.9 3.7 0 3.7 1.3 7.3 1.3 3.7 0 3.7-8.7 7.3-8.7 3.7 0 3.7 11.5 7.3 11.5 3.7 0 3.7-1.4 7.3-1.4 3.7 0 3.7-2.6 7.3-2.6 3.7 0 3.7-5.8 7.3-5.8 3.7 0 3.7-1.3 7.3-1.3 3.7 0 3.7 6.6 7.3 6.6s3.7-9.3 7.3-9.3c3.7 0 3.7 0.2 7.3 0.2 3.7 0 3.7 8.5 7.3 8.5 3.7 0 3.7 0.2 7.3 0.2 3.7 0 3.7-1.5 7.3-1.5 3.7 0 3.7 1.6 7.3 1.6s3.7-5.1 7.3-5.1c2.2 0 0.6 9.6 2.4 10.7s4.1-2 5.1-0.1c1 1.8 10.3 2.2 10.3 4.3 0 3.4-10.7 3.4-10.7 6.8s1.2 3.4 1.2 6.8 1.9 3.4 1.9 6.8c0 2.2 7.2 7.7 6.2 9.5-1.1 1.8-12.3-6.5-14.1-5.5-1.7 0.9-0.1 6.2-2.2 6.2z" fill="transparent" stroke="#fff"></path>
 <path class="voltage line-2" d="m216.3 52.1c-3 0-3-0.5-6-0.5s-3 3-6 3-3-2-6-2-3 1.6-6 1.6-3-0.4-6-0.4-3-1.2-6-1.2-3 3.4-6 3.4-3-2.2-6-2.2-3-3.4-6-3.4-3-0.5-6-0.5-3 1.4-6 1.4-3 4.8-6 4.8-3-5.5-6-5.5-3 2-6 2-3 2-6 2-3 1.6-6 1.6-3-4.4-6-4.4-3-0.2-6-0.2-3 1-6 1-3 3.1-6 3.1-3-4.9-6-4.9-3 1.5-6 1.5-3 1.6-6 1.6-3-1.3-6-1.3-3 3.7-6 3.7-3-6.4-6-6.4-3 2.5-6 2.5h-6c-3 0-3-0.6-6-0.6s-3-1.4-6-1.4-3 0.9-6 0.9-3 4.3-6 4.3-3-3.5-6-3.5c-2.2 0-3.4-1.3-5.2-2.3-1.8-1.1-3.6-1.5-4.6-3.3s-4.4-3.5-4.4-5.7c0-3.4 0.4-3.4 0.4-6.8s2.9-3.4 2.9-6.8-0.8-3.4-0.8-6.8c0-2.2 0.3-4.2 1.3-5.9 1.1-1.8 0.8-6.2 2.6-7.3 1.8-1 5.5-2 7.7-2 3 0 3 2 6 2s3-0.5 6-0.5 3 5.1 6 5.1 3-1.1 6-1.1 3-5.6 6-5.6 3 4.8 6 4.8 3 0.6 6 0.6 3-3.8 6-3.8 3 5.1 6 5.1 3-0.6 6-0.6 3-1.2 6-1.2 3-2.6 6-2.6 3-0.6 6-0.6 3 2.9 6 2.9 3-4.1 6-4.1 3 0.1 6 0.1 3 3.7 6 3.7 3 0.1 6 0.1 3-0.6 6-0.6 3 0.7 6 0.7 3-2.2 6-2.2 3 4.4 6 4.4 3-1.7 6-1.7 3-4 6-4 3 4.7 6 4.7 3-0.5 6-0.5 3-0.8 6-0.8 3-3.8 6-3.8 3 6.3 6 6.3 3-4.8 6-4.8 3 1.9 6 1.9 3-1.9 6-1.9 3 1.3 6 1.3c2.2 0 5-0.5 6.7 0.5 1.8 1.1 2.4 4 3.5 5.8 1 1.8 0.3 3.7 0.3 5.9 0 3.4 3.4 3.4 3.4 6.8s-3.3 3.4-3.3 6.8 4 3.4 4 6.8c0 2.2-6 2.7-7 4.4-1.1 1.8 1.1 6.7-0.7 7.7-1.6 0.8-4.7-1.1-6.8-1.1z" fill="transparent" stroke="#fff"></path>
</svg>
<div class="dots">
 <div class="dot dot-1"></div>
 <div class="dot dot-2"></div>
 <div class="dot dot-3"></div>
 <div class="dot dot-4"></div>
 <div class="dot dot-5"></div>
</div>
</div>
</div>
</div>
                  )}
                  <div className="row">
                    <div className="col">
                    <div className="rating-containerss">
    <p className="card-text rating-textss">عدد المقيمين بتقييم 10: %{selectedAnimedatail.tenRatingsCount}</p>
  </div>
  <div className="rating-containerss">
    <p className="card-text rating-textss">عدد المقيمين بتقييم 0: %{selectedAnimedatail.zeroRatingsCount}</p>
  </div>
  {/*
                      <button className="btn-md rounded my-button">مراجعات</button>
                      */}
                    </div>

                    
                    <div className="col">
  <div className="row">
    <div className="col">
      <div className="honor-points">
        <div className="circle-honor">
          <div>نقاط الشرف</div>
          <div>{selectedAnimedatail.honorPoints.toFixed(1)}</div>
        </div>
      </div>
      
    
    </div>
    
    <div className="col">
  <div className="review-section">
    <button className="btn-md rounded comm-button" type="button" onClick={() => this.handleExpandes(selectedAnimedatail._id)}>
      <strong>نشر تعليق علني</strong>
      <div id="container-stars">
    <div id="stars"></div>
  </div>
  

  <div id="glowx">
    <div class="circle"></div>
    <div class="circle"></div>
  </div>
    </button>

    {showExpandes && selectedAnimecomm && (

             <div className="expanded-overlayss">
    <div className="expanded-cardss">

<div className="modal-overlay">
<div className="modal-contents">
  <button className="close-button" onClick={this.handleExpandes}>×</button>
  <h5>نشر تعليق علني</h5>
     <div className="anime-info">
     <img src={selectedAnimecomm.imageUrl} alt={selectedAnimecomm.name} className="anime-image" />
    <span className="anime-name">{selectedAnimecomm.name}</span>
     </div>
    <input
    type="hidden"
   value={selectedAnimecomm.id}
     className="anime-id-input"
      />
    <textarea
        ref={this.textareaRef}
        value={commentText}
        onChange={this.handleCommentTextChange}
        maxLength="250"
        placeholder="اكتب تعليقك هنا (250 حرف كحد أقصى)"
        className="comment-input"
      ></textarea>
  <div className="comment-type-switch">
  
  <div class="checkboxtype">
  {commentType === 'critic' ? 'تعليقك انتقادي وفي حال فوزه سيخصم نقاط شرف' : 'تعليق مدح وفي حال فوزه سيزيد نقاط الشرف'}
           </div>
    <input id="checkbox_toggle" type="checkbox" class="check"  checked={commentType === 'critic'}
        onChange={this.handleCommentTypeChange}/>
      
<div class="checkbox">
  <label class="slide" for="checkbox_toggle">
    <label class="toggle" for="checkbox_toggle"></label>
    <label class="textt" for="checkbox_toggle"> {commentType === 'critic' ? 'انتقاد' : 'مدح'}</label>
    
    
   
  </label>
  
</div>
  </div>
  <div className="comment-info-tooltip">
    <span className="tooltip-icon">ℹ️</span>

    <div className="tooltip-text">مفهوم التلعيق العلني هو ان يتم نشر تعليق علني على بطاقه الانمي بحيث بطاقه الانمي تعرض تعليق واحد فقط بنفس الوقت ويتم انشاء طابور لاي تعليقات اخرى ويتم نشرهم تلقائيا وكل تعليق له مده ظهور معينه بالصفحه الرئيسيه  وبعدها يختفي ويهدف نظام التعليق العلني ان يستطيع متابع الانمي تسليط عيون الجمهور على رأيه وبناءا على عدد من يتفق معك او يختلف معك يؤثر تعليقك على ترتيب الانمي وبالطبع التعليقات العلنيه تظهر بالصفحه الرئيسيه لذلك تكون المشاهدات عاليه  وتستطيع نشر رأيك للعالم وهناك  3 باقات للتعليق العلني وكلما زادت باقتك زاد صدى صوت تعليقك </div>
  </div>
  <table className="comment-pricing-table">
    <thead>
      <tr>
      <th>ذهبي - 15 دولار
      <img src="https://i.imgur.com/NqQSvC2.png"  className="pricing-icon" />
      </th>
        <th>فضي - 3 دولار 
        <img src="https://i.imgur.com/ge7iVrg.png"  className="pricing-icon" />
        </th>
        <th>مجاني 
        <img src="https://i.imgur.com/dTiRNW3.jpg"  className="pricing-icon" />
        </th>
      </tr>
    </thead>
    <tbody>
    <tr>
            <td className="golden-column">
              <ol>
                <li>جميع مميزات الباقه الفضيه</li>
                <li>تم عرضه بالصفحه لمده 300 دقيقه</li>
                <li>يتم ارسال اشعار جماعي لجميع مستخدمين الموقع بنص تعليقك على هذا الانمي لحظه النشر وسيظهر الاشعار حتى للحسابات الجديده اللذي سيتم انشاءها من اي مستخدم مستقبلا </li>
                <li>اذا كان تعليقك مدحا ستتم زياده مباشر لحظه النشر بمقدار 3%+250 من نقاط الشرف لهذا الانمي في حال الانتقاد سيتم خصم نفس الكميه وبالطبع نقاط الشرف تؤثر في ترتيب الانمي</li>
              </ol>
            </td>
            <td className="silver-column">
              <ol>
                <li>جميع مميزات الباقه المجانيه</li>
                <li>من يمتلك عضويه الموقع يستطيع نشر تعليق فضي مجانا مره واحده يوميا</li>
                <li>يتم عرضه بالصفحه لمده 120 دقيقه</li>
                <li>اذا كان هناك اي تعليق مجاني نشط حاليا على هذا الانمي سيتم اسكاته ونشر تعليقك الفضي بااختصار التعليقات الفضيه والذهبيه لاتنتظر بالطابور وتتخطاه</li>
                <li>اذا كان تعليقك مدحا ستتم زياده مباشر لحظه النشر بمقدار 1%+100 من نقاط الشرف لهذا الانمي في حال الانتقاد سيتم خصم نفس الكميه وبالطبع نقاط الشرف تؤثر في ترتيب الانمي</li>
              </ol>
            </td>
            <td className="free-column">
              <ol>
                <li>يتم عرض التعليق المجاني على بطاقه الانمي بالصفحه الرئيسيه لمده 30 دقيقه</li>
                <li>ترتفع بطاقه الانمي اللذي علقت عليها لقمه الصفحه حتى يرى الناس تعليقك</li>
                <li>مسموح بعرض تعليق نشط واحد فقط على نفس الانمي واي تعليق اخر يتم نشره اثناء ذلك ينضم للطابور ويتم نشره تلقائيا حسب دوره</li>
                <li>نتائج التعليق العلني تؤثر في ترتيب الانمي وفي ترتيبك انت ايها المستخدم بين المحنكين</li>
                <li>بعد انتهاء 30 دقيقه سيأتيك اشعار بتفاصيل نتائج التعليق من عدد اتافاقات واختلافات وهل فاز تعليقك ام خسر ومامقدر تأثيره على مرتبه هذا الانمي ومقدار التأثير يعتمد على عدد الاتفاقات والاختلافات الخاصه بتعليقك</li>
              </ol>
            </td>
          </tr>
      <tr>
        <td>
          
       
          <button onClick={this.openCheckoutgolden}>الدفع لنشر تعليق ذهبي <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-credit-card-fill" viewBox="0 0 16 16">
  <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v1H0zm0 3v5a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7zm3 2h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-1a1 1 0 0 1 1-1"/>
</svg></button>
          <div className="paypal">
          <PayPalScriptProvider options={initialOptions}>
        <PayPalButtons
          style={{
            shape: "rect",
            layout: "vertical",
          }}
          createOrder={this.createOrdergolden} // استدعاء الدالة الخارجية هنا
          onApprove={async (data, actions) => {
            try {
              const response = await fetch(
                `/api/orders/${data.orderID}/capture`,
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                },
              );
            
              const orderData = await response.json();
              // Three cases to handle:
              //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
              //   (2) Other non-recoverable errors -> Show a failure message
              //   (3) Successful transaction -> Show confirmation or thank you message

              const errorDetail = orderData?.details?.[0];

              if (errorDetail?.issue === "INSTRUMENT_DECLINED") {
                // (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                // recoverable state, per https://developer.paypal.com/docs/checkout/standard/customize/handle-funding-failures/
                return actions.restart();
              } else if (errorDetail) {
                // (2) Other non-recoverable errors -> Show a failure message
                throw new Error(
                  `${errorDetail.description} (${orderData.debug_id})`,
                );
              } else {
                // (3) Successful transaction -> Show confirmation or thank you message
        
      this.submitCommentgoldenpaypal(); // استدعاء الدالة هنا
    
                // Or go to another URL:  actions.redirect('thank_you.html');
                const transaction = orderData.purchase_units[0].payments.captures[0];
                alert(`Transaction ${transaction.status}: ${transaction.id}`);
                
                console.log(
                  "Capture result",
                  orderData,
                  JSON.stringify(orderData, null, 2),
                );
              }
            } catch (error) {
              console.error(error);
              alert(`Sorry, your transaction could not be processed...${error}`);

            }
          }}
        />
      </PayPalScriptProvider>
      </div>
        </td>
        <td>
          <button onClick={this.openCheckoutsilver} >الدفع لنشر تعليق فضي<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-credit-card-fill" viewBox="0 0 16 16">
  <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v1H0zm0 3v5a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7zm3 2h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-1a1 1 0 0 1 1-1"/>
</svg></button>
          <div className="paypal">
          <PayPalScriptProvider options={initialOptions}>
        <PayPalButtons
          style={{
            shape: "rect",
            layout: "vertical",
          }}
          createOrder={this.createOrdersilver} // استدعاء الدالة الخارجية هنا
          onApprove={async (data, actions) => {
            try {
              const response = await fetch(
                `/api/orders/${data.orderID}/capture`,
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                },
              );
            
              const orderData = await response.json();
              // Three cases to handle:
              //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
              //   (2) Other non-recoverable errors -> Show a failure message
              //   (3) Successful transaction -> Show confirmation or thank you message

              const errorDetail = orderData?.details?.[0];

              if (errorDetail?.issue === "INSTRUMENT_DECLINED") {
                // (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                // recoverable state, per https://developer.paypal.com/docs/checkout/standard/customize/handle-funding-failures/
                return actions.restart();
              } else if (errorDetail) {
                // (2) Other non-recoverable errors -> Show a failure message
                throw new Error(
                  `${errorDetail.description} (${orderData.debug_id})`,
                );
              } else {
                // (3) Successful transaction -> Show confirmation or thank you message
        
      this.submitCommentsilverpaypal(); // استدعاء الدالة هنا
    
                // Or go to another URL:  actions.redirect('thank_you.html');
                const transaction = orderData.purchase_units[0].payments.captures[0];
                alert(`Transaction ${transaction.status}: ${transaction.id}`);
                
                console.log(
                  "Capture result",
                  orderData,
                  JSON.stringify(orderData, null, 2),
                );
              }
            } catch (error) {
              console.error(error);
              alert(`Sorry, your transaction could not be processed...${error}`);

            }
          }}
        />
      </PayPalScriptProvider>
      </div>
        </td>
        <td>
        <button onClick={this.submitComment}>نشر تعليق مجاني</button>
        </td>
      </tr>
    </tbody>
  </table>
</div>
</div>
</div>
</div>
)}


    </div>
    </div>


  </div>

  
  </div>
                    <div className="col">
             
                    <div className="col">
 
  <div className="button-row">
  <button className="btn-md rounded button-style" onClick={() => this.handleExpandexranktweet(selectedAnimedatail._id)}>ترتيب مغردين تويتر</button>
    {showExpandexranktweet && selectedAnimecomm && selectedAnimecomm._id === selectedAnimedatail._id && (

<div className="expanded-overlayssx">
<div className="expanded-cardssxtweet">

<button className="close-button" onClick={this.handleExpandexranktweet}>×</button>
  
     <div className="anime-info">
     <img src={selectedAnimecomm.imageUrl} alt={selectedAnimecomm.name} className="anime-imagex" />
    <span className="anime-name">{selectedAnimecomm.name}</span>
     </div>

     <div className="comment-info-tooltip">
    <span className="tooltip-icon">ℹ️</span>

    <div className="tooltip-text">هناك جائزه وعقاب اولا المغرد الذهبي هي جائزه للمغرد صاحب المرتبه الاولى واللذي استطاع ان يصنع محتوى جيد يجعل الناس تصوت له لهذا الانمي واستطاع ان يثبت انه محنك بينما المغرد المطلوب للعداله يكون هو الاسوأ بين توب 6 مغردين  بااختصار هو الخاسر في منافسه الافضل  </div>
  </div>
     <div className="tweetlist">
                      <button className="addwithtweeter" onClick={this.handleButtonClicktweet}>اضف نفسك لقائمه مغردين هذا الانمي
                      <span>
                       
                      <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 24 24">
<path d="M10.053,7.988l5.631,8.024h-1.497L8.566,7.988H10.053z M21,6v12	c0,1.657-1.343,3-3,3H6c-1.657,0-3-1.343-3-3V6c0-1.657,1.343-3,3-3h12C19.657,3,21,4.343,21,6z M17.538,17l-4.186-5.99L16.774,7	h-1.311l-2.704,3.16L10.552,7H6.702l3.941,5.633L6.906,17h1.333l3.001-3.516L13.698,17H17.538z"></path>
</svg>
   </span>
                      </button>
                      {(goldenTweeterData || justiceTweeterData) && (
  <div className="tweeter-cards-container">
    {goldenTweeterData && (
      <div className="tweeter-cardx">
        <div className="golden-tweeter">
          <h5>المغرد الذهبي</h5>
          <img className="profile-imagetwtx" src={goldenTweeterData.twitterProfileImage} alt={goldenTweeterData.twitterUsername} onClick={() => window.open(goldenTweeterData.twitteraccountlink, '_blank')} />
          <p>"{goldenTweeterData.twitterUsername}"</p>
          <p1>عدد التصويتات: {goldenTweeterData.voteCount}</p1>
        </div>
      </div>
    )}
    {justiceTweeterData && (
      <div className="tweeter-cardx">
        <div className="justice-tweeter">
          <h5>المغرد المطلوب للعدالة</h5>
          <img className="profile-imagetwtx" src={justiceTweeterData.twitterProfileImage} alt={justiceTweeterData.twitterUsername} onClick={() => window.open(justiceTweeterData.twitteraccountlink, '_blank')} />
          <p>"{justiceTweeterData.twitterUsername}"</p>
         {/* <p >عدد التصويتات: {justiceTweeterData.voteCount} </p>*/}
        </div>
      </div>
    )}
  </div>
)}
                      {loggedTweeterData && (
                        <div className="tweeter-card" style={{ backgroundImage: `url(${loggedTweeterData.profile_banner_url})` }}>
                          <img src={loggedTweeterData.twitterProfileImage} alt={loggedTweeterData.twitterUsername} className="profile-imagetwt" />
                          <div className="tweeter-info">
                            <span className="tweeter-name">{loggedTweeterData.twitterUsername}</span>
                            <span className="number-badge"> {loggedTweeterData.rankingtwitter.currentRank}</span>
                          </div>
                          <div className="tweeter-buttons">
                            <button onClick={() => this.handleVoteTweeter(loggedTweeterData._id)}>تصويت</button>
                            <button id={`copy-button-${loggedTweeterData._id}`} className="vote-buttoncopy" onClick={() => this.generateVoteLink(loggedTweeterData._id)}>نسخ رابط التصويت</button>
                            <button onClick={() => window.open(loggedTweeterData.twitteraccountlink, '_blank')}>الانتقال لحساب تويتر</button>
                            <button
  onClick={() => window.open(`/profile?username=${loggedTweeterData.siteUsername}`, '_blank', 'noopener,noreferrer')}
>
   اذهب لبروفايل واستكشف ذوقه
  </button>
                          </div>
                        </div>
                      )}
                      <hr />
                      {tweetersData.length === 0 ? (
  <div className="listloadingxt">
    <p>لا يوجد مغردين مسجلين لهذا الانمي مارأيك ان تكون اولهم؟</p>
  </div>
) : (
  <InfiniteScroll
    dataLength={tweetersData.length}
    next={this.fetchMoreTweeters}
    hasMore={hasMoretweeters}
    loader={
      <div className="listloadingx">
        <p>جاري التحميل...</p>
        <div className="loader"></div>
      </div>
    }
  >
    {tweetersData.map((tweeter, index) => (
      <div key={tweeter._id} className="tweeter-card" style={{ backgroundImage: `url(${tweeter.profile_banner_url})` }}>
        <div className="number-badge">{index + 1}</div>
        <img src={tweeter.twitterProfileImage} alt={tweeter.twitterUsername} className="profile-imagetwt" />
        <div className="tweeter-info">
          <span className="tweeter-name">{tweeter.twitterUsername}</span>
        </div>
        <div className="tweeter-buttons">
        <button
                className={this.getButtonClassvote(tweeter._id)}
                onClick={() => this.handleVoteTweeter(tweeter._id)}
              >
                تصويت
              </button>
          <button id={`copy-button-${tweeter._id}`} className="vote-buttoncopy" onClick={() => this.generateVoteLink(tweeter._id)}>نسخ رابط التصويت</button>
          <button onClick={() => window.open(tweeter.twitteraccountlink, '_blank')}>الانتقال لحساب تويتر</button>
          <button
  onClick={() => window.open(`/profile?username=${tweeter.siteUsername}`, '_blank', 'noopener,noreferrer')}
>
   اذهب لبروفايل واستكشف ذوقه
  </button>
        </div>
      </div>
    ))}
  </InfiniteScroll>
)}
     </div>
     </div>
     </div>
    )}
    {/*
    <button className="btn-md rounded button-style">افضل كوليكشن</button>
   */}
  </div>
  <div className="button-row">
  <Link to={`/watchwithfriend/${selectedAnimedatail._id}/${selectedAnimedatail.slug}`}  className="btn-md rounded button-style"  style={{ color: 'white', textDecoration: 'none' }} >
   شاهد انمي مع رفيق
            </Link>
            <button className="btn-md rounded button-style" onClick={() => this.handleExpandexrank(selectedAnimedatail._id)}>تفاصيل المرتبة</button>
    {showExpandexrank && selectedAnimecomm &&  (

<div className="expanded-overlayssx">
<div className="expanded-cardssxrank">

<button className="close-button" onClick={this.handleExpandexrank}>×</button>
  
     <div className="anime-info">
     <img src={selectedAnimecomm.imageUrl} alt={selectedAnimecomm.name} className="anime-imagex" />
    <span className="anime-name">{selectedAnimecomm.name}</span>
     </div>
   
     <div className="ranking-info">
  
  
                    <div className="ranking-row">
                      <span className="ranking-label">المرتبة الحالية:</span>
                      <span className="ranking-value">{selectedAnimecomm.ranking.currentRank}</span>
                    </div>
                    <div className="ranking-row">
                      <span className="ranking-label">المرتبة السابقة:</span>
                      <span className="ranking-value">{selectedAnimecomm.ranking.previousRank}</span>
                    </div>
                    <div className="ranking-row">
                      <span className="ranking-label">آخر تغيير في المرتبة:</span>
                      <span className="ranking-value">
                        {new Date(selectedAnimecomm.ranking.lastchangeinRank).toLocaleDateString('ar-EG', {
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric'
                        })}
                      </span>
                    </div>
                  </div>
                


                </div>
              </div>
            )}
            {/*
    <button className="btn-md rounded button-style">منتجات</button>
    */}
  </div>
</div>

</div>
</div>
</div>

{selectedAnimedatail.activeComment &&  (
<div className="col">
  <div className="row2">
  <div className={`comment-card card ${selectedAnimedatail.activeComment.commentType === 'critic' ? 'critic-comment' : 'praiser-comment'}`} style={this.getCommentStyle(selectedAnimedatail.activeComment.commentPrice)}>
                    <div className="glow-bar">
                    <div className="card-body">
                      <div className="row">
                      <div className="col-auto">
  <img
    src={selectedAnimedatail.activeComment.userAvatar.startsWith('http') ? selectedAnimedatail.activeComment.userAvatar : `/${selectedAnimedatail.activeComment.userAvatar.replace(/\\/g, '/')}`}
    alt="User Avatar"
    className="user-avatar"
    style={avatarStyle}
  />
</div>
                        <div className="col">
                          <div className="row">
                            <div className="col-auto">
                              <span className="user-name">{selectedAnimedatail.activeComment.userName}</span>
                            </div>
                            <div className="col-auto">
                              <span className={`comment-price ${selectedAnimedatail.activeComment.commentPrice}`}>{selectedAnimedatail.activeComment.commentPrice}</span>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col">
                              <div className="comment-text">{selectedAnimedatail.activeComment.commentText}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                        <button
                  className={this.getButtonClass(selectedAnimedatail.activeComment, userId, 'like')}
                  onClick={() => this.likeComment(selectedAnimedatail._id, selectedAnimedatail.activeComment._id)}
                >
                                  <span className="likes-count">{selectedAnimedatail.activeComment.likesCount}  <svg viewBox="0 0 50 50" version="1.1" xmlns="http://www.w3.org/2000/svg" width="19" height="19" class="icon">
    <path d="M 24.10 6.29 Q 28.34 7.56 28.00 12.00 Q 27.56 15.10 27.13 18.19 A 0.45 0.45 4.5 0 0 27.57 18.70 Q 33.16 18.79 38.75 18.75 Q 42.13 18.97 43.23 21.45 Q 43.91 22.98 43.27 26.05 Q 40.33 40.08 40.19 40.44 Q 38.85 43.75 35.50 43.75 Q 21.75 43.75 7.29 43.75 A 1.03 1.02 0.0 0 1 6.26 42.73 L 6.42 19.43 A 0.54 0.51 -89.4 0 1 6.93 18.90 L 14.74 18.79 A 2.52 2.31 11.6 0 0 16.91 17.49 L 22.04 7.17 A 1.74 1.73 21.6 0 1 24.10 6.29 Z M 21.92 14.42 Q 20.76 16.58 19.74 18.79 Q 18.74 20.93 18.72 23.43 Q 18.65 31.75 18.92 40.06 A 0.52 0.52 88.9 0 0 19.44 40.56 L 35.51 40.50 A 1.87 1.83 5.9 0 0 37.33 39.05 L 40.51 23.94 Q 40.92 22.03 38.96 21.97 L 23.95 21.57 A 0.49 0.47 2.8 0 1 23.47 21.06 Q 23.76 17.64 25.00 12.00 Q 25.58 9.36 24.28 10.12 Q 23.80 10.40 23.50 11.09 Q 22.79 12.80 21.92 14.42 Z M 15.57 22.41 A 0.62 0.62 0 0 0 14.95 21.79 L 10.01 21.79 A 0.62 0.62 0 0 0 9.39 22.41 L 9.39 40.07 A 0.62 0.62 0 0 0 10.01 40.69 L 14.95 40.69 A 0.62 0.62 0 0 0 15.57 40.07 L 15.57 22.41 Z" fill-opacity="1.000"></path>
    <circle r="1.51" cy="37.50" cx="12.49" fillOpacity="1.000"></circle>
    </svg> أتفق</span>
                                </button>
                                <button
                  className={this.getButtonClass(selectedAnimedatail.activeComment, userId, 'dislike')}
                  onClick={() => this.dislikeComment(selectedAnimedatail._id, selectedAnimedatail.activeComment._id)}
                >
                                  <span className="dislikes-count">{selectedAnimedatail.activeComment.dislikesCount} <svg viewBox="0 0 50 50" version="1.1" xmlns="http://www.w3.org/2000/svg" width="19" height="19" class="icon" transform="rotate(180 0 0)">
    <path d="M 24.10 6.29 Q 28.34 7.56 28.00 12.00 Q 27.56 15.10 27.13 18.19 A 0.45 0.45 4.5 0 0 27.57 18.70 Q 33.16 18.79 38.75 18.75 Q 42.13 18.97 43.23 21.45 Q 43.91 22.98 43.27 26.05 Q 40.33 40.08 40.19 40.44 Q 38.85 43.75 35.50 43.75 Q 21.75 43.75 7.29 43.75 A 1.03 1.02 0.0 0 1 6.26 42.73 L 6.42 19.43 A 0.54 0.51 -89.4 0 1 6.93 18.90 L 14.74 18.79 A 2.52 2.31 11.6 0 0 16.91 17.49 L 22.04 7.17 A 1.74 1.73 21.6 0 1 24.10 6.29 Z M 21.92 14.42 Q 20.76 16.58 19.74 18.79 Q 18.74 20.93 18.72 23.43 Q 18.65 31.75 18.92 40.06 A 0.52 0.52 88.9 0 0 19.44 40.56 L 35.51 40.50 A 1.87 1.83 5.9 0 0 37.33 39.05 L 40.51 23.94 Q 40.92 22.03 38.96 21.97 L 23.95 21.57 A 0.49 0.47 2.8 0 1 23.47 21.06 Q 23.76 17.64 25.00 12.00 Q 25.58 9.36 24.28 10.12 Q 23.80 10.40 23.50 11.09 Q 22.79 12.80 21.92 14.42 Z M 15.57 22.41 A 0.62 0.62 0 0 0 14.95 21.79 L 10.01 21.79 A 0.62 0.62 0 0 0 9.39 22.41 L 9.39 40.07 A 0.62 0.62 0 0 0 10.01 40.69 L 14.95 40.69 A 0.62 0.62 0 0 0 15.57 40.07 L 15.57 22.41 Z" fill-opacity="1.000"></path>
    <circle r="1.51" cy="37.50" cx="12.49" fill-opacity="1.000"></circle>
</svg> أختلف</span>
                                </button>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <div className="comment-type-container">
                            <div className={`comment-type ${selectedAnimedatail.activeComment.commentType === 'critic' ? 'critic-comment' : 'praiser-comment'}`}>
                              {selectedAnimedatail.activeComment.commentType === 'critic' ? 'تعليق انتقادي' : 'تعليق مدح'}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <div className="comment-notification" style={{ fontSize: '12px', textShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', padding: '4px', marginTop: 'auto', marginBottom: '0', textAlign: 'center' }}>
                              {selectedAnimedatail.activeComment.commentType === 'critic' ? 'سيتم خصم نقاط شرف من هذا الانمي إذا كان عدد الاتفافات أكبر من عدد الاختلافات لهذا التعليق' : 'سيتم زيادة نقاط شرف هذا الانمي إذا كان عدد الاتفاقات أكثر من عدد الاختلافات لهذا التعليق'}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <div className="remaining-time">
                              الوقت المتبقي: {selectedAnimedatail.activeComment.remainingTime} دقيقة
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    </div>
                  </div>
  </div>
</div>
 )}
</div>

</div>



)}
</div>






</div>






          
          
         
        
       
        <style dangerouslySetInnerHTML={{__html: "\n      /* تحديد خلفية الموقع */\n    body {\n        \n      background: linear-gradient(to right, #ffffff , #fcfcfc 0%);\n  }\n\n    " }} />
      

        
        </div>
        </div> 
         </HelmetProvider>
    );
  }
}





export default withParams(AnimeDetailPage);


