import logo from './logo14400.png';
import ErrorPage from "./ErrorPage.js";
import CharPage from './char.js';
import WeekEp from './weekep.js';
import AnimePage from "./anime.js";
import CharPageweek from "./charweek.js"
import Login from './login.js';
import FavPage from "./fav.js";
import RatePage from "./rate.js";
import FavPagee from "./favv.js";

import HatePagee from "./hatee.js";
import HatePage from "./hate.js";
import WaifuPage from "./waifu.js";

import RatePagee from "./ratee.js";
import Badgegived from "./Badgegived.js";
import Badgereceive from "./Badgereceive.js";
import Badgegivedd from "./‏‏Badgegivedd.js";
import Badgereceivee from "./‏‏Badgereceivee.js";
import Posts from "./posts.js";
import Memories from "./memories.js";
import Memoriess from "./memoriess.js";

//import Signup from './signup.js';
//import Button from './button.js';
import './components/profile.css';

//import { anime } from "../../backend/server.js"; // import Anime model from animeModel.js


import axios from 'axios';
import './assets/css/bootstrap.min.css'; // استيراد ملف CSS من Bootstrap
import 'animate.css/animate.min.css';
//import './assets/js/bootstrap.bundle.js';
//import bootstrap from 'bootstrap';




import React, { useEffect, useState } from 'react';
import api from './api';
import { SocialIcon } from "react-social-icons";
import ReactDOM from 'react-dom';
import {BrowserRouter as Router, Route, Link, Routes, Navigate,} from 'react-router-dom';
import Cookies from 'js-cookie';
//import { BrowserRouter, Route, Switch } from "react-router-dom";
//import { Button } from 'bootstrap';
//import { Offcanvas } from 'bootstrap';
import { Navbar, Offcanvas, Card, Button, CardDeck, CardGroup, CardImg, Dropdown} from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { keyframes } from "styled-components";
//import keyframe from 'keyframe';
//import { Animated, Easing, keyframe } from 'react-native';
//import LottieView from 'lottie-react-native';
import {
  useScene,
  useSceneItem,
  useFrame,
  useNowFrame,
} from "react-scenejs";
import { Animate, AnimateKeyframes, AnimateGroup } from "react-simple-animate";



//import { Offcanvas } from 'react-bootstrap';

import _, { size } from 'lodash';
//import Switch from "react-switch";
import { Tabs, Tab, Box, TabPanel, styled } from '@mui/material';


//import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
//import 'react-tabs/style/react-tabs.css';








class Profilepage extends React.Component {

  constructor(props) {
    super(props);
    // نضع قيمة الحالة الأولية في this.state
    this.state = {
      showBottom: false,
      showModal: false,
      message: '',
      animeData: [], // an empty array to store the anime data from the database
      userdata: "",
      isMobile: window.innerWidth <= 991, // تعيين القيمة الافتراضية للموبايل
      isActive: true,
      value: 0,
      
      
      redirectToNewPage: false,
      showExpanded: false,

      showExpandedd: false,

      showExpandeddd: false,

      showExpandedddd: false,

      showExpandeddddd: false,

      showExpandedddddd: false,
      
      showExpandes: false,
      
      isLoggedIn: false,
     
      menuOpen: false,
      token: "", // قيمة الرمز
      //username: "", // اسم المستخدم
      profileImage: "", // صورة الملف الشخصي 
      //Cookies: "",
      redirect: false,

      // تعيين الحالة query إلى فارغة
      query: '',
      // تعيين الحالة results إلى مصفوفة فارغة
      results: [],
      
      profileId: null, // الحالة التي تحتوي على معرف الملف الشخصي الذي يتم عرضه في الصفحة
      
      isOwnProfile: false,
      
      ratedCharacters: [], // مصفوفة فارغة لتخزين الشخصيات المقيمة
      resultss: [],
      selectedImage: null,
      selectedGenre: "قم بتقييم هذا المحنك", // التصنيف المختار
      rating: "", // لا يوجد تقييم سابق
  ratingAverage: 1, // متوسط التقييم هو 8.5
  ratingCount: 0, // عدد المستخدمين الذين قاموا بالتقييم هو 12
  socialLink: "",
  socialName: "",
  socialLinks: "",
  updatedSocialLinks:"",
  number: 1,
  title: "",

  currentbadges: "", // البيانات الخاصة بالأوسمة الشرفية
  error: "", // الخطأ الذي قد يحدث أثناء الحصول على البيانات
      loading: true // الحالة التي تشير إلى ما إذا كانت البيانات قيد التحميل أم لا
  
    };

    this.handleChangevalue = this.handleChangevalue.bind(this);
    this.fileInput = React.createRef();
    this.rateUserr = this.rateUserr.bind(this);
    this.handleSocial = this.handleSocial.bind(this);
    this.handleChangesocial = this.handleChangesocial.bind(this);
    this.fileInputBackground = React.createRef();
this.fileInputAvatar = React.createRef();

    
  }




  

  async calculateRankings() {
    try {
      // إنشاء طلب GET إلى المسار /calculate
      const response = await fetch("/calculate");
      // التحقق من حالة الاستجابة
      if (response.ok) {
        // تحويل الاستجابة إلى نص
        const message = await response.text();
        // طباعة الرسالة في وحدة التحكم
        console.log(message);
      } else {
        // طباعة رسالة الخطأ في وحدة التحكم
        console.error("حدث خطأ في الاستجابة");
      }
    } catch (err) {
      // التعامل مع الخطأ في الطلب
      console.error(err);
    }
  }


  


  rateUserr(event) {

    const selectedGenre = event.currentTarget.getAttribute('value');
  
    // الحصول على قيمة التقييم من العنصر الذي تم النقر عليه
    const rating = event.currentTarget.getAttribute('value'); // الحصول على القيمة كرقم
    this.setState({ selectedGenre:rating });
    
    // الحصول على معرف المستخدم الحالي من الكوكيز أو الهيدر
    const userId = Cookies.get("userId") || localStorage.getItem("userId");
    const searchParams = new URLSearchParams(window.location.search);
    // الحصول على اسم المستخدم الذي يتم تقييمه من رابط الصفحة الحالية
    const username = searchParams.get("username");
    // إرسال طلب post إلى المسار الخاص بالتقييم في الباك اند
    fetch(`/rating/${username}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        headers: {'userId': this.props.userId},
      },
      body: JSON.stringify({ rating: rating }) // إرسال قيمة التقييم في الجسم
    })
      .then((response) => response.json()) // تحويل الاستجابة إلى JSON
      .then((data) => {
        // التحقق من حالة الاستجابة ورسالتها
        if (data.status === 200) {
          // إظهار رسالة تأكيد بنجاح التقييم
          alert(data.message);
          // تحديث قيمة التقييم ومتوسط التقييم وعدد المقيمين في الصفحة
          this.setState({
            rating: data.rating,
            ratingAverage: data.ratingAverage,
            ratingCount: data.ratingCount
          });
        } else {
          // إظهار رسالة خطأ بفشل التقييم
          alert(data.message);
        }
      })
      .catch((error) => {
        // إظهار رسالة خطأ بوجود مشكلة في الخادم
        alert("هناك مشكلة في الخادم، يرجى المحاولة لاحقا");
      });
  }
  

  getRatingFromDatabase() {
    // الحصول على معرف المستخدم الحالي من الكوكيز أو الهيدر
    const userId = Cookies.get("userId") || localStorage.getItem("userId");
    const searchParams = new URLSearchParams(window.location.search);
    // الحصول على اسم المستخدم الذي يتم تقييمه من رابط الصفحة الحالية
    const username = searchParams.get("username");
    // إرسال طلب get إلى المسار الخاص بالتقييم في الباك اند
    fetch(`/ratingg/${username}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        headers: { userId: this.props.userId }
      }
    })
      .then((response) => response.json()) // تحويل الاستجابة إلى JSON
      .then((data) => {
        // التحقق من حالة الاستجابة ورسالتها
        if (data.status === 200) {
          // تحديث قيمة التقييم المسجلة في الحالة
          this.setState({ selectedGenre: data.rating });
        } else {
          // إظهار رسالة خطأ بفشل الحصول على التقييم
          console.error(data.message);
        }
      })
      .catch((error) => {
        // إظهار رسالة خطأ بوجود مشكلة في الخادم
        console.error("هناك مشكلة في الخادم، يرجى المحاولة لاحقا");
      });
  }
  




  // دالة للتعامل مع حدث تغيير قيمة الإدخال
handleImageChange = (e) => {
  // تحديد الصورة المختارة
  const image = e.target.files[0];
  // تغيير قيمة الحالة بالصورة المختارة
  this.setState({selectedImage: image}, () => {
    // استدعاء الدالة handleImageSubmit بعد تغيير الحالة
    this.handleImageSubmit(e);
  });
}

// دالة لإرسال الطلب POST إلى المسار /updateImage
handleImageSubmit = (e) => {
  // منع السلوك الافتراضي للحدث
  e.preventDefault();
  // إنشاء نموذج بيانات يحتوي على الصورة المختارة
  const formData = new FormData();
  formData.append('image', this.state.selectedImage);
  // تحديد الخيارات اللازمة للطلب
  const requestOptions = {
    method: 'POST',
    // إرسال الرقم التعريفي للمستخدم في الرأس
    headers: {'userId': this.props.userId},
    body: formData
  };
  // إرسال الطلب باستخدام الدالة fetch
  fetch('/updateImage', requestOptions)
    .then(response => response.json())
    .then(data => {
      // التعامل مع الاستجابة
      // يمكنك عرض البيانات المحدثة أو رسالة النجاح
      // استبدال console.log ب alert
      alert(data.message);
      // تغيير قيمة الحالة التي تحتوي على رابط الصورة بالرابط الجديد
      this.setState({imageURI: data.imageURI});
    })
    .catch(error => {
      // التعامل مع الخطأ
      // يمكنك عرض رسالة الخطأ أو التنبيه
      // استبدال console.error ب alert
      alert(error);
    });
}








   // تعريف الدالة التي تستدعي المسار
   getRatedCharacters = () => {
    // الحصول على معرف المستخدم من الكوكيز أو الذاكرة المحلية
    const userId = Cookies.get("userId") || localStorage.getItem("userId");
    // إرسال طلب get إلى المسار مع معرف المستخدم في الرأس
    axios
      .get("/rated", {
        headers: { userId: userId },
      })
      .then((response) => {
        // تحديث الحالة بالمستندات الموجودة
        this.setState({ ratedCharacters: response.data });
      })
      .catch((error) => {
        // التعامل مع الخطأ
        console.error(error);
      });
  };

  handleChangevalue(event, newValue) {
    this.setState({ value: newValue });
  }



  // تعريف دالة handleChange للمكون
  handleChange = (event) => {
    // تحديث قيمة الحالة query بقيمة الحدث
    this.setState({ query: event.target.value });
    // استدعاء دالة searchCharacters مع قيمة الحدث
    this.searchCharacters(event.target.value);
  };

 // تعريف دالة addFavorite للمكون
addFavorite = (name, imageUrl) => {
  // إنشاء كائن معطيات يحتوي على اسم الشخصية وصورتها
  const data = { name: name, imageUrl: imageUrl };
  // إرسال طلب POST إلى المسار '/addFavorite' مع الكائن data
  axios
    .post('/addFavorite', data)
    .then((res) => {
      // إذا تم الطلب بنجاح، إظهار رسالة الاستجابة
      alert(res.data.message);
      // تحديث الحالة userdata بالشخصية المضافة إذا كانت موجودة في الاستجابة
      if (res.data.character) {
        this.setState((prevState) => ({
          userdata: {
            ...prevState.userdata,
            favoriteCharacters: [
              ...prevState.userdata.favoriteCharacters,
              res.data.character,
            ],
          },
        }));
      }
    })
    .catch((err) => {
      // إذا حدث خطأ، إظهار رسالة الخطأ
      alert(err.response.data.message);
    });
};




// تعريف دالة deleteFavorite للمكون
deleteFavorite = (name) => {
  // إنشاء كائن معطيات يحتوي على اسم الشخصية ومعرف المستخدم
  const data = { name: name, userId: this.state.userdata._id };
  // إرسال طلب DELETE إلى المسار '/deleteFavorite' مع الكائن data
  axios
    .delete('/deleteFavorite', { data: data })
    .then((res) => {
      // إذا تم الطلب بنجاح، إظهار رسالة الاستجابة
      alert(res.data.message);
      // تحديث الحالة userdata بحذف الشخصية من المصفوفة
      this.setState((prevState) => ({
        userdata: {
          ...prevState.userdata,
          favoriteCharacters: prevState.userdata.favoriteCharacters.filter(
            (char) => char.name !== name
          ),
        },
      }));
    })
    .catch((err) => {
      // إذا حدث خطأ، إظهار رسالة الخطأ
      alert(err.response.data.message);
    });
};

  // تعريف دالة searchCharacters للمكون
  searchCharacters = (query) => {
    // إرسال طلب GET إلى المسار '/search' مع استعلام البحث كمعامل
    axios
      .get('/search', { params: { q: query } })
      .then((res) => {
        // إذا تم الطلب بنجاح، تحديث الحالة بالنتائج
        this.setState({ results: res.data });
      })
      .catch((err) => {
        // إذا حدث خطأ، إظهار رسالة الخطأ
        alert(err.response.data.message);
      });
  };

   // تعريف دالة hideSuggestions للمكون
   hideSuggestions = () => {
    // تغيير قيمة الحالة results إلى مصفوفة فارغة
    this.setState({ results: [] });
  };

  // داخل الكلاس الخاصة بمكونك
handleExpand = () => {
  this.setState((prevState) => ({
    showExpanded: !prevState.showExpanded,
  }));
};

 // داخل الكلاس الخاصة بمكونك
 handleExpandd = () => {
  this.setState((prevState) => ({
    showExpandedd: !prevState.showExpandedd,
  }));
};


// داخل الكلاس الخاصة بمكونك
handleExpanddd = () => {
  this.setState((prevState) => ({
    showExpandeddd: !prevState.showExpandeddd,
  }));
};

// داخل الكلاس الخاصة بمكونك
handleExpandddd = () => {
  this.setState((prevState) => ({
    showExpandedddd: !prevState.showExpandedddd,
  }));
};

// داخل الكلاس الخاصة بمكونك
handleExpanddddd = () => {
  this.setState((prevState) => ({
    showExpandeddddd: !prevState.showExpandeddddd,
  }));
};

// داخل الكلاس الخاصة بمكونك
handleExpandddddd = () => {
  this.setState((prevState) => ({
    showExpandedddddd: !prevState.showExpandedddddd,
  }));
};

handleExpandes = () => {
  this.setState((prevState) => ({
    showExpandes: !prevState.showExpandes,
  }));
};
  // دالة لجلب بيانات الأنمي من المسار /anime
  fetchData = async () => {
    try {
      const response = await axios.get('/anime');
      this.setState({ animeData: response.data });
    } catch (error) {
      console.error(error);
    }
  };

  // تعريف الدالة في مكون الصفحة الشخصية
getUserData = () => {
  // الحصول على اسم المستخدم من الرابط
  const searchParams = new URLSearchParams(window.location.search);
  const username = searchParams.get("username");


  const currentUser = Cookies.get("username") || localStorage.getItem("username"); // الحصول على اسم المستخدم الحالي من الكوكيز أو الذاكرة المحلية
const isOwnProfile = username === currentUser; // مقارنة الاسمين
this.setState({ isOwnProfile: isOwnProfile }); // تحديث الحالة بنتيجة المقارنة

  // إرسال طلب get إلى الواجهة البرمجية مع اسم المستخدم
  axios
    .get(`/profile/${username}`)
    .then((response) => {
      // تغيير الحالة ببيانات المستخدم
      this.setState({ userdata: response.data });
    })
    .catch((error) => {
      // التعامل مع الخطأ
      console.error(error);
      // هنا يمكنك تحديد الحالة التي تريد التعامل معها، مثل 404 أو غيرها
      if (error.response.status === 404) {
        // هنا يمكنك تحديد الإجراء الذي تريد اتخاذه، مثل إعادة التوجيه أو عرض رسالة
        this.setState({ redirect: true,  });
        return (
          <ErrorPage
            message="الرابط ربما يكون خاطئًا أو المستخدم غير موجود"
            // يجب أن تحدد النمط الذي تريد تطبيقه على الرسالة ككائن يحتوي على خصائص CSS
            // مثلاً، إذا كنت تريد جعل الخط واضح وكبير وسميك وفي منتصف الصفحة ومرتب ومنظم، فتكتب:
            style={{
              color: "red",
              fontSize: "30px",
              fontWeight: "bold",
              textAlign: "center",
              margin: "auto",
            }}
          />
        );
      }
    });
};







 
  

  // use componentDidMount to fetch data from node server when the component mounts
  componentDidMount() {
    // use api.get to send a get request to /api endpoint
    api.get('/api')
      .then((res) => {
        // if the request is successful, set the message state to the data from the response
        this.setState({ message: res.data.message });
      })
      .catch((err) => {
        // if there is an error, log it and set the message state to an error message
        console.error(err);
        this.setState({ message: 'Something went wrong' });
      });



      window.addEventListener('resize', this.handleWindowResize);

     //this.fetchData();
    
     //this.handleLogin();

 

     const userId = Cookies.get('userId') || localStorage.getItem('userId');
      // التحقق من وجود token في الذاكرة المحلية
      const token = Cookies.get('token') || localStorage.getItem("token") 
    if (token) {
      this.setState({ isLoggedIn: true });
      // ارسل token إلى الواجهة البرمجية للحصول على بيانات المستخدم
     
    };




    this.getUserData();
   
  
    this.getRatingFromDatabase();
    
    //this.calculateRankings();
    //this.fetchBadgesReceived();
    //this.fetchBadges();
    this.getcurreuntBadges();
       
      }




      getcurreuntBadges = async () => {
        // الحصول على معرف المستخدم من localStorage أو sessionStorage
        const userId = Cookies.get("userId") || localStorage.getItem("userId");
    
        // إنشاء خيارات الطلب
        const options = {
          method: "GET", // طريقة الطلب
          headers: {
            "Content-Type": "application/json", // نوع المحتوى
            userId: userId // رأس يحمل معرف المستخدم
          }
        };
    
        try {
          // إرسال طلب إلى المسار /profilecurrent
          const response = await fetch("/profilecurrent", options);
    
          // التحقق من حالة الاستجابة
          if (response.ok) {
            // تحويل الاستجابة إلى جسون
            const data = await response.json();
    
            // تحديث الحالة بالبيانات المستلمة
            //console.log(data);
            this.setState({
              currentbadges: data,
              error: null,
              loading: false
            });
          } else {
            // رمي خطأ بحالة الاستجابة
            throw new Error(response.status);
          }
        } catch (err) {
          // التعامل مع الخطأ
          console.error(err);
          this.setState({
            currentbadges: null,
            error: err.message,
            loading: false
          });
        }
      };
    

      






         // تعريف الدالة التي تستدعي المسار
   getRatedAnimes = () => {
    // الحصول على معرف المستخدم من الكوكيز أو الذاكرة المحلية
    const userId = Cookies.get("userId") || localStorage.getItem("userId");
    // إرسال طلب get إلى المسار مع معرف المستخدم في الرأس
    axios
      .get("/ratedd", {
        headers: { userId: userId },
      })
      .then((response) => {
        // تحديث الحالة بالمستندات الموجودة
        this.setState({ ratedAnimes: response.data });
      })
      .catch((error) => {
        // التعامل مع الخطأ
        console.error(error);
      });
  };

  



  // تعريف دالة handleChange للمكون
  handleChangee = (event) => {
    // تحديث قيمة الحالة query بقيمة الحدث
    this.setState({ query: event.target.value });
    // استدعاء دالة searchCharacters مع قيمة الحدث
    this.searchAnimes(event.target.value);
  };

 // تعريف دالة addFavorite للمكون
addFavoritee = (name, imageUrl) => {
  // إنشاء كائن معطيات يحتوي على اسم الشخصية وصورتها
  const data = { name: name, imageUrl: imageUrl };
  // إرسال طلب POST إلى المسار '/addFavorite' مع الكائن data
  axios
    .post('/addFavoritee', data)
    .then((res) => {
      // إذا تم الطلب بنجاح، إظهار رسالة الاستجابة
      alert(res.data.message);
      // تحديث الحالة userdata بالشخصية المضافة إذا كانت موجودة في الاستجابة
      if (res.data.anime) {
        this.setState((prevState) => ({
          userdata: {
            ...prevState.userdata,
            favoriteAnimes: [
              ...prevState.userdata.favoriteAnimes,
              res.data.anime,
            ],
          },
        }));
      }
    })
    .catch((err) => {
      // إذا حدث خطأ، إظهار رسالة الخطأ
      alert(err.response.data.message);
    });
};




// تعريف دالة deleteFavorite للمكون
deleteFavoritee = (name) => {
  // إنشاء كائن معطيات يحتوي على اسم الشخصية ومعرف المستخدم
  const data = { name: name, userId: this.state.userdata._id };
  // إرسال طلب DELETE إلى المسار '/deleteFavorite' مع الكائن data
  axios
    .delete('/deleteFavoritee', { data: data })
    .then((res) => {
      // إذا تم الطلب بنجاح، إظهار رسالة الاستجابة
      alert(res.data.message);
      // تحديث الحالة userdata بحذف الشخصية من المصفوفة
      this.setState((prevState) => ({
        userdata: {
          ...prevState.userdata,
          favoriteAnimes: prevState.userdata.favoriteAnimes.filter(
            (anime) => anime.name !== name
          ),
        },
      }));
    })
    .catch((err) => {
      // إذا حدث خطأ، إظهار رسالة الخطأ
      alert(err.response.data.message);
    });
};

  // تعريف دالة searchCharacters للمكون
  searchAnimes = (query) => {
    // إرسال طلب GET إلى المسار '/search' مع استعلام البحث كمعامل
    axios
      .get('/search', { params: { q: query } })
      .then((res) => {
        // إذا تم الطلب بنجاح، تحديث الحالة بالنتائج
        this.setState({ results: res.data });
      })
      .catch((err) => {
        // إذا حدث خطأ، إظهار رسالة الخطأ
        alert(err.response.data.message);
      });
  };

   // تعريف دالة hideSuggestions للمكون
   hideSuggestionss = () => {
    // تغيير قيمة الحالة results إلى مصفوفة فارغة
    this.setState({ resultss: [] });
  };


  handleChangesocial = (event) => {
    this.setState({
      socialLink: event.target.value,
    });
  };
  handleSocial = async (event) => {
    event.preventDefault();
    try {
      // انتظر حتى يتم إرسال الطلب واستلام الرد
      const response = await axios.post("/social", { socialLink: this.state.socialLink });
      // اعرض رسالة النجاح
      console.log(response.data.message);
      // تحقق من إذا كان الرابط تم إضافته بنجاح
      if (response.data.socialLink) {
        // حدث الحالة بالرابط المضاف
        this.setState((prevState) => ({
          userdata: {
            ...prevState.userdata,
            socialLinks: [
              ...prevState.userdata.socialLinks,
              response.data.socialLink,
            ],
          },
          socialLink: "",
        }));
      }
    } catch (error) {
      // اعرض رسالة الخطأ
      alert(error.response.data.message);
    }
  };

  handleChangedeletesocial = (event) => {
    this.setState({
      socialName: event.target.value,
    });
  };

  // اجعل الدالة غير متزامنة
handledeletesocial = async (socialName) => {
  try {
    // انتظر حتى يتم إرسال الطلب واستلام الرد
    const response = await axios.delete(`/desocial`, {
      data: {
        name: socialName,
      },
    });
    // تحقق من حالة الرد
    if (response.status === 200) {
      // اعرض رسالة النجاح
      console.log(response.data.message);
      // تحديث الحالة لإزالة وسيلة التواصل التي تم حذفها
      this.setState((prevState) => ({
        userdata: {
          ...prevState.userdata,
          socialLinks: prevState.userdata.socialLinks.filter(
            (social) => social.name !== socialName
          ),
        },
      }));
    } else {
      // اعرض رسالة الخطأ
      alert("Something went wrong");
    }
  } catch (err) {
    // اعرض رسالة الخطأ
    alert(err.message);
  }
};




  // create a function to fetch the badges given by the current user
fetchBadges = () => {
  // get the user id from the local storage or any other source
  const userId = Cookies.get("userId") || localStorage.getItem("userId");

  // make a GET request to the backend route with the user id as a header
  axios
    .get("/getbadges", { headers: { userId } })
    .then((res) => {
      // if the request is successful, get the data from the response
      const { badges, numberOfBadgesGiven } = res.data;

      // update the state with the data
      this.setState({ badges, numberOfBadgesGiven });
      console.log("تم جلب الأوسمة الممنوحة بنجاح");
    })
    .catch((err) => {
      // if there is an error, handle it
      console.error(err);
      // you can also set an error state or show a message to the user
    });
};


// create a function to fetch the badges received by the current user
fetchBadgesReceived = () => {
  // get the user id from the local storage or any other source
  const userId = Cookies.get("userId") || localStorage.getItem("userId");

  // make a GET request to the backend route with the user id as a header
  axios
    .get("/getbadgesreceived", { headers: { userId } })
    .then((res) => {
      // if the request is successful, get the data from the response
      const { badges, numberOfBadgesReceived } = res.data;

      // update the state with the data
      this.setState({ badges, numberOfBadgesReceived });
      console.log("تم جلب الأوسمة المتلقاة بنجاح");
    })
    .catch((err) => {
      // if there is an error, handle it
      console.error(err);
      // you can also set an error state or show a message to the user
    });
};




// دالة استدعاء لمنح وسام شرفي لمستخدم آخر
giveBadge = async () => {
  // الحصول على معرف المستخدم المسجل دخوله حاليا من الكوكيز أو localStorage
  const giverId = Cookies.get("userId") || localStorage.getItem("userId");

  // الحصول على اسم المستخدم المستلم من الرابط
  const receiverUsername = new URLSearchParams(window.location.search).get(
    "username"
  );

  // الحصول على عدد وعنوان الوسام من الحالة
  const { number, title } = this.state;

  // إنشاء كائن البيانات الذي يحمل عدد وعنوان الوسام
  const data = {
    number,
    title,
  };

  // إنشاء خيارات الطلب
  const options = {
    method: "POST", // طريقة الطلب
    headers: {
      "Content-Type": "application/json", // نوع المحتوى
      userId: giverId, // رأس يحمل معرف المستخدم المانح
    },
    body: JSON.stringify(data), // تحويل البيانات إلى جسون
  };

  try {
    // إرسال طلب إلى المسار /givebadge مع اسم المستخدم المستلم كمعامل
    const response = await fetch(`/givebadge?username=${receiverUsername}`, options);

    // التحقق من حالة الاستجابة
    if (response.ok) {
      // تحويل الاستجابة إلى جسون
      const data = await response.json();

      // عرض رسالة النجاح
      alert(data.message);
      this.setState({ currentbadges: { ...this.state.currentbadges, ownbadges: data.newOwnBadges } });
    } else {
      // رمي خطأ بحالة الاستجابة
      throw new Error(response.status);
    }
  } catch (err) {
    
    // التعامل مع الخطأ
    console.error(err);
    alert("احرص على ان لايتعدى عنوان الوسام 30 حرفا او ان يكون العنوان فارغا");
  }
};


// call the function with the receiver username, the number and the title of the badge
// you can also get these values from the state or the user input
handleNumberChange = (e) => {
  this.setState({ number: e.target.value });
};

handleTitleChange = (e) => {
  this.setState({ title: e.target.value });
};

incrementNumber = () => {
  this.setState((prevState) => ({ number: prevState.number + 1 }));
};

decrementNumber = () => {
  this.setState((prevState) => ({ number: Math.max(prevState.number - 1, 0) }));
};







// دالة للتعامل مع حدث تغيير قيمة الإدخال
handleImageChangebackground = (e) => {
  // تحديد الصورة المختارة
  const image = e.target.files[0];
  // تغيير قيمة الحالة بالصورة المختارة
  this.setState({selectedImage: image}, () => {
    // استدعاء الدالة handleImageSubmit بعد تغيير الحالة
    this.handleImageSubmitt(e);
  });
}

// دالة لإرسال الطلب POST إلى المسار /updateImage
handleImageSubmitt = (e) => {
  // منع السلوك الافتراضي للحدث
  e.preventDefault();
  // إنشاء نموذج بيانات يحتوي على الصورة المختارة
  const formData = new FormData();
  formData.append('image', this.state.selectedImage);
  // تحديد الخيارات اللازمة للطلب
  const requestOptions = {
    method: 'POST',
    // إرسال الرقم التعريفي للمستخدم في الرأس
    headers: {'userId': this.props.userId},
    body: formData
  };
  // إرسال الطلب باستخدام الدالة fetch
  fetch('/updateBackground', requestOptions)
    .then(response => response.json())
    .then(data => {
      // التعامل مع الاستجابة
      // يمكنك عرض البيانات المحدثة أو رسالة النجاح
      // استبدال console.log ب alert
      alert(data.message);
      // تغيير قيمة الحالة التي تحتوي على رابط الصورة بالرابط الجديد
      this.setState({imageURI: data.imageURI});
    })
    .catch(error => {
      // التعامل مع الخطأ
      // يمكنك عرض رسالة الخطأ أو التنبيه
      // استبدال console.error ب alert
      alert(error);
    });
}



// دالة للتعامل مع حدث تغيير قيمة الإدخال
handleImageChangeavatar = (e) => {
  // تحديد الصورة المختارة
  const image = e.target.files[0];
  // تغيير قيمة الحالة بالصورة المختارة
  this.setState({selectedImage: image}, () => {
    // استدعاء الدالة handleImageSubmit بعد تغيير الحالة
    this.handleImageSubmittt(e);
  });
}

// دالة لإرسال الطلب POST إلى المسار /updateImage
handleImageSubmittt = (e) => {
  // منع السلوك الافتراضي للحدث
  e.preventDefault();
  // إنشاء نموذج بيانات يحتوي على الصورة المختارة
  const formData = new FormData();
  formData.append('image', this.state.selectedImage);
  // تحديد الخيارات اللازمة للطلب
  const requestOptions = {
    method: 'POST',
    // إرسال الرقم التعريفي للمستخدم في الرأس
    headers: {'userId': this.props.userId},
    body: formData
  };
  // إرسال الطلب باستخدام الدالة fetch
  fetch('/updateProfile', requestOptions)
    .then(response => response.json())
    .then(data => {
      // التعامل مع الاستجابة
      // يمكنك عرض البيانات المحدثة أو رسالة النجاح
      // استبدال console.log ب alert
      alert(data.message);
      // تغيير قيمة الحالة التي تحتوي على رابط الصورة بالرابط الجديد
      this.setState({imageURI: data.imageURI});
    })
    .catch(error => {
      // التعامل مع الخطأ
      // يمكنك عرض رسالة الخطأ أو التنبيه
      // استبدال console.error ب alert
      alert(error);
    });
}







      render() {

    


        const { showModal, animeData, data, error, showCards, isMobile, isActive, isCharActive, dataType, activeTab, showLogin,  message, isLoggedIn, menuOpen, profileImage, token, redirect, params, userdata, showExpanded, query, results, isOwnProfile, ratedCharacters, resultss, showExpandedd, showExpandeddd, selectedImage, selectedGenre, rating, ratingAverage, ratingCount, socialLink, showExpandedddd, socialName, socialLinks, updatedSocialLinks, showExpandeddddd, showExpandedddddd, number, title, currentbadges, loading, showExpandes  } = this.state;
        const { value } = this.state;
        

        return (

      

      

      
      
            <div>
                 
 {/* الظاهر للزائر العادي */}
 { this.state.userdata && !this.state.isOwnProfile &&   (
          <div className="profile-container">

<div>
<Tabs
          value={value}
          onChange={this.handleChangevalue}
          centered
          className="memorytab"
        
         
        >
          <Tab
            label="حائط الذكريات"
            style={{
              fontSize: '1.4rem',
              textShadow: '2px 2px 4px rgba(0, 0, 0, 1.3)',
               
              borderRadius: '15px',
              margin: '0 10px',
              padding: '10px 20px',
              color: '#ffffff',
             
              textdecoration: 'none',
              
            }}
          />
         
        
        </Tabs>
        {value === 0 && <Memoriess />}
       
</div>

<div className="badge-card">
        <div className="badge-info">
        
          <div className="badge-description">
            <p>حصل على {this.state.userdata.badges.numberofbadgesreceived} وسام شرفي</p>
          </div>
          { this.state.currentbadges && this.state.isLoggedIn &&   (
          <div className="badge-buttons" >
            <button className="give-badge-button" onClick={this.handleExpanddddd}>منح وسام</button>
            </div>
            )}
            <div className="badge-buttons" style={{
      
      marginTop: '100px',
     
    }}>
            <button className="badge-list-button" onClick={this.handleExpandddddd}>قائمة الأوسمة</button>
          </div>
          
        </div>
        {this.state.showExpandeddddd && (
        <div className="expanded-overlay">
          <div className="expanded-cardbadge">
  <button className="close-button" onClick={this.handleExpanddddd}>
    إغلاق
  </button>

  <div className="badge-count">
  <div className="badge-count-wrapper">
  <button className="badge-increment-button" >
          +
        </button>
    <span className="badge-count-text">{this.state.currentbadges.ownbadges}</span>
    <span className="badge-count-label">عدد الأوسمة القابلة للمنح</span>
  </div>
  
</div>

          <div className="badge-iconnn">
          <img src="https://i.imgur.com/Wtb7AUh.png" alt="شعار الوسام الشرفي" />
          </div>

          <div class="input-container">
    <div class="input-content">
      <div class="input-dist">
        <div class="input-type">
          <input
            type="text"
            value={title}
            onChange={this.handleTitleChange}
            className="badge-title-input"
            placeholder="عنوان الوسام"
            maxLength="50"
          />
</div>
</div>
      </div>
    </div>
  
<div className="badge-number-container">
  <button className="badge-decrement-button" onClick={this.decrementNumber}>
    -
  </button>
  <input
    type="text"
    value={`${number} Medal of honor`}
    readOnly
    className="badge-number-input"
  />
  <button className="badge-increment-button" onClick={this.incrementNumber}>
    +
  </button>
</div>

          <button className="give-badge-button" onClick={this.giveBadge}>
            تأكيد المنح
          </button>


          <div className="badge-iconnn" >
        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor" class="bi bi-exclamation-circle" viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
  <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z"/>
</svg>

شرح نظام اوسمه الشرف 
          <div className="badge-tooltippp">
            <p>نظام الاوسمه الشرفيه هو نظام مميز للتعبير عن تقديرك لأي شخص يعجبك في موقعنا مثلا ان تعجب بمراجعات احدهم وبذوقه وتمنحه وسام شرفي واللذي يؤثر بشكل كبير في مرتبته في ترتيب المحنكين ب4 اضعاف وكل حساب يكون لديه 3 اوسمه فقط بالبدايه ويمكنه زيادتهم عن طريق شراءالمزيد</p>
          </div>
          </div>

  
  <Box
    style={{
      position: 'relative',
      marginTop: '30px',
      backgroundColor: 'linear-gradient(to right, #000000, #ffffff)',
      marginBottom: '30px',
    }}
  ></Box>
</div>
          </div>
        )}


{this.state.showExpandedddddd && (
        <div className="expanded-overlay">
          <div className="expanded-card">
  <button className="close-button" onClick={this.handleExpandddddd}>
    إغلاق
  </button>

  
  <Box
    style={{
      position: 'relative',
      marginTop: '30px',
      backgroundColor: 'linear-gradient(to right, #000000, #ffffff)',
      marginBottom: '30px',
    }}
  >


<Tabs
          value={value}
          onChange={this.handleChangevalue}
          centered
          style={{
            margin: '0px auto',
    display: 'flex',
    fontWeight: 'bold',
    fontSize: '14px',
    color: '#ffffff',
    marginBottom: '10px',
    textShadow: '1px 1px 2px rgba(0, 0, 0, 0.3)',
    borderRadius: '25px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
    background: '#333333',
    height: 'auto',
            
          }}
        >
          <Tab
            label="أوسمة استلمها"
            style={{
              fontSize: '1.4rem',
              textShadow: '2px 2px 4px rgba(0, 0, 0, 1.3)',
                background: 'linear-gradient(to top, #ffffff, #000000)',
              borderRadius: '15px',
              margin: '0 10px',
              padding: '10px 20px',
              color: '#ffffff',
              boxShadow: '0 0 10px #fff, 0 0 20px #fff, 0 0 80px #0ff, 0 0 100px #0ff, 0 0 150px #0ff',
              textdecoration: 'none',
              
            }}
          />
          <Tab
            label="أوسمة منحها"
            style={{
              fontSize: '1.4rem',
              textShadow: '2px 2px 4px rgba(0, 0, 0, 1.3)',
                background: 'linear-gradient(to top, #ffffff, #000000)',
              borderRadius: '15px',
              margin: '0 10px',
              padding: '10px 20px',
              color: '#ffffff',
              boxShadow: '0 0 10px #fff, 0 0 20px #fff, 0 0 80px #0ff, 0 0 100px #0ff, 0 0 150px #0ff',
              textdecoration: 'none',
              
            }}
          />
        
        </Tabs>
        {value === 0 && <Badgereceivee />}
        {value === 1 && <Badgegivedd />}
       
  </Box>
</div>
          </div>
        )}



        <div className="badge-icon">
          <img src="https://i.imgur.com/Wtb7AUh.png" alt="شعار الوسام الشرفي" />
          </div>
          
        <div className="badge-icon" >
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-exclamation-circle" viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
  <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z"/>
</svg>
          <div className="badge-tooltip">
            <p>نظام الاوسمه الشرفيه هو نظام مميز للتعبير عن تقديرك لأي شخص يعجبك في موقعنا مثلا ان تعجب بمراجعات احدهم وبذوقه وتمنحه وسام شرفي واللذي يؤثر بشكل كبير في مرتبته في ترتيب المحنكين ب4 اضعاف وكل حساب يكون لديه 3 اوسمه فقط بالبدايه ويمنكه زيادتهم عن طريق شراءالمزيد</p>
          </div>
          </div>
      </div>







<div className="social-bar">
  <div className="social-buttons">
    {this.state.userdata.socialLinks.map((link, index) => (
      <a href={link.link} target="_blank" rel="noopener noreferrer" key={index}>
        <div className="social-button">
          <img src={link.image}  />
          <span>{link.name}</span>
        </div>
      </a>
    ))}
   

     
        
    
  </div>
  {this.state.userdata.socialLinks.length === 0 && (
    <div className="empty-messagee">
      <p>لم يتم اضافة اي وسائل تواصل اجتماعي لهذا الحساب </p>
    </div>
  )}
</div>








<div className="idcard-container">
  <div className="idcard">
    <h4 className="idcard-title">هوية المحنك</h4>
    <div className="idcard-content">
    <div class="rating-box">
  <p>متوسط تقييم هذا المحنك:</p>
  <div class="rating-value">{this.state.userdata.ratingAverage}</div>
</div>

<div class="rating-boxx">
  <p>عدد المقيمين:</p>
  <div class="rating-value">{this.state.userdata.ratingCount}</div>
</div>
      <h7>تقييمك لهذا المحنك</h7><Dropdown> 
          <Dropdown.Toggle variant="success" id="dropdown-basic" style={{ backgroundColor: '#333', color: '#fff', fontWeight: 'bold', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)', overflowY: 'auto' }}>
          {this.state.selectedGenre}
          </Dropdown.Toggle>

          <Dropdown.Menu style={{backgroundColor: 'rgba(0, 0, 0, 0.8)',  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)', border: 'none', overflowY: 'auto'}}>
           
            <Dropdown.Item style={{ color: '#fff', fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.8)', borderRadius: '0', borderBottom: '1px solid #666' }}
              onClick={this.rateUserr}
              value={0}
            
            >
              0/10
            </Dropdown.Item>
            <Dropdown.Item style={{ color: '#fff', fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.8)', borderRadius: '0', borderBottom: '1px solid #666' }}
              onClick={this.rateUserr}
              value={1}
            >
              1/10
            </Dropdown.Item>
            <Dropdown.Item style={{ color: '#fff', fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.8)', borderRadius: '0', borderBottom: '1px solid #666' }}
              onClick={this.rateUserr}
              value={2}
            >
              2/10
            </Dropdown.Item>
            <Dropdown.Item style={{ color: '#fff', fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.8)', borderRadius: '0', borderBottom: '1px solid #666' }}
              onClick={this.rateUserr}
              value={3}
            >
              3/10
            </Dropdown.Item>
            <Dropdown.Item style={{ color: '#fff', fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.8)', borderRadius: '0', borderBottom: '1px solid #666' }}
              onClick={this.rateUserr}
              value={4}
            >
              4/10
            </Dropdown.Item>
            <Dropdown.Item style={{ color: '#fff', fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.8)', borderRadius: '0', borderBottom: '1px solid #666' }}
              onClick={this.rateUserr}
              value={5}
            >
              5/10
            </Dropdown.Item>
            <Dropdown.Item style={{ color: '#fff', fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.8)', borderRadius: '0', borderBottom: '1px solid #666' }}
              onClick={this.rateUserr}
              value={6}
            >
              6/10
            </Dropdown.Item>
            <Dropdown.Item style={{ color: '#fff', fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.8)', borderRadius: '0', borderBottom: '1px solid #666' }}
              onClick={this.rateUserr}
              value={7}
            >
              7/10
            </Dropdown.Item>
            <Dropdown.Item style={{ color: '#fff', fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.8)', borderRadius: '0', borderBottom: '1px solid #666' }}
              onClick={this.rateUserr}
              value={8}
            >
              8/10
            </Dropdown.Item>
            <Dropdown.Item style={{ color: '#fff', fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.8)', borderRadius: '0', borderBottom: '1px solid #666' }}
              onClick={this.rateUserr}
              value={9}
            >
              9/10
            </Dropdown.Item>
            <Dropdown.Item style={{ color: '#fff', fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.8)', borderRadius: '0', borderBottom: '1px solid #666' }}
              onClick={this.rateUserr}
              value={10}
            >
              10/10
            </Dropdown.Item>
                      </Dropdown.Menu>
        </Dropdown>

        <div class="rank-box">
  <p>ترتيبه بين المحنكين هو</p>
  <div class="rank-value">#{this.state.userdata.ranking.rankNumber}</div>
</div>

        </div>

       

        </div>
       
</div>

          <div className="profile-header">
            <img
              src={this.state.userdata.profileImage}
              alt="Profile"
              className="profile-imagee"
            />
            <h2 className="username">{this.state.userdata.username}</h2>
          </div>
          <div className="profilebackground">
           <img
             src={this.state.userdata.profileBackground}
             
             className="background-image"
           />
          
         </div>
          <div className="points-container">
            <div className="points-column">
              <h4>عدد الانميات المقيمه</h4>
              <p>{this.state.userdata.points.numofratedanimes}</p>
            </div>
            <div className="points-column">
              <h4>عدد الشخصيات المقيمه</h4>
              <p>{this.state.userdata.points.numofratedchars}</p>
            </div>
            <div className="points-column">
              <h4>عدد الحلقات المقيمه</h4>
              <p>{this.state.userdata.points. numofratedweekeps}</p>
            </div>
            <div className="points-column">
              <h4>نسبه التقييمات الصفريه للحساب</h4>
              <p>{this.state.userdata.points.zeroratingpercent}%</p>
            </div>
            <div className="points-column">
              <h4>نسبه التقييمات العشريه للحساب</h4>
              <p>{this.state.userdata.points.tenratingpercent}%</p>
            </div>
            <div className="points-column">
              <h4>عدد جوائز محنك اليوم اللذي حصل عليها</h4>
              <p>{this.state.userdata.points.numofprizes}</p>
            </div>

          </div>


          <div className="favorite-character-container">
  <div className="favorite-character-boxxx">
    {this.state.userdata.similarCharacter.image.length > 0 ? (
      <>
        <h3 className="favorite-character-title">الشخصيه المشابهة</h3>
        <div className="favorite-character-image">
        <img
  src={this.state.userdata.similarCharacter.image}
  alt="صورة الشخصية المشابهة"
/>
        </div>
      
      
    
   

      </>
    ) : (<>

<h3 className="favorite-character-title">الشخصيه المشابهة</h3>

      <div className="no-favorite-characters">
        <h6>لم يتم إضافة صوره شخصيه مشابهه بعد</h6>
        
        
      </div>
      
      </>
    )}
  </div>
</div>



          <div className="favorite-character-container">
  <div className="favorite-character-box">
    {this.state.userdata.favoriteCharacters.length > 0 ? (
      <>
        <h3 className="favorite-character-title">الشخصيات المفضلة #1</h3>
        <div className="favorite-character-image">
          <img
            src={this.state.userdata.favoriteCharacters[0].image}
            alt={this.state.userdata.favoriteCharacters[0].name}
          />
        </div>
        <div className="favorite-character-name">
          <h3>{this.state.userdata.favoriteCharacters[0].name}</h3>
        </div>
        <div className="expand-button">
          <button onClick={this.handleExpand}>عرض جميع الشخصيات</button>
        </div>
        {this.state.showExpanded && (
  <div className="expanded-overlay">
    <div className="expanded-card">
      <button className="close-button" onClick={this.handleExpand}>
        إغلاق
      </button>
     <Box
        style={{
          position: 'relative',
          marginTop: '30px',
          
          backgroundColor: 'linear-gradient(to right, #000000, #ffffff)',
          marginBottom: '30px',
          
          
        }}
      >
        <Tabs
          value={value}
          onChange={this.handleChangevalue}
          centered
          style={{
            margin: '0px auto',
    display: 'flex',
    fontWeight: 'bold',
    fontSize: '14px',
    color: '#ffffff',
    marginBottom: '10px',
    textShadow: '1px 1px 2px rgba(0, 0, 0, 0.3)',
    borderRadius: '25px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
    background: '#333333',
    height: 'auto',
            
          }}
        >
          <Tab
            label="قائمة الشخصيات المفضلة"
            style={{
              fontSize: '1.4rem',
              textShadow: '2px 2px 4px rgba(0, 0, 0, 1.3)',
                background: 'linear-gradient(to top, #ffffff, #000000)',
              borderRadius: '15px',
              margin: '0 10px',
              padding: '10px 20px',
              color: '#ffffff',
              boxShadow: '0 0 10px #fff, 0 0 20px #fff, 0 0 80px #0ff, 0 0 100px #0ff, 0 0 150px #0ff',
              textdecoration: 'none',
              
            }}
          />
          <Tab
            label="جميع الشخصيات اللذي قيمها"
            style={{
              fontSize: '1.4rem',
              textShadow: '2px 2px 4px rgba(0, 0, 0, 1.3)',
                background: 'linear-gradient(to top, #ffffff, #000000)',
              borderRadius: '15px',
              margin: '0 10px',
              padding: '10px 20px',
              color: '#ffffff',
              boxShadow: '0 0 10px #fff, 0 0 20px #fff, 0 0 80px #0ff, 0 0 100px #0ff, 0 0 150px #0ff',
              textdecoration: 'none',
              
            }}
          />
           <Tab
            label="شخصيات اكرهها"
            style={{
              fontSize: '1.4rem',
              textShadow: '2px 2px 4px rgba(0, 0, 0, 1.3)',
                background: 'linear-gradient(to top, #ffffff, #000000)',
              borderRadius: '15px',
              margin: '0 10px',
              padding: '10px 20px',
              color: '#ffffff',
              boxShadow: '0 0 10px #fff, 0 0 20px #fff, 0 0 80px #0ff, 0 0 100px #0ff, 0 0 150px #0ff',
              textdecoration: 'none',
              
            }}
          />

<Tab
            label="قائمه الوايفوز"
            style={{
              fontSize: '1.4rem',
              textShadow: '2px 2px 4px rgba(0, 0, 0, 1.3)',
                background: 'linear-gradient(to top, #ffffff, #000000)',
              borderRadius: '15px',
              margin: '0 10px',
              padding: '10px 20px',
              color: '#ffffff',
              boxShadow: '0 0 10px #fff, 0 0 20px #fff, 0 0 80px #0ff, 0 0 100px #0ff, 0 0 150px #0ff',
              textdecoration: 'none',
              
            }}
          />
        
        </Tabs>
        {value === 0 && <FavPage />}
        {value === 1 && <RatePage />}
        {value === 2 && <HatePage />}
        {value === 3 && <WaifuPage />}
       
      </Box>
    </div>
  </div>
)}
      </>
    ) : (<>

<h3 className="favorite-character-title">الشخصيات المفضلة #1</h3>

      <div className="no-favorite-characters">
        <h6>لم يتم إضافة شخصيات مفضلة بعد</h6>
        
        <div className="expand-button">
          <button onClick={this.handleExpand}>عرض جميع الشخصيات المقيمه</button>
        </div>
        {this.state.showExpanded && (
  <div className="expanded-overlay">
    <div className="expanded-card">
      <button className="close-button" onClick={this.handleExpand}>
        إغلاق
      </button>
     <Box
        style={{
          position: 'relative',
          marginTop: '30px',
          
          backgroundColor: 'linear-gradient(to right, #000000, #ffffff)',
          marginBottom: '30px',
          
          
        }}
      >
        <Tabs
          value={value}
          onChange={this.handleChangevalue}
          centered
          style={{
            margin: '0px auto',
    display: 'flex',
    fontWeight: 'bold',
    fontSize: '14px',
    color: '#ffffff',
    marginBottom: '10px',
    textShadow: '1px 1px 2px rgba(0, 0, 0, 0.3)',
    borderRadius: '25px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
    background: '#333333',
    height: 'auto',
            
          }}
        >
          <Tab
            label="قائمة الشخصيات المفضلة"
            style={{
              fontSize: '1.4rem',
              textShadow: '2px 2px 4px rgba(0, 0, 0, 1.3)',
                background: 'linear-gradient(to top, #ffffff, #000000)',
              borderRadius: '15px',
              margin: '0 10px',
              padding: '10px 20px',
              color: '#ffffff',
              boxShadow: '0 0 10px #fff, 0 0 20px #fff, 0 0 80px #0ff, 0 0 100px #0ff, 0 0 150px #0ff',
              textdecoration: 'none',
              
            }}
          />
          <Tab
            label="جميع الشخصيات اللذي قيمها"
            style={{
              fontSize: '1.4rem',
              textShadow: '2px 2px 4px rgba(0, 0, 0, 1.3)',
                background: 'linear-gradient(to top, #ffffff, #000000)',
              borderRadius: '15px',
              margin: '0 10px',
              padding: '10px 20px',
              color: '#ffffff',
              boxShadow: '0 0 10px #fff, 0 0 20px #fff, 0 0 80px #0ff, 0 0 100px #0ff, 0 0 150px #0ff',
              textdecoration: 'none',
              
            }}
            />
            <Tab
             label="شخصيات اكرهها"
             style={{
               fontSize: '1.4rem',
               textShadow: '2px 2px 4px rgba(0, 0, 0, 1.3)',
                 background: 'linear-gradient(to top, #ffffff, #000000)',
               borderRadius: '15px',
               margin: '0 10px',
               padding: '10px 20px',
               color: '#ffffff',
               boxShadow: '0 0 10px #fff, 0 0 20px #fff, 0 0 80px #0ff, 0 0 100px #0ff, 0 0 150px #0ff',
               textdecoration: 'none',
               
             }}
           />
 
 <Tab
             label="قائمه الوايفوز"
             style={{
               fontSize: '1.4rem',
               textShadow: '2px 2px 4px rgba(0, 0, 0, 1.3)',
                 background: 'linear-gradient(to top, #ffffff, #000000)',
               borderRadius: '15px',
               margin: '0 10px',
               padding: '10px 20px',
               color: '#ffffff',
               boxShadow: '0 0 10px #fff, 0 0 20px #fff, 0 0 80px #0ff, 0 0 100px #0ff, 0 0 150px #0ff',
               textdecoration: 'none',
               
             }}
           />
         
         </Tabs>
         {value === 0 && <FavPage />}
         {value === 1 && <RatePage />}
         {value === 2 && <HatePage />}
         {value === 3 && <WaifuPage />}
        
       </Box>
    </div>
  </div>
)}
      </div>
      
      </>
    )}
  </div>
</div>
          {/* أضف المزيد من بيانات المستخدم هنا */}

          







          <div className="favorite-character-container">
  <div className="favorite-character-boxx">
    {this.state.userdata.favoriteAnimes.length > 0 ? (
      <>
        <h3 className="favorite-character-title">الانميات المفضلة #1</h3>
        <div className="favorite-character-image">
          <img
            src={this.state.userdata.favoriteAnimes[0].image}
            alt={this.state.userdata.favoriteAnimes[0].name}
          />
        </div>
        <div className="favorite-character-name">
          <h3>{this.state.userdata.favoriteAnimes[0].name}</h3>
        </div>
        <div className="expand-button">
          <button onClick={this.handleExpandd}>عرض جميع الانميات</button>
        </div>
        {this.state.showExpandedd && (
  <div className="expanded-overlay">
    <div className="expanded-card">
      <button className="close-button" onClick={this.handleExpandd}>
        إغلاق
      </button>
     <Box
        style={{
          position: 'relative',
          marginTop: '30px',
          
          backgroundColor: 'linear-gradient(to right, #000000, #ffffff)',
          marginBottom: '30px',
          
          
        }}
      >
        <Tabs
          value={value}
          onChange={this.handleChangevalue}
          centered
          style={{
            margin: '0px auto',
    display: 'flex',
    fontWeight: 'bold',
    fontSize: '14px',
    color: '#ffffff',
    marginBottom: '10px',
    textShadow: '1px 1px 2px rgba(0, 0, 0, 0.3)',
    borderRadius: '25px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
    background: '#333333',
    height: 'auto',
            
          }}
        >
          <Tab
            label="قائمة الانميات المفضلة"
            style={{
              fontSize: '1.4rem',
              textShadow: '2px 2px 4px rgba(0, 0, 0, 1.3)',
                background: 'linear-gradient(to top, #ffffff, #000000)',
              borderRadius: '15px',
              margin: '0 10px',
              padding: '10px 20px',
              color: '#ffffff',
              boxShadow: '0 0 10px #fff, 0 0 20px #fff, 0 0 80px #0ff, 0 0 100px #0ff, 0 0 150px #0ff',
              textdecoration: 'none',
              
            }}
          />
          <Tab
            label="جميع الانميات اللذي قيمها"
            style={{
              fontSize: '1.4rem',
              textShadow: '2px 2px 4px rgba(0, 0, 0, 1.3)',
                background: 'linear-gradient(to top, #ffffff, #000000)',
              borderRadius: '15px',
              margin: '0 10px',
              padding: '10px 20px',
              color: '#ffffff',
              boxShadow: '0 0 10px #fff, 0 0 20px #fff, 0 0 80px #0ff, 0 0 100px #0ff, 0 0 150px #0ff',
              textdecoration: 'none',
              
            }}
          />

<Tab
            label="انميات اكرهها"
            style={{
              fontSize: '1.4rem',
              textShadow: '2px 2px 4px rgba(0, 0, 0, 1.3)',
                background: 'linear-gradient(to top, #ffffff, #000000)',
              borderRadius: '15px',
              margin: '0 10px',
              padding: '10px 20px',
              color: '#ffffff',
              boxShadow: '0 0 10px #fff, 0 0 20px #fff, 0 0 80px #0ff, 0 0 100px #0ff, 0 0 150px #0ff',
              textdecoration: 'none',
              
            }}
          />
        
        </Tabs>
        {value === 0 && <FavPagee />}
        {value === 1 && <RatePagee />}
        {value === 2 && <HatePagee />}
      </Box>
    </div>
  </div>
)}
      </>
    ) : (<>

<h3 className="favorite-character-title">الانميات المفضلة #1</h3>

      <div className="no-favorite-characters">
        <h6>لم يتم إضافة انميات مفضلة بعد</h6>
        
        <div className="expand-button">
          <button onClick={this.handleExpandd}>عرض جميع الانميات المقيمه</button>
        </div>
        {this.state.showExpandedd && (
  <div className="expanded-overlay">
    <div className="expanded-card">
      <button className="close-button" onClick={this.handleExpandd}>
        إغلاق
      </button>
     <Box
        style={{
          position: 'relative',
          marginTop: '30px',
          
          backgroundColor: 'linear-gradient(to right, #000000, #ffffff)',
          marginBottom: '30px',
          
          
        }}
      >
        <Tabs
          value={value}
          onChange={this.handleChangevalue}
          centered
          style={{
            margin: '0px auto',
    display: 'flex',
    fontWeight: 'bold',
    fontSize: '14px',
    color: '#ffffff',
    marginBottom: '10px',
    textShadow: '1px 1px 2px rgba(0, 0, 0, 0.3)',
    borderRadius: '25px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
    background: '#333333',
    height: 'auto',
            
          }}
        >
          <Tab
            label="قائمة الانميات المفضلة"
            style={{
              fontSize: '1.4rem',
              textShadow: '2px 2px 4px rgba(0, 0, 0, 1.3)',
                background: 'linear-gradient(to top, #ffffff, #000000)',
              borderRadius: '15px',
              margin: '0 10px',
              padding: '10px 20px',
              color: '#ffffff',
              boxShadow: '0 0 10px #fff, 0 0 20px #fff, 0 0 80px #0ff, 0 0 100px #0ff, 0 0 150px #0ff',
              textdecoration: 'none',
              
            }}
          />
          <Tab
            label="جميع الانميات اللذي قيمها"
            style={{
              fontSize: '1.4rem',
              textShadow: '2px 2px 4px rgba(0, 0, 0, 1.3)',
                background: 'linear-gradient(to top, #ffffff, #000000)',
              borderRadius: '15px',
              margin: '0 10px',
              padding: '10px 20px',
              color: '#ffffff',
              boxShadow: '0 0 10px #fff, 0 0 20px #fff, 0 0 80px #0ff, 0 0 100px #0ff, 0 0 150px #0ff',
              textdecoration: 'none',
              
            }}
            />

<Tab
            label="انميات اكرهها"
            style={{
              fontSize: '1.4rem',
              textShadow: '2px 2px 4px rgba(0, 0, 0, 1.3)',
                background: 'linear-gradient(to top, #ffffff, #000000)',
              borderRadius: '15px',
              margin: '0 10px',
              padding: '10px 20px',
              color: '#ffffff',
              boxShadow: '0 0 10px #fff, 0 0 20px #fff, 0 0 80px #0ff, 0 0 100px #0ff, 0 0 150px #0ff',
              textdecoration: 'none',
              
            }}
          />
        
        </Tabs>
        {value === 0 && <FavPagee />}
        {value === 1 && <RatePagee />}
        {value === 2 && <HatePagee />}
      </Box>
    </div>
  </div>
)}
      </div>
      
      </>
    )}
  </div>
</div>
        </div>
          )}


          



          

          {/* الظاهر لصاحب الحساب */}
          {this.state.isLoggedIn && this.state.userdata && this.state.isOwnProfile && (

            
            
         <div className="profile-container">










<div>
<Tabs
          value={value}
          onChange={this.handleChangevalue}
          centered
          className="memorytab"
          
        >
          <Tab
            label="حائط الذكريات"
            style={{
              fontSize: '1.4rem',
              textShadow: '2px 2px 4px rgba(0, 0, 0, 1.3)',
               
              borderRadius: '15px',
              margin: '0 10px',
              padding: '10px 20px',
              color: '#ffffff',
             
              textdecoration: 'none',
              
            }}
          />
        
        
        </Tabs>
        {value === 0 && <Memories />}
       
</div>
<div className="badge-card">
        <div className="badge-info">
        
          <div className="badge-description">
            <p>حصل على {this.state.userdata.badges.numberofbadgesreceived} وسام شرفي</p>
          </div>
          <div className="badge-buttons">
            
            <button className="badge-list-button" onClick={this.handleExpandddddd}>قائمة الأوسمة</button>
          </div>
        </div>

        
  <div className="bbadge-count">
  <div className="bbadge-count-wrapper">
  <button className="badge-increment-button" >
          +
        </button>
    <span className="badge-count-text">{this.state.userdata.badges.ownbadges}</span>
    <span className="badge-count-label">عدد الأوسمة القابلة للمنح</span>
  </div>
  
</div>
       


{this.state.showExpandedddddd && (
        <div className="expanded-overlay">
          <div className="expanded-cardbadge">
  <button className="close-button" onClick={this.handleExpandddddd}>
    إغلاق
  </button>

  
  <Box
    style={{
      position: 'relative',
      marginTop: '30px',
      backgroundColor: 'linear-gradient(to right, #000000, #ffffff)',
      marginBottom: '30px',
    }}


    
  >


<Tabs
          value={value}
          onChange={this.handleChangevalue}
          centered
          style={{
            margin: '0px auto',
    display: 'flex',
    fontWeight: 'bold',
    fontSize: '14px',
    color: '#ffffff',
    marginBottom: '10px',
    textShadow: '1px 1px 2px rgba(0, 0, 0, 0.3)',
    borderRadius: '25px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
    background: '#333333',
    height: 'auto',
            
          }}
        >
          <Tab
            label="أوسمة استلمتها"
            style={{
              fontSize: '1.4rem',
              textShadow: '2px 2px 4px rgba(0, 0, 0, 1.3)',
                background: 'linear-gradient(to top, #ffffff, #000000)',
              borderRadius: '15px',
              margin: '0 10px',
              padding: '10px 20px',
              color: '#ffffff',
              boxShadow: '0 0 10px #fff, 0 0 20px #fff, 0 0 80px #0ff, 0 0 100px #0ff, 0 0 150px #0ff',
              textdecoration: 'none',
              
            }}
          />
          <Tab
            label="أوسمة منحتها"
            style={{
              fontSize: '1.4rem',
              textShadow: '2px 2px 4px rgba(0, 0, 0, 1.3)',
                background: 'linear-gradient(to top, #ffffff, #000000)',
              borderRadius: '15px',
              margin: '0 10px',
              padding: '10px 20px',
              color: '#ffffff',
              boxShadow: '0 0 10px #fff, 0 0 20px #fff, 0 0 80px #0ff, 0 0 100px #0ff, 0 0 150px #0ff',
              textdecoration: 'none',
              
            }}
          />
        
        </Tabs>
        {value === 0 && <Badgereceive />}
        {value === 1 && <Badgegived />}
       
  </Box>
</div>
          </div>
        )}



        <div className="badge-icon">
          <img src="https://i.imgur.com/Wtb7AUh.png" alt="شعار الوسام الشرفي" />
          </div>
          
        <div className="badge-icon" >
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-exclamation-circle" viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
  <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z"/>
</svg>
          <div className="badge-tooltip">
            <p>نظام الاوسمه الشرفيه هو نظام مميز للتعبير عن تقديرك لأي شخص يعجبك في موقعنا مثلا ان تعجب بمراجعات احدهم وبذوقه وتمنحه وسام شرفي واللذي يؤثر بشكل كبير في مرتبته في ترتيب المحنكين ب4 اضعاف وكل حساب يكون لديه 3 اوسمه فقط بالبدايه ويمكنه زيادتهم عن طريق شراءالمزيد</p>
          </div>
          </div>
      </div>






<div className="social-bar">
  <div className="social-buttons">
    {this.state.userdata.socialLinks.map((link, index) => (
      <a href={link.link} target="_blank" rel="noopener noreferrer" key={index}>
        <div className="social-button">
          <img src={link.image}  />
          <span>{link.name}</span>
        </div>
      </a>
    ))}
    {this.state.userdata.socialLinks.length < 7 && (
      <div className="add-button" onClick={this.handleExpandddd}>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-patch-plus-fill" viewBox="0 0 16 16">
          <path d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01zM8.5 6v1.5H10a.5.5 0 0 1 0 1H8.5V10a.5.5 0 0 1-1 0V8.5H6a.5.5 0 0 1 0-1h1.5V6a.5.5 0 0 1 1 0"/>
        </svg>
       
      </div>
      )}

      {this.state.showExpandedddd && (
        <div className="expanded-overlay">
          <div className="expanded-card">
  <button className="close-button" onClick={this.handleExpandddd}>
    إغلاق
  </button>
  <div className="social-form">
    <input
      type="text"
      placeholder="أدخل رابط وسيلة التواصل"
      value={this.state.socialLink}
      onChange={this.handleChangesocial}
    />
    <button onClick={this.handleSocial}>إضافة</button>
  </div>
  {this.state.userdata.socialLinks.map((link, index) => (
    <div key={index} className="social-buttonn">
      <a href={link.link} target="_blank" rel="noopener noreferrer">
        <img src={link.image} alt={link.name} />
       <span> {link.name}</span>
      </a>
      <button className="delete-button" onClick={() => this.handledeletesocial(link.name)}>حذف</button>
    </div>
      ))}
  <Box
    style={{
      position: 'relative',
      marginTop: '30px',
      backgroundColor: 'linear-gradient(to right, #000000, #ffffff)',
      marginBottom: '30px',
    }}
  ></Box>
</div>
          </div>
        )}
        
    
  </div>
  {this.state.userdata.socialLinks.length === 0 && (
    <div className="empty-message">
      <p>اوني تشان أضف روابط وسائل التواصل الاجتماعي الخاصة بك </p>
    </div>
  )}
</div>





<div className="idcard-container">
  <div className="idcard">
    <h4 className="idcard-title">هوية المحنك</h4>
    <div className="idcard-content">
    <div class="rating-box">
  <p>متوسط تقييم هذا المحنك:</p>
  <div class="rating-value">{this.state.userdata.ratingAverage}</div>
</div>

<div class="rating-boxx">
  <p>عدد المقيمين:</p>
  <div class="rating-value">{this.state.userdata.ratingCount}</div>
</div>


<div class="rank-box">
  <p>ترتيبه بين المحنكين هو</p>
  <div class="rank-value">#{this.state.userdata.ranking.rankNumber}</div>
</div>
      
        </div>

       

        </div>
       
</div>
         <div className="profile-header">
           <img
             src={this.state.userdata.profileImage}
             alt="Profile"
             className="profile-imagee"
           />
           <h2 className="username">{this.state.userdata.username}</h2>

           <div className="expand-buttonnnn">
          <button onClick={this.handleExpandes}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pen" viewBox="0 0 16 16" backgroundColor="grey">
  <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001m-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708z"/>
</svg></button>
        </div>
        {this.state.showExpandes && (
          
  <div className="expanded-overlay">
    <div className="expanded-card">
      <button className="close-button" onClick={this.handleExpandes}>
        إغلاق
      </button>
     

<div>

<input
  type="file"
  accept="image/jpg, image/jpeg, image/png, image/gif"
  onChange={this.handleImageChangebackground}
  // جعل العنصر مخفيا باستخدام خاصية display
  style={{display: 'none'}}
  // تعيين المرجع الذي يشير إلى العنصر input
  ref={this.fileInputBackground}
/>


<div className="expand-buttonq">

<button onClick={() => this.fileInputBackground.current.click()}>تحديث صوره خلفيه البروفايل</button>

</div>
</div>


<div>

<input
  type="file"
  accept="image/jpg, image/jpeg, image/png, image/gif"
  onChange={this.handleImageChangeavatar}
  // جعل العنصر مخفيا باستخدام خاصية display
  style={{display: 'none'}}
  // تعيين المرجع الذي يشير إلى العنصر input
  ref={this.fileInputAvatar}
/>


<div className="expand-buttonq">

<button onClick={() => this.fileInputAvatar.current.click()}>تحديث صوره البروفايل</button>

</div>
</div>
    
    </div>
  </div>
)}
         </div>

         <div className="profilebackground">
           <img
             src={this.state.userdata.profileBackground}
             
             className="background-image"
           />
          
         </div>
         <div className="points-container">
            <div className="points-column">
              <h4>عدد الانميات المقيمه</h4>
              <p>{this.state.userdata.points.numofratedanimes}</p>
            </div>
            <div className="points-column">
              <h4>عدد الشخصيات المقيمه</h4>
              <p>{this.state.userdata.points.numofratedchars}</p>
            </div>
            <div className="points-column">
              <h4>عدد الحلقات المقيمه</h4>
              <p>{this.state.userdata.points. numofratedweekeps}</p>
            </div>
            <div className="points-column">
              <h4>نسبه التقييمات الصفريه للحساب</h4>
              <p>{this.state.userdata.points.zeroratingpercent}%</p>
            </div>
            <div className="points-column">
              <h4>نسبه التقييمات العشريه للحساب</h4>
              <p>{this.state.userdata.points.tenratingpercent}%</p>
            </div>
            <div className="points-column">
              <h4>عدد جوائز محنك اليوم اللذي حصل عليها</h4>
              <p>{this.state.userdata.points.numofprizes}</p>
            </div>

          </div>




         <div className="favorite-character-container">
  <div className="favorite-character-boxxx">
    {this.state.userdata.similarCharacter.image.length > 0 ? (
      <>
        <h3 className="favorite-character-title">الشخصيه المشابهة</h3>
        <div className="favorite-character-image">
        <img
  src={this.state.userdata.similarCharacter.image}
  alt="صورة الشخصية المشابهة"
/>
        </div>
      
        <div className="expand-buttonnn">
          <button onClick={this.handleExpanddd}>تعديل</button>
        </div>
        {this.state.showExpandeddd && (
  <div className="expanded-overlay">
    <div className="expanded-card">
      <button className="close-button" onClick={this.handleExpanddd}>
        إغلاق
      </button>
      <div className="textx">
      <h6>
  المقصود بالشخصية المشابهة هي <strong> شخصية الأنمي المشابهة لك من ناحية الشخصية أو المظهر</strong>.
  <br />
  يمكنك وضع صورة <strong>كوسبلاي</strong> أو  <strong>رسمةانيميشن</strong> مشابهة لك خارج نطاق الأنميات.
  <br />
  يمكنك أيضًا استخدام <strong>فلاتر الذكاء الاصطناعي</strong> لتحويل صورة وجهك إلى صورة أنمي ووضعها هنا إن أردت.
</h6>
</div>

<div>

<input
  type="file"
  accept="image/jpg, image/jpeg, image/png, image/gif"
  onChange={this.handleImageChange}
  // جعل العنصر مخفيا باستخدام خاصية display
  style={{display: 'none'}}
  // تعيين المرجع الذي يشير إلى العنصر input
  ref={this.fileInput}
/>


<div className="expand-button">

<button onClick={() => this.fileInput.current.click()}>اختر صورة</button>

</div>
</div>
    
    </div>
  </div>
)}
      </>
    ) : (<>

<h3 className="favorite-character-title">الشخصيه المشابهة</h3>

      <div className="no-favorite-characters">
        <h6>لم يتم إضافة صوره شخصيه مشابهه بعد</h6>
        
        <div className="expand-buttonnn">
          <button onClick={this.handleExpanddd}>تعديل</button>
        </div>
        {this.state.showExpandeddd && (
  <div className="expanded-overlay">
    <div className="expanded-card">
      <button className="close-button" onClick={this.handleExpanddd}>
        إغلاق
      </button>
      <div className="textx">
      <h6>
  المقصود بالشخصية المشابهة هي <strong> شخصية الأنمي المشابهة لك من ناحية الشخصية أو المظهر</strong>.
  <br />
  يمكنك وضع صورة <strong>كوسبلاي</strong> أو  <strong>رسمةانيميشن</strong> مشابهة لك خارج نطاق الأنميات.
  <br />
  يمكنك أيضًا استخدام <strong>فلاتر الذكاء الاصطناعي</strong> لتحويل صورة وجهك إلى صورة أنمي ووضعها هنا إن أردت.
</h6>
</div>
<div>

<input
  type="file"
  accept="image/jpg, image/jpeg, image/png, image/gif"
  onChange={this.handleImageChange}
  // جعل العنصر مخفيا باستخدام خاصية display
  style={{display: 'none'}}
  // تعيين المرجع الذي يشير إلى العنصر input
  ref={this.fileInput}
/>


<div className="expand-button">

<button onClick={() => this.fileInput.current.click()}>اختر صورة</button>

</div>
</div>
    </div>
  </div>
)}
      </div>
      
      </>
    )}
  </div>
</div>




         <div className="favorite-character-container">
 <div className="favorite-character-box">
   {this.state.userdata.favoriteCharacters.length > 0 ? (
     <>
       <h3 className="favorite-character-title">الشخصيات المفضلة #1</h3>
       <div className="favorite-character-image">
         <img
           src={this.state.userdata.favoriteCharacters[0].image}
           alt={this.state.userdata.favoriteCharacters[0].name}
         />
       </div>
       <div className="favorite-character-name">
         <h3>{this.state.userdata.favoriteCharacters[0].name}</h3>
       </div>
       <div className="expand-button">
         <button onClick={this.handleExpand}>عرض جميع الشخصيات</button>
       </div>

       {this.state.showExpanded && (
  <div className="expanded-overlay">
    <div className="expanded-card">
      <button className="close-button" onClick={this.handleExpand}>
        إغلاق
      </button>
      
      
      <Box
        style={{
          position: 'relative',
          marginTop: '30px',
          
          backgroundColor: 'linear-gradient(to right, #000000, #ffffff)',
          marginBottom: '30px',
          
          
        }}
      >
        <Tabs
          value={value}
          onChange={this.handleChangevalue}
          centered
          style={{
            margin: '0px auto',
    display: 'flex',
    fontWeight: 'bold',
    fontSize: '14px',
    color: '#ffffff',
    marginBottom: '10px',
    textShadow: '1px 1px 2px rgba(0, 0, 0, 0.3)',
    borderRadius: '25px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
    background: '#333333',
    height: 'auto',
            
          }}
        >
          <Tab
            label="قائمة الشخصيات المفضلة"
            style={{
              fontSize: '1.4rem',
              textShadow: '2px 2px 4px rgba(0, 0, 0, 1.3)',
                background: 'linear-gradient(to top, #ffffff, #000000)',
              borderRadius: '15px',
              margin: '0 10px',
              padding: '5px 20px',
              color: '#ffffff',
              boxShadow: '0 0 10px #fff, 0 0 20px #fff, 0 0 80px #0ff, 0 0 100px #0ff, 0 0 150px #0ff',
              textdecoration: 'none',
              
            }}
          />
          <Tab
            label="جميع الشخصيات اللذي قيمها"
            style={{
              fontSize: '1.4rem',
              textShadow: '2px 2px 4px rgba(0, 0, 0, 1.3)',
                background: 'linear-gradient(to top, #ffffff, #000000)',
              borderRadius: '15px',
              margin: '0 10px',
              padding: '10px 20px',
              color: '#ffffff',
              boxShadow: '0 0 10px #fff, 0 0 20px #fff, 0 0 80px #0ff, 0 0 100px #0ff, 0 0 150px #0ff',
              textdecoration: 'none',
              
            }}
            />
            <Tab
             label="شخصيات اكرهها"
             style={{
               fontSize: '1.4rem',
               textShadow: '2px 2px 4px rgba(0, 0, 0, 1.3)',
                 background: 'linear-gradient(to top, #ffffff, #000000)',
               borderRadius: '15px',
               margin: '0 10px',
               padding: '10px 20px',
               color: '#ffffff',
               boxShadow: '0 0 10px #fff, 0 0 20px #fff, 0 0 80px #0ff, 0 0 100px #0ff, 0 0 150px #0ff',
               textdecoration: 'none',
               
             }}
           />
 
 <Tab
             label="قائمه الوايفوز"
             style={{
               fontSize: '1.4rem',
               textShadow: '2px 2px 4px rgba(0, 0, 0, 1.3)',
                 background: 'linear-gradient(to top, #ffffff, #000000)',
               borderRadius: '15px',
               margin: '0 10px',
               padding: '10px 20px',
               color: '#ffffff',
               boxShadow: '0 0 10px #fff, 0 0 20px #fff, 0 0 80px #0ff, 0 0 100px #0ff, 0 0 150px #0ff',
               textdecoration: 'none',
               
             }}
           />
         
         </Tabs>
         {value === 0 && <FavPage />}
         {value === 1 && <RatePage />}
         {value === 2 && <HatePage />}
         {value === 3 && <WaifuPage />}
        
       </Box>
    </div>
  </div>
)}
     </>
   ) : (<>
   <h3 className="favorite-character-title">الشخصيات المفضلة #1</h3>
     <div className="no-favorite-characters">
       <h6>لم يتم إضافة شخصيات مفضلة بعد</h6>
       
     </div>
     <div className="expandd-button">
         <button onClick={this.handleExpand}>أضف توب شخصياتك المفضله عشان مايحسبونك عديم ذوق</button>
       </div>
       {this.state.showExpanded && (
  <div className="expanded-overlay">
    <div className="expanded-card">
      <button className="close-button" onClick={this.handleExpand}>
        إغلاق
      </button>
      <Box
        style={{
          position: 'relative',
          marginTop: '30px',
          
          backgroundColor: 'linear-gradient(to right, #000000, #ffffff)',
          marginBottom: '30px',
          
          
        }}
      >
        <Tabs
          value={value}
          onChange={this.handleChangevalue}
          centered
          style={{
            margin: '0px auto',
    display: 'flex',
    fontWeight: 'bold',
    fontSize: '14px',
    color: '#ffffff',
    marginBottom: '10px',
    textShadow: '1px 1px 2px rgba(0, 0, 0, 0.3)',
    borderRadius: '25px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
    background: '#333333',
    height: 'auto',
            
          }}
        >
          <Tab
            label="قائمة الشخصيات المفضلة"
            style={{
              fontSize: '1.4rem',
              textShadow: '2px 2px 4px rgba(0, 0, 0, 1.3)',
                background: 'linear-gradient(to top, #ffffff, #000000)',
              borderRadius: '15px',
              margin: '0 10px',
              padding: '10px 20px',
              color: '#ffffff',
              boxShadow: '0 0 10px #fff, 0 0 20px #fff, 0 0 80px #0ff, 0 0 100px #0ff, 0 0 150px #0ff',
              textdecoration: 'none',
              transition: 'transform 0.3s ease, background 0.3s ease',
            }}
            classes={{
              root: 'tab-root',
              selected: 'tab-selected',
            }}
          />
          <Tab
            label="جميع الشخصيات اللذي قيمها"
            style={{
              fontSize: '1.4rem',
              textShadow: '2px 2px 4px rgba(0, 0, 0, 1.3)',
                background: 'linear-gradient(to top, #ffffff, #000000)',
              borderRadius: '15px',
              margin: '0 10px',
              padding: '10px 20px',
              color: '#ffffff',
              boxShadow: '0 0 10px #fff, 0 0 20px #fff, 0 0 80px #0ff, 0 0 100px #0ff, 0 0 150px #0ff',
              textdecoration: 'none',
              transition: 'transform 0.3s ease, background 0.3s ease',
            }}
            classes={{
              root: 'tab-root',
              selected: 'tab-selected',
            }}
            />
            <Tab
             label="شخصيات اكرهها"
             style={{
               fontSize: '1.4rem',
               textShadow: '2px 2px 4px rgba(0, 0, 0, 1.3)',
                 background: 'linear-gradient(to top, #ffffff, #000000)',
               borderRadius: '15px',
               margin: '0 10px',
               padding: '10px 20px',
               color: '#ffffff',
               boxShadow: '0 0 10px #fff, 0 0 20px #fff, 0 0 80px #0ff, 0 0 100px #0ff, 0 0 150px #0ff',
               textdecoration: 'none',
               
             }}
           />
 
 <Tab
             label="قائمه الوايفوز"
             style={{
               fontSize: '1.4rem',
               textShadow: '2px 2px 4px rgba(0, 0, 0, 1.3)',
                 background: 'linear-gradient(to top, #ffffff, #000000)',
               borderRadius: '15px',
               margin: '0 10px',
               padding: '10px 20px',
               color: '#ffffff',
               boxShadow: '0 0 10px #fff, 0 0 20px #fff, 0 0 80px #0ff, 0 0 100px #0ff, 0 0 150px #0ff',
               textdecoration: 'none',
               
             }}
           />
         
         </Tabs>
         {value === 0 && <FavPage />}
         {value === 1 && <RatePage />}
         {value === 2 && <HatePage />}
         {value === 3 && <WaifuPage />}
        
       </Box>
    </div>
  </div>
)}
     </>
   )}
 </div>
</div>
         {/* أضف المزيد من بيانات المستخدم هنا */}










         <div className="favorite-character-container">
 <div className="favorite-character-boxx">
   {this.state.userdata.favoriteAnimes.length > 0 ? (
     <>
       <h3 className="favorite-character-title">الانميات المفضلة #1</h3>
       <div className="favorite-character-image">
         <img
           src={this.state.userdata.favoriteAnimes[0].image}
           alt={this.state.userdata.favoriteAnimes[0].name}
         />
       </div>
       <div className="favorite-character-name">
         <h3>{this.state.userdata.favoriteAnimes[0].name}</h3>
       </div>
       <div className="expand-button">
         <button onClick={this.handleExpandd}>عرض جميع الانميات</button>
       </div>

       {this.state.showExpandedd && (
  <div className="expanded-overlay">
    <div className="expanded-card">
      <button className="close-button" onClick={this.handleExpandd}>
        إغلاق
      </button>
      
      
      <Box
        style={{
          position: 'relative',
          marginTop: '30px',
          
          backgroundColor: 'linear-gradient(to right, #000000, #ffffff)',
          marginBottom: '30px',
          
          
        }}
      >
        <Tabs
          value={value}
          onChange={this.handleChangevalue}
          centered
          style={{
            margin: '0px auto',
    display: 'flex',
    fontWeight: 'bold',
    fontSize: '14px',
    color: '#ffffff',
    marginBottom: '10px',
    textShadow: '1px 1px 2px rgba(0, 0, 0, 0.3)',
    borderRadius: '25px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
    background: '#333333',
    height: 'auto',
            
          }}
        >
          <Tab
            label="قائمة الانميات المفضلة"
            style={{
              fontSize: '1.4rem',
              textShadow: '2px 2px 4px rgba(0, 0, 0, 1.3)',
                background: 'linear-gradient(to top, #ffffff, #000000)',
              borderRadius: '15px',
              margin: '0 10px',
              padding: '10px 20px',
              color: '#ffffff',
              boxShadow: '0 0 10px #fff, 0 0 20px #fff, 0 0 80px #0ff, 0 0 100px #0ff, 0 0 150px #0ff',
              textdecoration: 'none',
              
            }}
          />
          <Tab
            label="جميع الانميات اللذي قيمها"
            style={{
              fontSize: '1.4rem',
              textShadow: '2px 2px 4px rgba(0, 0, 0, 1.3)',
                background: 'linear-gradient(to top, #ffffff, #000000)',
              borderRadius: '15px',
              margin: '0 10px',
              padding: '10px 20px',
              color: '#ffffff',
              boxShadow: '0 0 10px #fff, 0 0 20px #fff, 0 0 80px #0ff, 0 0 100px #0ff, 0 0 150px #0ff',
              textdecoration: 'none',
              
            }}
          />

<Tab
            label="انميات اكرهها"
            style={{
              fontSize: '1.4rem',
              textShadow: '2px 2px 4px rgba(0, 0, 0, 1.3)',
                background: 'linear-gradient(to top, #ffffff, #000000)',
              borderRadius: '15px',
              margin: '0 10px',
              padding: '10px 20px',
              color: '#ffffff',
              boxShadow: '0 0 10px #fff, 0 0 20px #fff, 0 0 80px #0ff, 0 0 100px #0ff, 0 0 150px #0ff',
              textdecoration: 'none',
              
            }}
          />
        
        </Tabs>
        {value === 0 && <FavPagee />}
        {value === 1 && <RatePagee />}
        {value === 2 && <HatePagee />}
       
      </Box>
    </div>
  </div>
)}
     </>
   ) : (<>
   <h3 className="favorite-character-title">الانميات المفضلة #1</h3>
     <div className="no-favorite-characters">
       <h6>لم يتم إضافة انميات مفضلة بعد</h6>
       
     </div>
     <div className="expandd-button">
         <button onClick={this.handleExpandd}>لولوش ڤي بريطانيا يأمرك ان تخبره بالانمي المفضل لديك</button>
       </div>
       {this.state.showExpandedd && (
  <div className="expanded-overlay">
    <div className="expanded-card">
      <button className="close-button" onClick={this.handleExpandd}>
        إغلاق
      </button>
      <Box
        style={{
          position: 'relative',
          marginTop: '30px',
          
          backgroundColor: 'linear-gradient(to right, #000000, #ffffff)',
          marginBottom: '30px',
          
          
        }}
      >
        <Tabs
          value={value}
          onChange={this.handleChangevalue}
          centered
          style={{
            margin: '0px auto',
    display: 'flex',
    fontWeight: 'bold',
    fontSize: '14px',
    color: '#ffffff',
    marginBottom: '10px',
    textShadow: '1px 1px 2px rgba(0, 0, 0, 0.3)',
    borderRadius: '25px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
    background: '#333333',
    height: 'auto',
            
          }}
        >
          <Tab
            label="قائمة الانميات المفضلة"
            style={{
              fontSize: '1.4rem',
              textShadow: '2px 2px 4px rgba(0, 0, 0, 1.3)',
                background: 'linear-gradient(to top, #ffffff, #000000)',
              borderRadius: '15px',
              margin: '0 10px',
              padding: '10px 20px',
              color: '#ffffff',
              boxShadow: '0 0 10px #fff, 0 0 20px #fff, 0 0 80px #0ff, 0 0 100px #0ff, 0 0 150px #0ff',
              textdecoration: 'none',
              transition: 'transform 0.3s ease, background 0.3s ease',
            }}
           
          />
          <Tab
            label="جميع الانميات اللذي قيمها"
            style={{
              fontSize: '1.4rem',
              textShadow: '2px 2px 4px rgba(0, 0, 0, 1.3)',
                background: 'linear-gradient(to top, #ffffff, #000000)',
              borderRadius: '15px',
              margin: '0 10px',
              padding: '10px 20px',
              color: '#ffffff',
              boxShadow: '0 0 10px #fff, 0 0 20px #fff, 0 0 80px #0ff, 0 0 100px #0ff, 0 0 150px #0ff',
              textdecoration: 'none',
              transition: 'transform 0.3s ease, background 0.3s ease',
            }}
            
            />

            <Tab
                        label="انميات اكرهها"
                        style={{
                          fontSize: '1.4rem',
                          textShadow: '2px 2px 4px rgba(0, 0, 0, 1.3)',
                            background: 'linear-gradient(to top, #ffffff, #000000)',
                          borderRadius: '15px',
                          margin: '0 10px',
                          padding: '10px 20px',
                          color: '#ffffff',
                          boxShadow: '0 0 10px #fff, 0 0 20px #fff, 0 0 80px #0ff, 0 0 100px #0ff, 0 0 150px #0ff',
                          textdecoration: 'none',
                          
                        }}
                      />
                    
                    </Tabs>
                    {value === 0 && <FavPagee />}
                    {value === 1 && <RatePagee />}
                    {value === 2 && <HatePagee />}
                   
                  </Box>
    </div>
  </div>
)}
     </>
   )}
 </div>
</div>


       </div>
        )}

                </div>


);
}
}


                export default Profilepage;